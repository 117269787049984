import produce from "immer";
import TSW from "../TSW/TSW";
import {AuditLogQuery, AuditLogQueryResposne, ZWaveActionType} from "genius-hub-types";
import {actionZWaveCommands, GOT_HUB_DATA, GOT_HUB_DATA_FAIL, GOT_HUB_DEVICE_DATA} from "./hubs";
import {getHubProxyByHubName} from "./hubsCache";
import {push} from "redux-first-history";
import {beepNegative, beepPositive} from "../utils/beep";
import {ConnectionStatus} from "genius-hub-proxy";
import {HubDataType} from "./hubTypes";


/**********************
 * ACTION CONSTANTS   *
 *********************/

const UPDATE_TOOLS_STATE = "UPDATE_TOOLS_STATE";
const SCANNER_RESULT = "SCANNER_RESULT";
const UPDATE_AUDIT_LOG_QUERY_RESULT = "UPDATE_AUDIT_LOG_QUERY_RESULT";

/**********************
 * INTERFACES         *
 *********************/

export interface ToolsState {
    scannerServerURI: string,
    scannerResult: { success: boolean, message: string }
    scannerPassword: string,
    wordpressUsername: string,
    wordpressPassword: string,
    wordpressToken: string,
    auditLogQueryResponse: AuditLogQueryResposne
}

/**********************
 * ACTION CREATORS    *
 *********************/

export const actionUpdateToolsState = (update: Partial<ToolsState>) => {
    return {
        type: UPDATE_TOOLS_STATE,
        payload: {
            update
        }
    }
}

export const actionSendScanResult = (device: string, eui64: string) => {

    return async (dispatch: any, getState: any) => {
        // debugger
        const activeHubName = getState().app.activeHubName;
        const state = getState();
        const hubs = state.hubs;
        if (hubs && hubs.hasOwnProperty(activeHubName)) {
            try {
                fetch(`https://${state.tools.scannerServerURI}/inventory/add_eui64`, {
                    headers: {"Content-Type": "application/json; charset=utf-8"},
                    method: 'POST',
                    body: JSON.stringify({
                        uid: hubs[activeHubName].data.version.UID,
                        eui64,
                        device,
                        password: state.tools.scannerPassword
                    })
                }).then((response) => {

                    // console.debug("fetch result", response.status);

                    if (response.status === 200) {
                        beepPositive();
                        dispatch({
                            type: SCANNER_RESULT, payload: {
                                success: true,
                                message: `${eui64} OK`
                            }
                        });
                    } else if (response.status !== 200) {
                        console.warn("Could not save scan result")
                        beepNegative();
                        response.text().then( (message) => dispatch({
                            type: SCANNER_RESULT, payload: {
                                success: false,
                                message: `${eui64} problem - ${message}`
                            }
                        }));

                    }
                })

            } catch (err: any) {
                console.error("Could not send scan result:", JSON.stringify(err))
                beepNegative();
                dispatch({
                    type: SCANNER_RESULT, payload: {
                        success: false,
                        message: `cannot connect to server: ${JSON.stringify(err)}`
                    }
                });


            }
        }
    }
}

export const actionQueryAuditLog = (query: AuditLogQuery, hubName?: string) => {

    return async (dispatch: any, getState: any) => {
        let hubProxy;
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        try {
            hubProxy = await getHubProxyByHubName(activeHubName, hubs);// Hub(hub);
        } catch(err: any) {
            console.error(`actionQueryAuditLog: ${err.message} - ${activeHubName}`);
            return;
        }

        const data = await hubProxy.hubAPI.auditLogGetLogEvents(query)
        if (data.connectionStatus === ConnectionStatus.OK) {
            dispatch({
                type: UPDATE_AUDIT_LOG_QUERY_RESULT,
                payload: data.data
            })
        } else {
            const errMsg = `actionQueryAuditLog(${query}): ${ConnectionStatus[data.connectionStatus]}`;
            console.error(errMsg);
        }
    }
}


/**********************
 * REDUCERS           *
 *********************/

function createToolsState(initialState?: Partial<ToolsState>): ToolsState {
    const defaultState: ToolsState = {
        scannerServerURI: 'http://10.0.0.11:8085/',
        scannerResult: {success: true, message: ''},
        scannerPassword: '',
        wordpressUsername: '',
        wordpressPassword: '',
        wordpressToken: '',
        auditLogQueryResponse: {
            complete: false,
            sessions: []
        }
    }

    return (!!initialState) ? {...defaultState, ...initialState} : defaultState;
}

export const initialState = createToolsState();

export default produce((state = initialState, action): ToolsState => {

    switch (action.type) {

        case UPDATE_TOOLS_STATE:
            const iter = Object.entries(action.payload.update);
            for (let i of iter) {
                const k = i[0], v = i[1];
                state[k] = v;
            }
            break;

        case SCANNER_RESULT:
            state.scannerResult = {...action.payload};
            break;

        case UPDATE_AUDIT_LOG_QUERY_RESULT:
            state.auditLogQueryResponse = action.payload;
            console.debug(`Audit log response: ${JSON.stringify(action.payload)}`);
            break;

        default:
            return state;
    }
});
