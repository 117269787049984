"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regex_hub_c_mac = exports.regex_hub_auth_string_unregistered = exports.regex_hub_auth_string = exports.regex_hub_uid = exports.regex_hub_postcode = exports.regex_hub_phone = exports.regex_hub_email = exports.regex_hub_lastname = exports.regex_hub_firstname = exports.regex_hub_signature = exports.regex_hub_password = exports.regex_hub_username = exports.regex_zwave_homeid = exports.regex_zigbee_eui64 = exports.regex_zigbee_pan = void 0;
exports.regex_zigbee_pan = /ZB-[0-9a-fA-F]{16}/;
exports.regex_zigbee_eui64 = /0x[0-9a-fA-F]{16}/;
exports.regex_zwave_homeid = /0x[0-9a-fA-F]{8}/;
exports.regex_hub_username = /[a-zA-Z0-9_@.]{4,32}/;
exports.regex_hub_password = /[a-zA-Z0-9_@.!£$%^&*()]{6,32}/;
exports.regex_hub_signature = /[a-zA-Z0-9]{64}/;
exports.regex_hub_firstname = /[a-zA-Z0-9.\-\s]{1, 32}/;
exports.regex_hub_lastname = /[a-zA-Z0-9.\-\s]{1, 32}/;
exports.regex_hub_email = /^\S+@\S+\.\S+$/;
exports.regex_hub_phone = /^[0-9\s+]+$/;
exports.regex_hub_postcode = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/i;
// No HUB-Bs!
// export const regex_hub_uid = /^(?:230[01][a-fA-F0-9]{4})$|^(?:[a-fA-F0-9]{6})$/;
exports.regex_hub_uid = /^230[01][a-fA-F0-9]{4}$/;
exports.regex_hub_auth_string = /([a-zA-Z0-9_@.]{4,32}):([0-9a-zA-Z]{64})/;
exports.regex_hub_auth_string_unregistered = /^((?:\*B8:27:EB:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2})|(?:\*18:CC:23:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2})):([0-9a-fA-F]{64}|)$/i;
exports.regex_hub_c_mac = /18:[cC]{2}:23:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}/;
