"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoneManagerOptions = exports.DHWControlStrategy = exports.SetpointControlStrategy = exports.ZoneCalibrationStatus = exports.ZoneOptions = exports.Options = exports.ZoneJSON_Flags = exports.ZoneDisplayFlag = exports.ZoneKit = exports.ZoneMode = exports.ZoneFlags = exports.ZoneSubType = exports.ZoneTypeNames = exports.ZoneType = void 0;
var ZoneType;
(function (ZoneType) {
    ZoneType[ZoneType["Manager"] = 1] = "Manager";
    ZoneType[ZoneType["OnOffTimer"] = 2] = "OnOffTimer";
    ZoneType[ZoneType["ControlSP"] = 3] = "ControlSP";
    ZoneType[ZoneType["ControlOnOffPID"] = 4] = "ControlOnOffPID";
    ZoneType[ZoneType["TPI"] = 5] = "TPI";
    ZoneType[ZoneType["Surrogate"] = 6] = "Surrogate";
    ZoneType[ZoneType["OpenTherm"] = 7] = "OpenTherm";
})(ZoneType || (exports.ZoneType = ZoneType = {}));
exports.ZoneTypeNames = {};
exports.ZoneTypeNames[ZoneType.Manager] = "Whole House";
exports.ZoneTypeNames[ZoneType.OnOffTimer] = "On / Off";
exports.ZoneTypeNames[ZoneType.ControlSP] = "Radiator";
exports.ZoneTypeNames[ZoneType.ControlOnOffPID] = "Radiator TPI (N/A)";
exports.ZoneTypeNames[ZoneType.TPI] = "TPI";
exports.ZoneTypeNames[ZoneType.Surrogate] = "Group";
exports.ZoneTypeNames[ZoneType.OpenTherm] = "OpenTherm";
var ZoneSubType;
(function (ZoneSubType) {
    ZoneSubType[ZoneSubType["WetUFH"] = 0] = "WetUFH";
    ZoneSubType[ZoneSubType["OpenThermHeating"] = 1] = "OpenThermHeating";
    ZoneSubType[ZoneSubType["DHW"] = 2] = "DHW";
})(ZoneSubType || (exports.ZoneSubType = ZoneSubType = {}));
var ZoneFlags;
(function (ZoneFlags) {
    ZoneFlags[ZoneFlags["Frost"] = 1] = "Frost";
    ZoneFlags[ZoneFlags["Timer"] = 2] = "Timer";
    ZoneFlags[ZoneFlags["Footprint"] = 4] = "Footprint";
    ZoneFlags[ZoneFlags["Boost"] = 8] = "Boost";
    ZoneFlags[ZoneFlags["Away"] = 16] = "Away";
    ZoneFlags[ZoneFlags["WarmupAuto"] = 32] = "WarmupAuto";
    ZoneFlags[ZoneFlags["WarmupManual"] = 64] = "WarmupManual";
    ZoneFlags[ZoneFlags["Reactive"] = 128] = "Reactive";
    ZoneFlags[ZoneFlags["Linked"] = 256] = "Linked";
    ZoneFlags[ZoneFlags["WeatherComp"] = 512] = "WeatherComp";
    ZoneFlags[ZoneFlags["Temps"] = 1024] = "Temps";
    ZoneFlags[ZoneFlags["TPI"] = 2048] = "TPI";
})(ZoneFlags || (exports.ZoneFlags = ZoneFlags = {}));
var ZoneMode;
(function (ZoneMode) {
    ZoneMode[ZoneMode["None"] = 0] = "None";
    ZoneMode[ZoneMode["Off"] = 1] = "Off";
    ZoneMode[ZoneMode["Timer"] = 2] = "Timer";
    ZoneMode[ZoneMode["Footprint"] = 4] = "Footprint";
    ZoneMode[ZoneMode["Away"] = 8] = "Away";
    ZoneMode[ZoneMode["Boost"] = 16] = "Boost";
    ZoneMode[ZoneMode["Early"] = 32] = "Early";
    ZoneMode[ZoneMode["Test"] = 64] = "Test";
    ZoneMode[ZoneMode["Linked"] = 128] = "Linked";
    ZoneMode[ZoneMode["Other"] = 256] = "Other";
    ZoneMode[ZoneMode["Calibrate"] = 32768] = "Calibrate";
})(ZoneMode || (exports.ZoneMode = ZoneMode = {}));
var ZoneKit;
(function (ZoneKit) {
    // monitor only - PSP
    // timer mode only - valve
    // Footprint - valve + PSP
    ZoneKit[ZoneKit["Temp"] = 1] = "Temp";
    ZoneKit[ZoneKit["Valve"] = 2] = "Valve";
    ZoneKit[ZoneKit["PIR"] = 4] = "PIR";
    ZoneKit[ZoneKit["Power"] = 8] = "Power";
    ZoneKit[ZoneKit["Switch"] = 16] = "Switch";
    ZoneKit[ZoneKit["Dimmer"] = 32] = "Dimmer";
    ZoneKit[ZoneKit["Alarm"] = 64] = "Alarm";
    ZoneKit[ZoneKit["GlobalTemp"] = 128] = "GlobalTemp";
    ZoneKit[ZoneKit["Humidity"] = 256] = "Humidity";
    ZoneKit[ZoneKit["Luminance"] = 512] = "Luminance";
    ZoneKit[ZoneKit["GasMeter"] = 1024] = "GasMeter";
    ZoneKit[ZoneKit["CO2"] = 2048] = "CO2";
})(ZoneKit || (exports.ZoneKit = ZoneKit = {}));
var ZoneDisplayFlag;
(function (ZoneDisplayFlag) {
    ZoneDisplayFlag[ZoneDisplayFlag["HasGlobalTemp"] = 1] = "HasGlobalTemp";
    ZoneDisplayFlag[ZoneDisplayFlag["HasLocalTemp"] = 2] = "HasLocalTemp";
    ZoneDisplayFlag[ZoneDisplayFlag["HasPIR"] = 4] = "HasPIR";
    ZoneDisplayFlag[ZoneDisplayFlag["HasValve"] = 8] = "HasValve";
    ZoneDisplayFlag[ZoneDisplayFlag["HasTemp"] = 16] = "HasTemp";
    ZoneDisplayFlag[ZoneDisplayFlag["HasLuminance"] = 32] = "HasLuminance";
    ZoneDisplayFlag[ZoneDisplayFlag["HasHumidity"] = 64] = "HasHumidity";
    ZoneDisplayFlag[ZoneDisplayFlag["HasGasMeter"] = 128] = "HasGasMeter";
})(ZoneDisplayFlag || (exports.ZoneDisplayFlag = ZoneDisplayFlag = {}));
var ZoneJSON_Flags;
(function (ZoneJSON_Flags) {
    ZoneJSON_Flags[ZoneJSON_Flags["Basic"] = 1] = "Basic";
    ZoneJSON_Flags[ZoneJSON_Flags["Timer"] = 2] = "Timer";
    ZoneJSON_Flags[ZoneJSON_Flags["Footprint"] = 4] = "Footprint";
    ZoneJSON_Flags[ZoneJSON_Flags["Warmup"] = 8] = "Warmup";
    ZoneJSON_Flags[ZoneJSON_Flags["Nodes"] = 16] = "Nodes";
    ZoneJSON_Flags[ZoneJSON_Flags["Datapoints"] = 32] = "Datapoints";
    ZoneJSON_Flags[ZoneJSON_Flags["All"] = 255] = "All";
})(ZoneJSON_Flags || (exports.ZoneJSON_Flags = ZoneJSON_Flags = {}));
var Options;
(function (Options) {
    Options[Options["NoOptions"] = 0] = "NoOptions";
    Options[Options["WarningEnable_NoBoilerController"] = 1] = "WarningEnable_NoBoilerController";
    Options[Options["WarningEnable_NoWholeHouseTemp"] = 2] = "WarningEnable_NoWholeHouseTemp";
    Options[Options["ZoneOptions_UseTempHierarchy"] = 8] = "ZoneOptions_UseTempHierarchy";
    Options[Options["ZoneOptions_SendSPOnDemand"] = 16] = "ZoneOptions_SendSPOnDemand";
    Options[Options["ZoneOptions_ShowZMDeviceIssues"] = 32] = "ZoneOptions_ShowZMDeviceIssues";
    Options[Options["Feature_TimerPlus"] = 256] = "Feature_TimerPlus";
})(Options || (exports.Options = Options = {}));
var ZoneOptions;
(function (ZoneOptions) {
    ZoneOptions[ZoneOptions["NoOptions"] = 0] = "NoOptions";
    ZoneOptions[ZoneOptions["WarningEnable_NoBoilerController"] = 1] = "WarningEnable_NoBoilerController";
    ZoneOptions[ZoneOptions["WarningEnable_NoWholeHouseTemp"] = 2] = "WarningEnable_NoWholeHouseTemp";
    ZoneOptions[ZoneOptions["Zone_UseTempHierarchy"] = 8] = "Zone_UseTempHierarchy";
    ZoneOptions[ZoneOptions["Zone_SendSPOnDemand"] = 16] = "Zone_SendSPOnDemand";
    ZoneOptions[ZoneOptions["Zone_ShowZMDeviceIssues"] = 32] = "Zone_ShowZMDeviceIssues";
    ZoneOptions[ZoneOptions["Feature_TimerPlus"] = 256] = "Feature_TimerPlus";
})(ZoneOptions || (exports.ZoneOptions = ZoneOptions = {}));
var ZoneCalibrationStatus;
(function (ZoneCalibrationStatus) {
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_IDLE"] = 0] = "ECALIBRATION_STATE_IDLE";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_UNMOUNT"] = 1] = "ECALIBRATION_STATE_WAIT_FOR_UNMOUNT";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_MOUNT"] = 2] = "ECALIBRATION_STATE_WAIT_FOR_MOUNT";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_SWITCH_BINARY_OFF"] = 3] = "ECALIBRATION_STATE_WAIT_FOR_SWITCH_BINARY_OFF";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_VALVE_CLOSE"] = 4] = "ECALIBRATION_STATE_WAIT_FOR_VALVE_CLOSE";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_COOL_DOWN"] = 5] = "ECALIBRATION_STATE_WAIT_FOR_COOL_DOWN";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_ADAPTATION_START"] = 6] = "ECALIBRATION_STATE_WAIT_FOR_ADAPTATION_START";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_ADAPTATION_COMPLETE"] = 7] = "ECALIBRATION_STATE_WAIT_FOR_ADAPTATION_COMPLETE";
    ZoneCalibrationStatus[ZoneCalibrationStatus["ECALIBRATION_STATE_WAIT_FOR_MODE_COMPLETE"] = 8] = "ECALIBRATION_STATE_WAIT_FOR_MODE_COMPLETE";
})(ZoneCalibrationStatus || (exports.ZoneCalibrationStatus = ZoneCalibrationStatus = {}));
var SetpointControlStrategy;
(function (SetpointControlStrategy) {
    SetpointControlStrategy[SetpointControlStrategy["CONSTANT_SP"] = 0] = "CONSTANT_SP";
    SetpointControlStrategy[SetpointControlStrategy["SCALE_TEMP_DIFF"] = 1] = "SCALE_TEMP_DIFF";
    SetpointControlStrategy[SetpointControlStrategy["SCALE_TEMP_MEAN_ERR"] = 2] = "SCALE_TEMP_MEAN_ERR";
    SetpointControlStrategy[SetpointControlStrategy["PI_CONTROL"] = 3] = "PI_CONTROL";
    SetpointControlStrategy[SetpointControlStrategy["FLOW_RETURN"] = 4] = "FLOW_RETURN";
})(SetpointControlStrategy || (exports.SetpointControlStrategy = SetpointControlStrategy = {}));
var DHWControlStrategy;
(function (DHWControlStrategy) {
    DHWControlStrategy[DHWControlStrategy["COMBI"] = 0] = "COMBI";
    DHWControlStrategy[DHWControlStrategy["DHW_PRIORITY"] = 1] = "DHW_PRIORITY";
})(DHWControlStrategy || (exports.DHWControlStrategy = DHWControlStrategy = {}));
var ZoneManagerOptions;
(function (ZoneManagerOptions) {
    ZoneManagerOptions[ZoneManagerOptions["WarningEnable_NoBoilerController"] = 1] = "WarningEnable_NoBoilerController";
    ZoneManagerOptions[ZoneManagerOptions["WarningEnable_NoWholeHouseTemp"] = 2] = "WarningEnable_NoWholeHouseTemp";
    ZoneManagerOptions[ZoneManagerOptions["ZoneOptions_UseTempHierarchy"] = 8] = "ZoneOptions_UseTempHierarchy";
    ZoneManagerOptions[ZoneManagerOptions["ZoneOptions_SendSPOnDemand"] = 16] = "ZoneOptions_SendSPOnDemand";
    ZoneManagerOptions[ZoneManagerOptions["ZoneOptions_ShowZMDeviceIssues"] = 32] = "ZoneOptions_ShowZMDeviceIssues";
    ZoneManagerOptions[ZoneManagerOptions["Feature_TimerPlus"] = 256] = "Feature_TimerPlus";
})(ZoneManagerOptions || (exports.ZoneManagerOptions = ZoneManagerOptions = {}));
