import {HubAuth} from 'genius-hub-proxy';
import {HubServices, HubReleaseInfo} from 'genius-hub-proxy';
import {getHubCachedCredentials} from './hubsCache';
import {ConnectionStatus} from "genius-hub-proxy";
import produce from 'immer';
import version from '../version.json'
import {CordovaDeviceInfo} from "./cordova";
import {actionNotify, LogType, Type} from "./log";


declare var device: CordovaDeviceInfo;
const cordovaDevice = typeof device !== 'undefined' ? device : null;

export type FeedbackType = "feedback" | "bugReport";

export enum HubServicesStatus {
    OK,
    FETCHING_DATA,
    SENDING_FEEDBACK,
    ERROR
}

export interface HubServiceCheckinData {
    external_ip: string,
    internal_ip: string,
    hub_release: string,
    last_checkin: string,
    tunnel: {
        connect_time: string,
        last_update: string,
        disconnect_time: string,
        disconnect_reason: string | null,
        server_name: string
    }
}


export interface HubServicesState {
    status: HubServicesStatus,
    releases: Array<HubReleaseInfo>,
    checkin: HubServiceCheckinData | null
}

export interface HubServicesStates {
    [hubName: string]: HubServicesState
}

function createHubServicesHubState(): HubServicesState {
    return {
        status: HubServicesStatus.OK,
        releases: [],
        checkin: null
    }
}

/**********************
 * ACTION CONSTANTS   *
 *********************/
export const GETTING_FIRMWARE_RELEASES = "GETTING_FIRMWARE_RELEASES";
export const GETTING_CHECKIN_DATA = "GETTING_CHECKIN_DATA";
export const GOT_FIRMWARE_RELEASES = "GOT_FIRMWARE_RELEASES";
export const GOT_CHECKIN_DATA = "GOT_CHECKIN_DATA";
export const SENDING_FEEDBACK = "SENDING_FEEDBACK";
export const FEEDBACK_SENT = "FEEDBACK_SENT";
export const SERVICES_ERROR = "SERVICES_ERROR";
export const HUB_INITIALISED = "HUB_INITIALISED";

/**********************
 * ACTION CREATORS    *
 *********************/
export const actionGetReleases = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;

        dispatch({
            type: GETTING_FIRMWARE_RELEASES,
            payload: {
                hubName: activeHubName
            }
        });

        const state = getState();

        try {

            const hubAuth = new HubAuth();
            const credentials = getHubCachedCredentials(activeHubName, state.hubs);
            hubAuth.setAuth(credentials);
            const servicesProxy = new HubServices(hubAuth);

            const result: any = await servicesProxy.getHubReleases();

            if (result.status !== ConnectionStatus.OK) {
                dispatch({
                    type: SERVICES_ERROR,
                    payload: {
                        hubName: activeHubName,
                        data: result
                    }
                });

            } else {
                dispatch({
                    type: GOT_FIRMWARE_RELEASES,
                    payload: {
                        hubName: activeHubName,
                        releases: result.data.data
                    }
                });
            }
        } catch (err: any) {
            console.error("Error getting releases:", err);
            dispatch({
                type: SERVICES_ERROR,
                payload: {
                    hubName,
                    data: err
                }
            });
        }
    }
};


export const actionGetCheckinData = (hubName: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch({
            type: GETTING_CHECKIN_DATA,
            payload: {
                hubName
            }
        });

        const state = getState();
        const hubAuth = new HubAuth();
        const credentials = getHubCachedCredentials(hubName, state.hubs);
        hubAuth.setAuth(credentials);
        const servicesProxy = new HubServices(hubAuth);

        try {
            const result: any = await servicesProxy.getCheckinData();

           /* if (result.status !== ConnectionStatus.OK) {
                dispatch({
                    type: SERVICES_ERROR,
                    payload: {
                        hubName,
                        data: result
                    }
                });

            } else {*/
                dispatch({
                    type: GOT_CHECKIN_DATA,
                    payload: {
                        hubName,
                        checkinData: result
                    }
                });
            // }
        } catch (err: any) {
            console.error("Error getting checkin data:", err);
            dispatch({
                type: SERVICES_ERROR,
                payload: {
                    hubName,
                    data: err
                }
            });
        }
    }
};

export const actionSendFeedback = (hubName: string, type: FeedbackType, message: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch({
            type: SENDING_FEEDBACK,
            payload: {
                hubName
            }
        });

        const state = getState();
        const hubAuth = new HubAuth();
        const credentials = getHubCachedCredentials(hubName, state.hubs);
        hubAuth.setAuth(credentials);
        const servicesProxy = new HubServices(hubAuth);
        const hub = state.hubs[hubName];

        const _version = hub.data.version;

        const hubVersion = {
            version: _version.release,
            buildNumber: _version.commit,
            buildTimestamp: _version.build_time
        };

        const appVersion = {
            version: `${version.major}.${version.minor}.${version.commit}`,
            buildNumber: version.commit,
            buildTimestamp: version.buildDate
        }

        const deviceData = cordovaDevice ? cordovaDevice : {
            manufacturer: 'N/A',
            model: 'N/A',
            platform: 'N/A',
            platformVersion: 'N/A',
            UUID: 'N/A'
        };

        try {
            const result = await servicesProxy.feedback(type, appVersion, deviceData, hubVersion, message);

            if (result.status !== ConnectionStatus.OK) {
                dispatch({
                    type: SERVICES_ERROR,
                    payload: {
                        hubName,
                        data: result
                    }
                });
                return false;

            } else {
                dispatch({
                    type: FEEDBACK_SENT,
                    payload: {
                        hubName,
                    }
                });
                return true;
            }
        } catch (err: any) {
            console.error("Error getting checkin data:", err);
            dispatch({
                type: SERVICES_ERROR,
                payload: {
                    hubName,
                    data: err
                }
            });
            return false;
        }
    }
};

export const actionInitialiseHub = (uid: string) => {
    return async (dispatch: any, getState: any) => {

        const state = getState();
        const hubAuth = new HubAuth();
        // const credentials =  {
        //     username: '',
        //     password: '',
        //     signature: '',
        // }
        // hubAuth.setAuth(credentials);
        const servicesProxy = new HubServices(hubAuth);

        try {
            const result: any = await servicesProxy.initialiseHub(uid);

            if (result.status !== ConnectionStatus.OK) {
                dispatch({
                    type: SERVICES_ERROR,
                    payload: {
                        hubName: uid,
                        data: result
                    }
                });

            } else {
                dispatch({
                    type: HUB_INITIALISED,
                    payload: {
                        hubName: uid,
                    }
                });
                dispatch(actionNotify(`Initialised hub ${uid}`, LogType.Info));
            }
        } catch (err: any) {
            console.error(`Error initialising hub: ${uid}`, err);
            dispatch({
                type: SERVICES_ERROR,
                payload: {
                    hubName: uid,
                    data: err
                }
            });
        }
    }
};

export const actionRegisterHub = (uid: string,
                                  username: string,
                                  password: string,
                                  fullName: string,
                                  email: string,
                                  phoneNumber: string,
                                  postCode: string,
                                  countryCode: string,
                                  latitude: string,
                                  longitude: string
) => {
    return async (dispatch: any, getState: any) => {

        const state = getState();
        const hubAuth = new HubAuth();
        // const credentials =  {
        //     username: '',
        //     password: '',
        //     signature: '',
        // }
        // hubAuth.setAuth(credentials);
        const servicesProxy = new HubServices(hubAuth);

        try {
            const result: any = await servicesProxy.registerHub(uid,
                username,
                password,
                fullName,
                email,
                phoneNumber,
                postCode,
                countryCode,
                latitude,
                longitude
            )

            if (result.status !== ConnectionStatus.OK) {
                dispatch({
                    type: SERVICES_ERROR,
                    payload: {
                        hubName: uid,
                        data: result
                    }
                });

            } else {
                dispatch({
                    type: HUB_INITIALISED,
                    payload: {
                        hubName: uid,
                    }
                });
                dispatch(actionNotify(`Initialised hub ${uid}`, LogType.Info));
            }
        } catch (err: any) {
            console.error(`Error initialising hub: ${uid}`, err);
            dispatch({
                type: SERVICES_ERROR,
                payload: {
                    hubName: uid,
                    data: err
                }
            });
        }
    }
};


/**********************
 * REDUCERS           *
 *********************/
export const initialState: HubServicesStates = {};


export default produce((state = initialState, action) => {
    switch (action.type) {
        case GETTING_FIRMWARE_RELEASES:
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubServicesHubState();
            }
            state[action.payload.hubName].status = HubServicesStatus.FETCHING_DATA;
            break;

        case GOT_FIRMWARE_RELEASES: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubServicesHubState();
            }
            state[action.payload.hubName].status = HubServicesStatus.OK;
            state[action.payload.hubName].releases = action.payload.releases;
            break;
        }

        case GETTING_CHECKIN_DATA:
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubServicesHubState();
            }
            state[action.payload.hubName].status = HubServicesStatus.FETCHING_DATA;
            break;

        case GOT_CHECKIN_DATA: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubServicesHubState();
            }
            state[action.payload.hubName].status = HubServicesStatus.OK;
            state[action.payload.hubName].checkin = action.payload.checkinData;
            break;
        }

        case SENDING_FEEDBACK:
            state[action.payload.hubName].status = HubServicesStatus.OK;
            break;

        case SERVICES_ERROR: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubServicesHubState();
            }
            state[action.payload.hubName].status = HubServicesStatus.ERROR;
            break;
        }

        default:
            return state;
    }
});
