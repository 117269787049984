import {
    ZoneCollection,
    Issue,
    HubCacheData,
    IssueLevel,
    ZoneTPI,
    Issues,
    getZoneDefaultSettings,
    ZoneType,
    ZoneSubType,
    ZoneBase,
    TPI,
    ZoneWholeHouse, ZoneMapping
} from 'genius-hub-types'
import {iterateZones} from './issueTools';
import {getDataNodeFromPath, objectDiff} from "genius-hub-utils";
import {registerFormatter} from "./issueFormatter";


registerFormatter('config:tpi', issue => `TPI settings for zone ${issue.data.zoneID} do not seem to be correct: ${JSON.stringify(issue.data.diff)}`);
registerFormatter('hub:wh_shared_path', issue => `A device assigned to Whole House is also assigned elsewhere`);
registerFormatter('zone:device_multiple_assignment', issue => `A device (${issue.data.nodeID}) is assigned to multiple zones`);


function detectTPIIssues(zone: ZoneTPI): Issues {

    const checkTPI = (a: Partial<TPI>, b: Partial<TPI>): boolean => {
        if (a.kI !== b.kI) return false;
        if (a.kP !== b.kP) return false;
        if (a.cyclePeriod !== b.cyclePeriod) return false;
        if (a.fControlDeadband !== b.fControlDeadband) return false;
        return true;
    }

    let defaultZoneTPI: ZoneTPI | null = null;

    if ((zone.iType === ZoneType.TPI) && (zone.zoneSubType === ZoneSubType.WetUFH)) {
        defaultZoneTPI = (getZoneDefaultSettings(ZoneType.TPI, ZoneSubType.WetUFH) as ZoneTPI);
    } else if ((zone.iType === ZoneType.TPI) && (zone.zoneSubType === ZoneSubType.DHW)) {
        defaultZoneTPI = getZoneDefaultSettings(ZoneType.TPI, ZoneSubType.WetUFH) as ZoneTPI;
    }
    if (defaultZoneTPI === null) {
        return [];
    }

    if (checkTPI(defaultZoneTPI.tpi, zone.tpi)) {
        return [];
    }
    const diff = objectDiff<TPI>(defaultZoneTPI.tpi, zone.tpi);

    const diffVals: any = {};
    diff.notEqual.forEach(x => {
        diffVals[x] = {
            // @ts-ignore
            expect: defaultZoneTPI.tpi[x],
            actual: zone.tpi[x]
        }
    })

    return [new Issue(
        'config:tpi',
        IssueLevel.WARNING,
        {zoneID: zone.iID, diffVals}
    )];
}

function detectWHSharedSP(hubData: HubCacheData): Issues {

    const match = (whPath: string, zonePath: string) => {

        // if whPath ends with SP, check for first part in zonePath

        return whPath === zonePath;
    }

    const sharedDevicePaths: Array<{ path: string, zoneID: number }> = [];

    // get any device that is in WH and in another zone
    let wholeHouseDevices: Array<string> = [];
    const mappings = (hubData?.zones?.[0] as ZoneWholeHouse)?.mappings || {};
    wholeHouseDevices = mappings[0] || [];

    const iter = Object.entries(mappings);
    for (let i of iter) {
        const zoneID = parseInt(i[0]), zoneMappings: Array<string> = i[1];

        if (zoneID === 0) {
            continue;
        }

        if (!zoneMappings || !wholeHouseDevices) {
            console.warn("No mappings");
        }

        zoneMappings.forEach((path) => {
            wholeHouseDevices.forEach((whPath) => {
                if (match(whPath, path)) {
                    // shared
                    sharedDevicePaths.push({path: whPath, zoneID});
                }
            })
        })

    }

    if (sharedDevicePaths.length) {
        return [new Issue(
            'hub:wh_shared_path',
            IssueLevel.WARNING,
            {
                sharedDevicePaths
            })
        ]
    } else {
        return [];
    }
}

function detectNodeMultipleAssignment(hubData:HubCacheData): Issues {
    let result: Array<Issue> = [];

    const mappings = (hubData?.zones?.[0] as ZoneWholeHouse)?.mappings || {};

    const deviceToZoneMapping: { [devicePath: string]: Array<number> } = {};

    const iter = Object.entries(mappings);
    for (let i of iter) {
        const zoneID = parseInt(i[0]), v: Array<string> = i[1];

        for (let path of v) {
            if (deviceToZoneMapping.hasOwnProperty(path)) {
                deviceToZoneMapping[path].push(zoneID);
            } else {
                deviceToZoneMapping[path] = [zoneID];
            }
        }
    }

    const iter2 = Object.entries(deviceToZoneMapping);
    for (let i of iter2) {
        const path = i[0], zones: Array<number> = i[1];

        if (zones.length > 1) {
            // check that the zone exists
            if (!zones.reduce((acc, zoneID) => acc && hubData.zones.hasOwnProperty(zoneID), true)) {
                continue;
            }
            // check that the device exists
            const node = getDataNodeFromPath(hubData.devices, path);
            if (!node) {
                continue;
            }

            result.push(new Issue(
                'zone:device_multiple_assignment',
                IssueLevel.WARNING,
                {
                    path,
                    zones,
                    nodeID: node.addr
                })
            )
        }
    }

    return result;
}


export function detectZoneConfigurationIssues(hubData: HubCacheData): Issues {
    let result: Array<Issue> = [];

    result = result.concat(
        iterateZones(hubData, (zone: ZoneBase) => detectTPIIssues(zone as ZoneTPI)),
        detectWHSharedSP(hubData),
        detectNodeMultipleAssignment(hubData)
    );

    return result;
}
