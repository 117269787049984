/**********************
 * ACTION CONSTANTS   *
 *********************/
import {DashboardBase, DashboardConsolidatedView, DashboardSummaryView, DashboardType} from "genius-hub-types";
import produce from "immer";
import SHA from 'sha.js'
import {actionGetHubData} from "./hubs";
import {HubDataType} from "./hubTypes";

export const DASHBOARD_CREATE = "DASHBOARD_CREATE";
export const DASHBOARD_UPDATE = "DASHBOARD_UPDATE";
export const DASHBOARD_DELETE = "DASHBOARD_DELETE";
export const DASHBOARD_REFRESH_BEGIN = "DASHBOARD_REFRESH_BEGIN";
export const DASHBOARD_REFRESH_COMPLETE = "DASHBOARD_REFRESH_COMPLETE";


/**********************
 * INTERFACES         *
 *********************/
export interface DashboardState {
    dashboards: Array<DashboardBase>,
    newlyCreateDashboard: DashboardBase | null
}

/**********************
 * HELPERS            *
 *********************/

/**
 * Encode a base64 string to base64url
 * @param base64str
 */
export function encodeBase64url(base64str: string): string {
    return base64str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export function dashboardCollectHubNames(dashboard: DashboardBase): Array<string> {
    const hubNames : Array<string> = [];
    switch (dashboard.type) {
        case DashboardType.ConsolidatedView:
            const dv = dashboard as DashboardConsolidatedView;
            for (const hubName of Object.keys(dv.order)) {
                if (!hubNames.includes(hubName)) {
                    hubNames.push(hubName);
                }
            }
            break;
        case DashboardType.SummaryView:
            const sv = dashboard as DashboardSummaryView;
            for (const hubName of sv.order) {
                hubNames.push(hubName);
            }
            break;
    }
    return hubNames;
}

/**
 * Decode a base64url string to base64
 * @param url
 */
export function decodeBase64url(url: string): string {
    return url.replace(/-/g, '+').replace(/_/g, '/');
}

export function updateDashboardHash(dashboard: DashboardBase): DashboardBase {
    const newDashboard = {...dashboard};
    const hash = SHA('sha256');
    hash.update(newDashboard.id);
    hash.update(newDashboard.name);
    hash.update(newDashboard.description);
    hash.update(`${newDashboard.type}`);

    const iter = Object.entries(newDashboard.hubCredentials);
    for (let i of iter) {
        const k = i[0], v = i[1];
        hash.update(v.username);
        hash.update(v.password);
        hash.update(v.token);
        hash.update(v.refreshToken);
        hash.update(v.signature);
    }

    const newVersion = {
        previousHash: dashboard.version.hash,
        hash: encodeBase64url(hash.digest('base64')),
        timestamp: Date.now(),
    }

    newDashboard.version = newVersion;

    return newDashboard;
}

/**********************
 * ACTION CREATORS    *
 *********************/
export const actionDashboardCreate = (name: string, type: DashboardType) => {

    let d: DashboardBase = {
        id: crypto.randomUUID(),
        name: name,
        type: type,
        description: "",
        version: {hash: '', previousHash: '', timestamp: 0},
        hubCredentials: {}
    };
    d = updateDashboardHash(d);

    if (d.type === DashboardType.ConsolidatedView) {
        (d as DashboardConsolidatedView).order = [];
    } else if (d.type === DashboardType.SummaryView) {
        (d as DashboardSummaryView).order = [];
    }

    return {
        type: DASHBOARD_CREATE,
        payload: d
    }
};

export const actionDashboardUpdate = (dashboard: DashboardBase) => {
    return {
        type: DASHBOARD_UPDATE,
        payload: dashboard
    }
};

export const actionDashboardDelete = (dashboard: DashboardBase) => {
    return {
        type: DASHBOARD_DELETE,
        payload: dashboard
    }
};


export const actionDashboardRefresh = (dashboard: DashboardBase) => {

    return async (dispatch: any) => {
        dispatch({
            type: DASHBOARD_REFRESH_BEGIN,
            payload: null
        });

        const hubNames: Array<string> = dashboardCollectHubNames(dashboard);

        for (const hubName of hubNames) {
            await dispatch(actionGetHubData(HubDataType.Devices |
                HubDataType.ZWave |
                HubDataType.Zigbee |
                HubDataType.Zones, hubName));
        }

        dispatch({
            type: DASHBOARD_REFRESH_BEGIN,
            payload: null
        });

    }

};


/**********************
 * REDUCERS           *
 *********************/
export const initialState: DashboardState = {
    dashboards: [],
    newlyCreateDashboard: null
};

export default produce((state = initialState, action): DashboardState => {
    switch (action.type) {
        case DASHBOARD_CREATE: {
            state.dashboards.push(action.payload);
            break;
        }

        case DASHBOARD_UPDATE: {
            state.dashboards = state.dashboards.map(x => {
                if (x.id === action.payload.id) {
                    updateDashboardHash(action.payload);
                    return action.payload;
                }
                return x;
            })
            break;
        }

        case DASHBOARD_DELETE:
            state.dashboards = state.dashboards.filter(x => x.id !== action.payload.id);
            break;

        default:
            return state;
    }
}, initialState);
