import produce from "immer";
import {
    HubCredentials,
    Issue,
    IssueLevel,
    QuestionData,
    ResponseGiven,
    SolutionData,
    ZWaveActionType
} from "genius-hub-types";
import {actionLogin, actionZWaveCommands} from "./hubs";
import {AnyAction} from "redux";
import {ThunkAction} from "redux-thunk";
import {RootState} from "./index";
import {solveTSW} from "../TSW/TSWUtils";
import {questions} from '../TSW/tsw_metadata';
import {GeniusHubAppServer, GeniusHubAppServerResponseStatus, HubAuth} from "genius-hub-proxy";
import {AppState} from "./app";
import {HubsCollection} from "./hubTypes";
import {InjectIssueMetaData} from "../TSW/TSWTypes";
import {useNavigate} from "react-router-dom";
import {push} from "redux-first-history";
import merge from "lodash/merge";
import {webNavigate} from "../utils/webNavigate";


/**********************
 * ACTION CONSTANTS   *
 *********************/

const SETUP_CONTEXT = "SETUP_CONTEXT";
const SET_PAGE = "SET_PAGE";
const UPDATE_SOLUTION_CONTEXT = "UPDATE_SOLUTION_CONTEXT";
const UPDATE_SOLVER_CONTEXT = "UPDATE_SOLVER_CONTEXT";
const RESET_TSW = "RESET_TSW";
const TSW_SOLUTION_BUTTON = "TSW_SOLUTION_BUTTON";
const TSW_DEBOUNCE_ZWAVE_UPDATE = "TSW_DEBOUNCE_ZWAVE_UPDATE";
const RESPOND_TO_QUESTION = "RESPOND_TO_QUESTION";
const TSW_SKIP_STEP = "TSW_SKIP_STEP";
const TSW_TRIGGER_RESPONSE_WITH_MATCHING_HINT = "TSW_TRIGGER_RESPONSE_WITH_MATCHING_HINT";
const TSW_QUEUE_ANALYTICS = "TSW_QUEUE_ANALYTICS";
const TSW_POP_FIRST_ANALYTICS_REPORT = "TSW_POP_FIRST_ANALYTICS_REPORT";
const TSW_LOG = "TSW_LOG";
const TSW_EXIT = "TSW_EXIT";
const TSW_SET_TITLE = "TSW_SET_TITLE";

const ZWAVE_DEBOUNCE_TIMEOUT = 500;

/**********************
 * INTERFACES         *
 *********************/


export enum TSWSolutionStatus {
    AskQuestion,
    FinalizedSolutions,
    NoSolutionFound,
    NoMoreQuestionsLeft
}

export enum TSWExitType {
    IGNORE,
    FIXED,
    NOT_FIXED,
    FEEDBACK
}

export interface TSWSolution {
    status: TSWSolutionStatus,
    nextQuestionID: number,
}

export interface TSWSolutionExtraInfo extends TSWSolution {
    solutions: Array<SolutionData>,
    questionsWithRank: Array<[QuestionData, any]>
}

export interface ResponseWould {
    questionID: number,
    responseIndex: number,
    solutionsRemoved: number,
    solutionsRemaining: number
}


export interface TSWSolverContext {
    solutionRequiresTags: Array<string>,
    solutionMustNotHaveTags: Array<string>,
    history: Array<ResponseGiven>,
    solution: TSWSolution
}

export enum TSWSolutionButtonAction {
    NONE,
    NEXT,
    BACK,
    SKIP,
    RETRY,
    DONE,
    HIDE
}

export enum MacroStatus {
    NONE,
    SUCCESS,
    WAITING,
    FAIL,
    FAIL_NO_RETRY
}

export enum TSWDisplay {
    Question,
    Solution,
    SolutionComplete
}

export interface TSWSolutionContext {
    credentials: null | HubCredentials,
    totalSteps: number,
    currentStep: number,
    includedDeviceID: number,
    selectedZoneID: number | null, // -1 = create zone, null = skip
    selectedZWaveNodeID: number | null,
    selectedZigbeeNodeID: string | null,
    devicePath: string,
    createZone: boolean,
    operationStatus: MacroStatus,
    statusMessage: string,
    hideNavigationButtons: boolean
    lastButtonAction: TSWSolutionButtonAction
    startStep: number | null
    debounceZWaveActionTimestamp: number,
    log: Array<string>,
}

export interface TSWAnalytics {
    timestamp: number,
    exitType: TSWExitType,
    exitMessage: string,
    exitRating: number,
    returnLocation: string,
    initialTags: Array<string>,
    initialID: number,
    initialDisplay: TSWDisplay,
    solverHistory: Array<ResponseGiven>,
    solutionHistory: Array<string>,
    injectedIssue: InjectIssueMetaData,
}


export interface TSWContext {
    display: TSWDisplay,
    pageID: number,
    title: string,
    debug: boolean,
    returnLocation: string,
    solverCtx: TSWSolverContext,
    solutionCtx: TSWSolutionContext,
    analytics: TSWAnalytics,
    pendingAnalytics: Array<TSWAnalytics>
}


export interface TSWCallbacks {
    onUpdateContext: (newContext: TSWContext) => void
}


/**********************
 * ACTION CREATORS    *
 *********************/

export const actionTSWSetPage = (pageID: number): AnyAction => {
    const action: AnyAction = {
        type: SET_PAGE,
        payload: {
            pageID: pageID,
        }
    }
    return action;
}

export const actionTSWUpdateSolutionContext = (x: Partial<TSWSolutionContext>): AnyAction => {
    return {
        type: UPDATE_SOLUTION_CONTEXT,
        payload: x
    }
}

export const actionTSWUpdateSolverContext = (x: Partial<TSWSolverContext>): AnyAction => {
    return {
        type: UPDATE_SOLVER_CONTEXT,
        payload: x
    }
}

export const actionTSWLog = (message: string): AnyAction => {
    return {
        type: TSW_LOG,
        payload: {
            ts: (new Date()).getTime() / 1000,
            message
        }
    }
}

export const actionTSWRespondToQuestion = (responseGiven: ResponseGiven): AnyAction => {
    return {
        type: RESPOND_TO_QUESTION,
        payload: {
            question: {...responseGiven.question, responses: []},
            response: responseGiven.response
        }
    }
}

/*!
 * Reset the TSW
 * Switch hub credentials if specified
 */
export const actionTSWReset = (initialState?: Partial<TSWContext>, switchToCredentials?: HubCredentials): AnyAction => {
/*
    const _initialState: Partial<TSWContext> = {
        ...initialState,
        analytics: {
            returnLocation: initialState.returnLocation,
            initialTags: initialState.solverCtx?.solutionRequiresTags ?? [],
            initialID: initialState.pageID,
            exitType: TSWExitType.IGNORE,
            exitMessage: "",
            initialDisplay: initialState.display,
            solverHistory: [],
            solutionHistory: [],
            exitRating: 0,
            timestamp: 0,
            injectedIssue: {
                issue: (new Issue('', IssueLevel.INFO, null)).toJSON(),
                tags: [],
                returnURL: '',
                linkTo: null,
                linkType: null,
                metaData: {}
            }

        }
    }
*/

    return {
        type: RESET_TSW,
        payload: createTSWContext(initialState)
    }
};

export const actionTSWQueueAnalytics = (): AnyAction => {
    return {
        type: TSW_QUEUE_ANALYTICS,
        payload: {
            timestamp: (new Date()).getTime() / 1000
        }
    }
}

export const actionTSWSendAnalytics = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any, getState: any) => {

        const state = getState();

        let analytics;
        do {
            analytics = getState().tsw.pendingAnalytics;
            if (analytics.length) {
                const activeHubName = (state.app as AppState)?.activeHubName;
                const activeHubCredentials = (state.hubs as HubsCollection)[activeHubName]?.data?.credentials;
                const auth = new HubAuth();
                auth.setAuth(activeHubCredentials);

                const service = new GeniusHubAppServer(auth, "https://hub.geniushub.co.uk");
                // debugger
                const response = await service.sendDoctorAnalytics(analytics[0]);
                if (response.status === GeniusHubAppServerResponseStatus.OK) {
                    console.debug("Sent analytics report");
                    await dispatch({
                        type: TSW_POP_FIRST_ANALYTICS_REPORT,
                        payload: null
                    });
                } else {
                    console.error("Failed to send analytics report");
                    break;
                }
            }
        } while (analytics.length > 0);
    }
}

export const actionTSWSolutionButton = (type: TSWSolutionButtonAction): AnyAction => {

    return {
        type: TSW_SOLUTION_BUTTON,
        payload: {type}
    }
}

export const actionTSWExit = (exitRating: number, exitMessage: string = null): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch: any, getState: any) => {
        await dispatch({
            type: TSW_EXIT,
            payload: {
                exitRating,
                exitMessage,
                exitType: exitMessage? TSWExitType.FEEDBACK : TSWExitType.FIXED,
            }
        });
        await dispatch(actionTSWQueueAnalytics());
        await dispatch(actionTSWSendAnalytics());

        const path = getState().tsw.returnLocation;
        webNavigate(path);

        // const path = `/tsw/question/1`;
        // dispatch(push(path));
        // window.location = path;

    }
}

export const actionTSWSkipStep = (currentStep?: number): AnyAction => {
    console.debug("actionTSWSkipStep", currentStep);
    return {
        type: TSW_SKIP_STEP,
        payload: {currentStep}
    }
}

export const actionTSWZWaveAction = (type: ZWaveActionType, nodeID: number = null): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch: any, getState: any) => {

        const activeHubName = getState().app.activeHubName;
        const state = getState();
        const hubs = state.hubs;
        if (hubs && hubs.hasOwnProperty(activeHubName)) {
            const tmNow = Date.now();
            if (tmNow < state.tsw.solutionCtx.debounceZWaveActionTimestamp) {
                // multiple zwave commands received very quickly...
                console.warn(`Debounced zwave action ${ZWaveActionType[type]} as an action was received ${(state.tsw.solutionCtx.debounceZWaveActionTimestamp - tmNow) / 1000} seconds ago`)
            } else {
                dispatch({type: TSW_DEBOUNCE_ZWAVE_UPDATE, payload: null});
                await dispatch(actionZWaveCommands([{type, nodeID}], activeHubName));
            }
        }
    }

}

export const actionTSWTriggerResponseWithMatchingHint = (hint: string): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch: any, getState: any) => {
        const state = getState();
        const question = questions[state.context.pageID];
        const responses = question.responses.filter(r => r.hint === hint);

        if (hint && responses.length > 0) {

            dispatch(actionTSWRespondToQuestion({
                question,
                response: responses[0]
            }))
        }
    }
}

export const actionTSWSetTitle = (title: string): AnyAction => {
    return {
        type: TSW_SET_TITLE,
        payload: {title}
    }
}


/**********************
 * REDUCERS           *
 *********************/

export function createTSWContext(initialState?: Partial<TSWContext>): TSWContext {
    const context: TSWContext = {
        pageID: 1,
        debug: true,
        display: TSWDisplay.Question,
        returnLocation: '',
        title: '',
        solutionCtx: {
            totalSteps: 1,
            currentStep: 1,
            credentials: null,
            includedDeviceID: 0,
            selectedZoneID: null,
            selectedZWaveNodeID: 0,
            selectedZigbeeNodeID: null,
            devicePath: '',
            statusMessage: '',
            createZone: false,
            hideNavigationButtons: false,
            lastButtonAction: TSWSolutionButtonAction.NONE,
            startStep: null,
            debounceZWaveActionTimestamp: 0,
            operationStatus: MacroStatus.NONE,
            log: [],
        },
        solverCtx: {
            solutionRequiresTags: [],
            solutionMustNotHaveTags: [],
            history: [],
            solution: {
                status: TSWSolutionStatus.NoSolutionFound,
                nextQuestionID: 0
            }
        },
        analytics: {
            initialID: 0,
            initialDisplay: TSWDisplay.Question,
            exitType: TSWExitType.IGNORE,
            exitMessage: '',
            initialTags: [],
            returnLocation: '',
            solverHistory: [],
            solutionHistory: [],
            timestamp: 0,
            exitRating: 0,
            injectedIssue: {
                issue: (new Issue('', IssueLevel.INFO, null)).toJSON(),
                tags: [],
                returnURL: '',
                linkTo: null,
                linkType: null,
                metaData: {}
            }
        },
        pendingAnalytics: [],
    }

    // return (!!initialState) ? {...context, ...initialState} : context;
    return merge(context, initialState);
}

export const initialState = createTSWContext();

export default produce((state = initialState, action): TSWContext => {

    switch (action.type) {

        case SETUP_CONTEXT:
            state.display = action.payload.display;
            state.debug = action.payload.debug;
            state.returnLocation = action.payload.returnLocation;
            // console.debug(`Return location: ${state.returnLocation}`)
            break;

        case SET_PAGE:
            state.pageID = action.payload.pageID;
            if (action.payload.hasOwnProperty('display')) {
                state.display = action.payload.display;
            }
            break;

        case RESET_TSW:

            return {
                ...action.payload,
                pendingAnalytics: state.pendingAnalytics,
                // maintain the return location
                // returnLocation: state.returnLocation ,
            };

        case TSW_SOLUTION_BUTTON:
            state.solutionCtx.hideNavigationButtons = false;
            if (action.payload.type === TSWSolutionButtonAction.NEXT) {
                state.solutionCtx.currentStep += 1;

            } else if (action.payload.type === TSWSolutionButtonAction.BACK) {
                state.solutionCtx.currentStep -= 1;

            } else if (action.payload.type === TSWSolutionButtonAction.RETRY) {
                state.solutionCtx.currentStep = state.solutionCtx.startStep;

            } else if (action.payload.type === TSWSolutionButtonAction.DONE) {
                state.display = TSWDisplay.SolutionComplete;

            } else if (action.payload.type === TSWSolutionButtonAction.HIDE) {
                state.solutionCtx.hideNavigationButtons = true;

            } else if (state.solutionCtx.currentStep >= state.solutionCtx.totalSteps) {
                state.display = TSWDisplay.SolutionComplete;
            }
            state.solutionCtx.lastButtonAction = action.payload.type;
            break;

        case TSW_DEBOUNCE_ZWAVE_UPDATE:
            state.solutionCtx.debounceZWaveActionTimestamp = Date.now() + ZWAVE_DEBOUNCE_TIMEOUT;
            break;

        case UPDATE_SOLUTION_CONTEXT: {
            const iter = Object.entries(action.payload);
            for (let i of iter) {
                const k = i[0], v = i[1];
                state.solutionCtx[k] = v;
            }
            break;
        }

        case UPDATE_SOLVER_CONTEXT: {
            const iter = Object.entries(action.payload);
            for (let i of iter) {
                const k = i[0], v = i[1];
                state.solverCtx[k] = v;
            }
            break;
        }

        case RESPOND_TO_QUESTION: {
            state.solverCtx.history.push(action.payload);
            if (action.payload.response.tags.length > 0) {
                const includeTags = action.payload.response.tags.filter((tag: string) => (tag.length > 0) && tag[0] !== '-');
                const excludeTags = action.payload.response.tags.filter((tag: string) => (tag.length > 0) && tag[0] === '-');

                if (action.payload.response.linkType === "reset-tags") {
                    state.solverCtx.solutionRequiresTags = includeTags;
                    state.solverCtx.solutionMustNotHaveTags = excludeTags;
                    state.solutionCtx.currentStep = 1;

                } else {
                    const _newIncludeTags = includeTags.filter((tag: string) => !state.solverCtx.solutionRequiresTags.includes(tag));
                    const _newExcludeTags = excludeTags.filter((tag: string) => !state.solverCtx.solutionMustNotHaveTags.includes(tag));
                    state.solverCtx.solutionRequiresTags = [...state.solverCtx.solutionRequiresTags, ..._newIncludeTags];
                    state.solverCtx.solutionMustNotHaveTags = [...state.solverCtx.solutionMustNotHaveTags, ..._newExcludeTags];
                }
            }
            if (action.payload.response.linkType === "question") {
                state.pageID = action.payload.response.linkTo;
                state.display = TSWDisplay.Question;

            } else if (action.payload.response.linkType === "solution") {
                state.pageID = action.payload.response.linkTo;
                state.display = TSWDisplay.Solution;
                state.solutionCtx.currentStep = 1;

            } else if (["none", "reset-tags", "", null].find(x => x === action.payload.response.linkType)) {
                const solution = solveTSW(state);
                state.solverCtx.solution = solution;
                if (solution.status === TSWSolutionStatus.AskQuestion) {
                    state.display = TSWDisplay.Question;
                    state.pageID = solution.nextQuestionID;
                } else if (solution.status === TSWSolutionStatus.FinalizedSolutions) {
                    state.display = TSWDisplay.Solution;
                    state.pageID = solution.solutions[0].id ?? 0;
                } else if (solution.status === TSWSolutionStatus.NoMoreQuestionsLeft) {
                    state.display = TSWDisplay.Question;
                    state.pageID = 0;
                } else if (solution.status === TSWSolutionStatus.NoSolutionFound) {
                    state.display = TSWDisplay.Solution;
                    state.pageID = 0;
                }
            }

            break;
        }

        case TSW_SKIP_STEP:
            if (action.payload.currentStep ?? false) {
                state.solutionCtx.currentStep = action.payload.currentStep + 1;
            } else {
                state.solutionCtx.currentStep += 1;
            }
            break;

        case TSW_TRIGGER_RESPONSE_WITH_MATCHING_HINT:
            // NOP
            break;

        case TSW_QUEUE_ANALYTICS:
            state.analytics.solverHistory = state.solverCtx.history;
            state.analytics.solutionHistory = state.solutionCtx.log;
            state.analytics.timestamp = action.payload.timestamp;
            state.pendingAnalytics.push({...state.analytics});
            break;

        case TSW_POP_FIRST_ANALYTICS_REPORT:
            state.pendingAnalytics.shift();
            break;

        case TSW_LOG:
            state.solutionCtx.log.push(action.payload);
            break;

        case TSW_EXIT:
            state.analytics.exitType = action.payload.exitType;
            state.analytics.exitMessage = action.payload.exitMessage;
            state.analytics.exitRating = action.payload.exitRating;
            break;

        case TSW_SET_TITLE:
            state.title = action.payload.title;
            break;

        default:
            return state;
    }

});
