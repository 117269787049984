import {DeviceTypeZWave, ManufacturerSpecificData, ZWaveNodeInfo} from "genius-hub-types";
import Joi, {ObjectSchema} from "joi";
import {deviceHash, shortHashToLongHash} from "genius-hub-utils";

export interface DeviceZWaveConfigDefault {
    nodeInfo: ZWaveNodeInfo,
    commandClasses: { [commandClassName: string]: any }
}


export const DeviceZWaveConfigDefaults: { [sku: string]: ObjectSchema<any> } = {};


export function createSchemaZWaveDeviceNonListening() {
    return Joi.object({
        _cfg: Joi.any(),
        avg_answer_latency: Joi.number().optional(),
        avg_callback_latency: Joi.number().optional(),
        avg_response_latency: Joi.number().optional(),
        max_answer_latency: Joi.number().optional(),
        max_callback_latency: Joi.number().optional(),
        max_response_latency: Joi.number().optional(),
        min_answer_latency: Joi.number().optional(),
        min_callback_latency: Joi.number().optional(),
        min_response_latency: Joi.number().optional(),
        short_cc_delivery_percent: Joi.number().optional(),
        neighbour_processing: Joi.any(),
        possible_routes: Joi.any(),
        removal_processing: Joi.any(),
        return_route_processing: Joi.any(),
        routing_properties: Joi.any(),
        neighbours: Joi.any(),
        "awake": Joi.boolean().required(),
        "ccFrameQueue": Joi.array(),
        "disabledTimeRemaining": Joi.number().min(0),
        "frameQueue": Joi.array(),
        "id": Joi.number().min(1).max(232),
        "lastComms": Joi.number().min(0).allow(null),
        "linkStrength": Joi.any(),
        "listening": Joi.boolean().valid(false).required(),
        "long_cc_delivery_percent": Joi.number().min(0).max(1000),
        "long_wakeup_percent": Joi.number().min(0).max(150),
        "lwr": Joi.any(),
        "nodeNeighbourList": Joi.array().items(Joi.number().min(1).max(232)),
        "short_wakeup_percent": Joi.number().min(0).max(150),
        "short_frame_delivery_percent": Joi.number().min(0).max(1000),
    });
}

export function createSchemaZWaveDeviceListening() {
    return Joi.object({
        _cfg: Joi.any(),
        avg_answer_latency: Joi.number().optional(),
        avg_callback_latency: Joi.number().optional(),
        avg_response_latency: Joi.number().optional(),
        max_answer_latency: Joi.number().optional(),
        max_callback_latency: Joi.number().optional(),
        max_response_latency: Joi.number().optional(),
        min_answer_latency: Joi.number().optional(),
        min_callback_latency: Joi.number().optional(),
        min_response_latency: Joi.number().optional(),
        short_cc_delivery_percent: Joi.number().optional(),
        neighbour_processing: Joi.any(),
        possible_routes: Joi.any(),
        removal_processing: Joi.any(),
        return_route_processing: Joi.any(),
        routing_properties: Joi.any(),
        neighbours: Joi.any(),
        "awake": Joi.boolean().required(),
        "ccFrameQueue": Joi.array(),
        "disabledTimeRemaining": Joi.number().min(0),
        "frameQueue": Joi.array(),
        "id": Joi.number().min(1).max(232),
        "lastComms": Joi.number().min(0).allow(null),
        "linkStrength": Joi.any(),
        "listening": Joi.boolean().valid(true).required(),
        "long_cc_delivery_percent": Joi.number().min(0).max(100),
        "long_frame_delivery_percent": Joi.number().min(0).max(1000),
        "long_wakeup_percent": Joi.number().min(0).max(150),
        "lwr": Joi.any(),
        "nodeNeighbourList": Joi.array().items(Joi.number().min(1).max(232)),
        "short_frame_delivery_percent": Joi.number().min(0).max(1000),
        "short_wakeup_percent": Joi.number().min(0).max(150)
    });
}

/*! use for 'sub' nodes in command class multi, for example */
export function createSchemaZWaveDevicePsuedoListening() {
    return Joi.object({
        _cfg: Joi.any(),
        "awake": Joi.boolean().required(),
        "ccFrameQueue": Joi.array(),
        "disabledTimeRemaining": Joi.number().min(0),
        "frameQueue": Joi.array(),
        "id": Joi.number().min(1).max(232),
        "lastComms": Joi.number().min(0).allow(null),
        "linkStrength": Joi.any(),
        "listening": Joi.boolean().required(),
        "long_cc_delivery_percent": Joi.number().min(0).max(100),
        "long_wakeup_percent": Joi.number().min(0).max(150),
        "lwr": Joi.any(),
        "nodeNeighbourList": Joi.array().items(Joi.number().min(1).max(232)),
        "short_cc_delivery_percent": Joi.number().min(0).max(100),
        "short_wakeup_percent": Joi.number().min(0).max(150),
        "commandClasses": {}
    });
}

export function createSchemaPsuedoNodeInfo() {
    return {
        nodeInfo: Joi.object({
            "bListening": Joi.boolean().required(),
            "bSensor1000ms": Joi.boolean().required(),
            "bSensor250ms": Joi.boolean().required(),
            "basicDeviceClass": Joi.number().required(),
            "genericDeviceClass": Joi.number().required(),
            "optFunc": Joi.boolean().required(),
            "protocolSpecificPart1": Joi.number().required(),
            "protocolSpecificPart2": Joi.number().required(),
            "protocolSpecificPart3": Joi.number().required(),
            "specificDeviceClass": Joi.number().required(),
        }),
        return_route_processing: Joi.any(),
        routing_properties: Joi.any(),
        removal_processing: Joi.any(),
        neighbour_processing: Joi.any(),
        possible_routes: Joi.any(),

    }
}


export function createSchemaZWaveCommandClassAssociation() {
    return {
        "COMMAND_CLASS_ASSOCIATION": Joi.object({
            "groups": {},
            "id": 133,
            "name": "COMMAND_CLASS_ASSOCIATION",
            "version": Joi.number().valid(1, 2)
        })
    }
}

export function createSchemaZWaveCommandClassBasic() {
    return {
        "COMMAND_CLASS_BASIC": Joi.object({
            "id": 32,
            "name": "COMMAND_CLASS_BASIC",
            "value": Joi.number().min(0).max(255).allow(null),
            "version": Joi.number().valid(1, 2)
        }).required()
    }
}

export function createSchemaZWaveCommandClassBattery() {
    return {
        "COMMAND_CLASS_BATTERY": Joi.object({
            "id": 128,
            "name": "COMMAND_CLASS_BATTERY",
            "value": Joi.number().min(0).max(100).allow(255),
            "version": 1
        }).required()
    }
}

export function createSchemaZWaveCommandClassClimateControlSchedule() {
    return {
        "COMMAND_CLASS_CLIMATE_CONTROL_SCHEDULE": Joi.object({
            "id": 70,
            "name": "COMMAND_CLASS_CLIMATE_CONTROL_SCHEDULE",
            "setback": Joi.number().allow(null),
            "setback_requested": Joi.number().allow(null),
            "version": 1
        })
    }
}

export function createSchemaZWaveCommandClassClock() {
    return {
        "COMMAND_CLASS_CLOCK": Joi.object({
            "hour": Joi.number().min(0).max(23).allow(null),
            "id": 129,
            "minute": Joi.number().min(0).max(59).allow(null),
            "name": "COMMAND_CLASS_CLOCK",
            "version": 1,
            "weekday": Joi.number().min(0).max(6).allow(null),
        })
    }
}

export function createZWaveConfigValueSchema(configParameterID: number, configParameterSize: number, value: any) {
    let _value;

    if (typeof value === 'number') {
        _value = Joi.number().valid(value);
    } else if (Array.isArray(value)) {
        _value = Joi.array().items(Joi.number().valid(value));
    } else {
        _value = value;
    }

    return {
        [configParameterID]: {
            size: configParameterSize,
            value: _value,
            value_requested: Joi.number()
        }
    }
}

export function createSchemaZWaveCommandClassConfiguration(configValues: any) {
    return {
        "COMMAND_CLASS_CONFIGURATION": Joi.object({
            "id": 112,
            "name": "COMMAND_CLASS_CONFIGURATION",
            "version": Joi.number().valid(1, 2, 3, 4),
            ...configValues
        })
    }
}

export function createSchemaZWaveCommandClassIndicator() {
    return {
        "COMMAND_CLASS_INDICATOR": Joi.object({
            "id": 135,
            "name": "COMMAND_CLASS_INDICATOR",
            "value": Joi.number(),
            "version": Joi.number().valid(1, 2, 3)
        })
    }
}

export function createSchemaZWaveCommandClassManufacturerSpecific(deviceDef: DeviceTypeZWave) {
    const manfData: ManufacturerSpecificData = {...deviceDef as ManufacturerSpecificData};
    const shortHash = deviceHash(manfData);
    const longHash = shortHashToLongHash(shortHash)
    return {
        "COMMAND_CLASS_MANUFACTURER_SPECIFIC": Joi.object({
            "hash": longHash,
            "id": 114,
            "manufacturerID": deviceDef.manfID,
            "name": "COMMAND_CLASS_MANUFACTURER_SPECIFIC",
            "productID": deviceDef.productID,
            "productType": deviceDef.productType,
            "version": Joi.number().min(1).max(5)
        })
    }
}

export function createSchemaZWaveCommandClassMark() {
    return {
        "COMMAND_CLASS_MARK": Joi.object({
            "id": 239,
            "name": "COMMAND_CLASS_MARK",
            "version": 1
        })
    }
}

export function createSchemaZWaveCommandClassMultiChannel(nodes: object, identical: boolean = true) {
    return {
        "COMMAND_CLASS_MULTI_CHANNEL": Joi.object({
            "dynamic": false,
            "id": 96,
            "identical": identical,
            "name": "COMMAND_CLASS_MULTI_CHANNEL",
            "nodes": nodes,
            "version": Joi.number().min(1).max(4)
        })
    };
}

export function createSchemaZWaveCommandClassMultiCmd() {
    return {
        "COMMAND_CLASS_MULTI_CMD": Joi.object({
            "id": 143,
            "name": "COMMAND_CLASS_MULTI_CMD",
            "version": 1
        })
    }
}

export function createSchemaZWaveCommandClassMultilevel(sensors?: object, sensorLimits?: object, specialValues?: object) {

    if (!sensors) {
        sensors = {
            "TEMPERATURE": Joi.number().min(-20).max(100),
            "RELATIVE_HUMIDITY": Joi.number().min(0).max(100),
            "WATER_TEMPERATURE": Joi.number().min(-20).max(100),
        }
    }

    if (!sensorLimits) {
        sensorLimits = {
            "TEMPERATURE": Joi.object({
                "limit": Joi.object({
                    "lower": -20,
                    "upper": 100
                })
            }),
            "WATER_TEMPERATURE": Joi.object({
                "limit": {
                    "lower": -20,
                    "upper": 100
                }
            })
        }
    }
    return {
        "COMMAND_CLASS_SENSOR_MULTILEVEL": Joi.object({
            "id": 49,
            "name": "COMMAND_CLASS_SENSOR_MULTILEVEL",
            "sensor_limits": Joi.object({
                ...sensorLimits
            }).allow(null),
            "sensors": Joi.object({
                ...sensors
            }),
            "special_values": specialValues || {},
            "version": Joi.number().min(1).max(6),
        })
    }
}

export function createSchemaZWaveCommandClassNOP() {
    return {
        "COMMAND_CLASS_NO_OPERATION": Joi.object({
            "id": 0,
            "name": "COMMAND_CLASS_NO_OPERATION",
            "version": 1
        })
    }
}

export function createSchemaZWaveCommandClassPowerLevel() {
    return {
        "COMMAND_CLASS_POWERLEVEL": Joi.object({
            "id": 115,
            "name": "COMMAND_CLASS_POWERLEVEL",
            "powerLevel": Joi.number(),
            "version": 1
        })
    }
}

export function createSchemaZWaveCommandClassProtection() {
    return {
        "COMMAND_CLASS_PROTECTION": Joi.object({
            "id": 117,
            "name": "COMMAND_CLASS_PROTECTION",
            "value": Joi.number(),
            "version": Joi.number().valid(1, 2)
        })
    }
}

export function createSchemaZWaveCommandClassSchedule() {
    return {
        "COMMAND_CLASS_SCHEDULE": Joi.object({
            "id": 83,
            "name": "COMMAND_CLASS_SCHEDULE",
            "version": 1
        })
    }
}

export function createSchemaZWaveCommandClassSensorBinary() {
    return {
        "COMMAND_CLASS_SENSOR_BINARY": Joi.object({
            "id": 48,
            "name": "COMMAND_CLASS_SENSOR_BINARY",
            "sensors": Joi.object({
                "TEMPERATURE": Joi.number().min(-20).max(100),
                "RELATIVE_HUMIDITY": Joi.number().min(0).max(100),
                "WATER_TEMPERATURE": Joi.number().min(-20).max(100),
            }),
            "special_values": {},
            "version": Joi.number().min(1).max(6),
        })
    }
}

export function createSchemaZWaveCommandClassSwitchAll() {
    return {
        "COMMAND_CLASS_SWITCH_ALL": Joi.object({
            "id": 39,
            "name": "COMMAND_CLASS_SWITCH_ALL",
            "mode": Joi.number().min(0).max(255).allow(null),
            "version": 1,
        }).required()
    }
}

export function createSchemaZWaveCommandClassSwitchBinary() {
    return {
        "COMMAND_CLASS_SWITCH_BINARY": Joi.object({
            "currentValue": Joi.number().min(0).max(1).allow(null),
            "duration": 0,
            "id": 37,
            "name": "COMMAND_CLASS_SWITCH_BINARY",
            "targetValue": Joi.number().min(0).max(1).allow(null),
            "value": Joi.number().min(0).max(1).allow(null),
            "version": Joi.number().valid(1, 2, 3)
        }).required()
    }
}

export function createSchemaZWaveCommandClassThermostatMode() {
    return {
        "COMMAND_CLASS_THERMOSTAT_MODE": Joi.object({
            "id": 64,
            "name": "COMMAND_CLASS_THERMOSTAT_MODE",
            "thermostatMode": Joi.number().min(0).max(15),
            "version": Joi.number().min(1).max(3),
        })
    }
}


export function createSchemaZWaveCommandClassThermostatSetpoint() {
    return {
        "COMMAND_CLASS_THERMOSTAT_SETPOINT": Joi.object({
            "id": 67,
            "name": "COMMAND_CLASS_THERMOSTAT_SETPOINT",
            "setpoints": {
                "HEATING_1": {
                    "type": 1,
                    "val": Joi.number().min(0).max(50),
                }
            },
            "version": Joi.number().min(1).max(3),
        })
    }
}

export function createSchemaZWaveCommandClassVersion(overrides: object = {}) {
    return {
        "COMMAND_CLASS_VERSION": Joi.object({
            "applicationSubVersion": Joi.number().min(0).max(255),
            "applicationVersion": Joi.number().min(0).max(20),
            "commandClassVersions": Joi.array().items(
                Joi.object({
                    "commandClassID": Joi.number().min(0).max(255),
                    "commandClassVersion": Joi.number().min(0).max(16)
                }),
            ),
            "id": 134,
            "libraryType": Joi.number().valid(0, 2, 3, 6),
            "name": "COMMAND_CLASS_VERSION",
            "protocolSubVersion": Joi.number().min(0).max(128),
            "protocolVersion": Joi.number().min(0).max(10),
            "version": Joi.number().valid(1, 2, 3),
            "hardwareVersion": Joi.number().optional()
        }),
        overrides
    }
}

export function createSchemaZWaveCommandClassWakeup(override: any = {}) {
    return {
        "COMMAND_CLASS_WAKE_UP": Joi.object({
            "id": 132,
            "intervalSeconds": 450,
            "intervalSeconds_requested": Joi.number(),
            "name": "COMMAND_CLASS_WAKE_UP",
            "notifyNodeID": 1,
            "version": Joi.number().min(0).max(2),
            ...override
        }).required()
    }
}
