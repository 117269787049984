import {pad} from "./utils";


export function convertMACToNumber(mac: string): bigint {

    const reMAC = /([0-9a-fA-F]{2})[:-]([0-9a-fA-F]{2})[:-]([0-9a-fA-F]{2})[:-]([0-9a-fA-F]{2})[:-]([0-9a-fA-F]{2})[:-]([0-9a-fA-F]{2})/;

    const match = mac.match(reMAC);

    if (!match || match.length !== 7) {
        throw new Error(`convertMACToNumber: ${mac} is not a valid MAC address format`);
    }

    const collatedMac = match[1] + match[2] + match[3] + match[4] + match[5] + match[6];

    const result = BigInt('0x' + collatedMac);

    return result;
}

export function convertNumberToMAC(_x: bigint): string {
    let x = BigInt(_x);
    let mask = BigInt(0xFF0000000000);
    let result = '';

    for (let i = 0; i < 6; i++) {
        const n1 = BigInt(mask & x);
        const n2 = BigInt(n1 >> BigInt((5-i)*8));

        mask = BigInt(mask >> BigInt(8));
        const s = n2.toString(16);
        result += s.length < 2 ? '0' + s : s;
        if (i < 5) {
            result += ':';
        }
    }
    return result.toUpperCase();
}

/**
 * For a given date, return a human readable string like:
 * '3 minutes ago', or 'in 1 day'.
 * Timespans: ms, seconds, minutes, hours, days, weeks, months, years
 * @param t Date object holding the time to format OR number representing the time difference (+ve or -ve)
 * @param now Optional date object for relative comparison
 */
export function toRelativeTimeString(t: Date | number, now?: Date): string {

    const _now = now || new Date();
    let diff;
    if (typeof t === 'object') {
        diff = _now.getTime() - t.getTime();
    } else {
        diff = t;
    }
    const absDiff = Math.abs(diff);

    const st = (v:number, futureNotPast:boolean, timeString: string): string => {
        let plural = '';
        if (v > 1) plural = 's';
        if (futureNotPast){
            return `in ${v} ${timeString}${plural}`;
        } else {
            return `${v} ${timeString}${plural} ago`;
        }
    }
    let timeUnit, timeValue;

    if (absDiff < 1000) {
        timeUnit = 'ms';
        timeValue = absDiff;
    } else if (absDiff < 60 * 1000) {
        timeUnit = 'second';
        timeValue = Math.floor(absDiff / 1000);
    } else if (absDiff < 3600 * 1000) {
        timeUnit = 'minute';
        timeValue = Math.floor(absDiff / (60 * 1000));
    } else if (absDiff < 86400000) {
        timeUnit = 'hour';
        timeValue = Math.floor(absDiff / (3600 * 1000));
    } else if (absDiff < 7 * 86400000) {
        timeUnit = 'day';
        timeValue = Math.floor(absDiff / 86400000);
    } else if (absDiff < (30 * 86400000)) {
        timeUnit = 'week';
        timeValue = Math.floor(absDiff / (7 * 86400000));
    } else if (absDiff < (365 * 86400000)) {
        timeUnit = 'month';
        timeValue = Math.floor(absDiff / (30 * 86400000));
    } else {
        timeUnit = 'year';
        timeValue = Math.floor(absDiff / (365 * 86400000));
    }

    return st(timeValue, diff < 0, timeUnit);
}

export function convertdbmToPercent(dbm: number): number {
    const convTable = [
        -113, -112, -111, -110, -109, -108, -107, -106, -105, -104, -103, -102, -101, -99, -98, -97, -96, -95, -94, -93,
        -92, -91, -90, -89, -88, -87, -86, -85, -84, -83, -82, -81, -80, -79, -78, -77, -75, -74, -73, -72, -70, -69, -68, -67,
        -65, -64, -63, -62, -60, -59, -58, -56, -55, -53, -52, -50, -50, -49, -48, -48, -47, -46, -45, -44, -44, -43, -42, -42,
        -41, -40, -39, -38, -37, -35, -34, -33, -32, -30, -29, -28, -27, -25, -24, -23, -22, -20, -19, -18, -17, -16, -15, -14,
        -13, -12, -10, -10, -10, -10, -10, -10, -10
    ];

    let _dbm = dbm;
    if (_dbm < convTable[0]) _dbm = convTable[0];
    if (_dbm > convTable[convTable.length - 1]) _dbm = convTable[convTable.length - 1];

    let result;
    for (result = 0; result < convTable.length; result += 1) {
        if (convTable[result] >= _dbm) break;
    }
    return result;
}
