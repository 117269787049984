import * as React from 'react';
import {ChartSeriesData, ChartSeriesWithData, ChartSeriesParameter, ChartSeriesType, HubChartData} from "genius-hub-types";
import * as recharts from 'recharts';
import {
    ResponsiveContainer, LineChart, Line, Legend, Tooltip, YAxis, XAxis
} from 'recharts';
import {isoTime, pad} from '../utils/utils';
import {ErrorBoundary} from "../components/ErrorBoundary";



/**
 * Take a data structure like { tag1: [ {ts, value}, {ts, value}, ...], tag2: [ {ts, value}, {ts, value}, ...]}
 * and turn it into [ {ts, tag1: tag1_value, tag2: tag2_value}, ... ]
 *
 */
export function blendData(data: HubChartData): ChartSeriesData {
    let time_groups: { [key: number]: any } = {};

    for (let tagName in data) {
        if (Array.isArray(data[tagName])) { // check that there is data, rather than an error
            for (let r of data[tagName]) {
                if (!time_groups.hasOwnProperty(r.ts)) {     // if we've not seen this timestamp before (and hence
                    // not yet set the default values)
                    time_groups[r.ts] = {ts: r.ts};
                    time_groups[r.ts][tagName] = r.value;

                } else {
                    time_groups[r.ts][tagName] = r.value;
                }
            }
        } else {
            console.warn("Unexpected format");
        }
    }


    const result: ChartSeriesData = [];

    // convert to array
    for (let ts in time_groups) {
        result.push(time_groups[ts]);
    }

    result.sort((a, b) => {
        if (a.ts < b.ts) return -1;
        if (a.ts === b.ts) return 0;
        return 1;
    });

    return result;
}



export default function HubChart(props: {
    chartSeries: ChartSeriesWithData,
    height?: number

}) {

    // console.debug("HubChart rendering", props.chartSeries);

    if (!props.chartSeries.data || props.chartSeries.data.length === 0) {
        return <><div></div><div className={"notification has-text-centered"}>No data to draw chart with!</div></>;
    }


    let yAxisLabel = '';

    // console.debug(props.chartSeries);
    // calculate the series
    if ((props.chartSeries === null) || (props.chartSeries.params === null)) {
        // debugger
    }
    const arr = Object.entries(props.chartSeries.params);
    const series = arr.map((i) => {
        const k = i[0], v: ChartSeriesParameter = i[1];

        yAxisLabel = v.unitsDescription || v.units;

        if (v.type === ChartSeriesType.Line) {
            // console.debug(v.name);

            return <Line key={`series-${k}`}
                // @ts-ignore
                         type={v.lineType}
                         dataKey={k}
                         legendType={'circle'}
                         dot={ v.dot || null}
                         activeDot={{stroke: v.colour, strokeWidth: 4}}
                         label={false}
                         strokeWidth={3}
                         stroke={v.colour}
                         connectNulls={true}
                         unit={v.units}
                         name={v.name}
                         isAnimationActive={false}

            />;
        } else {
            throw new Error(`Chart type ${v.type} not supported`);
        }

    });

    // Calculate the time range and interval for the X axis
    const minTime = props.chartSeries.data[0].ts;
    const maxTime = props.chartSeries.data[props.chartSeries.data.length - 1].ts;
    let minX = minTime;
    let maxX = maxTime;
    const range = maxX - minX;
    let interval = 300;

    if (range < 3600) {
        interval = 600;
        // minX = minTime - (minTime % 3600);
        // maxX = (maxTime) + (3600 - (maxTime % 3600));
    } else if (range < 86400) {
        interval = 3600;
        // minX = minTime - (minTime % 86400);
        // maxX = (maxTime) + (86400 - (maxTime % 86400));
    } else if (range < (7 * 86400)) {
        interval = 86400/2;
        // minX = minTime - (minTime % (86400));
        // maxX = (maxTime) + ((86400) - (maxTime % (86400)));
    } else {
        interval = 86400/2;
        // minX = minTime - (minTime % (86400));
        // maxX = (maxTime) + ((86400) - (maxTime % (86400)));
    }

    const ticks = [];
    for (let i = minX; i <= maxX; i += interval) {
        ticks.push(i);
    }

    // console.debug(`minTime=${minTime}, maxTime=${maxTime}, minX=${minX}, maxX=${maxX}`);

    // Compute tick labels on X axis
    let prevTickValue = 86399;
    function RotateTickAxis(props) {
        const {x, y, stroke, payload} = props;

        let tickLabel;
        if ( (prevTickValue % 86400) > (payload.value % 86400) ) {
            tickLabel = isoTime(payload.value).split(' ');
        }
        else {
            const dt = new Date(payload.value * 1000);
            tickLabel = [`${pad(dt.getHours())}:${pad(dt.getMinutes())}:${pad(dt.getSeconds())}`];
        }
        prevTickValue = payload.value;

        const textLines = tickLabel.map( (x, idx, lst) => {
            const fontWeight = lst.length > 1 ? 'bold' : 'normal';
            return <tspan key={idx} fontWeight={fontWeight} x="0" dy="1.2em">{x}</tspan>;
        });
        return (
            <g key={payload.value} transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{textLines}</text>
            </g>
        );
    }

    const tooltipLabelFormatter = (value: string) => {
        return <span>{isoTime(value)}</span>;
    };

    const tooltipFormatter = (value: string|number) => {
        if (typeof value === 'number') {
            return value.toFixed(1);
        }
        return value;
    };

    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];


    // compute Y axis ticks
    // console.debug("Rendering", JSON.stringify(props.chartSeries))
    return (
        // <ErrorBoundary label={"Error rendering chart"}
        //                extraInfo={props.chartSeries}
        //                actions={[{label:"Clear chart data", action: ()=>{} }]}
        // >
            <ResponsiveContainer width="100%" minWidth={320} minHeight={props.height || 240 }>
                <LineChart data={props.chartSeries.data}
                           margin={{top: 20, right: 30, left: 40, bottom: 0}}>

                    <XAxis
                        dataKey="ts"
                        type={"number"}
                        allowDataOverflow={true}
                        // domain={['dataMin', 'dataMax']}
                        domain={[minX, maxX]}
                        tick={<RotateTickAxis/>}
                        // tickFormatter={hhmm}
                        height={110}
                        ticks={ticks}
                    />

                    <YAxis label={ { value: yAxisLabel, angle: -90, position: 'left'} }/>

                    {/*{<CartesianGrid strokeDasharray="3 3"/>}*/}
                    <Tooltip
                        labelFormatter={tooltipLabelFormatter}
                        formatter={ tooltipFormatter }
                    />
                    <Legend verticalAlign="top" height={128} />
                    {/*//{<ReferenceLine x="Page C" stroke="green" label="Min PAGE"/>}*/}
                    {/*//{<ReferenceLine y={20} label="Max" stroke="red" strokeDasharray="3 3"/>}*/}
                    {series}
                </LineChart>
            </ResponsiveContainer>


         // </ErrorBoundary>
    );
}

