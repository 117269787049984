import {Vector3} from "./vectors";


export function getColourAlongGradient(ratio: number, stops: Array<Vector3>): Vector3 {
    if (!stops || stops.length === 0) {
        return new Vector3(0,0,0);
    }
    const N1 =  (stops.length - 1);
    const x = ratio * N1;
    const idx1 = Math.floor(x), idx2 = Math.ceil(x);
    const col1 = stops[idx1], col2 = stops[idx2];
    const progress = x-Math.trunc(x);
    const v = col2.sub(col1);
    const result = v.scale(progress).add(col1);
    // console.debug(`x=${x} progress=${progress} col1[${idx1}]=${col1}, col2[${idx2}]=${col2}`)
    return result;
}
