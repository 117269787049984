
import {LOGGING_IN, LOGIN_OK, LOGIN_FAIL, getStatusDescription, GOT_HUB_DATA_FAIL} from './hubs'


const LOG_SIZE = 20;

/**********************
 * ACTION CONSTANTS   *
 *********************/
export const LOG = "LOG";
export const LOG_CLEAR = "LOG_CLEAR";
export const NOTIFICATION = "NOTIFICATION";

/**********************
 * INTERFACES         *
 *********************/

export enum LogType {
    Trace,
    Debug,
    Info,
    Warn,
    Error,
    Critical
}

interface LogEntry {
    ts: number,
    entry: string,
    type: LogType
}

export interface LogState {
    log: Array<LogEntry>,
    historyLength: number
}

export enum Type {
    Trace,
    Debug,
    Info,
    Warning,
    Error,
    Critical
}


/**********************
 * ACTION CREATORS    *
 *********************/
export const actionLog = (message: string, type: LogType) => {
    return {
        type: LOG,
        payload: {
            type, message
        }
    }
};

export const actionClear = () => {
    return {
        type: LOG_CLEAR,
        payload: {}
    }
};

export function actionNotify(message: string, type: LogType) {
    return {
        type: NOTIFICATION,
        payload: {
            message,
            type
        }
    }
}



/**********************
 * REDUCERS           *
 *********************/
export const initialState: LogState = {
    log: [],
    historyLength: 100
};

export default function reduce(state = initialState, action: any) {
    let result = {...state};
    let newEntry: LogEntry = { ts: Date.now(), entry: '', type: LogType.Info };

    switch (action.type) {
        case LOG_CLEAR:
            result.log = [];
            break;

        case LOGGING_IN: {
            newEntry.entry = `Logging in with username '${action.payload.username}'...`;
            break;
        }

        case LOGIN_OK: {
            newEntry.entry = `Login OK`;
            break;
        }

        case LOGIN_FAIL: {
            newEntry.entry = `Login Failed: code = ${action.payload} ${getStatusDescription(action.payload)}`;
            newEntry.type = LogType.Error;
            break;
        }

        case LOG:
            newEntry.entry = action.payload.message;
            newEntry.type = action.payload.type;
            break;

        case NOTIFICATION:
            newEntry.entry = action.payload.message;
            newEntry.type = action.payload.type;
            break;

        case GOT_HUB_DATA_FAIL:
            newEntry.entry = `Error communicating with hub '${action.payload.hubName}': ${action.payload.errMsg}`;
            newEntry.type = LogType.Error;
            break;

        default:
            return result;
    }

    // limit the log size
    return {
        ...state,
        log: [ newEntry, ...state.log.slice(0, state.historyLength-1) ]
    };

}
