import {getHubProxyByHubName} from './hubsCache';
import {WifiSettingsStatus, WifiSSID, WifiState} from 'genius-hub-types';
import {ConnectionStatus} from 'genius-hub-proxy';
import {ParseWifiData} from "../utils/ParseWifiData";


/**********************
 * ACTION CONSTANTS   *
 *********************/
export const WIFI_COUNTRY = "WIFI_COUNTRY";
export const WIFI_MODE = "WIFI_MODE";
export const WIFI_APPLY = "WIFI_APPLY";
export const WIFI_REVERT = "WIFI_REVERT";
export const WIFI_RESET = "WIFI_RESET";
export const WIFI_REFRESH = "WIFI_REFRESH";
export const WIFI_SAVE = "WIFI_SAVE";
export const WIFI_SCAN = "WIFI_SCAN";
export const WIFI_STATION_TEST = "WIFI_STATION_TEST";
export const WIFI_STATION_SSID = "WIFI_STATION_SSID";
export const WIFI_STATION_KEY = "WIFI_STATION_KEY";
export const WIFI_STATION_ENCRYPTION = "WIFI_STATION_ENCRYPTION";

export const WIFI_UPDATE_SYSTEM_STATE = "WIFI_UPDATE_SYSTEM_STATE";
export const WIFI_UPDATE_ACCESS_POINTS = "WIFI_UPDATE_ACCESS_POINTS";

export const WIFI_QUERYING = "WIFI_QUERYING";
export const WIFI_QUERY_COMPLETE = "WIFI_QUERY_COMPLETE";


export const WIFI_SET_STATUS = "WIFI_SET_STATUS";
export const WIFI_UPDATE_SSIDS = "WIFI_UPDATE_SSIDS";

function Timeout(duration: number): Promise<void> {
    let resolver = null;

    const p = new Promise<void>((resolve, reject) => {
        resolver = resolve;
    });

    setTimeout(resolver, duration);

    return p;
}

/**********************
 * INTERFACES         *
 *********************/


export interface StationSettings {
    ssid: string,
    key: string,
    encryption: string
}


/**********************
 * ACTION CREATORS    *
 *********************/

export const actionWifiSetStatus = (status: WifiSettingsStatus, hubName?: string) => {
    return {
        type: WIFI_SET_STATUS,
        payload: {hubName, status}
    };
}

export const actionWifiGetStatus = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.QueryingStatus, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            for (let i = 0; i < 10; i++) {
                const result = await hubProxy.hubAPI.wireless('GET', '/network/system_status', null, 120000);

                if (result.connectionStatus === ConnectionStatus.OK) {
                    if (result.data.hasOwnProperty('wireless-mode')) {
                        if (result.data['wireless-mode'] === "off") {
                            dispatch({type: WIFI_MODE, payload: {hubName: activeHubName, mode: 'off'}});
                        }
                    } else {
                        dispatch({
                            type: WIFI_UPDATE_SYSTEM_STATE,
                            payload: {hubName: activeHubName, systemState: result.data}
                        });
                    }
                    break;
                } else {
                    await Timeout(10000);
                }

            }

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

export const actionWifiGetAccessPoints = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.GettingsSSIDs, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.wifi();
            if (result.connectionStatus === ConnectionStatus.OK) {

                const apList: Array<WifiSSID> = ParseWifiData(result.data.data);

                dispatch({type: WIFI_UPDATE_SSIDS, payload: {hubName: activeHubName, ssids: apList}});
            } else {
                console.error("actionWifiGetAccessPoints: " + ConnectionStatus[result.connectionStatus]);
            }

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

export const actionWifiSetMode = (mode: string, hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.ApplyingSettings, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            let result = await hubProxy.hubAPI.wireless('PUT', '/configuration/current/mode', {val: mode});
            await dispatch(actionWifiSetStationSettings({
                ssid: 'Choose a wifi network',
                key: '',
                encryption: 'psk2'
            }));
            if (result.connectionStatus === ConnectionStatus.OK) {
                dispatch({type: WIFI_MODE, payload: {hubName: activeHubName, mode}});
                await dispatch(actionWifiApply(activeHubName));
            } else {
                console.error("actionWifiSetMode: " + ConnectionStatus[result.connectionStatus]);
            }
            await dispatch(actionWifiGetStatus(activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

export const actionWifiSetStationSettings = (settings: StationSettings, hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.ApplyingSettings, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result1 = await hubProxy.hubAPI.wireless('PUT', '/configuration/current/station/ssid', {val: settings.ssid});
            const result2 = await hubProxy.hubAPI.wireless('PUT', '/configuration/current/station/key', {val: settings.key});
            const result3 = await hubProxy.hubAPI.wireless('PUT', '/configuration/current/station/encryption', {val: settings.encryption});

            if ((result1.connectionStatus === ConnectionStatus.OK) &&
                (result2.connectionStatus === ConnectionStatus.OK) &&
                (result3.connectionStatus === ConnectionStatus.OK)) {
                await dispatch(actionWifiApply(activeHubName));
            } else {
                console.error(`actionWifiSetStationSettings: ${ConnectionStatus[result1.connectionStatus]}, ${ConnectionStatus[result2.connectionStatus]}, ${ConnectionStatus[result3.connectionStatus]}`);
            }
            await dispatch(actionWifiGetStatus(activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

export const actionWifiApply = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.ApplyingSettings, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.wireless('POST', '/configuration/current/apply_configuration',
                null,
                10000
            );
            if (result.connectionStatus === ConnectionStatus.OK) {
            } else {
                console.error("actionWifiApply: " + ConnectionStatus[result.connectionStatus]);
            }

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

export const actionWifiRevert = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.ApplyingSettings, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.wireless('POST', '/configuration/current/revert_configuration');
            if (result.connectionStatus === ConnectionStatus.OK) {
            } else {
                console.error("actionWifiRevert: " + ConnectionStatus[result.connectionStatus]);
            }
            await dispatch(actionWifiGetStatus(activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

export const actionWifiSave = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        dispatch(actionWifiSetStatus(WifiSettingsStatus.ApplyingSettings, activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.wireless('POST', '/configuration/current/save_configuration');
            if (result.connectionStatus === ConnectionStatus.OK) {
            } else {
                console.error("actionWifiSave: " + ConnectionStatus[result.connectionStatus]);
            }
            await dispatch(actionWifiGetStatus(activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
        dispatch(actionWifiSetStatus(WifiSettingsStatus.Idle, activeHubName));
    }
};

/**********************
 * REDUCERS           *
 *********************/
export const initialState: WifiState = {
    wifiSystemState: {
        "network-interface": {
            "mac-address": "",
            "ip-address": "",
            "broadcast": "",
            "netmask": ""
        },
        "wireless-device": {
            "wireless-mode": "",
            "ssid": "",
            "channel": "",
            "signal": "",
            "encryption": ""
        },
        "station-mode-configuration": {
            "ssid": "",
            "key": "",
            "encryption": "",
            "country": ""
        },
        "station-network-status": {
            "access-point-visible": "",
            "connected-to-access-point": ""
        }
    },
    status: WifiSettingsStatus.Idle,
    ssids: []
};

// state is from immer, so can be modified directly
export const reducerWifi = (state = initialState, action: any) => {

    switch (action.type) {
        case WIFI_SET_STATUS:
            state.status = action.payload.status;
            break;

        case WIFI_QUERY_COMPLETE:
            state.status = WifiSettingsStatus.Idle;
            break;

        case WIFI_UPDATE_SYSTEM_STATE:
            state.wifiSystemState = action.payload.systemState;
            break;

        case WIFI_UPDATE_SSIDS:
            state.ssids = action.payload.ssids;
            break;

        case WIFI_MODE:
            state.wifiSystemState["wireless-device"]["wireless-mode"] = action.payload.mode;
            break;

    }
};

