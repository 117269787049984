import {FOCUS_GAINED, FOCUS_LOST, FOCUS_CHECK} from '../app';
import {actionStartPoll, actionStopPoll} from './poll';

const pollOnFocus = store => next => action => {

    if (action.type === FOCUS_GAINED) {
        store.dispatch(actionStartPoll());
    }
    if (action.type === FOCUS_LOST) {
        store.dispatch(actionStopPoll());
    }

    return next(action)
};


export default pollOnFocus;
