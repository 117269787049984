import {
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic, createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveDeviceListening, createZWaveConfigValueSchema,
    DeviceZWaveConfigDefaults
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


DeviceZWaveConfigDefaults['PH-ERS-A'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(16).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().valid(1).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassBasic(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['PH-ERS-A']),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassSwitchBinary(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassSwitchAll(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 1, 2),
            ...createZWaveConfigValueSchema(2, 1, 1),
            ...createZWaveConfigValueSchema(3, 1, 1),
            ...createZWaveConfigValueSchema(4, 1, 1),
            ...createZWaveConfigValueSchema(5, 1, 0),
            ...createZWaveConfigValueSchema(6, 1, 0),
            ...createZWaveConfigValueSchema(11, 1, 1),
        }),
    })
});