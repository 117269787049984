import {
    BatteryData,
    DataNode, DeviceTypeZWave, Issue, IssueLevel, Issues,
    ManufacturerSpecificData,
    regex_zwave_homeid, SingleValueData,
    WakeupData,
    ZWaveData,
    ZWaveNode
} from "genius-hub-types";
import {parseInt} from "lodash";
import {deviceHash} from "./device_tools";
import {DeviceDefinitionZWave} from "genius-hub-types";


export function getHomeID(rootNode: DataNode): string | null {
    if (!rootNode) return null;
    if (rootNode.hasOwnProperty('addr') && rootNode.addr === 'site') {
        if (rootNode.hasOwnProperty('childNodes')) {
            const rootKeys = Object.keys(rootNode.childNodes);
            for (const i of rootKeys) {
                if (i.match(regex_zwave_homeid)) {
                    return i;
                }
            }
        }
    }
    return null;
}

export function getZWaveDeviceDataNode(rootNode: DataNode, nodeID: number, _homeID?: string): DataNode | null {
    const homeID = _homeID || getHomeID(rootNode);
    if (rootNode.hasOwnProperty('childNodes') && (homeID !== null)) {
        if (rootNode.childNodes.hasOwnProperty(homeID)) {
            if (rootNode.childNodes[homeID].hasOwnProperty('childNodes')) {
                if (rootNode.childNodes[homeID].childNodes.hasOwnProperty(nodeID)) {
                    return rootNode.childNodes[homeID].childNodes[nodeID.toString()];
                }
            }
        }
    }
    return null;
}


export function getZWaveNode(zwaveData: ZWaveData, nodeID: number) {
    const result = zwaveData.nodes.find((zwaveNode) => zwaveNode.id === nodeID);

    if (result !== undefined) {
        return result;
    }
    return null;
}


export function getZWaveDeviceNode(deviceRootData: DataNode, nodeID: number): DataNode | null {
    const key = Object.keys(deviceRootData.childNodes).find((keyName) => keyName.match(/0x[0-9a-fA-F]{8}/) !== null);

    if (key !== undefined) {
        const zwaveRootNode = deviceRootData.childNodes[key];
        if (zwaveRootNode.childNodes.hasOwnProperty(nodeID)) {
            return zwaveRootNode.childNodes[nodeID];
        }
    }
    return null;
}

export function getZWaveNodeCommandClass(zwaveNode: ZWaveNode, commandClassName: string): any | null {

    if (zwaveNode.commandClasses.hasOwnProperty(commandClassName)) {
        return zwaveNode.commandClasses[commandClassName];
    }
    return null;
}



export function getDeviceManfSpecData(zwaveNode: ZWaveNode): ManufacturerSpecificData | null {

    if (zwaveNode.commandClasses?.hasOwnProperty('COMMAND_CLASS_MANUFACTURER_SPECIFIC')) {
        return {
            manfID: zwaveNode.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.manufacturerID,
            productType: zwaveNode.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.productType,
            productID: zwaveNode.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.productID
        }
    }

    return null;
}


export function getWakeupData(zwaveNode: ZWaveNode): WakeupData | null {

    if (zwaveNode.commandClasses.hasOwnProperty('COMMAND_CLASS_WAKE_UP')) {
        return {
            node: zwaveNode.commandClasses.COMMAND_CLASS_WAKE_UP.notifyNodeID,
            interval: zwaveNode.commandClasses.COMMAND_CLASS_WAKE_UP.intervalSeconds
        }
    }

    return null;
}

export function getBatteryData(zwaveNode: ZWaveNode): BatteryData | null {

    if (zwaveNode.commandClasses.hasOwnProperty('COMMAND_CLASS_BATTERY')) {
        return {
            value: zwaveNode.commandClasses.COMMAND_CLASS_BATTERY.value,
        }
    }

    return null;
}

export function getAirTempData(zwaveNode: ZWaveNode): SingleValueData | null {

    if (zwaveNode.commandClasses.hasOwnProperty('COMMAND_CLASS_SENSOR_MULTILEVEL')) {
        const cc: any = zwaveNode.commandClasses.COMMAND_CLASS_SENSOR_MULTILEVEL;
        if (cc.hasOwnProperty('sensors') && cc.sensors.hasOwnProperty('TEMPERATURE')) {
            return {
                value: cc.sensors.TEMPERATURE,
            }
        }
    }

    return null;
}


export function getZWaveNodeDeviceTypeInfo(node: ZWaveNode): DeviceTypeZWave|null {

    // get the catalog entry for the device
    const manfSpecificData: ManufacturerSpecificData = getDeviceManfSpecData(node) as ManufacturerSpecificData;
    if (!manfSpecificData) {
        return null;
    }
    const hash = deviceHash(manfSpecificData);
    const def = DeviceDefinitionZWave.hasOwnProperty(hash) ? DeviceDefinitionZWave[hash] : null;
    if (!def) {
        return null;
    }

    return def;
}
