import isEqual from "lodash/isEqual";
import {isNull} from "lodash";


export function pad(num: number | bigint, digits: number = 2): string {
    return ("" + num).padStart(digits, '0');
}


export function hhmmss(secs: number): string {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}


export function hhmm(secs: number): string {
    let minutes = Math.floor(secs / 60);

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}`;
}

/**
 * Convert a timestamp to a nice string for display
 * @param timestamp - timestamp in seconds
 */
export function timestampToString(timestamp: number): string {

    let ts = Math.abs(timestamp);

    const years = Math.floor(ts / (365 * 86400));
    ts = ts % (365 * 86400);
    const months = Math.floor(ts / (30 * 24 * 60 * 60));
    ts = ts % (30 * 86400);
    // const weeks = Math.floor(ts / (7 * 24 * 60 * 60));
    // ts = ts % weeks;
    const days = Math.floor(ts / (24 * 60 * 60));
    ts = ts % 86400;
    const hours = Math.floor(ts / (60 * 60));
    ts = ts % 3600;
    const minutes = Math.floor(ts / 60);
    ts = ts % 60;
    const seconds = ts;

    const fmt = (x: number, unit: string) => {
        if (x === 0) return '';
        if (x === 1) return `${x} ${unit} `;
        return `${x} ${unit}s `;
    }

    const result =
        fmt(years, "year") +
        fmt(months, "month") +
        fmt(days, "day") +
        fmt(hours, "hour") +
        fmt(minutes, "minute") +
        fmt(seconds, "second");

    return result.trim();
}

/**
 * Relative timestring (negative values are in the past, positive in the future)
 * @param timestamp - timestamp in seconds
 * @returns string - e.g. "1 day 2 hours ago" (if timestamp is negative)
 */
export function relativeTimestamp(timestamp: number): string {
    if (timestamp < 0) {
        return timestampToString(timestamp) + ' ago';
    } else {
        return 'in ' + timestampToString(timestamp);
    }
}


export function numberToHex(num: number, width?: number) {
    if (num === null) return '-';

    let hexString = num.toString(16);
    if (width && width > hexString.length) {
        hexString = '0'.repeat(width - hexString.length) + hexString;
    }
    return hexString;
}

export function isoTime(epochSeconds: number): string {

    if (epochSeconds === 0) {
        return "never";
    }

    if (Number.isNaN(epochSeconds) || epochSeconds === null || epochSeconds === undefined) {
        return "-";
    }

    const dt = new Date(epochSeconds * 1000);

    return `${dt.getFullYear()}-${pad(dt.getMonth() + 1)}-${pad(dt.getDate())} ${pad(dt.getHours())}:${pad(dt.getMinutes())}:${pad(dt.getSeconds())}`;
}

/*
export function lzw_encode(s:string): string {
  let dict:{[key:string]:number} = {};
  let data = (s + "").split("");
  let out: Array<string> = [];
  let currChar: string;
  let phrase: string = data[0];
  let code = 256;
  for (let i=1; i<data.length; i++) {
    currChar=data[i];
    if (dict[phrase + currChar] != null) {
      phrase += currChar;
    }
    else {
      out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
      dict[phrase + currChar] = code;
      code++;
      phrase=currChar;
    }
  }
  out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
  for (let i=0; i<out.length; i++) {
    out[i] = String.fromCharCode(out[i]);
  }

  console.debug(`Dict: ${JSON.stringify(dict)}`);
  const result = out.join("");
  console.debug(`output: ${out}`);
  return result;
}

// Decompress an LZW-encoded string
export function lzw_decode(s) {
  let dict = {};
  let data = (s + "").split("");
  let currChar = data[0];
  let oldPhrase = currChar;
  let out = [currChar];
  let code = 256;
  let phrase;
  for (let i=1; i<data.length; i++) {
    let currCode = data[i].charCodeAt(0);
    if (currCode < 256) {
      phrase = data[i];
    }
    else {
      phrase = dict[currCode] ? dict[currCode] : (oldPhrase + currChar);
    }
    out.push(phrase);
    currChar = phrase.charAt(0);
    dict[code] = oldPhrase + currChar;
    code++;
    oldPhrase = phrase;
  }
  return out.join("");
}
*/


/**
 * Sort based on multiple fields. e.g.
 *
 *    const lst = [ {type: 1, priority: 1}, {type: 2, priority: 1},{type: 1, priority: 2},{type: 2, priority: 2}];
 *    const sorted = lst.sort(fieldSortOptimized(['priority', 'type']));
 *
 * @param fields - Array of field names. Will be sorted by first field, then second, then... prepend '-' to reverse sort order
 * @returns {Function}
 */
export function fieldSorterOptimized(fields: any) {
    let dir: Array<number> = [], i, l = fields.length;
    fields = fields.map(function (o: any, i: any) {
        if (o[0] === "-") {
            dir[i] = -1;
            o = o.substring(1);
        } else {
            dir[i] = 1;
        }
        return o;
    });

    return function (a: any, b: any) {
        for (i = 0; i < l; i++) {
            let o = fields[i];
            if (a[o] > b[o]) return dir[i];
            if (a[o] < b[o]) return -(dir[i]);
        }
        return 0;
    };
}


export interface ObjectDiff<T> {
    notEqual: Array<keyof T>,
    extraFields: Array<keyof T>,
    missingFields: Array<keyof T>
}


export function removeArrayDuplicates<T>(arr: Array<T>): Array<T> {
    return arr.filter((value, index) => arr.indexOf(value) === index);
}


export function objectDiff<T>(a: any, b: any): ObjectDiff<T> {
    const result: ObjectDiff<T> = {notEqual: [], extraFields: [], missingFields: []};

    const aKeys: Array<string> = Object.keys(a);
    const bKeys: Array<string> = Object.keys(b);

    aKeys.forEach(key => {
        // console.log(`key: ${key}, aKeys: ${aKeys}, bKeys: ${bKeys}`)
        if (bKeys.includes(key)) {
            if (!isEqual(a[key], b[key])) {
                result.notEqual.push(key as keyof T);
            }
        } else {
            result.missingFields.push(key as keyof T);
        }
    });

    bKeys.forEach(key => {
        if (!aKeys.includes(key)) {
            result.extraFields.push(key as keyof T);
        }
    })

    return result;
}


export const emptyArray = (length: number, fill: number) => Array.from({length}, (_, i) => fill);


export interface SemanticVersion {
    major: number,
    minor: number,
    patch: number
    prerelease?: string,
    build?: string
}

export function semanticVersionFromString(s: string): SemanticVersion | null {
    let result: SemanticVersion | null = null;
    if (!s) return result;

    const re = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
    const match = s.match(re);

    if (match?.length && match.length >= 3) {
        result = {
            major: parseInt(match[1]),
            minor: parseInt(match[2]),
            patch: parseInt(match[3]),
        };

        if (match.length >= 4) {
            result.prerelease = match[4]
        }
        if (match.length >= 5) {
            result.build = match[5]
        }
    }

    return result;
}

/**
 Compare two semantic versions
 @return -1 if a < b, 0 if a == b, 1 if a > b

 */
export function compareSemanticVersion(a: SemanticVersion | null, b: SemanticVersion | null): number {
    if (!isNull(a) && isNull(b)) return 1;
    if (isNull(a) && !isNull(b)) return -1;
    if (isNull(a) && isNull(b)) return 0;

    if ((a as SemanticVersion).major > (b as SemanticVersion).major) return 1;
    if ((a as SemanticVersion).major < (b as SemanticVersion).major) return -1;

    if ((a as SemanticVersion).minor > (b as SemanticVersion).minor) return 1;
    if ((a as SemanticVersion).minor < (b as SemanticVersion).minor) return -1;

    if ((a as SemanticVersion).patch > (b as SemanticVersion).patch) return 1;
    if ((a as SemanticVersion).patch < (b as SemanticVersion).patch) return -1;

    if (((a as SemanticVersion).prerelease || "") > ((b as SemanticVersion).prerelease || "")) return 1;
    if (((a as SemanticVersion).prerelease || "") < ((b as SemanticVersion).prerelease || "")) return -1;

    if (((a as SemanticVersion).build || "") > ((b as SemanticVersion).build || "")) return 1;
    if (((a as SemanticVersion).build || "") < ((b as SemanticVersion).build || "")) return -1;

    return 0;
}


/**
 * deepEqual - Deep comparison of two objects
 * @param obj1 - first object
 * @param obj2 - second object
 * @returns true if the objects are deeply equal, false otherwise
 */
export function deepEqual(obj1: any, obj2: any) {
    // Base case: If both objects are identical, return true.
    if (obj1 === obj2) {
        return true;
    }
    // Check if both objects are objects and not null.
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }
    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
        return false;
    }
    // Iterate through the keys and compare their values recursively.
    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    // If all checks pass, the objects are deep equal.
    return true;
}
