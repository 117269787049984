import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel,
    createZWaveConfigValueSchema,
    createSchemaZWaveCommandClassBattery,
    createSchemaZWaveCommandClassMark,
    createSchemaZWaveCommandClassMultiCmd,
    createSchemaZWaveCommandClassSensorBinary,
    createSchemaZWaveCommandClassWakeup
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


DeviceZWaveConfigDefaults['PH-WRS-B'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(false).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(7).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().valid(1).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassBattery(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['PH-WRS-B']),
        ...createSchemaZWaveCommandClassMark(),
        ...createSchemaZWaveCommandClassMultiCmd(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassSensorBinary(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassMultilevel({
            "LUMINANCE": Joi.number().min(0).max(100),
            "TEMPERATURE": Joi.number().min(-20).max(100),
        }, {
            "TEMPERATURE": Joi.object({
                "limit": Joi.object({
                    "lower": -20,
                    "upper": 100
                })
            }),
        }, {} ),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(2, 1, -1), //, 'Temperature report delta'
            ...createZWaveConfigValueSchema(3, 1, 88), //, 'Humidity report delta'
            ...createZWaveConfigValueSchema(4, 1, 99), //, 'Temperature offset'
            ...createZWaveConfigValueSchema(5, 1, -100), //, 'Humidity offset'
            ...createZWaveConfigValueSchema(6, 1, 55), //, 'Proximity sensor retrigger interval'
            ...createZWaveConfigValueSchema(7, 1, 92), //, 'Proximity sensor enable'
            ...createZWaveConfigValueSchema(8, 1, 8), //, 'LED brightness'
            ...createZWaveConfigValueSchema(9, 1, 0), //, 'LED timeout'
            ...createZWaveConfigValueSchema(10, 1, 0), //, 'Temperature  hysteresis'
            ...createZWaveConfigValueSchema(11, 1, 0), //, 'Min temp limit'
            ...createZWaveConfigValueSchema(12, 1, 0), //, 'Max temp limit'
            ...createZWaveConfigValueSchema(13, 1, 1), //, 'Relay state report interval'
            ...createZWaveConfigValueSchema(20, 1, 30), //, 'Relay state report interval'
            ...createZWaveConfigValueSchema(21, 1, 1), //, 'Relay state report interval'
            ...createZWaveConfigValueSchema(22, 1, 0), //, 'Relay state report interval'
        }),
        ...createSchemaZWaveCommandClassWakeup({"intervalSeconds": 1800})
    })
});

