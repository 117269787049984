"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cluster = exports.createDefaultZigbeeData = exports.ZigbeeActionType = void 0;
var ZigbeeActionType;
(function (ZigbeeActionType) {
    ZigbeeActionType[ZigbeeActionType["FORM_NETWORK"] = 0] = "FORM_NETWORK";
    ZigbeeActionType[ZigbeeActionType["LEAVE_NETWORK"] = 1] = "LEAVE_NETWORK";
    ZigbeeActionType[ZigbeeActionType["ALLOW_JOIN"] = 2] = "ALLOW_JOIN";
    ZigbeeActionType[ZigbeeActionType["CANCEL_ALLOW_JOIN"] = 3] = "CANCEL_ALLOW_JOIN";
    ZigbeeActionType[ZigbeeActionType["DISCOVER_DEVICES"] = 4] = "DISCOVER_DEVICES";
    ZigbeeActionType[ZigbeeActionType["DISCOVER_DEVICE"] = 5] = "DISCOVER_DEVICE";
    ZigbeeActionType[ZigbeeActionType["COMMISSION_DEVICE"] = 6] = "COMMISSION_DEVICE";
    ZigbeeActionType[ZigbeeActionType["PURGE_DEVICE"] = 7] = "PURGE_DEVICE";
    ZigbeeActionType[ZigbeeActionType["UPDATE_CLUSTER"] = 8] = "UPDATE_CLUSTER";
    ZigbeeActionType[ZigbeeActionType["UPDATE_NETWORK_MAP"] = 9] = "UPDATE_NETWORK_MAP";
    ZigbeeActionType[ZigbeeActionType["START_ENERGY_SCAN"] = 10] = "START_ENERGY_SCAN";
    ZigbeeActionType[ZigbeeActionType["START_ACTIVE_SCAN"] = 11] = "START_ACTIVE_SCAN";
    ZigbeeActionType[ZigbeeActionType["STOP_SCAN"] = 12] = "STOP_SCAN";
    ZigbeeActionType[ZigbeeActionType["CHANGE_CHANNEL"] = 13] = "CHANGE_CHANNEL";
    ZigbeeActionType[ZigbeeActionType["REQUEST_DEVICE_LEAVE"] = 14] = "REQUEST_DEVICE_LEAVE";
})(ZigbeeActionType || (exports.ZigbeeActionType = ZigbeeActionType = {}));
function createDefaultZigbeeData() {
    return {
        "network_manager": {
            "extended_pan_id": '',
            "hardware_path": '',
            "last_network_key_update_time": 0,
            "last_network_map_time": 0,
            "last_network_open_time": 0,
            "mac_to_meta_data": {},
            "mac_to_status": {},
            "manufacturer": '',
            "network_join_enabled": false,
            "network_map": {
                coordinator: { node_id: 0 }
            },
            "network_status": '',
            "node_euid_mac": '',
            "node_id": 0,
            "node_type": '',
            "pan_id": 0,
            "radio_channel": 0,
            "radio_tx_power": 0,
            "uncommissioned_devices": [],
            "undiscovered_devices": [],
            scan_results: {
                active: {
                    channel_mask: 0,
                    duration: 0,
                    scan_time: 0,
                    results: []
                },
                energy: {
                    channel_mask: 0,
                    duration: 0,
                    scan_time: 0,
                    results: []
                }
            }
        },
        "data_manager": {
            "nodes": { addr: '', type: '', childNodes: {}, childValues: {} }
        },
    };
}
exports.createDefaultZigbeeData = createDefaultZigbeeData;
var Cluster;
(function (Cluster) {
    Cluster[Cluster["Basic"] = 0] = "Basic";
    Cluster[Cluster["Power"] = 1] = "Power";
    Cluster[Cluster["Identify"] = 3] = "Identify";
    Cluster[Cluster["TimeServer"] = 10] = "TimeServer";
    Cluster[Cluster["OTA_Update"] = 25] = "OTA_Update";
    Cluster[Cluster["PollControl"] = 32] = "PollControl";
    Cluster[Cluster["Thermostat"] = 513] = "Thermostat";
    Cluster[Cluster["ThermostatUI"] = 516] = "ThermostatUI";
    Cluster[Cluster["Diagnostics"] = 2821] = "Diagnostics";
})(Cluster || (exports.Cluster = Cluster = {}));
