
import hmac from 'js-crypto-hmac';
import {sha256} from "sha.js";
import {isNode} from "./platform";
import {deepEqual} from "./utils";


export interface AuthExchange {
    id: string,
    message: string,
    hash: Uint8Array
}

export interface AuthExchangeOffering {
    offer: AuthExchange,
    signThis: string
}


export function generateSignature(username: string, password: string): string {
    return new sha256().update(username + password).digest('hex');
}


export async function generateAuthExchange(username: string, message: string, secret: string): Promise<AuthExchange> {

    const _message = new TextEncoder().encode(message);
    const _secret = new TextEncoder().encode(secret);
    const hash = await hmac.compute(_message, _secret, 'SHA-256');
    return {
        id: username,
        message: message,
        hash
    }
}

export async function validateAuthExchange(offering: AuthExchangeOffering, secret: string, response: AuthExchange): Promise<boolean> {
    const responseShouldBe = offering.offer.message + offering.signThis;

    if (response.message !== responseShouldBe) {
        return false;
    }

    const compareTo = await generateAuthExchange(offering.offer.id, responseShouldBe, secret);

    return deepEqual(compareTo.hash, response.hash);
}
