import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel, createZWaveConfigValueSchema
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


function createESW(sku: string) {
    return DeviceZWaveConfigDefaults[sku] = createSchemaZWaveDeviceListening().append({
        nodeInfo: Joi.object({
            "bListening": Joi.boolean().valid(true).required(),
            "bSensor1000ms": Joi.boolean().valid(false).required(),
            "bSensor250ms": Joi.boolean().valid(false).required(),
            "basicDeviceClass": Joi.number().valid(4).required(),
            "genericDeviceClass": Joi.number().valid(16).required(),
            "optFunc": Joi.boolean().valid(true).required(),
            "protocolSpecificPart1": Joi.number().valid(83).required(),
            "protocolSpecificPart2": Joi.number().valid(28).required(),
            "protocolSpecificPart3": Joi.number().valid(0).required(),
            "specificDeviceClass": Joi.number().valid(0).required(),
        }),
        commandClasses: Joi.object().append({
            ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave[sku]),
            ...createSchemaZWaveCommandClassAssociation(),
            ...createSchemaZWaveCommandClassBasic(),
            ...createSchemaZWaveCommandClassNOP(),
            ...createSchemaZWaveCommandClassVersion(),
            ...createSchemaZWaveCommandClassThermostatMode(),
            ...createSchemaZWaveCommandClassSwitchBinary(),
            ...createSchemaZWaveCommandClassIndicator(),
            ...createSchemaZWaveCommandClassPowerLevel(),
            ...createSchemaZWaveCommandClassSchedule(),
            ...createSchemaZWaveCommandClassMultilevel(),
            ...createSchemaZWaveCommandClassConfiguration({
                ...createZWaveConfigValueSchema(1, 1, 0), // 'Enable fail safe timer'
                ...createZWaveConfigValueSchema(2, 1, 0), // 'Temp scale (0 = deg C, 255 = deg F)'
                ...createZWaveConfigValueSchema(3, 2, 600), // 'Temp reporting intervals (seconds)'
                ...createZWaveConfigValueSchema(4, 2, 5), // 'Temp report trigger delta 0.1C'
                ...createZWaveConfigValueSchema(5, 2, 0), // 'Temp cut off'
            }),
        })
    });
}
DeviceZWaveConfigDefaults['HO-ESW-C'] = createESW('HO-ESW-C');
DeviceZWaveConfigDefaults['HO-ESW-D'] = createESW('HO-ESW-D');
DeviceZWaveConfigDefaults['HO-ESW-E'] = createESW('HO-ESW-E');
DeviceZWaveConfigDefaults['HO-ESW-E'] = createESW('HO-ESW-E-TMP');

