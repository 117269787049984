import {ZWaveData, ZWaveNode} from "genius-hub-types";


export function getRoutes(from: number, to: number, zwaveData: ZWaveData, currentRoute: Array<number> = []): Array<Array<number>> {
    const routes: Array<Array<number>> = [];

    const rootNode: ZWaveNode|undefined = zwaveData.nodes.find((node) => node.id === from);

    if (!rootNode || !rootNode.listening) {
        return [];
    }

    if (currentRoute.length === 0) {
        currentRoute = [from];
    }

    const neighbours = rootNode.neighbours?.list || rootNode.nodeNeighbourList || [];
    for (const neighbourID of neighbours) {
        // don't go back on ourselves
        if (neighbourID === from) {
            continue;
        }
        // don't create a loop
        if (currentRoute.includes(neighbourID)) {
            continue;
        }
        // if we've found the destination, add the route to the list
        if (neighbourID === to) {
            routes.push([...currentRoute, neighbourID]);
        }

        routes.push(...getRoutes(neighbourID, to, zwaveData, [...currentRoute, neighbourID]));
    }
    return routes;
}


export function nodeInRoute(from: number, to: number, zwaveData: ZWaveData, nodeID: number, route: Array<number> = []): boolean {

    const routes = getRoutes(from, to, zwaveData);

    for (const r of routes) {
        if (r.includes(nodeID)) {
            return true;
        }
    }
    return false;
}


