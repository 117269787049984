import {ConnectionStatus, HubAuth, HubServices, QueryResponse} from 'genius-hub-proxy';
import { push } from "redux-first-history";
import {
    HubCacheData,
    HubCredentials,
    HubUpgradeStatus, ZigbeeAction, ZigbeeActionType,
    ZoneBase,
    ZoneCollection,
    ZoneFootprint,
    ZoneSP_Collection,
    ZWaveAction,
    ZWaveActionType
} from 'genius-hub-types'
import produce from 'immer';
import {boostZoneOnModeChange} from './actionHooks';
import {HubDataType, HubsCollection, SingleHubAuthState} from './hubTypes';
import {
    createConnectionManager,
    createHubObject, extractConnectionManagerData,
    getHubProxyByHubName,
    getOrCreateActiveHub,
    restoreConnectionManagerData
} from './hubsCache';
import {reducerWifi} from './hubs_wifi';
import {actionLog, actionNotify, LogType} from "./log";
import {ThunkAction} from "redux-thunk";
import {RootState} from "./index";
import {AnyAction} from "redux";
import sleep from "../utils/sleep";
import { LOAD } from 'redux-storage';
import {SAVE_STATE} from "./app";
import cloneDeep from "lodash/cloneDeep";


/**********************
 * ACTION CONSTANTS   *
 *********************/
export const GETTING_HUB_DATA = "GETTING_HUB_DATA";
export const GOT_HUB_DATA = "GOT_HUB_DATA";
export const GOT_HUB_DATA_FAIL = "GOT_HUB_DATA_FAIL";
export const GET_HUB_DATA = "GET_HUB_DATA";
export const GOT_HUB_ZONE_DATA = "GOT_HUB_ZONE_DATA";
export const GOT_HUB_ZWAVE_DATA = "GOT_HUB_ZWAVE_DATA";
export const GOT_HUB_ZIGBEE_DATA = "GOT_HUB_ZIGBEE_DATA";
export const GOT_HUB_ZWAVE_LOG = "GOT_HUB_ZWAVE_LOG";
export const GOT_HUB_ZWAVE_INCLUSION_STATE = "GOT_HUB_ZWAVE_INCLUSION_STATE";
export const GOT_HUB_DEVICE_DATA = "GOT_HUB_DEVICE_DATA";
export const GOT_HUB_STATS = "GOT_HUB_STATS";
export const GOT_HUB_INFO_DATA = "GOT_HUB_INFO_DATA";
export const GOT_HUB_TOKENS = "GOT_HUB_TOKENS";
export const HUB_DATA_CHANGED = "HUB_DATA_CHANGED";
export const CLEAR_HUB = "CLEAR_HUB";
export const SET_SNAPSHOT_DATA = "SET_SNAPSHOT_DATA";
export const CLEAR_SNAPSHOT_DATA = "CLEAR_SNAPSHOT_DATA";

export const APPLY_ZONES_DELTA = "APPLY_ZONES_DELTA";
export const APPLY_ZONE_DELTA = "APPLY_ZONE_DELTA";
export const ZONE_CREATED = "ZONE_CREATED";
export const ZONE_DELETED = "ZONE_DELETED";

export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_OK = "LOGIN_OK";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ACTIVE_CREDENTIALS = "SET_ACTIVE_CREDENTIALS";
export const UPGRADING_HUB = "UPGRADING_HUB";
export const REBOOTING_HUB = "REBOOTING_HUB";
export const UPGRADE_LOG = "UPGRADE_LOG";
export const TOKEN_CREATED = "TOKEN_CREATED";

export const NORMALIZE_DATA_STORE = "NORMALIZE_DATA_STORE";

export const SAVE_HUB_MANAGER_SETTINGS = "SAVE_HUB_MANAGER_SETTINGS";
export const LOAD_HUB_MANAGER_SETTINGS = "LOAD_HUB_MANAGER_SETTINGS";


/**********************
 * HELPERS         *
 *********************/

const zoneListToZoneMap = (zoneList: Array<ZoneBase>) => {
    const result: ZoneCollection = {};

    if (!zoneList) return result;

    for (const z of zoneList) {
        result[z.iID] = z;
    }
    return result;
};

function convertHubProxyStatusToHubAuthState(hubProxyStatus: ConnectionStatus): SingleHubAuthState {
    switch (hubProxyStatus) {
        case ConnectionStatus.NOT_INIT:
            return SingleHubAuthState.LOGGED_OUT;
        case ConnectionStatus.OK:
            return SingleHubAuthState.OK;
        case ConnectionStatus.NO_TUNNEL:
            return SingleHubAuthState.NO_TUNNEL;
        case ConnectionStatus.AUTH_TUNNEL:
            return SingleHubAuthState.AUTH_TUNNEL;
        case ConnectionStatus.AUTH_HUB:
            return SingleHubAuthState.AUTH_HUB;
        case ConnectionStatus.TUNNEL_ERROR:
            return SingleHubAuthState.TUNNEL_ERROR;
        case ConnectionStatus.HUB_NO_RESPONSE:
            return SingleHubAuthState.HUB_NO_RESPONSE;
        case ConnectionStatus.HUB_ERROR:
            return SingleHubAuthState.HUB_ERROR;
        case ConnectionStatus.HUB_FATAL_ERROR:
            return SingleHubAuthState.HUB_FATAL_ERROR;
        case ConnectionStatus.HUB_TIMEOUT:
            return SingleHubAuthState.HUB_TIMEOUT;
        case ConnectionStatus.REQUEST_NOT_FOUND:
            return SingleHubAuthState.REQUEST_NOT_FOUND;
        case ConnectionStatus.LIBRARY_ERROR:
            return SingleHubAuthState.LIBRARY_ERROR;
        case ConnectionStatus.UNKNOWN:
            return SingleHubAuthState.UNKNOWN;
    }
}

export function getStatusDescription(status: SingleHubAuthState) {
    switch (status) {
        case SingleHubAuthState.LOGGED_OUT:
            return 'Logged out';
        case SingleHubAuthState.OK:
            return 'OK';
        case SingleHubAuthState.NO_TUNNEL:
            return 'Hub not connected to server';
        case SingleHubAuthState.AUTH_TUNNEL:
            return 'Invalid username or password (521)';
        case SingleHubAuthState.AUTH_HUB:
            return 'Invalid username or password (hub)';
        case SingleHubAuthState.TUNNEL_ERROR:
            return 'Error on tunnel server';
        case SingleHubAuthState.HUB_NO_RESPONSE:
            return 'Tunnel OK, no response from Hub';
        case SingleHubAuthState.HUB_ERROR:
            return 'Error response from Hub';
        case SingleHubAuthState.HUB_FATAL_ERROR:
            return 'Fatal error response from Hub';
        case SingleHubAuthState.HUB_TIMEOUT:
            return 'Request timed out';
        case SingleHubAuthState.REQUEST_NOT_FOUND:
            return 'Request endpoint not found';
        case SingleHubAuthState.LIBRARY_ERROR:
            return 'Library request error';
        case SingleHubAuthState.UNKNOWN:
            return 'Unknown response';
    }
    return null;
}


/**********************
 * ACTION CREATORS    *
 *********************/

export const actionHubManagerSaveSettings = () => {
    return async (dispatch: any, getState: any) => {
        const hubs = getState().hubs;
        const hubManagerSettings = await extractConnectionManagerData(hubs);

        await dispatch({
            type: SAVE_HUB_MANAGER_SETTINGS,
            payload: hubManagerSettings
        });

        await dispatch({ type: SAVE_STATE, payload: null });
    }
}

export const actionHubManagerLoadSettings = () => {
    return async (dispatch: any, getState: any) => {
        const hubs = getState().hubs;
        await  restoreConnectionManagerData(hubs);
    }
}



export const actionSetActiveCredentials = (credentials: HubCredentials) => {
    return {
        type: SET_ACTIVE_CREDENTIALS,
        payload: credentials
    }
}


export const actionLogin = (credentials: HubCredentials, redirectAfterLogin:string = '/') => {
    return async (dispatch: any, getState: any) => {

        const auth = new HubAuth();
        auth.setAuth(credentials);

        try {
            const manager = await createConnectionManager(auth);
        }
        catch(e:any) {
            console.error(`Could not connect to hub ${auth.username}: ${e.message}`);
            return;
        }

        await dispatch({
            type: LOGGING_IN,
            payload: auth.getStorableCredentials()
        });

        const hubProxy = await getHubProxyByHubName(auth.username, getState().hubs);
        // await hubProxy.hubREST.auth.setAuth(credentials);

        try {
            const result = await hubProxy.hubAPI.testConnection();
            console.log("Logging in result = ", ConnectionStatus[result.connectionStatus], result);

            if (hubProxy.hubREST.getStatus() !== ConnectionStatus.OK) {
                const errorCode = convertHubProxyStatusToHubAuthState(hubProxy.hubREST.getStatus());
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        errorCode,
                        errorMessage: getStatusDescription(errorCode),
                        username: credentials.username,
                        credentials: hubProxy.hubREST.auth.getStorableCredentials()
                    }
                });

            } else {
                await dispatch({
                    type: LOGIN_OK,
                    payload: hubProxy.hubREST.auth.getStorableCredentials()
                });

                const services = new HubServices(auth);
                try {
                    const checkin = await services.getCheckinData();
                    // checkin.data.
                }
                catch(err: any) {
                    console.error(`Error getting checkin data for hub '${auth.username}':`, err);
                }

                if (redirectAfterLogin) {
                    dispatch(push(redirectAfterLogin));
                }
                const hub = getOrCreateActiveHub(credentials.username, getState().hubs);
                await dispatch(actionGetHubData(HubDataType.ZWave, credentials.username));
                await dispatch(actionGetHubData(HubDataType.Zigbee, credentials.username));
                await dispatch(actionGetHubData(HubDataType.Zones, credentials.username));
                await dispatch(actionGetHubData(HubDataType.Devices, credentials.username));
                await dispatch(actionGetHubData(HubDataType.HubVersion, credentials.username));
            }
        } catch(err: any) {
            console.error("Error logging into hub:", err);
            dispatch({
                type: LOGIN_FAIL,
                payload: {
                    username: credentials.username,
                    errorCode: SingleHubAuthState.LIBRARY_ERROR,
                    errorMessage: err.message
                }
            });
        }
    }
};

const actionGotHubData = (type: string, payload: any) => {
    return {
        type, payload
    }
};

export const actionGetHubData = (dataType: HubDataType, hubName?: string) => {

    return async (dispatch: any, getState: any) => {
        let result = false;
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        dispatch({
            type: GETTING_HUB_DATA,
            payload: dataType
        });
        const requestTimestamp = Date.now();

        let hubProxy;
        try {
            hubProxy = await getHubProxyByHubName(activeHubName, hubs);// Hub(hub);
        } catch(err: any) {
            console.warn(`actionGetHubData: ${err.message} - ${hubName}`);
            return;
        }
        const status = hubProxy.hubREST.getStatus();

        if (dataType & HubDataType.Devices) {
            const data: QueryResponse = await hubProxy.hubAPI.getDeviceData();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                dispatch(actionGotHubData(GOT_HUB_DEVICE_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                dispatch(actionGotHubData(GOT_HUB_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp,
                    hubTimestamp: data.ts,
                    hubTimestring: data.tm
                }));
                result = true;
            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }
        }

        if (dataType & HubDataType.ZWave) {
            const data: QueryResponse = await hubProxy.hubAPI.getZWaveData();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                // console.debug(`Got zone data: ${JSON.stringify(data)}`);
                dispatch(actionGotHubData(GOT_HUB_ZWAVE_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                dispatch(actionGotHubData(GOT_HUB_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp,
                    hubTimestamp: data.ts,
                    hubTimestring: data.tm

                }));
                result = true;

            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }

            const data2: QueryResponse = await hubProxy.hubAPI.getZWaveLog();//.then(

            if (data2.connectionStatus === ConnectionStatus.OK) {
                // console.debug(`Got zone data: ${JSON.stringify(data)}`);
                dispatch(actionGotHubData(GOT_HUB_ZWAVE_LOG, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                result = true;

            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }

        }

        if (dataType & HubDataType.ZWaveInclusion) {
            const data: QueryResponse = await hubProxy.hubAPI.getZWaveInclusionState();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                // console.debug(`Got zone data: ${JSON.stringify(data)}`);
                dispatch(actionGotHubData(GOT_HUB_ZWAVE_INCLUSION_STATE, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                result = true;

            } else {
                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }
        }

        if (dataType & HubDataType.Zigbee) {
            const data: QueryResponse = await hubProxy.hubAPI.getZigbeeData();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                // console.debug(`Got zone data: ${JSON.stringify(data)}`);
                dispatch(actionGotHubData(GOT_HUB_ZIGBEE_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                dispatch(actionGotHubData(GOT_HUB_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp,
                    hubTimestamp: data.ts,
                    hubTimestring: data.tm
                }));
                result = true;

            } else {
                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }
        }

        if (dataType & HubDataType.Chart) {
        }


        if (dataType & HubDataType.Zones) {
            const data: QueryResponse = await hubProxy.hubAPI.getZoneData();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                // console.debug(`Got zone data: ${JSON.stringify(data)}`);
                dispatch(actionGotHubData(GOT_HUB_ZONE_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                dispatch(actionGotHubData(GOT_HUB_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp,
                    hubTimestamp: data.ts,
                    hubTimestring: data.tm

                }));
                result = true;

            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));

            }
        }

        if (dataType & HubDataType.HubVersion) {
            const data: QueryResponse = await hubProxy.hubAPI.getHubInfo();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                // console.debug(`Got info data: ${JSON.stringify(data)}`);
                dispatch(actionGotHubData(GOT_HUB_INFO_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                dispatch(actionGotHubData(GOT_HUB_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp,
                    hubTimestamp: data.ts,
                    hubTimestring: data.tm
                }));
                result = true;

            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }

        }

        if (dataType & HubDataType.UpgradeStatus) {
            const data: QueryResponse = await hubProxy.hubAPI.upgradeStatus();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                // console.debug("UpgradeStatus:", JSON.stringify(data))
                if (data.connectionStatus === ConnectionStatus.OK) {
                    // console.debug(`Got info data: ${JSON.stringify(data)}`);
                    dispatch({
                        type: UPGRADE_LOG, payload: {
                            log: data.data.log,
                            hubName: activeHubName,
                        }
                    });
                }
                result = true;

            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }
        }

        if (dataType & HubDataType.Stats) {
            const data: QueryResponse = await hubProxy.hubAPI.getHubStats();//.then(

            if (data.connectionStatus === ConnectionStatus.OK) {
                dispatch(actionGotHubData(GOT_HUB_STATS, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp
                }));
                dispatch(actionGotHubData(GOT_HUB_DATA, {
                    hubName: activeHubName,
                    data: data.data,
                    requestTimestamp,
                    hubTimestamp: data.ts,
                    hubTimestring: data.tm
                }));
                result = true;
            } else {

                const errMsg = `actionGetHubData(${HubDataType[dataType]}: ${ConnectionStatus[data.connectionStatus]}`;
                console.error(errMsg);
                dispatch(actionGotHubData(GOT_HUB_DATA_FAIL, {
                    hubName: activeHubName,
                    errMsg
                }));
            }
        }


        return result;
    }
};

export const actionZWaveCommands = (actions: Array<ZWaveAction>, hubName?: string ) => {
    return async (dispatch: any, getState: any) => {

        let hubProxy;
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        try {
            hubProxy = await getHubProxyByHubName(activeHubName, hubs);// Hub(hub);
        } catch(err: any) {
            console.warn(`actionZWaveCommands: ${err.message} - ${activeHubName}`);
            return;
        }

        try {
            for (let action of actions) {
                let cmd = '';
                let nodeCmd = true;
                let method = null;

                switch (action.type) {
                    case ZWaveActionType.INCLUDE:
                        cmd = 'include';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.EXCLUDE:
                        cmd = 'exclude';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.CANCEL:
                        cmd = 'cancel';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.PING:
                        cmd = 'ping';
                        break;
                    case ZWaveActionType.REFRESH_VALUES:
                        cmd = 'update_values';
                        break;
                    case ZWaveActionType.RELEARN:
                        cmd = 'relearn';
                        break;
                    case ZWaveActionType.CONFIGURE:
                        cmd = 'configure';
                        break;
                    case ZWaveActionType.UPDATE_NEIGHBOURS:
                        cmd = 'update_neighbours';
                        break;
                    case ZWaveActionType.ASSIGN_RETURN_ROUTES:
                        cmd = 'assign_return_routes';
                        break;
                    case ZWaveActionType.DELETE_RETURN_ROUTES:
                        cmd = 'delete_return_routes';
                        break;
                    case ZWaveActionType.GET_NEIGHBOURS:
                        cmd = 'get_neighbours';
                        method = 'GET';
                        break;
                    case ZWaveActionType.CLEAR_QUEUE:
                        cmd = 'clear';
                        break;
                    case ZWaveActionType.REMOVE_NODE:
                        cmd = 'remove';
                        break;
                    case ZWaveActionType.RESET:
                        nodeCmd = false;
                        cmd = 'reset_soft';
                        break;
                    case ZWaveActionType.RESET_HARD:
                        cmd = 'reset_hard';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.ENABLE_REPAIR:
                        cmd = 'enable_network_recovery';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.DISABLE_REPAIR:
                        cmd = 'disable_network_recovery';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.SET_CONFIG_PARAMETER:
                        cmd = 'set_config_param';
                        nodeCmd = true;
                        break;
                    case ZWaveActionType.SET_REQUIRES_NEIGHBOUR_UPDATE:
                        cmd = 'set_requires_neighbour_update';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.SET_REQUIRES_RETURN_ROUTE_UPDATE:
                        cmd = 'set_requires_return_route_update';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.SCHEDULE_NEIGHBOUR_UPDATE:
                        cmd = 'schedule_neighbour_update';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.SET_AUTO_NEIGHBOUR_UPDATE:
                        cmd = 'set_auto_neighbour_update';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.CANCEL_NEIGHBOUR_UPDATE_EXECUTION:
                        cmd = 'cancel_neighbour_update_execution';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.SCHEDULE_RETURN_ROUTE_UPDATE:
                        cmd = 'schedule_return_route_update';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.SET_AUTO_RETURN_ROUTE_UPDATE:
                        cmd = 'set_auto_return_route_update';
                        nodeCmd = false;
                        break;
                    case ZWaveActionType.CANCEL_RETURN_ROUTE_UPDATE_EXECUTION:
                        cmd = 'cancel_return_route_update_execution';
                        nodeCmd = false;
                        break;
                }

                if (nodeCmd) {
                    await hubProxy.hubAPI.zwaveNodeCommand(cmd, action.nodeID, method, action.data);
                } else {
                    await hubProxy.hubAPI.zwaveCommand(cmd, action.data);
                }
            }

        } catch(err: any) {
            console.error("Error sending zwave action to hub:", err, actions, activeHubName);
        }
    }
};


export const actionZigbeeCommands = (actions: Array<ZigbeeAction>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        let hubProxy;
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        try {
            hubProxy = await getHubProxyByHubName(activeHubName, hubs);// Hub(hub);
        } catch(err: any) {
            console.warn(`actionZWaveCommands: ${err.message} - ${activeHubName}`);
            return;
        }

        try {
            for (let action of actions) {
                let cmd = '';
                let nodeCmd = true;
                let method = null;

                switch (action.type) {
                    case ZigbeeActionType.ALLOW_JOIN:
                        cmd = 'enable_join';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.CANCEL_ALLOW_JOIN:
                        cmd = 'disable_join';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.PURGE_DEVICE:
                        cmd = 'purge_device';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.DISCOVER_DEVICES:
                        cmd = 'discover_devices';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.DISCOVER_DEVICE:
                        cmd = 'discover_device';
                        nodeCmd = true;
                        // needs mac-address | node-id
                        break;

                    case ZigbeeActionType.COMMISSION_DEVICE:
                        cmd = 'commission_device';
                        nodeCmd = true;
                        // needs mac-address | node-id
                        break;

                    case ZigbeeActionType.UPDATE_CLUSTER:
                        cmd = 'update_cluster';
                        nodeCmd = true;
                        break;

                    case ZigbeeActionType.UPDATE_NETWORK_MAP:
                        cmd = 'refresh_network_map';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.START_ENERGY_SCAN:
                        cmd = 'start_energy_scan';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.START_ACTIVE_SCAN:
                        cmd = 'start_active_scan';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.STOP_SCAN:
                        cmd = 'stop_scan';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.CHANGE_CHANNEL:
                        cmd = 'change_radio_channel';
                        nodeCmd = false;
                        break;

                    case ZigbeeActionType.REQUEST_DEVICE_LEAVE:
                        cmd = 'request_device_leave';
                        nodeCmd = true;
                        break;
                }

                if (nodeCmd) {
                    await hubProxy.hubAPI.zigbeeNodeCommand(cmd, method, action.data);
                } else {
                    await hubProxy.hubAPI.zigbeeCommand(cmd, action.data);
                }
            }

        } catch(err: any) {
            console.error("Error sending Zigbee action to hub:", err, actions, activeHubName);
        }
    }
};


export const actionUpdateDataChangeTimestamp = (hubName: string) => {
    return {
        type: HUB_DATA_CHANGED,
        payload: {
            dataChangeTimestamp: Date.now(),
            hubName
        }
    };
};

export const actionApplyZoneDelta = (zoneID: number, delta: any, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        const zoneData: ZoneBase = hubs[activeHubName]?.data.zones[zoneID];

        // Ensure that setting mode to boost, actually does a boost
        delta = boostZoneOnModeChange(delta, zoneData);

        dispatch({
            type: APPLY_ZONE_DELTA,
            payload: {
                hubName: activeHubName, zoneID, delta
            }
        });



        // TODO: cancel any current data fetches
        dispatch(actionUpdateDataChangeTimestamp(activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.patchZone(zoneID, delta);
            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionApplyZonesDelta1 = (zoneIDs: Array<number>, delta: any, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        dispatch({
            type: APPLY_ZONES_DELTA,
            payload: {
                hubName: activeHubName, zoneIDs, delta
            }
        });

        dispatch(actionUpdateDataChangeTimestamp(activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.patchZonesCommon(zoneIDs, delta);
            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }

    };
};

export const actionApplyZonesDelta = (delta: any, zoneIDs?: Array<number>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        dispatch(actionUpdateDataChangeTimestamp(activeHubName));

        let payload: any;

        if (zoneIDs) {
            payload = {};
            zoneIDs.forEach((zoneID: number) => payload[zoneID] = {...delta});
        } else {
            payload = delta;
        }

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.patchZonesIndividual(payload);
            if (result.connectionStatus === ConnectionStatus.OK) {
                dispatch({
                    type: APPLY_ZONES_DELTA,
                    payload: {
                        hubName: activeHubName, zoneIDs, delta
                    }
                });
            } else {
                dispatch(actionLog(`Could not update Hub ${activeHubName}`, LogType.Error ));
            }

            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }

    };
};

export const actionCopyTimerToZones = (fromZoneID: number, toZoneIDs: Array<number>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        const objTimer: ZoneSP_Collection = hubs[activeHubName]?.data.zones[fromZoneID]?.objTimer;

        for (let zoneID of toZoneIDs) {
            dispatch({
                type: APPLY_ZONE_DELTA,
                payload: {
                    hubName: activeHubName,
                    zoneID,
                    delta: {objTimer}
                }
            });
        }

        dispatch(actionUpdateDataChangeTimestamp(activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.patchZonesCommon(toZoneIDs, {objTimer});
            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionCopyFootprintToZones = (fromZoneID: number, toZoneIDs: Array<number>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;
        const objFootprint: ZoneFootprint = hubs[activeHubName]?.data.zones[fromZoneID]?.objFootprint;

        for (let zoneID of toZoneIDs) {
            dispatch({
                type: APPLY_ZONE_DELTA,
                payload: {
                    hubName: activeHubName,
                    zoneID,
                    delta: {objFootprint}
                }
            });
        }

        dispatch(actionUpdateDataChangeTimestamp(activeHubName));

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.patchZonesCommon(toZoneIDs, {objFootprint});
            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionNormalizeDataStore = () => {
    return {
        type: NORMALIZE_DATA_STORE
    }
};

export function actionClearHub(username: string) {
    return {
        type: CLEAR_HUB,
        payload: username
    }
}

export const actionUpgradeHub = (url: string, checksum: string, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        dispatch({type: UPGRADING_HUB, payload: {hubName, url, checksum}});

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = hubProxy.hubAPI.upgrade(url, checksum);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionRebootHub = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        dispatch({type: REBOOTING_HUB, payload: {hubName}});

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = hubProxy.hubAPI.reboot();
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionShutdownHub = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = hubProxy.hubAPI.shutdown();
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionCreateZone = (zoneProps: Partial<ZoneBase> | Array<Partial<ZoneBase>>, hubName?: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: any, getState: any): Promise<Array<number>> => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            let zones;
            if (Array.isArray(zoneProps)) {
                zones = zoneProps
            } else {
                zones = [zoneProps];
            }

            let retVal: Array<number> = [];

            for (const zone of zones) {
                let result = await hubProxy.hubAPI.createZone(zone);
                dispatch({type: ZONE_CREATED, payload: {hubName, zoneData: result.data}});
                await new Promise(r => setTimeout(r, 1000));
                const zoneID = result.data.iID;

                retVal.push(zoneID);

                if (zone.hasOwnProperty('_assignDevices')) {
                    // @ts-ignore
                    for (const devicePath of zone._assignDevices) {
                        await hubProxy.hubAPI.assignDevice(zoneID, devicePath);
                    }
                }
            }
            dispatch(actionGetHubData(HubDataType.Zones, activeHubName));
            dispatch(actionGetHubData(HubDataType.Devices, activeHubName));

            return retVal;

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionDeleteZone = (zoneID: number, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.deleteZone(zoneID);
            dispatch({type: ZONE_DELETED, payload: {hubName, zoneData: result.data}});
            dispatch(actionGetHubData(HubDataType.Zones, activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionGetTokens = (hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.getTokens();
            dispatch({type: GOT_HUB_TOKENS, payload: {hubName: activeHubName, tokens: result.data}});

        } catch (err) {
            console.error("Error getting tokens:", err);
        }
    };
}

export const actionCreateToken = (name: string, description: string, expiry: number, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.createToken(name, description, expiry);
            dispatch({type: TOKEN_CREATED, payload: {hubName, token: result.data}});

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionDeleteToken = (tokenID: string, hubName?:string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.deleteToken(tokenID);
            dispatch({type: TOKEN_CREATED, payload: {hubName, token: result.data}});

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionAssignDevice = (zoneID: number, devicePath: string, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.assignDevice(zoneID, devicePath);
            dispatch(actionGetHubData(HubDataType.Zones, activeHubName));
            dispatch(actionGetHubData(HubDataType.Devices, activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export const actionUnassignDevice = (zoneID: number, devicePath: string, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);

            const result = await hubProxy.hubAPI.unassignDevice(zoneID, devicePath);

            // console.debug(`Unassigning device ${devicePath} from zone ${zoneID} on hub ${activeHubName}`);
            // await sleep(2000);

            dispatch(actionGetHubData(HubDataType.Zones, activeHubName));
            dispatch(actionGetHubData(HubDataType.Devices, activeHubName));

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
}

export function actionSetSnapshotData(hubName: string, snapshot: HubCacheData) {
    return {
        type: SET_SNAPSHOT_DATA,
        payload: {
            hubName,
            snapshot
        }
    }
}

/*
export function actionClearSnapshotData(hubName: string) {
    return {
        type: CLEAR_SNAPSHOT_DATA,
        payload: {
            hubName,
            snapshot
        }
    }
}
*/


export const actionZoneLinkTo = (zoneID:number, linkToZoneIDs: Array<number>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.linkZone(zoneID, linkToZoneIDs);
            dispatch(actionGetHubData(HubDataType.Zones, activeHubName));

            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionZoneUnlinkFrom = (zoneID:number, unlinkFromZoneIDs: Array<number>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.unlinkZone(zoneID, unlinkFromZoneIDs);
            dispatch(actionGetHubData(HubDataType.Zones, activeHubName));

            console.log("Sent data to hub, result = ", result);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionZoneSetOverrideLimit = (zoneID:number, value: number | null, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.setOverrideMax(zoneID, value);

            if (result.connectionStatus !== ConnectionStatus.OK) {
                const errorCode = result.error;
                dispatch(actionNotify(`Cannot set an override temperature limit on this zone ${errorCode}`, LogType.Error ));
            }

        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionSetDatapoint = (address:string, value: number, hubName?: string) => {
    return async (dispatch: any, getState: any) => {
        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            const result = await hubProxy.hubAPI.setDatapoint(address, value);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionZoneStartCalibration = (zoneID: number, devices: Array<string>, hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            console.debug(`Calibrating ${zoneID} devices: [${devices.join(', ')}] on hub ${activeHubName}`)
            const result = await hubProxy.hubAPI.startCalibration(zoneID, devices);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};

export const actionZoneStopCalibration = (zoneID: number, hubName?: string) => {
    return async (dispatch: any, getState: any) => {

        const activeHubName = hubName || getState().app.activeHubName;
        const hubs = getState().hubs;

        try {
            const hubProxy = await getHubProxyByHubName(activeHubName, hubs);
            console.debug(`Stopping calibration for zone ${zoneID} on hub ${activeHubName}`)
            const result = await hubProxy.hubAPI.stopCalibration(zoneID);
        } catch(err: any) {
            console.error("Error sending data to hub:", err);
        }
    };
};


/**********************
 * REDUCERS           *
 *********************/
export const initialState: HubsCollection = {};


export default produce((state = initialState, action): HubsCollection=> {

    if (action.type.slice(0, 4) === 'WIFI') {

        const wifiState = state[action.payload.hubName].data.wifi;
        reducerWifi(wifiState, action);
        return;
    }

    switch (action.type) {
        case GOT_HUB_DATA:
            if (!state.hasOwnProperty(action.payload.hubName)) {
                // console.debug("Not found ", action.payload.hubName, "creating hub object");
                state[action.payload.hubName] = createHubObject();
            }
            state[action.payload.hubName].data.hubTimestamp = action.payload.hubTimestamp;
            state[action.payload.hubName].data.hubTimestring = action.payload.hubTimestring;
            break;

        case GOT_HUB_ZONE_DATA: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.zones = zoneListToZoneMap(action.payload.data);
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_ZWAVE_DATA: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.zwave = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_ZIGBEE_DATA: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.zigbee = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_ZWAVE_LOG: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.zwave_log = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_ZWAVE_INCLUSION_STATE: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.zwaveInclusionStatus = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_DEVICE_DATA: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.devices = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_INFO_DATA: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.version = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case GOT_HUB_STATS: {
            if (!state.hasOwnProperty(action.payload.hubName)) {
                state[action.payload.hubName] = createHubObject();
            }
            if (action.payload.requestTimestamp >= state[action.payload.hubName].lastUpdate) {
                state[action.payload.hubName].data.stats = action.payload.data;
                state[action.payload.hubName].lastUpdate = action.payload.requestTimestamp;
            } else {
                console.warn(`Stale data from hub - not updating state. Request timestamp = ${action.payload.requestTimestamp} last updated at ${state[action.payload.hubName].lastUpdate}`);
            }
            break;
        }

        case HUB_DATA_CHANGED:
            state[action.payload.hubName].lastUpdate = action.payload.dataChangeTimestamp;
            break;

        case LOGGING_IN: {
            getOrCreateActiveHub(action.payload.username, state);
            state[action.payload.username].connectionStatus = SingleHubAuthState.LOGGING_IN;
            state[action.payload.username].connectionStatusDescription = getStatusDescription(SingleHubAuthState.LOGGING_IN);
            break;
        }

        case LOGIN_OK: {
            state[action.payload.username].connectionStatus = SingleHubAuthState.OK;
            state[action.payload.username].connectionStatusDescription = getStatusDescription(SingleHubAuthState.OK);
            state[action.payload.username].data.credentials = {...action.payload};
            break;
        }

        case LOGIN_FAIL: {
            if (!state.hasOwnProperty(action.payload.username)) {
                state[action.payload.username] = createHubObject();
            }
            state[action.payload.username].connectionStatus = action.payload.errorCode;
            state[action.payload.username].connectionStatusDescription = action.payload.errorMessage;
            break;
        }

        case SET_ACTIVE_CREDENTIALS: {
            getOrCreateActiveHub(action.payload.username, state);
            state[action.payload.username].connectionStatus = SingleHubAuthState.OK;
            state[action.payload.username].connectionStatusDescription = getStatusDescription(SingleHubAuthState.OK);
            state[action.payload.username].data.credentials = {...action.payload};
            break;
        }

        case APPLY_ZONE_DELTA: {
            const zonesData = state[action.payload.hubName].data.zones;
            const zoneID = action.payload.zoneID;
            // debugger
            // const arrayIndex = getZoneArrayZoneID(zonesData, action.payload.zoneID);
            // console.log("arrX", arrayIndex);
            zonesData[zoneID] = {...zonesData[zoneID], ...action.payload.delta};
            // const xx  = {...zonesData[arrayIndex], ...action.payload.delta};
        }
            break;

        case APPLY_ZONES_DELTA: {
            if (action.payload.zoneIDs) {
                const zoneIDs = action.payload.zoneIDs;
                zoneIDs.forEach((zoneID: number) => {
                    const zonesData = state[action.payload.hubName].data.zones;
                    zonesData[zoneID] = {...zonesData[zoneID], ...action.payload.delta};
                });
            } else {
                Object.keys(action.payload.delta).forEach( (zoneID: string) => {
                    const zonesData = state[action.payload.hubName].data.zones;
                    const _zoneID = parseInt(zoneID);
                    zonesData[_zoneID] = {...zonesData[_zoneID], ...action.payload.delta[_zoneID]};
                });
            }
        }
            break;

        case NORMALIZE_DATA_STORE:

            break;

        case CLEAR_HUB:
            delete state[action.payload];
            break;

        case UPGRADING_HUB:
            state[action.payload.hubName].data.upgradeState.status = HubUpgradeStatus.InProgress;
            break;

        case REBOOTING_HUB:

            break;

        case UPGRADE_LOG:
            state[action.payload.hubName].data.upgradeState.log = [];
            action.payload.log
                .split('\n')
                .forEach((line: string) => {
                    const re = /^([\d]{4}-[\d]{2}-[\d]{2} [\d]{2}:[\d]{2}:[\d]{2}) ([\d]{3}) (.*)$/gm;
                    const match = line.match(re);
                    if (match !== null) {
                        state[action.payload.hubName].data.upgradeState.log.push(line);
                        const code = parseInt(match[2]);
                        switch (code) {
                            case HubUpgradeStatus.INSTALL_OK:
                                state[action.payload.hubName].data.upgradeState.status = HubUpgradeStatus.Complete;
                                break;
                            case HubUpgradeStatus.INSTALL_FAIL:
                            case HubUpgradeStatus.DOWNLOAD_FAIL:
                            case HubUpgradeStatus.REVERT_FAIL:
                            case HubUpgradeStatus.BACKUP_FAIL:
                                state[action.payload.hubName].data.upgradeState.status = HubUpgradeStatus.Failed;
                                break;
                        }
                    }
                });
            break;

        case ZONE_CREATED:
            break;

        case GOT_HUB_TOKENS:
            state[action.payload.hubName].data.auth.tokens = action.payload.tokens;
            break;

        case SET_SNAPSHOT_DATA:
            state[action.payload.hubName].data = action.payload.snapshot;
            state[action.payload.hubName].dataIsSnapshot = true;
            break;

        case CLEAR_SNAPSHOT_DATA:
            state[action.payload.hubName].dataIsSnapshot = false;
            break;

        case LOAD:
            // restoreConnectionManagerData(cloneDeep(state)).then();
            break;

        case SAVE_HUB_MANAGER_SETTINGS: {
            const iter = Object.entries(action.payload);
            for (let i of iter) {
                const k = i[0], v = i[1];
                state[k]._connectionManagerData = v;
            }
        }
            break;


    }
}, initialState);
