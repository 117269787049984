import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel,
    createZWaveConfigValueSchema,
    createSchemaZWaveCommandClassThermostatSetpoint,
    createSchemaZWaveCommandClassMultiChannel,
    createSchemaZWaveDevicePsuedoListening, createSchemaPsuedoNodeInfo
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


DeviceZWaveConfigDefaults['HI-PRT-A'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(8).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().valid(6).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['HI-PRT-A']),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatSetpoint(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 1, 1), // 'Operation mode'
            ...createZWaveConfigValueSchema(2, 1, 2), // 'Sensor mode'
            ...createZWaveConfigValueSchema(3, 1, 0), // 'Floor sensor type'
            ...createZWaveConfigValueSchema(4, 1, 5), // 'Temperature control hysteresis (diF)'
            ...createZWaveConfigValueSchema(5, 2, 50), // 'Floor minimum temperature limit (Flo)'
            ...createZWaveConfigValueSchema(6, 2, 280), // 'Floor maximum temperature limit (FHi)'
            ...createZWaveConfigValueSchema(7, 2, 50), // 'Air (A2) minimum temperature limit (ALo)'
            ...createZWaveConfigValueSchema(8, 2, 400), // 'Air (A2) maximum temperature limit (AHi)'
            ...createZWaveConfigValueSchema(10, 1, Joi.number().min(-100).max(100)), // 'Room sensor calibration (A)'
            ...createZWaveConfigValueSchema(11, 1, 0), // 'Floor sensor calibration'
            ...createZWaveConfigValueSchema(12, 1, 0), // 'External sensor calibration'
            ...createZWaveConfigValueSchema(13, 1, 1), // 'Temperature display'
            ...createZWaveConfigValueSchema(14, 1, 50), // 'Button brightness - dimmed state'
            ...createZWaveConfigValueSchema(15, 1, 100), // 'Button brightness - active state'
            ...createZWaveConfigValueSchema(16, 1, 50), // 'Display brightness - dimmed state'
            ...createZWaveConfigValueSchema(17, 1, 100), // 'Display brightness – active state'
            ...createZWaveConfigValueSchema(18, 2, 300), // 'Temperature report interval'
            ...createZWaveConfigValueSchema(19, 1, 10), // 'Temperature report hysteresis'
            ...createZWaveConfigValueSchema(20, 2, 3600), // 'Meter report interval'
            ...createZWaveConfigValueSchema(21, 1, 0), // 'Meter report delta value'
        }),
        ...createSchemaZWaveCommandClassMultiChannel({
            "1": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
                commandClasses: Joi.object().append({
                    ...createSchemaZWaveCommandClassAssociation(),
                    ...createSchemaZWaveCommandClassThermostatMode(),
                    ...createSchemaZWaveCommandClassThermostatSetpoint(),
                }),
            }),
            "2": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
                commandClasses: Joi.object().append({
                    ...createSchemaZWaveCommandClassAssociation(),
                    ...createSchemaZWaveCommandClassMultilevel(),
                }),
            }),
            "3": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
                commandClasses: Joi.object().append({
                    ...createSchemaZWaveCommandClassAssociation(),
                    ...createSchemaZWaveCommandClassMultilevel(),
                }),
            }),
            "4": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
                commandClasses: Joi.object().append({
                    ...createSchemaZWaveCommandClassAssociation(),
                    ...createSchemaZWaveCommandClassMultilevel(),
                }),
            }),
        }, false),
    })
});