import * as React from 'react';
import {useReducer, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {HubState, HubDataType, HubsCollection} from "../redux/hubTypes";
import {actionGetHubData} from "../redux/hubs";
import {actionGetCheckinData, actionSendFeedback} from "../redux/services";
import {AppState} from "../redux/app";
import BugReport from './BugReport';


const confluenceURL = 'https://docs.geniushub.co.uk';

enum FeedbackPage {
    Type,
    Info,
    Feedback,
    Sent
}

enum FeedbackType {
    Comment,
    BugReport,
    FeatureRequest,
    SupportRequest
}

const feedbackTypeString = [
    "comment",
    "bugReport",
    "feature",
    "support"
];


function temp() {
    return (
        <div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Type of feedback</label>
                </div>
                <div className="field-body">
                    <div className="field is-narrow">
                        <div className="control">
                            <div className="select is-fullwidth is-rounded">
                                <select value={null} onChange={handleChangeType}>
                                    {/*<option value={"sales"}>Sales Enquiry</option>*/}
                                    {/*<option value={"bug"}>Report A Bug</option>*/}
                                    <option disabled={false} value={FeedbackType.Comment}>Comment</option>
                                    <option disabled={false} value={FeedbackType.BugReport}>Bug Report</option>
                                    <option disabled={false} value={FeedbackType.FeatureRequest}>Feature Request
                                    </option>
                                    <option disabled={false} value={FeedbackType.SupportRequest}>Support Request
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label"></label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            {instructions}
                        </div>
                        <small>chars remaining: {null}</small>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Feedback</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                          <textarea
                              className={"textarea gh-round-edges is-rounded " + (null ? "is-success" : "is-danger")}
                              placeholder="Comments here..."
                              onChange={handleUpdateFeedback}
                              value={state.feedback}
                          />
                        </div>
                        <small>chars remaining: {null}</small>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label">
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <button
                                className="button is-primary is-rounded"
                                onClick={handleSubmit}
                                disabled={null}
                            >
                                Send Feedback
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div>{state.foundLabels.join(' ')}</div>*/}

            {!!state.searchResults.length && <div className={"container"}>
                <span className={"subtitle"}>You may wish to see the following articles...</span>
                {state.searchResults.map((result, idx) => {
                    return <div key={idx} className={""}>
                        <a href={`${confluenceURL}/${result.url}`}
                           dangerouslySetInnerHTML={{__html: decodeURIComponent(result.title)}}/>
                        {/*<div className={"is-size-7"}>{result.bodyTextHighlights}</div>*/}
                    </div>;
                })}
            </div>}
        </div>

    )
}


function FeedbackPageType(props: {
    onSelectType: (type: FeedbackType) => void
}) {

    return (
        <div className="feedback-page">
            <div className="feedback-page__title">What type of feedback do you have?</div>
            <div className="feedback-page__buttons">
                <button className="button is-primary is-rounded feedback-page__button feedback-page__button--comment"
                        onClick={() => props.onSelectType(FeedbackType.Comment)}>Comment
                </button>
                <button className="button is-primary is-rounded feedback-page__button feedback-page__button--bug"
                        onClick={() => props.onSelectType(FeedbackType.BugReport)}>Bug Report
                </button>
                <button className="button is-primary is-rounded feedback-page__button feedback-page__button--feature"
                        onClick={() => props.onSelectType(FeedbackType.FeatureRequest)}>Feature Request
                </button>
                <button className="button is-primary is-rounded feedback-page__button feedback-page__button--support"
                        onClick={() => props.onSelectType(FeedbackType.SupportRequest)}>Support Request
                </button>
            </div>
        </div>
    );

}

function FeedbackPageInfoComment(props: {}) {

    return (
        <div className="feedback-page">
            <p>You have selected to leave a comment about the Genius Hub System.</p>

            <p>
                Please note that we will not be able to respond to your comment, but it will be recorded and taken into
                account when we are planning future updates.
            </p>

        </div>
    );

}

function FeedbackPageInfoBugReport(props: {}) {

    return (
        <div className="feedback-page">
            <p>You have selected to send in a bug report</p>

            <p><b>Please do not submit a bug report if</b></p>

            <p>
                Please note that we will not be able to respond to your comment, but it will be recorded and taken into
                account when we are planning future updates.
            </p>

        </div>
    );

}


export default function Feedback(props: {}) {

    const labels = [
        'app', 'assign', 'batteries', 'boiler', 'communication', 'compatibility', 'configuration', 'create', 'zone',
        'dead', 'node', 'devices', 'doctor', 'dual', 'channel', 'receiver', 'electric', 'heating', 'relay',
        'switch', 'errors',
        'factory', 'reset', 'footprint', 'forgotten', 'password', 'motion', 'sensor', 'radiator', 'valve',
        'thermostat', 'plug', 'group',
        'hot', 'water', 'include', 'internet', 'connection', 'login',
        'manual', 'override', 'mount', 'offset', 'pre-heat',
        'range', 'extender', 'dead', 'security', 'setup', 'single', 'temperature', 'timer', 'underfloor', 'weather', 'wiring', 'zone'
    ];

    const [state, setState] = useReducer((state: any, action: any) => ({...state, ...action}), {
        feedBackType: FeedbackType.Comment,
        feedback: '',
        foundLabels: [],
        searchResults: [],
        page: FeedbackPage.Type
    });

    const dispatch = useDispatch();
    const activeHub = useSelector((state: RootState): HubState | null => {
        const activeHubName = (state.app as AppState)?.activeHubName;
        return state.hubs.hasOwnProperty(activeHubName) ? (state.hubs as HubsCollection)[activeHubName] : null;
    });
    const hubName = activeHub?.data?.credentials?.username;

    const handleUpdateFeedback = async (evt: any) => {
        const feedback = evt.target.value;
        const foundLabels = labels.filter((label) => feedback.includes(label));
        let searchResults = [];
        if (JSON.stringify(foundLabels) != JSON.stringify(state.foundLabels)) {
            const query = encodeURI(foundLabels.join(' '));
            try {
                const response = await fetch(`${confluenceURL}/rest/searchv3/1.0/search?queryString=${query}&where=HS&type=page&pageSize=10&highlight=false&sessionUuid=0-0-0-0`)
                searchResults = (await response.json()).results;
                console.debug(searchResults);
            } catch (e) {
                console.error(e);
            }
        }

        setState({feedback, foundLabels, searchResults})
    };

    const handleChangeType = (evt: any) => {
        setState({feedBackType: parseInt(evt.target.value)});
    }

    const handleSelectType = (type: FeedbackType) => {
        setState({feedBackType: type, page: FeedbackPage.Info});
    }

    const handleSubmit = () => {
        let _feedbackType = '';
        switch (state.feedBackType) {
            case FeedbackType.Comment:
            case FeedbackType.FeatureRequest:
            case FeedbackType.BugReport:
                _feedbackType = feedbackTypeString[state.feedBackType];
                break;
            case FeedbackType.SupportRequest:
                break;

        }
        dispatch(actionSendFeedback(hubName, _feedBackType, state.feedback));
    }

    let instructions = null;
    switch (state.feedBackType) {
        case FeedbackType.Comment:
            instructions = <p>Please let us know any general comments that you have about the system</p>;
            break;
        case FeedbackType.BugReport:
            instructions = <>
                <p>A bug report is for when you think that something in the app is not working properly.</p>
                <p><b>Note!</b> You will not receive a response from a submitted bug report, but it will
                    go into our bug tracking system, and will be reported to the Hub Team.</p>
            </>;
            break;
        case FeedbackType.FeatureRequest:
            instructions = <>
                <p>Tell us any ideas or use cases that you have for the system, that it doesn't currently do</p>
                <p><b>Note!</b> You will not receive a response from submitted feature requests, but your message
                    will be reported to the Hub Team.</p>
            </>;
            break;

        case FeedbackType.SupportRequest:
            instructions = <p>This message will be sent as an e-mail to <a
                href={"mailto:support@geniushub.co.uk"}>support@geniushub.co.uk</a></p>;
            break;
    }

    const handleClickNext = () => {
        switch (state.page) {
            case FeedbackPage.Type:
                setState({page: FeedbackPage.Info});
                break;
            case FeedbackPage.Info:
                setState({page: FeedbackPage.Feedback});
                break;
            case FeedbackPage.Feedback:
                handleSubmit();
                break;
            case FeedbackPage.Sent:
                break;
        }
    }

    const handleClickPrevious = () => {
        switch (state.page) {
            case FeedbackPage.Type:
                break;
            case FeedbackPage.Info:
                setState({page: FeedbackPage.Type});
                break;
            case FeedbackPage.Feedback:
                setState({page: FeedbackPage.Info});
                break;
            case FeedbackPage.Sent:
                break;
        }
    }

    return (
        <BugReport />
/*
        <div>

            {
                (state.page === FeedbackPage.Type) &&
                <FeedbackPageType onSelectType={handleSelectType}/>
            }

            {(state.page === FeedbackPage.Info) &&

                <button onClick={handleClickNext}
                        className={"button is-primary is-rounded"}
                >{state.page === FeedbackPage.Feedback ? 'Submit' : 'Next'}</button>
            }

            {
                ((state.page !== FeedbackPage.Type) && (state.page !== FeedbackPage.Sent)) &&
                <button onClick={handleClickPrevious}
                        className={"button is-primary is-rounded"}
                >Previous</button>
            }


        </div>
*/
    );
}
