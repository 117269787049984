import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel, createZWaveConfigValueSchema
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


DeviceZWaveConfigDefaults['PH-PLG-C'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(16).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().min(0).max(2).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['PH-PLG-C']),
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassBasic(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassSwitchBinary(),
        ...createSchemaZWaveCommandClassSwitchAll(),
        ...createSchemaZWaveCommandClassIndicator(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 2, 720),
            ...createZWaveConfigValueSchema(2, 2, 6),
            ...createZWaveConfigValueSchema(3, 2, 3000),
            ...createZWaveConfigValueSchema(4, 2, 10000),
        }),
    })
});
DeviceZWaveConfigDefaults['PH-PLG-D'] = DeviceZWaveConfigDefaults['PH-PLG-C'];


//
// PH-PLG-E
//

DeviceZWaveConfigDefaults['PH-PLG-E'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(16).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().min(0).max(2).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['PH-PLG-E']),
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassBasic(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassSwitchBinary(),
        ...createSchemaZWaveCommandClassIndicator(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassConfiguration({}),
    })
});
