


export function extractSearchParams(uri: string): { [key: string]: string } {

    const result: { [key: string]: string } = {};

    const _uri = new URL(uri);

    if (_uri.searchParams.size) {
        _uri.searchParams.forEach((value, key) => {
            result[key] = value;
        });

    } else {
        // check the fragment
        const fragment = _uri.hash;
        const idx = fragment.indexOf('?');

        const _fragParams = new URLSearchParams(fragment.slice(idx + 1));

        _fragParams.forEach((value, key) => {
            result[key] = value;
        });
    }

    return result;
}
