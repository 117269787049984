import {regex_zigbee_eui64} from "genius-hub-types";

/**
 *
 * @param eui64 EUI64 string like 0x847127FFFEF53F3F
 * @return 'short' zigbee ID like 'F5-3F-3F'
 * @throws Error if input string is not in the correct format
 */

export function zigbeeShortID(eui64: string): string|null {
    if (typeof eui64 !== 'string') {
        return null;
    }
    if (eui64.match(regex_zigbee_eui64) === null) {
        throw new Error(`zigbeeShortID - invalid EUI64: ${eui64}`)
    }
    const lastPart = eui64.slice(12, 18)
    return `${lastPart.slice(0,2).toUpperCase()}-${lastPart.slice(2,4).toUpperCase()}-${lastPart.slice(4,6).toUpperCase()}`;
}
