"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEmptyHubCacheData = void 0;
var zigbee_1 = require("./zigbee");
var zwave_1 = require("./zwave");
var release_1 = require("./release");
function createEmptyZwaveMetricsHMAC() {
    return {
        metrics: {
            rx: {
                ack: { value: 0, wrapped: false },
                can: { value: 0, wrapped: false },
                dropped_bytes: { value: 0, wrapped: false },
                frame_success: { value: 0, wrapped: false },
                nak: { value: 0, wrapped: false },
                timeout: { value: 0, wrapped: false }
            },
            tx: {
                ack: { value: 0, wrapped: false },
                can: { value: 0, wrapped: false },
                frame_failed: { value: 0, wrapped: false },
                frame_send: { value: 0, wrapped: false },
                frame_success: { value: 0, wrapped: false },
                nak: { value: 0, wrapped: false },
                timeout: { value: 0, wrapped: false }
            }
        },
        stats: {
            ackCount: 0,
            canCount: 0,
            frameCount: 0,
            framesReceived: 0,
            framesSent: 0,
            nakCount: 0,
            oofCount: 0,
            runTime: 0,
            timeoutCount: 0
        }
    };
}
function createEmptyZwaveMetrics() {
    return {
        avg_hmac_wait_ms: 0,
        max_hmac_wait_ms: 0,
        rx_invalid_function_id_count: { value: 0, wrapped: false },
        rx_invalid_node_count: { value: 0, wrapped: false },
        rx_unhandled_cc_commands: {},
        uptime_m: 0,
    };
}
function createEmptyHubCacheData(x) {
    var skeleton = {
        auth: {
            tokens: []
        },
        credentials: {
            username: '',
            refreshToken: '',
            signature: '',
            token: ''
        },
        config: {
            defaultSetbackTemp: 0,
        },
        devices: {
            addr: '',
            childNodes: {},
            type: '',
            childValues: {}
        },
        hubTimestamp: 0,
        hubTimestring: '',
        upgradeState: {
            log: [],
            status: release_1.HubUpgradeStatus.None
        },
        version: {
            build_time: '',
            commit: '',
            release: '',
            UID: ''
        },
        wifi: {
            status: 0,
            ssids: [],
            wifiSystemState: {
                "network-interface": {
                    broadcast: '',
                    "mac-address": '',
                    "ip-address": '',
                    netmask: ''
                },
                "station-mode-configuration": {
                    country: '',
                    encryption: '',
                    ssid: '',
                    key: ''
                },
                "station-network-status": {
                    "connected-to-access-point": '',
                    "access-point-visible": ''
                },
                "wireless-device": {
                    "wireless-mode": "",
                    encryption: '',
                    channel: '',
                    ssid: '',
                    signal: ''
                }
            }
        },
        zones: {},
        zwave: {
            controllerNodeID: 1,
            controllerQueue: [],
            currentFrame: {
                callback: 0,
                checksum: 0,
                cmdID: 0,
                cmdName: '',
                direction: 0,
                failCount: 0,
                frameID: 0,
                payload: '',
                payloadLength: 0,
                timeout: 0,
                type: 0
            },
            metrics: createEmptyZwaveMetrics(),
            hmac: createEmptyZwaveMetricsHMAC(),
            homeID: '',
            incomingQueue: [],
            log: [],
            missingNodes: [],
            networkRecoveryRemaining: 0,
            nodes: [],
            state: 0,
            strState: '',
            strVersion: '',
            neighbour_update_helper: {
                automatic_scheduling_enabled: false,
                automatic_scheduling_period: 0,
                automatic_scheduling_time_of_day: 0,
                current_node_deadline: 0,
                current_node_id: 0,
                last_execution_complete_time: 0,
                last_execution_failed_devices: "",
                last_execution_start_time: 0,
                last_execution_success_devices: "",
                next_on_demand_execution: 0,
                next_scheduled_execution: 0,
                state: 0
            },
            return_route_update_helper: {
                automatic_scheduling_enabled: false,
                automatic_scheduling_period: 0,
                automatic_scheduling_time_of_day: 0,
                current_node_deadline: 0,
                current_node_id: 0,
                last_execution_complete_time: 0,
                last_execution_assign_failed_devices: "",
                last_execution_delete_failed_devices: "",
                last_execution_start_time: 0,
                last_execution_assign_success_devices: "",
                last_execution_delete_success_devices: "",
                next_on_demand_execution: 0,
                next_scheduled_execution: 0,
                state: 0
            }
        },
        zwave_log: [],
        zwaveInclusionStatus: {
            inclusionState: zwave_1.ZWaveInclusionState.INCLUSION_IDLE,
            lastIncludedNodeID: 0
        },
        zigbee: (0, zigbee_1.createDefaultZigbeeData)(),
        stats: {},
        process_monitor: {
            "mandatory_threads": [],
            "threads": {}
        }
    };
    return __assign(__assign({}, skeleton), x);
}
exports.createEmptyHubCacheData = createEmptyHubCacheData;
