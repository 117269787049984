import * as React from 'react'
import icons from './output.svg'


export function Icons() {

    return (<svg dangerouslySetInnerHTML={{__html: icons}}
                 style={{display: 'none'}}
        />);
}
