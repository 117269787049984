import * as React from 'react';
import {ReactElement} from "react";
import {CollapsibleDiv} from "../general/CollapsibleDiv";


export interface ErrorAction {
    label: string
    action: (error: Error) => void
}

interface ErrorBoundaryProps {
    label: string,
    extraInfo?: null|any
    actions?: Array<ErrorAction>
    children?: React.ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {

    _props: ErrorBoundaryProps;

    resetButton: ReactElement;

    state: {
        hasError: boolean,
        errorMessage: string,
        derivedErrorMessage: string,
        error: Error,
        showStack: boolean
    };

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: '',
            derivedErrorMessage: '',
            error: null,
            showStack: false
        };
        this._props = props;

        this.resetErrorFlag = this.resetErrorFlag.bind(this);
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, derivedErrorMessage: error.message, error};
    }

    componentDidCatch(error: Error, errorInfo: any) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        // console.debug("ErrorBoundary::componentDidCatch", error, errorInfo);
        const extraInfo = this._props.extraInfo ? JSON.stringify(this._props.extraInfo, null, 4) : '';
        const errorStack = JSON.stringify(errorInfo, null, 4).replace('\\n', '\n');
        this.setState({
            hasError: true,
            errorMessage: `${extraInfo}\n${errorStack}`
        });
    }

    resetErrorFlag() {
        // console.debug("Resetting", JSON.stringify(this.state));
        this.setState({hasError: false});
    }

    renderActions() {
        const result = [
            <button key={"reset"} className={"button is-primary is-rounded"}
                                onClick={() => this.resetErrorFlag()}>
                    Reset Error
            </button>
        ];

        if (this._props.actions) {
            for (const action of this._props.actions) {
                result.push(<button key={`button-${action.label.replaceAll(' ', '')}`}
                                    className={"button is-primary is-rounded"}
                                    onClick={() => action.action(this.state.error)}>
                    {action.label}</button>);
            }
        }
        return result;
    }

    renderErrorTable() {
        const rows = [];
        // rows.push(<tr><td>Name</td><td>{this.state.error.name}</td></tr>);
        // rows.push(<tr><td>Message</td><td>{this.state.error.message}</td></tr>);
        rows.push(<tr key={rows.length}><td>Error</td><td>{this.state.error.toString()}</td></tr>);

        if (this.state.error.hasOwnProperty('fileName')) {
            // @ts-ignore
            rows.push(<tr key={"file"}><td>Filename</td><td>{this.state.error.fileName}</td>
            </tr>)
        }
        if (this.state.error.hasOwnProperty('lineNumber')) {
            // @ts-ignore
            rows.push(<tr key={"line"}><td>Line number</td><td>{this.state.error.lineNumber}</td>
            </tr>)
        }
        if (this.state.error.hasOwnProperty('columnNumber')) {
            // @ts-ignore
            rows.push(<tr key={"col"}><td>Column number</td><td>{this.state.error.columnNumber}</td></tr>)
        }
        if (this.state.error.hasOwnProperty('stack') && this.state.showStack) {
            // @ts-ignore
            const stackText = this.state.error.stack.replaceAll(' at ', '<br/>at ');
            rows.push(<tr key={"stack"}>
                <td>Stack</td>
                <td dangerouslySetInnerHTML={{__html: stackText}}/>
            </tr>);
        }
        if (this._props.extraInfo) {
            // @ts-ignore
            const text = JSON.stringify(this._props.extraInfo, null, 4).replaceAll('\n', '<br/>');
            rows.push(<tr  key={"info"}>
                <td>Data</td>
                <td>
                    <CollapsibleDiv title={"Data"} startCollapsed={true}>
                        <code dangerouslySetInnerHTML={{__html: text}}/>
                    </CollapsibleDiv>
                </td>
            </tr>);
        }

        return (<table className={"table is-bordered"}
                       onDoubleClick={() => this.setState({showStack: !this.state.showStack})}>
                <tbody>{rows}</tbody>
            </table>
        );
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<div className={"full-height scrollable-area"}>
                <div>{JSON.stringify(this.state)}</div>
                <h1 className={"subtitle"}>{this._props.label ? this._props.label : 'Something went wrong'}</h1>

                {this.renderErrorTable()}

                {this.renderActions()}

            </div>);
        }
        return this._props.children;
    }
}
