import {WifiSSID} from "genius-hub-types";

const reCell = /Cell ([\d]{2}) - Address:\s((?:[0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2})/;
const reESSID = /\s+ESSID: "(.{1,32})".*/;
const reModeChannel = /\s+Mode: ([a-zA-Z]{1,10})\s+Channel: ([0-9]{1,2})/;
const reSignalQuality = /\s+Signal: (-?[0-9]{1,3}) dBm\s+Quality: ([0-9]{1,3})\/([0-9]{1,3})/;
const reEncryption = /\s+Encryption: (.*)/;

export function ParseWifiData(data: string): Array<WifiSSID> {
    const result:Array<WifiSSID> = [];

    const lines = data.split('\n');
    let currentSSID: WifiSSID;

    for (const line of lines) {

        const matchCell = line.match(reCell);
        const matchESSID = line.match(reESSID);
        const matchModeChannel = line.match(reModeChannel);
        const matchSignalQuality = line.match(reSignalQuality);
        const matchEncryption = line.match(reEncryption);

        if (matchCell) {
            currentSSID = {
                "security-modes": [],
                bssid: matchCell[2],
                country: "",
                signal: 0,
                ssid: ""
            }
            result.push(currentSSID)
        }

        if (matchESSID) {
            currentSSID.ssid = matchESSID[1];
        }

        if (matchModeChannel) {
            currentSSID.mode = matchModeChannel[1];
            currentSSID.channel = parseInt(matchModeChannel[2]);
        }

        if (matchSignalQuality) {
            currentSSID.signal = parseInt(matchSignalQuality[1]);
        }

        if (matchEncryption) {
            currentSSID["security-modes"] = matchEncryption[1];
        }

    }

    return result;
}
