import {
    ZoneCollection,
    Issue,
    HubCacheData,
    IssueLevel,
    ZoneTPI,
    Issues,
    getZoneDefaultSettings,
    ZoneType,
    ZoneSubType,
    ZoneBase,
    TPI, device_catalogue
} from 'genius-hub-types'
import {iterateZones} from './issueTools';
import {registerFormatter} from "./issueFormatter";
import {isZigbeeDeviceID, longHashToShortHash, toRelativeTimeString, zigbeeShortID} from "genius-hub-utils";


registerFormatter('zone:db_bug', issue => `Zone ${issue.data.zoneName} - device issue? Temp is ${issue.data.fPV.toFixed(1)}`);
registerFormatter('zone:using_weather_temp', issue => `Cannot read a temperature for ${issue?.data?.zoneName} - it is using the external temperature`);
registerFormatter('zone:using_assumed_temp', issue => `Cannot read a temperature for ${issue?.data?.zoneName} - it is using the 'assumed' temperature`);
registerFormatter('zone:waiting_for_temp', issue => `A temperature has not yet been reported for ${issue?.data?.zoneName}`);
registerFormatter('node:not_seen', issue => {
    const shortHash = longHashToShortHash(issue.data.nodeHash);
    const deviceTypeFound = device_catalogue.hasOwnProperty(shortHash);
    // @ts-ignore
    const desc = deviceTypeFound ? device_catalogue[shortHash].sku : '';
    const nodeID = isZigbeeDeviceID(issue.data.nodeID) ? zigbeeShortID(issue.data.nodeID) : issue.data.nodeID;

    return `The ${desc} in ${issue.data.zoneName} [device ${nodeID}] cannot be found by the Hub`;
});

registerFormatter('node:no_comms', issue => {
    const shortHash = longHashToShortHash(issue.data.nodeHash);
    const deviceTypeFound = device_catalogue.hasOwnProperty(shortHash);
    // @ts-ignore
    const desc = deviceTypeFound ? device_catalogue[shortHash].sku : '';

    const nodeID = isZigbeeDeviceID(issue.data.nodeID) ? zigbeeShortID(issue.data.nodeID) : issue.data.nodeID;

    return `${issue.data.zoneName} - ${desc} [device ${nodeID}] has lost communications`;

});

registerFormatter('node:low_battery', issue => {
    const shortHash = longHashToShortHash(issue.data.nodeHash);
    const deviceTypeFound = device_catalogue.hasOwnProperty(shortHash);
    // @ts-ignore
    const desc = deviceTypeFound ? device_catalogue[shortHash].sku : '';
    const nodeID = isZigbeeDeviceID(issue.data.nodeID) ? zigbeeShortID(issue.data.nodeID) : issue.data.nodeID;
    const level = issue.data.batteryLevel == 255 ? 0 : parseInt(issue.data.batteryLevel)

    return `The ${desc} in ${issue.data.zoneName} [device ${nodeID}] has a low battery (battery=${level}%)`;

});

registerFormatter('node:warn_battery', issue => {
    const shortHash = longHashToShortHash(issue.data.nodeHash);
    const deviceTypeFound = device_catalogue.hasOwnProperty(shortHash);
    // @ts-ignore
    const desc = deviceTypeFound ? device_catalogue[shortHash].sku : '';
    const nodeID = isZigbeeDeviceID(issue.data.nodeID) ? zigbeeShortID(issue.data.nodeID) : issue.data.nodeID;

    return `${issue.data.zoneName} - ${desc} [device ${nodeID}] has a low battery (${parseInt(issue.data.batteryLevel)}%) `;
});

registerFormatter('node:bad_temp', issue => {
    const shortHash = longHashToShortHash(issue.data.nodeHash);
    const deviceTypeFound = device_catalogue.hasOwnProperty(shortHash);
    // @ts-ignore
    const desc = deviceTypeFound ? device_catalogue[shortHash].sku : '';
    const nodeID = isZigbeeDeviceID(issue.data.nodeID) ? zigbeeShortID(issue.data.nodeID) : issue.data.nodeID;

    // return `${issue.data.zoneName} - ${desc} [device ${nodeID}] has reported a spurious temperature (${issue.data.fPV.toFixed(1)}%)`;
    return `${issue.data.zoneName} - ${desc} [device ${nodeID}] temperature probe is disconnected`;

});

registerFormatter('opentherm:lost_comms', issue => {
    const tm = toRelativeTimeString((issue.data.seconds_since_comms as number) * -1);
    // return `${issue.data.zoneName} - lost comms to boiler (${tm})`;
    return `OpenTherm communication lost to boiler`;
});


registerFormatter('opentherm:comms_status_module', issue => {
    const tm = toRelativeTimeString((issue.data.seconds_since_comms as number) * -1);
    // return `${issue.data.zoneName} - lost comms to boiler (${tm})`;
    return `OpenTherm module not present`;
});

registerFormatter('opentherm:comms_status_opentherm', issue => {
    const tm = toRelativeTimeString((issue.data.seconds_since_comms as number) * -1);
    // return `${issue.data.zoneName} - lost comms to boiler (${tm})`;
    return `OpenTherm communication lost to boiler`;
});


registerFormatter('zone:tpi_no_temp', issue => `${issue?.data?.zoneName || issue?.data} has no valid temperature`);

registerFormatter('manager:weather', issue => `Unable to update the weather data from Internet`);


function detectDataBrokerMappingBug(zone: ZoneBase): Issues {

    if ((ZoneType.ControlSP == zone.iType) ||
        ((ZoneType.TPI == zone.iType) && (zone.zoneSubType == ZoneSubType.WetUFH))
    ) {
        if (zone.fPV > 30) {
            return [
                new Issue(
                    'zone:db_bug',
                    IssueLevel.ERROR,
                    {
                        zoneID: zone.iID,
                        zoneTemp: zone.fPV,
                        zoneName: zone.strName
                    })
            ];
        }
    }
    return [];
}

function detectZoneReportedIssues(zone: ZoneBase, hubData: HubCacheData): Issues {
    const result: Issues = [];

    // Append the zoneID into .data
    for (const issue of zone.lstIssues) {

        const x = new Issue(issue.id, issue.level, issue.data);
        x.data = {...issue.data, zoneID: zone.iID, zoneName: zone.strName};
        if (x.data.hasOwnProperty('nodeID')) {
            const resultZwave = hubData?.zwave?.nodes?.find( node => node.id == x.data.nodeID);
            if (resultZwave === undefined) {
                continue;
            }
        }
        result.push(x);
    }
    return result;
}


export interface ObjectDiff<T> {
    notEqual: Array<keyof T>,
    extraFields: Array<keyof T>,
    missingFields: Array<keyof T>
}

export function detectZoneIssues(zone: ZoneBase, hubData: HubCacheData): Issues {

    return [
        ...detectDataBrokerMappingBug(zone),
        ...detectZoneReportedIssues(zone, hubData)
    ];
}

export function detectZoneAllIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];

    issues = issues.concat(iterateZones(hubData, (zone: ZoneBase) => {
        return [
            ...detectDataBrokerMappingBug(zone),
            ...detectZoneReportedIssues(zone, hubData)
        ];

    }));

    return issues;
}
