import {
    createSchemaPsuedoNodeInfo, createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassMultiChannel,
    createSchemaZWaveDevicePsuedoListening,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveDeviceListening, DeviceZWaveConfigDefaults} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


DeviceZWaveConfigDefaults['HO-DCR-C'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(8).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(0).required(),
        "specificDeviceClass": Joi.number().valid(0).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['HO-DCR-C']),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion({protocolSubVersion: 68}),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassSwitchBinary(),
        ...createSchemaZWaveCommandClassMultiChannel({
            "1": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
                "commandClasses": {
                    ...createSchemaZWaveCommandClassBasic(),
                    ...createSchemaZWaveCommandClassSwitchBinary(),
                    ...createSchemaZWaveCommandClassThermostatMode(),
                },
            }),
            "2": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
                "commandClasses": {
                    ...createSchemaZWaveCommandClassBasic(),
                    ...createSchemaZWaveCommandClassSwitchBinary(),
                    ...createSchemaZWaveCommandClassThermostatMode(),
                },
            }),
        }),
    })
});
DeviceZWaveConfigDefaults['HO-DCR-D'] = DeviceZWaveConfigDefaults['HO-DCR-D'];

