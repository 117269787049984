"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceDefinitionZigbee = void 0;
var devices_1 = require("./devices");
// zigbee 'hash' is `${manufacturer_name}/${model_identifier}
exports.DeviceDefinitionZigbee = {
    'Danfoss/eTRV0100': {
        type: devices_1.DeviceType.ZIGBEE,
        sku: 'DA-WRV-E',
        description: 'Wireless Radiator Valve',
        manufacturer_name: 'Danfoss',
        model_identifier: 'eTRV001'
    },
    'Danfoss/eTRV0103': {
        type: devices_1.DeviceType.ZIGBEE,
        sku: 'DA-WRV-E',
        description: 'Wireless Radiator Valve',
        manufacturer_name: 'Danfoss',
        model_identifier: 'eTRV003'
    },
    'DXJI0RG/eTW6FJWG': {
        type: devices_1.DeviceType.ZIGBEE,
        sku: 'DA-WRV-E',
        description: 'Wireless Radiator Valve',
        manufacturer_name: 'DXJI0RG',
        model_identifier: 'eTW6FJWG'
    },
    'Genius Concepts/PH-PLG-E': {
        type: devices_1.DeviceType.ZIGBEE,
        sku: 'PH-PLG-E',
        description: 'Smart Plug',
        manufacturer_name: 'Genius Concepts',
        model_identifier: 'PLG-E'
    },
};
exports.default = exports.DeviceDefinitionZigbee;
