import {HubCacheData, Issue, IssueLevel, ZoneBase, Issues, ZWaveData, ZWaveNode} from "genius-hub-types";
import isEqual from 'lodash/isEqual'


export type fnZoneIterator = ( zone: ZoneBase ) => Issues

export function iterateZones(hubData: HubCacheData, callback: fnZoneIterator): Issues {

    let result:Issues = [];

    if (!hubData?.zones || typeof hubData.zones !== 'object') {
        result.push(new Issue(
            'data',
            IssueLevel.WARNING,
            {
                target: 'hubData.zones is not an object',
                t: typeof hubData.zones
            }
        ));
    } else {

        const iter = Object.entries(hubData?.zones || {});
        for (let i of iter) {
            // @ts-ignore
            const zoneID:number = parseInt(i[0]), zone:Zone = i[1];

            result = [...result, ...callback(zone) ];

        }

    }

    return result;

}

export type fnZWaveNodeIterator = ( zwaveNode: ZWaveNode ) => Issues

export function iterateZWaveNodes(hubData: HubCacheData, callback: fnZWaveNodeIterator): Issues {

    let result:Issues = [];

    if (!hubData.zwave || !hubData.zwave.nodes || !Array.isArray(hubData.zwave.nodes)) {
        result.push(new Issue(
            'data',
            IssueLevel.WARNING,
            {
                target: 'zwaveData.nodes is not an array',
                t: typeof hubData.zwave.nodes
            })
        );
    } else {

        for (const node of hubData.zwave.nodes) {
            result = [...result, ...callback(node)];
        }

    }

    return result;

}
