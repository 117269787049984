/**
 *
 * @param startPrime 'previous' prime number
 * @param n generate 'n' more prime numbers
 */
export function* nextPrimes(startPrime: number, n: number) {
    let value = startPrime, i = 0;
    while (i++ < n) {
        if (value > 2) {
            let k, q;
            do {
                k = 3;
                value += 2;
                q = Math.floor(Math.sqrt(value));
                while (k <= q && value % k) {
                    k += 2;
                }
            } while (k <= q);
        } else {
            value = value === 2 ? 3 : 2;
        }
        yield value;
    }
}

/**
 * Determine is x is prime or not
 * @param x
 */
export function isPrime(x: number): boolean{
    let d = x-1;
    while (d > 1){
        if ((x % d) == 0) return false;
        d--;
    }
    return true;
}
