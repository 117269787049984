import {
    device_catalogue, DeviceTypeZigbee,
    DeviceTypeZWave,
    HasID,
    HasTags,
    HubCacheData, HubCredentials,
    Issue, IssueShape,
    QuestionData, regex_zigbee_eui64, regex_zigbee_pan,
    SolutionData,
    Tags
} from "genius-hub-types";
import {
    getDeviceChildValue, getHomeID,
    getNodeHash,
    getZigbeeDeviceDataNode,
    getZWaveDeviceDataNode,
    isPrime,
    longHashToShortHash,
    nextPrimes
} from "genius-hub-utils";
import {ResponseWould, TSWContext, TSWSolutionExtraInfo, TSWSolutionStatus} from "../redux/tsw";
import {questions, solutions} from "./tsw_metadata";
import {InjectIssueMetaData} from "./TSWTypes";
import {device_catalogue_zigbee} from "genius-hub-types";
import {useAppDispatch} from "../redux";
import {actionLogin, LOGIN_OK} from "../redux/hubs";
import {HubState} from "../redux/hubTypes";
import {webNavigate} from "../utils/webNavigate";
import {formatIssue} from "../../../genius-hub-issues/src";

export function getZWaveDeviceCatalogEntry(zwaveNodeID: number, hubData: HubCacheData): DeviceTypeZWave | undefined {
    const node = getZWaveDeviceDataNode(hubData?.devices, zwaveNodeID);
    if (node === null) return undefined;
    const longHash = getNodeHash(node);
    if (longHash === null) return undefined;
    const shortHash = longHashToShortHash(longHash);
    if (device_catalogue.hasOwnProperty(shortHash)) {
        return device_catalogue[shortHash];
    } else {
        return undefined;
    }
}

function getZigbeeDeviceCatalogEntry(nodeID: string, hubData: HubCacheData): DeviceTypeZigbee | undefined {
    const node = getZigbeeDeviceDataNode(hubData?.devices, nodeID);
    const zigbeeManfName = getDeviceChildValue(node, "ManufacturerName", true)?.val;
    const zigbeeModel = getDeviceChildValue(node, "ModelIdentifier", true)?.val;
    const zigbeeHash = `${zigbeeManfName}/${zigbeeModel}`;

    if (device_catalogue_zigbee.hasOwnProperty(zigbeeHash)) {
        return device_catalogue_zigbee[zigbeeHash];
    } else {
        return undefined;
    }
}

export function checkZWaveDeviceType(zwaveNodeID: number, type: string, hubData: HubCacheData): boolean | undefined {

    const catalogEntry = getZWaveDeviceCatalogEntry(zwaveNodeID, hubData);
    const deviceRegEx = /([A-Za-z]{2})-([A-Za-z]{3})-([A-Za-z]{1,8})/;

    if (catalogEntry) {

        const parts = catalogEntry.sku.match(deviceRegEx);

        if (parts.length === 4) {
            return parts[2].toUpperCase() === type.toUpperCase();
        } else {
            return false;
        }
    }
    return undefined;
}

export const issueTypes = [
    "config:tpi",
    "zone:db_bug",
    "zone:using_weather_temp",
    "zone:using_assumed_temp",
    "zone:waiting_for_temp",
    "node:not_seen",
    "node:no_comms",
    "node:low_battery",
    "node:warn_battery",
    "node:bad_temp",
    "opentherm:lost_comms",
    "zone:tpi_no_temp",
    "manager:weather",
    "hub:site_mapping",
    "zwave:config:param0",
    "zwave:device",
    "hub:version"
];


export function encodeInjectIssue(metaData: InjectIssueMetaData): string {
    const json = JSON.stringify(metaData);
    const b64 = btoa(json);
    return b64.replace('+', '_');
}

export function decodeInjectIssue(s: string): InjectIssueMetaData {
    const b64 = s.replace('_', '+');
    const json = atob(b64);
    try {
        const obj = JSON.parse(json);
        return obj;
    } catch (e) {
        throw new Error(`Failed to decode InjectIssueMetaData: ${e}`);
    }
}

/**
 * Takes a catalog entry based on manufacturer info, and returns an SKU for example 'WRV-C' or 'WMS'
 * @param catalogEntry
 */
function getDeviceSKUTag(catalogEntry: DeviceTypeZWave|DeviceTypeZigbee): string {

    switch (catalogEntry.sku) {

        // 5 letter code
        case "DA-WRT-C":
        case "HI-WRT-D":
        case "HO-WRT-B":
        case "PH-HUB-C":
            return catalogEntry.sku.slice(3).toLowerCase();

        // 3 letter code
        case "HI-PRT-A":
        case "HO-DCR-C":
        case "HO-DCR-D":
        case "HO-SCR-C":
        case "HO-SCR-D":
        case "PH-ERS-A":
        case "PH-IWM-A":
        case "PH-WMS-B":
        case "PH-WRS-A":
        case "PH-WRS-B":
        case "PH-WRS-D":
            return catalogEntry.sku.slice(3, 6).toLowerCase();


        // Special cases
        case "HO-ESW-C":
        case "HO-ESW-E":
            return "ESW";

        case "HO-ESW-D":
        case "HO-ESW-E-TMP":
            return "ESW-TTP";

        case "DA-WRV-A":
        case "DA-WRV-B":
        case "DA-WRV-C":
            return "WRV-C";

        case "PH-PLG-C":
        case "PO-PLG-B":
            return "PLG-C";
        case "PH-PLG-D":
        case "PH-PLG-E":
            return "PLG-E";

        case "DA-WRV-E":
            return "WRV-E";

        // unsupported devices
        case "AL-WRS-C":
        case "AL-WRS-D":
        case "AT-WRV-DEV":
        case "DA-DUR-A":
        case "EV-PLG-A":
        case "EV-WHS-A":
        case "EV-WMS-A":
        case "EV-WRV-A":
        case "HO-DCR-BEANBAG":
        case "HO-WRT-BEANBAG":
        case "HO-WRT-DEV":
        case "HO-WTS-B":
        case "LS-WTS-A":
        case "NQ-UMR-A":
        case "PH-ESW-B":
        case "SA-COS-A":

        default:
            return "unknown-device";
    }
}

export function getInjectedCredentials(injectedData: InjectIssueMetaData): HubCredentials | null {
    let result = null;
    if (injectedData.metaData.hasOwnProperty('credentials')) {
        result = injectedData.metaData['credentials'];
    }
    return result;
}

export function mapIssueToMetaData(issue: IssueShape, hubData: HubCacheData, existingData: InjectIssueMetaData = null): InjectIssueMetaData {
    let result: InjectIssueMetaData = {
        tags: [],
        metaData: {},
        issue: issue,
        returnURL: null,
        linkTo: null,
        linkType: null,
        ...existingData
    };
    let nodeID = issue?.data?.nodeID ?? null;
    let deviceCatalogEntry = null;
    let deviceSKU = null;

    if (nodeID) {
        if (typeof nodeID === 'string' && nodeID.match(regex_zigbee_eui64)) {
            result.metaData['zigbeeNodeID'] = nodeID;
            deviceCatalogEntry = getZigbeeDeviceCatalogEntry(issue.data.nodeID, hubData)
        } else if (!!nodeID && parseInt(nodeID)) {
            result.metaData['devicePath'] = `site/${getHomeID(hubData.devices)}/${nodeID}`;
            result.metaData['selectedZWaveNodeID'] = nodeID;
            deviceCatalogEntry = getZWaveDeviceCatalogEntry(issue.data.nodeID, hubData)
        }
    }

    if (deviceCatalogEntry) {
        deviceSKU = getDeviceSKUTag(deviceCatalogEntry).toLowerCase();
    }

    if (deviceSKU) {
        result.tags.push(deviceSKU);
    }

    if (nodeID && !deviceSKU) {
        result.tags.push("unknown");
    }

    result.metaData['credentials'] = getInjectedCredentials(result) || undefined;

    switch (issue?.id) {
        case "config:tpi":
            result.tags.push("zone", "config", "tpi");
            if (issue.data.hasOwnProperty('zoneID')) {
                result.metaData['zoneID'] = issue.data.zoneID;
            }
            break;

        case "zone:db_bug":
            result.tags.push("contact-gh");
            break;

        case "zone:device_multiple_assignment":
            result.tags.push("device", "unassign");
            break;

        case "zone:using_assumed_temp":
        case "zone:using_weather_temp":
        case "zone:waiting_for_temp":
            result.tags.push("zone", "no-temperature");
            break;

        case "device:unknown":
            result.tags.push("device");
            result.tags.push("unknown");
            break;

        case "node:not_seen":
        case "node:no_comms":
            result.tags.push("device");
            result.tags.push("comms");
            break;

        case "node:low_battery": // device reports 255
        case "node:warn_battery":// device report battery < 20%
            result.tags.push("device", "low-battery");
            break;

        case "node:bad_temp": // temp === 3276.8
            result.tags.push("device", "esw-ttp", "solid-red", "no-temperature");
            break;

        case "opentherm:lost_comms":
            result.tags.push("opentherm", "comms");
            break;

        case "opentherm:comms_status_module":
            result.tags.push("opentherm", "no-module");
            break;

        case "zone:tpi_no_temp":
            if (issue.data.hasOwnProperty('zoneID')) {
                result.metaData['zoneID'] = issue.data.zoneID;
            }
            result.tags.push("zone", "no-temperature");
            break;

        case "manager:weather":
            result.tags.push("hub", "no-weather");
            break;

        case "zwave:config:param0":
            result.tags.push("device", "configure");
            break;

        case "hub:update":
            result.tags.push("hub", "update");
            break;

        case "hub:sd_corrupt":
            result.tags.push("hub", "sd_corrupt");
            break;

        case "hub:sd_full":
            result.tags.push("hub", "sd_full");
            break;

        case "hub:unregistered":
            result.tags.push("hub", "unregistered");
            break;


        case "app:link":
            result.tags.push(...issue.data.tags);
            break;

        case "login:internal_error":
            result.tags.push("hub", "crashed");
            break;

        case "login:hg_server_offline":
            result.tags.push("hub", "network");
            break;

        case "login:unauthorized":
            result.tags.push("hub", "credentials");
            break;

        case "login:device_has_no_internet":
            result.tags.push("hub", "connection");
            break;

        case "login:hub_connection_broken":
            result.tags.push("hub", "network");
            break;

        case "zwave:mapped_nodes_missing":
            result.tags.push("zwave", "missing");
            break;

        //case "hub:site_mapping":
        default:

            // result.tags.push("no-solution");
            break;
    }

    // ensure that tags are unique
    result.tags = [...new Set(result.tags)];

    return result;
}


export type WithScores<T> = Array<[T, number]>;

export function scoreTagMatches<T extends HasTags>(tagged: Array<T>, tagsWanted: Tags, tagsNotWanted: Tags = []): WithScores<T> {
    const result: WithScores<T> = [];

    for (const o of tagged) {
        let score = 0;

        if (tagsWanted.length === 0 && o.tags.length === 0) {
            score += 10;

        } else {

            for (const tag of tagsWanted) {
                if (o.tags.includes(tag)) {
                    score += 1;
                }
            }
            for (const tag of tagsNotWanted) {
                if (o.tags.includes(tag)) {
                    score -= 1;
                }
            }
        }
        if (o.tags.length === tagsWanted.length) {
            let everythingMatches = true;
            o.tags.forEach(t => !tagsWanted.includes(t) ? everythingMatches = false : null)

            if (everythingMatches) score += 1;
        }
        result.push([o, score]);
    }

    result.sort((a: [T, number], b: [T, number]): number => {
        if (a[1] < b[1]) return 1;
        if (a[1] > b[1]) return -1;
        return 0;
    });

    return result;
}

export function collateTags<T extends HasTags>(array: Array<T>): Tags {
    const tags: Array<string> = [];
    array.forEach(solution => {
        solution.tags.forEach(tag => {
            if (!tags.includes(tag)) {
                tags.push(tag)
            }
        })
    });

    return tags;
}


export function filterTags<T extends HasTags>(list: Array<T>, includeTags: Array<string>, excludeTags: Array<string>): Array<T> {
    let result: Array<T> = [];

    if (includeTags.length === 0) {
        result = [...list];
    } else {
        for (const item of list) {
            let keep = true;
            for (const tag of includeTags) {
                if (!item.tags.includes(tag)) {
                    keep = false;
                }
            }
            if (keep) {
                result.push(item);
            }
        }
    }

    if (excludeTags.length) {
        for (let i = result.length - 1; i >= 0; i--) {
            const item = result[i];
            for (const tag of item.tags) {
                const idx = excludeTags.indexOf(tag);
                if (idx !== -1) {
                    result.splice(i, 1);
                    break;
                }
            }
        }
    }

    return result;
}

/**
 *
 * @param array array of object that extend HasTags
 * @return Array of T that are not unqiue
 */
export function checkUniqueTags<T extends HasTags>(array: Array<T>): Array<T> {
    let result: Array<T> = [];

    const tagNames = collateTags(array);
    tagNames.sort();

    const tagToPrime: { [tag: string]: number } = {};

    let n = 0;
    let p = 0;
    for (let i = 0; i < tagNames.length; i++) {
        p = nextPrimes(p, 1).next().value as number;
        const tag = tagNames[i];
        tagToPrime[tag] = p;
    }

    const arrayUniquenessValues: { [val: number]: Array<T> } = {};

    for (const el of array) {
        let val = 1;
        for (const t of el.tags) {
            val = val * tagToPrime[t];
        }
        if (!arrayUniquenessValues.hasOwnProperty(val)) {
            arrayUniquenessValues[val] = [];
        }
        arrayUniquenessValues[val].push(el);
    }

    const iter = Object.entries(arrayUniquenessValues);
    for (let i of iter) {
        const val = i[0], _arr = i[1];

        if (_arr.length > 1) {
            result = result.concat(_arr);
        }
    }

    return result;
}


/**
 *
 * @param array array of object that extend HasID
 * @return Array of T that are not unqiue
 */
export function checkUniqueIDs<T extends HasID>(array: Array<T>): Array<T> {
    let result: Array<T> = [];

    const arrayUniquenessValues: { [id: number]: Array<T> } = {};

    for (const el of array) {
        if (!arrayUniquenessValues.hasOwnProperty(el.id)) {
            arrayUniquenessValues[el.id] = [];
        }
        arrayUniquenessValues[el.id].push(el);
    }

    const iter = Object.entries(arrayUniquenessValues);
    for (let i of iter) {
        const val = i[0], _arr = i[1];

        if (_arr.length > 1) {
            result = result.concat(_arr);
        }
    }

    return result;
}


export function solveTSW(context: TSWContext): TSWSolutionExtraInfo {
    let result: TSWSolutionExtraInfo = {
        nextQuestionID: 0,
        solutions: [],
        status: TSWSolutionStatus.NoSolutionFound,
        questionsWithRank: []
    };

    const remainingSolutions = filterTags(solutions,
        context.solverCtx.solutionRequiresTags,
        context.solverCtx.solutionMustNotHaveTags);

    const remainingQuestions = questions.filter(question => {
        let keepQuestion = true;
        context.solverCtx.history.forEach(questionAsked => {
            if (question.id === questionAsked.question.id) {
                keepQuestion = false;
            }
        })
        return keepQuestion;
    });

    // console.debug(`solveTSW = ${remainingQuestions.length} questions left, ${remainingSolutions.length} solutions left: ${remainingSolutions.map(s => s.id).join(', ')}`);

    if (remainingSolutions.length === 0) {
        result.nextQuestionID = 0;
        result.solutions = [];
        result.status = TSWSolutionStatus.NoSolutionFound;
    } else if (remainingSolutions.length === 1) {
        result.nextQuestionID = 0;
        result.solutions = remainingSolutions;
        result.status = TSWSolutionStatus.FinalizedSolutions;
    } else {
        result.questionsWithRank = scoreTagMatches(remainingQuestions,
            context.solverCtx.solutionRequiresTags,
            context.solverCtx.solutionMustNotHaveTags);
        if (result.questionsWithRank[0][1] === 0) {
            result.status = TSWSolutionStatus.NoMoreQuestionsLeft;
        } else {
            result.nextQuestionID = figureOutNextQuestion_RankQuestionTags(context, remainingQuestions);
            // result.nextQuestionID = figureOutNextQuestion_HalfAndHalf(context, remainingQuestions, remainingSolutions);
            result.solutions = remainingSolutions;
            result.status = TSWSolutionStatus.AskQuestion;
        }
    }

    return result;
}


export function figureOutNextQuestion_RankQuestionTags(context: TSWContext, remainingQuestions: Array<QuestionData>): number | null {

    const scoredQuestions = scoreTagMatches(remainingQuestions,
        context.solverCtx.solutionRequiresTags,
        context.solverCtx.solutionMustNotHaveTags);

    if (scoredQuestions.length) {
        return scoredQuestions[0][0].id;
    }
    return null;
}

export function figureOutNextQuestion_HalfAndHalf(context: TSWContext, remainingQuestions: Array<QuestionData>, remainingSolutions: Array<SolutionData>): number | null {

    const responseWould: Array<ResponseWould> = [];

    remainingQuestions.forEach(question => {
        question.responses.forEach((response, idx) => {

            const includeTags = response.tags
                .filter(tag => tag[0] !== '-')
                .map(tag => tag[0] === '+' ? tag.slice(1) : tag);

            const excludeTags = response.tags
                .filter(tag => tag[0] === '-')
                .map(tag => tag.slice(1));

            const resultingSolutions = filterTags(remainingSolutions, includeTags, excludeTags);
            if (resultingSolutions.length === 0) return;

            responseWould.push({
                questionID: question.id,
                responseIndex: idx,
                solutionsRemaining: resultingSolutions.length,
                solutionsRemoved: remainingSolutions.length - resultingSolutions.length
            });
        })
    });

    const responseSorter = (a: ResponseWould, b: ResponseWould) => {
        // const aScore = a.solutionsRemaining - a.solutionsRemoved;
        // const bScore = b.solutionsRemaining - b.solutionsRemoved;
        const aScore = a.solutionsRemaining / a.solutionsRemoved;
        const bScore = b.solutionsRemaining / b.solutionsRemoved;
        if (aScore < bScore) return 1;
        if (aScore > bScore) return -1;
        return 0;
    }

    responseWould.sort(responseSorter);

    if (responseWould.length) {
        return responseWould[0].questionID;
    }
    return null;
}


export function LaunchTSW(issue: Issue, activeHub: HubState, baseURL: string, returnURL: string, navigate?: any) {
    const inject = mapIssueToMetaData(issue, activeHub.data);
    if (inject.tags.length) {
        if (!navigate) {
            const base = baseURL ?? '';
            const x = '/v6/tsw.html';
            const metaData: InjectIssueMetaData = {...inject, issue, returnURL: returnURL};

            const url = `${base}#/tsw?inject=${encodeInjectIssue(metaData)}`;
            webNavigate(url);
            // window.location.href = url;

        } else {
            const metaData: InjectIssueMetaData = {
                issue, returnURL: returnURL,
                ...inject
            }
            navigate(`/tsw?inject=${encodeInjectIssue(metaData)}`);
        }
    }
    else {
        alert(`Missing tags for issue ${issue.id}`);
    }

}
export function compareIssue(a: Issue, b: Issue): number {
    if (a.level > b.level) {
        return 1;
    } else if (a.level < b.level) {
        return -1;
    }
    /*
        if (a.id > b.id) {
            return 1;
        } else if (a.id < b.id) {
            return -1;
        }
    */

    const _a = formatIssue(a);
    const _b = formatIssue(b);
    if (_a > _b) {
        return 1;
    } else if (_a < _b) {
        return -1;
    }
    return 0;
}