
import {ZoneMode, ZoneBase} from 'genius-hub-types'

export function boostZoneOnModeChange(delta:any, zoneData: ZoneBase) {

    if (delta.hasOwnProperty('iMode') && delta.iMode === ZoneMode.Boost) {
        if (!delta.hasOwnProperty('iBoostTimeRemaining')) {
            delta['iBoostTimeRemaining'] = zoneData.iOverrideDuration;
        }
    }

    return delta;
}

