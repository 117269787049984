
import { focusChange } from './redux/app';


export default class FocusWatcher {

    dispatch: (any) => void;

    constructor(dispatch) {

        this.dispatch = dispatch;


        if (typeof window !== 'undefined') {
            window.addEventListener("blur", this.evtLostFocus.bind(this), false);
            window.addEventListener("focus", this.evtGainedFocus.bind(this), false);
        }
        else {
            document.addEventListener("pause", this.evtLostFocus.bind(this), false);
            document.addEventListener("resume", this.evtGainedFocus.bind(this), false);
        }

    }

    evtLostFocus() {
        this.dispatch(focusChange(false));
    };

    evtGainedFocus() {
        this.dispatch(focusChange(true));
    };

    checkFocus() {
        if (document) {
            if (document.hasFocus()) {
                this.evtGainedFocus();
            }
            else {
                this.evtLostFocus();
            }
        }
    }
}
