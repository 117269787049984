import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel,
    createZWaveConfigValueSchema,
    createSchemaZWaveDeviceNonListening,
    createSchemaZWaveCommandClassBattery,
    createSchemaZWaveCommandClassWakeup,
    createSchemaZWaveCommandClassThermostatSetpoint,
    createSchemaZWaveCommandClassMultiCmd,
    createSchemaZWaveCommandClassMark,
    createSchemaZWaveCommandClassClock,
    createSchemaZWaveCommandClassClimateControlSchedule
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";


DeviceZWaveConfigDefaults['HO-WRT-B'] = createSchemaZWaveDeviceNonListening().append({

    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(false).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(1, 4).required(),
        "genericDeviceClass": Joi.number().valid(8, 33).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(18, 83).required(),
        "protocolSpecificPart2": Joi.number().valid(22, 28).required(),
        "protocolSpecificPart3": Joi.number().valid(0).required(),
        "specificDeviceClass": Joi.number().valid(0, 1).required(),
    }),
    commandClasses: {
        ...createSchemaZWaveCommandClassBattery(),
        ...createSchemaZWaveCommandClassWakeup({
            "intervalSeconds": 600
        }),
        // ...createSchemaZWaveCommandClassBasic(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassThermostatSetpoint(),
        ...createSchemaZWaveCommandClassMultilevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassMultiCmd(),
        ...createSchemaZWaveCommandClassMark(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['HO-WRT-B']),
        ...createSchemaZWaveCommandClassClock(),
        ...createSchemaZWaveCommandClassClimateControlSchedule(),
        ...createSchemaZWaveCommandClassIndicator(),
        ...createSchemaZWaveCommandClassSchedule(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        // ...createSchemaZWaveCommandClassSwitchBinary(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 1, -1), // 'Temperature Report Threshold (0.1°C)'
            ...createZWaveConfigValueSchema(2, 1, 0), // 'Setpoint Display Resolution (0.1°C)'
            ...createZWaveConfigValueSchema(3, 1, 10), // 'Minimum Setpoint & Override Limit (1°C)'
        }),

    }
});

//
// DA-WRT-C
//
DeviceZWaveConfigDefaults['DA-WRT-C'] = createSchemaZWaveDeviceNonListening().append({

    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(false).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(33).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(0).required(),
        "specificDeviceClass": Joi.number().valid(1).required(),
    }),
    commandClasses: {
        ...createSchemaZWaveCommandClassBattery(),
        ...createSchemaZWaveCommandClassWakeup({
            "intervalSeconds": 600
        }),
        ...createSchemaZWaveCommandClassBasic(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatSetpoint(),
        ...createSchemaZWaveCommandClassMultilevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassMultiCmd(),
        ...createSchemaZWaveCommandClassMark(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['DA-WRT-C']),
        ...createSchemaZWaveCommandClassClock(),
        ...createSchemaZWaveCommandClassClimateControlSchedule(),
        ...createSchemaZWaveCommandClassIndicator(),
        ...createSchemaZWaveCommandClassSchedule(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 2, 5), // 'Temperature Report Threshold (0.1°C)'
            ...createZWaveConfigValueSchema(2, 2, 5), // 'Setpoint Display Resolution (0.1°C)'
            ...createZWaveConfigValueSchema(3, 2, 4), // 'Minimum Setpoint & Override Limit (1°C)'
            ...createZWaveConfigValueSchema(4, 2, 28), // 'Maximum Setpoint & Override Limit (1°C)'
            ...createZWaveConfigValueSchema(5, 2, 1), // 'LED Flash Period (1sec)'
            ...createZWaveConfigValueSchema(6, 1, 1), // 'Setpoint Control Function'
            ...createZWaveConfigValueSchema(7, 1, 1), // 'Temporary Override Scheduler'
            ...createZWaveConfigValueSchema(8, 1, 1), // 'Setpoint Type (in report)'
            ...createZWaveConfigValueSchema(9, 1, 1), // 'LED On Time (0.1 sec)'
            ...createZWaveConfigValueSchema(10, 1, 5), // 'Number of LED flashes'
            ...createZWaveConfigValueSchema(11, 1, 1), // 'LED Colour'
        }),

    }
});


//
// HI-WRT-D
//
DeviceZWaveConfigDefaults['HI-WRT-D'] = createSchemaZWaveDeviceListening().append({
    "WakeUp_Interval": Joi.number(),
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(true).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(8).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().valid(6).required(),
    }),
    commandClasses: {
        ...createSchemaZWaveCommandClassAssociation(),
        ...createSchemaZWaveCommandClassBattery(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatSetpoint(),
        ...createSchemaZWaveCommandClassMultilevel(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassMultiCmd(),
        ...createSchemaZWaveCommandClassMark(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['HI-WRT-D']),
        ...createSchemaZWaveCommandClassClock(),
        ...createSchemaZWaveCommandClassClimateControlSchedule(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassIndicator(),
        ...createSchemaZWaveCommandClassSchedule(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 2, 1800), //, 'Temperature report interval'
            ...createZWaveConfigValueSchema(2, 2, 5), //, 'Temperature report delta'
            ...createZWaveConfigValueSchema(3, 2, 10), //, 'Humidity report delta'
            ...createZWaveConfigValueSchema(4, 2, 0), //, 'Temperature offset'
            ...createZWaveConfigValueSchema(5, 2, 0), //, 'Humidity offset'
            ...createZWaveConfigValueSchema(6, 2, 20), //, 'Proximity sensor retrigger interval'
            ...createZWaveConfigValueSchema(7, 2, 0), //, 'Proximity sensor enable'
            ...createZWaveConfigValueSchema(8, 2, 50), //, 'LED brightness'
            ...createZWaveConfigValueSchema(9, 2, 3), //, 'LED timeout'
            ...createZWaveConfigValueSchema(10, 2, 3), //, 'Temperature  hysteresis'
            ...createZWaveConfigValueSchema(11, 2, 50), //, 'Min temp limit'
            ...createZWaveConfigValueSchema(12, 2, 280), //, 'Max temp limit'
            ...createZWaveConfigValueSchema(13, 2, 0), //, 'Relay state report interval'
            ...createZWaveConfigValueSchema(14, 2, 0), //, 'Report temp on proximity'

        }),
    }
});
