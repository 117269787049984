import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel,
    createZWaveConfigValueSchema,
    createSchemaZWaveDevicePsuedoListening,
    createSchemaZWaveCommandClassMultiChannel, createSchemaPsuedoNodeInfo
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";

DeviceZWaveConfigDefaults['PH-IWM-A'] = createSchemaZWaveDeviceListening().append({
    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(true).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(49).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(1).required(),
        "specificDeviceClass": Joi.number().valid(3).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassAssociation(),
        // ...createSchemaZWaveCommandClassBasic(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['PH-IWM-A']),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatMode(),
        ...createSchemaZWaveCommandClassPowerLevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassConfiguration({
            ...createZWaveConfigValueSchema(1, 2, 720),
            ...createZWaveConfigValueSchema(2, 2, 720),
            ...createZWaveConfigValueSchema(3, 1, 1),
            ...createZWaveConfigValueSchema(4, 2, 6000),
            ...createZWaveConfigValueSchema(5, 2, 6000),
        }),
        ...createSchemaZWaveCommandClassMultiChannel({
            "1": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
            }),
            "2": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
            }),
            "3": createSchemaZWaveDevicePsuedoListening().append({
                ...createSchemaPsuedoNodeInfo(),
            }),
        }),
    })
});
