"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditLogActionTargetID = exports.AuditLogEventSourceID = void 0;
var AuditLogEventSourceID;
(function (AuditLogEventSourceID) {
    AuditLogEventSourceID[AuditLogEventSourceID["ZWAVE"] = 0] = "ZWAVE";
    AuditLogEventSourceID[AuditLogEventSourceID["ZIGBEE"] = 1] = "ZIGBEE";
    AuditLogEventSourceID[AuditLogEventSourceID["OPENTHERM"] = 2] = "OPENTHERM";
    AuditLogEventSourceID[AuditLogEventSourceID["ZONE"] = 3] = "ZONE";
    AuditLogEventSourceID[AuditLogEventSourceID["WEATHER"] = 4] = "WEATHER";
    AuditLogEventSourceID[AuditLogEventSourceID["USER"] = 5] = "USER";
    AuditLogEventSourceID[AuditLogEventSourceID["SYSTEM"] = 6] = "SYSTEM";
})(AuditLogEventSourceID || (exports.AuditLogEventSourceID = AuditLogEventSourceID = {}));
var AuditLogActionTargetID;
(function (AuditLogActionTargetID) {
    AuditLogActionTargetID[AuditLogActionTargetID["ZWAVE"] = 0] = "ZWAVE";
    AuditLogActionTargetID[AuditLogActionTargetID["ZIGBEE"] = 1] = "ZIGBEE";
    AuditLogActionTargetID[AuditLogActionTargetID["OPENTHERM"] = 2] = "OPENTHERM";
    AuditLogActionTargetID[AuditLogActionTargetID["DATA"] = 3] = "DATA";
    AuditLogActionTargetID[AuditLogActionTargetID["ZONE"] = 4] = "ZONE";
    AuditLogActionTargetID[AuditLogActionTargetID["SECURITY"] = 5] = "SECURITY";
    AuditLogActionTargetID[AuditLogActionTargetID["SYSTEM"] = 6] = "SYSTEM";
    AuditLogActionTargetID[AuditLogActionTargetID["AUDIT_LOG"] = 7] = "AUDIT_LOG";
    AuditLogActionTargetID[AuditLogActionTargetID["NO_OPERATION"] = 65534] = "NO_OPERATION"; // NOTE: don't use 0xFFFF
})(AuditLogActionTargetID || (exports.AuditLogActionTargetID = AuditLogActionTargetID = {}));
