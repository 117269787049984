import { formatIssue } from "genius-hub-issues";
import {Issue} from "genius-hub-types";


export function removeDuplicateIssues(issues: Array<Issue>): Array<Issue> {
    const issuesMap = new Map<string, Issue>();
    issues.forEach( i => {
        const key = formatIssue(i);
        if (!issuesMap.has(key)) issuesMap.set(key, i);
    });
    return Array.from(issuesMap.values());
}
