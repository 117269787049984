"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeniusHubPermissions = void 0;
exports.GeniusHubPermissions = [
    { tag: "hub-admin", description: "Access & control your Hub (full hub control)" },
    { tag: "forum", description: "Verify access to use the Genius Hub Forum" },
    { tag: "discourse", description: "Verify access to use the Genius Hub Forum" },
    { tag: "ifttt", description: "Allow IFTTT to control my Genius Hub" },
    { tag: "alexa", description: "Allow Amazon Alexa to control my Genius Hub" },
];
