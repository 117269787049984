"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WifiSettingsStatus = void 0;
var WifiSettingsStatus;
(function (WifiSettingsStatus) {
    WifiSettingsStatus[WifiSettingsStatus["Idle"] = 0] = "Idle";
    WifiSettingsStatus[WifiSettingsStatus["GettingsSSIDs"] = 1] = "GettingsSSIDs";
    WifiSettingsStatus[WifiSettingsStatus["QueryingStatus"] = 2] = "QueryingStatus";
    WifiSettingsStatus[WifiSettingsStatus["ApplyingSettings"] = 3] = "ApplyingSettings";
    WifiSettingsStatus[WifiSettingsStatus["Busy"] = 4] = "Busy";
})(WifiSettingsStatus || (exports.WifiSettingsStatus = WifiSettingsStatus = {}));
