import {HubCacheData, Issue, IssueLevel, Issues, ReleaseInfo} from 'genius-hub-types'
import {detectZoneConfigurationIssues} from './ZoneConfigurationIssues';
import {detectZoneAllIssues} from './ZoneGeneralIssues';
import {detectHubDeviceMappingIssues} from "./HubDeviceMappingIssues";
import {detectZWaveIssues} from "./ZWaveIssues";
import {detectHubIssues, detectVersionIssues} from './HubIssues';
import {detectDeviceIssues} from "./DeviceIssues";
import {detectProcessMonitorIssues} from "./ProcessMonitorIssues";

export function detectIssues(hubData: HubCacheData, excludeList: Array<string> = [], includeList: Array<string> = [], releases?: Array<ReleaseInfo>): Array<Issue> {
    // console.debug("Scanning for issues...")
    let result: Issues = [];

    try {
        if (hubData) {
            result = [
                ...(releases ? detectVersionIssues(hubData, releases) : []),
                ...detectZoneConfigurationIssues(hubData),
                ...detectZoneAllIssues(hubData),
                ...detectHubDeviceMappingIssues(hubData),
                ...detectZWaveIssues(hubData),
                ...detectHubIssues(hubData),
                ...detectDeviceIssues(hubData),
                ...detectProcessMonitorIssues(hubData),
            ];
        }

    }
    catch(err:any) {
        console.error("detecting issues...", err);
        // debugger
        result.push(new Issue(
            "MONITOR",
            IssueLevel.CRITICAL,
            {
                message: err.toString()
            }
        ));
    }

    if (includeList.length) {
        result = result.filter(issue => includeList.includes(issue.id));
    }

    return result.filter(issue => !excludeList.includes(issue.id));
}

export * from './issueFormatter'
export * from './ZWaveIssues'
