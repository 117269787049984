import * as React from "react";
import './Loading.scss'

export default function Loading(props: {
    message?: string,
    blockBackground?: boolean
}) {

    if (props.blockBackground) {
        return (
            <div className={"modal is-active"}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <div className={"flow-box"}>

                        <div className="lds-dual-ring is-center"></div>
                    </div>

                    {props.message &&
                    <div className={"flow-box"}>
                        <div className={"is-size-1 has-text-centered has-text-white"}>
                            {props.message}
                        </div>
                    </div>}

                </div>
            </div>);
    } else {
        return (<>
            <div className={"flow-box"}>
                <div className="lds-dual-ring is-center"></div>
            </div>
            {props.message &&
            <div className={"flow-box"}>
                <div className={"is-size-1 has-text-centered has-text-white"}>
                    {props.message}
                </div>
            </div>}
        </>);
    }
}
