import {Store, Middleware} from "redux";
// import { RootState } from "..";


export const POLL_START = 'POLL_START';
export const POLL_STOP = 'POLL_STOP';
export const POLL_SET_INTERVAL = 'POLL_SET_INTERVAL';
export const POLL_TICK = 'POLL_TICK';
export const POLL_COMPLETE = 'POLL_COMPLETE';

export const actionStartPoll = () => ({type: POLL_START, payload: null});
export const actionStopPoll = () => ({type: POLL_STOP, payload: null});


let timer: any = null;
let pollInterval_ms = 30000;
let initialTimestamp = Date.now();
let polling = false;
let timerCount = 0;
let timers = [];

interface DispatchMessage {
    action: string,
    payload: any
}


const getPollInterval = (state: any): number => {
    if (state.hasOwnProperty('app')) {
        const app = state.app;
        if (app.hasOwnProperty('intervalHubPoll_ms')) {
            return app.intervalHubPoll_ms;
        }
    }
    return pollInterval_ms;
};

export default function poll(store: Store) {
    return (next:any) => (action:any) => {

        const timerCallback = () => {
            store.dispatch({
                type: POLL_TICK,
                payload: {
                    elapsedSeconds: Date.now()// - initialTimestamp
                }
            });
        };

        switch (action.type) {

            case POLL_COMPLETE:
                if (polling) {
                    const state = store.getState();
                    let pollInterval = getPollInterval(state) || 2500;

                    clearTimeout(timer);
                    timer = setTimeout(timerCallback, pollInterval);
                }
                break;


            case POLL_START: {
                const state = store.getState();
                let pollInterval = getPollInterval(state) || 2500;
                timerCount += 1;
                console.debug(`POLL_START pollTimerCount = ${timerCount}`);

                clearTimeout(timer);
                //timer = setInterval(timerCallback, 1000 /*getPollInterval(store.getState())*/);
                timer = setTimeout(timerCallback, pollInterval);
                store.dispatch({
                    type: POLL_TICK,
                    payload: {
                        elapsedSeconds: Date.now() - initialTimestamp
                    }
                });
                polling = true;
            }
                break;

            case POLL_STOP:
                timerCount -= 1;
                console.debug(`POLL_STOP pollTimerCount = ${timerCount}`);

                clearTimeout(timer);
                timer = null;
                polling = false;
                break;

            case POLL_SET_INTERVAL:
                pollInterval_ms = action.payload;
                break;
        }

        return next(action)
    };

}


export function actionPollComplete() {
    return {
        type: POLL_COMPLETE,
        payload: {}
    }
}
