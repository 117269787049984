"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZWaveInclusionState = exports.ZWaveControllerState = exports.ZWaveActionType = void 0;
var ZWaveActionType;
(function (ZWaveActionType) {
    ZWaveActionType[ZWaveActionType["INCLUDE"] = 0] = "INCLUDE";
    ZWaveActionType[ZWaveActionType["EXCLUDE"] = 1] = "EXCLUDE";
    ZWaveActionType[ZWaveActionType["CANCEL"] = 2] = "CANCEL";
    ZWaveActionType[ZWaveActionType["PING"] = 3] = "PING";
    ZWaveActionType[ZWaveActionType["REFRESH_VALUES"] = 4] = "REFRESH_VALUES";
    ZWaveActionType[ZWaveActionType["RELEARN"] = 5] = "RELEARN";
    ZWaveActionType[ZWaveActionType["CONFIGURE"] = 6] = "CONFIGURE";
    ZWaveActionType[ZWaveActionType["UPDATE_NEIGHBOURS"] = 7] = "UPDATE_NEIGHBOURS";
    ZWaveActionType[ZWaveActionType["CLEAR_QUEUE"] = 8] = "CLEAR_QUEUE";
    ZWaveActionType[ZWaveActionType["REMOVE_NODE"] = 9] = "REMOVE_NODE";
    ZWaveActionType[ZWaveActionType["RESET"] = 10] = "RESET";
    ZWaveActionType[ZWaveActionType["RESET_HARD"] = 11] = "RESET_HARD";
    ZWaveActionType[ZWaveActionType["GET_NEIGHBOURS"] = 12] = "GET_NEIGHBOURS";
    ZWaveActionType[ZWaveActionType["ENABLE_REPAIR"] = 13] = "ENABLE_REPAIR";
    ZWaveActionType[ZWaveActionType["DISABLE_REPAIR"] = 14] = "DISABLE_REPAIR";
    ZWaveActionType[ZWaveActionType["SET_CONFIG_PARAMETER"] = 15] = "SET_CONFIG_PARAMETER";
    ZWaveActionType[ZWaveActionType["ASSIGN_RETURN_ROUTES"] = 16] = "ASSIGN_RETURN_ROUTES";
    ZWaveActionType[ZWaveActionType["DELETE_RETURN_ROUTES"] = 17] = "DELETE_RETURN_ROUTES";
    ZWaveActionType[ZWaveActionType["SET_REQUIRES_NEIGHBOUR_UPDATE"] = 18] = "SET_REQUIRES_NEIGHBOUR_UPDATE";
    ZWaveActionType[ZWaveActionType["SET_REQUIRES_RETURN_ROUTE_UPDATE"] = 19] = "SET_REQUIRES_RETURN_ROUTE_UPDATE";
    ZWaveActionType[ZWaveActionType["SCHEDULE_NEIGHBOUR_UPDATE"] = 20] = "SCHEDULE_NEIGHBOUR_UPDATE";
    ZWaveActionType[ZWaveActionType["SET_AUTO_NEIGHBOUR_UPDATE"] = 21] = "SET_AUTO_NEIGHBOUR_UPDATE";
    ZWaveActionType[ZWaveActionType["CANCEL_NEIGHBOUR_UPDATE_EXECUTION"] = 22] = "CANCEL_NEIGHBOUR_UPDATE_EXECUTION";
    ZWaveActionType[ZWaveActionType["SCHEDULE_RETURN_ROUTE_UPDATE"] = 23] = "SCHEDULE_RETURN_ROUTE_UPDATE";
    ZWaveActionType[ZWaveActionType["SET_AUTO_RETURN_ROUTE_UPDATE"] = 24] = "SET_AUTO_RETURN_ROUTE_UPDATE";
    ZWaveActionType[ZWaveActionType["CANCEL_RETURN_ROUTE_UPDATE_EXECUTION"] = 25] = "CANCEL_RETURN_ROUTE_UPDATE_EXECUTION";
})(ZWaveActionType || (exports.ZWaveActionType = ZWaveActionType = {}));
var ZWaveControllerState;
(function (ZWaveControllerState) {
    ZWaveControllerState[ZWaveControllerState["IDLE"] = 0] = "IDLE";
    ZWaveControllerState[ZWaveControllerState["SENDING_FRAME"] = 1] = "SENDING_FRAME";
    ZWaveControllerState[ZWaveControllerState["WAITING_RESPONSE"] = 2] = "WAITING_RESPONSE";
    ZWaveControllerState[ZWaveControllerState["WAITING_FOR_RADIO"] = 3] = "WAITING_FOR_RADIO";
    ZWaveControllerState[ZWaveControllerState["NODE_INCLUDE"] = 4] = "NODE_INCLUDE";
    ZWaveControllerState[ZWaveControllerState["NODE_EXCLUDE"] = 5] = "NODE_EXCLUDE";
    ZWaveControllerState[ZWaveControllerState["NODE_UPDATE_NEIGHBOURS"] = 6] = "NODE_UPDATE_NEIGHBOURS";
})(ZWaveControllerState || (exports.ZWaveControllerState = ZWaveControllerState = {}));
var ZWaveInclusionState;
(function (ZWaveInclusionState) {
    ZWaveInclusionState[ZWaveInclusionState["INCLUSION_IDLE"] = 0] = "INCLUSION_IDLE";
    ZWaveInclusionState[ZWaveInclusionState["FAIL_TO_START_INCLUSION"] = 1] = "FAIL_TO_START_INCLUSION";
    ZWaveInclusionState[ZWaveInclusionState["FAIL_TO_START_EXCLUSION"] = 2] = "FAIL_TO_START_EXCLUSION";
    ZWaveInclusionState[ZWaveInclusionState["INCLUDING"] = 3] = "INCLUDING";
    ZWaveInclusionState[ZWaveInclusionState["EXCLUDING"] = 4] = "EXCLUDING";
    ZWaveInclusionState[ZWaveInclusionState["TIMEOUT"] = 5] = "TIMEOUT";
    ZWaveInclusionState[ZWaveInclusionState["INCLUDE_SUCCESS"] = 6] = "INCLUDE_SUCCESS";
    ZWaveInclusionState[ZWaveInclusionState["EXCLUDE_SUCCESS"] = 7] = "EXCLUDE_SUCCESS";
    ZWaveInclusionState[ZWaveInclusionState["INCLUDE_FAIL"] = 8] = "INCLUDE_FAIL";
    ZWaveInclusionState[ZWaveInclusionState["EXCLUDE_FAIL"] = 9] = "EXCLUDE_FAIL";
    ZWaveInclusionState[ZWaveInclusionState["CANCELLED"] = 10] = "CANCELLED";
})(ZWaveInclusionState || (exports.ZWaveInclusionState = ZWaveInclusionState = {}));
