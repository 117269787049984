// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../genius-hub-app-2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../genius-hub-app-2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
}

.lds-dual-ring:after {
  text-align: center;
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ffc600;
  border-color: #ffc600 transparent #ffc600 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./../genius-hub-widgets/src/general/Loading.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAGA;EACE,kBAAA;EAEA,YAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,qDAAA;EACA,6CAAA;AADF;;AAGA;EACE;IACE,uBAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":["\n\n.lds-dual-ring {\n  display: inline-block;\n  width: 80px;\n  height: 80px;\n  text-align: center;\n}\n.lds-dual-ring:after {\n  text-align: center;\n\n  content: \" \";\n  display: block;\n  width: 64px;\n  height: 64px;\n  margin: 8px;\n  border-radius: 50%;\n  border: 6px solid #ffc600;\n  border-color: #ffc600 transparent #ffc600 transparent;\n  animation: lds-dual-ring 1.2s linear infinite;\n}\n@keyframes lds-dual-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
