import {applyMiddleware, createStore, combineReducers, compose, AnyAction, Dispatch, Action} from 'redux';
import FocusWatcher from '../FocusWatcher';
import {createHashHistory, createBrowserHistory} from 'history';
import merge from 'lodash/merge';
import { createReduxHistoryContext } from "redux-first-history";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

/**********************************************************************
 * Reducers
 */
import app, {FOCUS_LOST, SAVE_STATE, AppState, initialState as AppInitialState} from './app';
import dashboards, {initialState as DashboardsInitialState } from './dashboards';
import log, {initialState as LogInitialState} from './log';
import hubs, {LOGIN_OK, actionNormalizeDataStore, initialState as HubsInitialState} from './hubs';
import charts, {ChartsState, initialState as ChartsInitialState} from './charts';
import services, {HubServicesState, initialState as ServicesInitialState} from './services';
import tsw, {initialState as TSWInitialState, TSWContext} from './tsw';
import tools, {initialState as ToolsInitialState, ToolsState} from './tools';
import filter from 'redux-storage-decorator-filter'

/**********************************************************************
 * Persistence stuff
 */
import * as storage from 'redux-storage'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createHashHistory()
});

const reducers/*: {
    app: AppState,
    log: any,
    hubs: HubsCollection,
    charts: ChartsState,
    services: HubServicesState,
    tsw: TSWContext,
    router: any
}*/ = {
    app,
    dashboards,
    log,
    hubs,
    charts,
    services,
    tsw,
    tools,
    router: routerReducer
};

const rootReducer = storage.reducer(
    combineReducers(reducers)
);


// export const useAppDispatch: () => AppDispatch = useDispatch
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


// @ts-ignore
import createEngine from 'redux-storage-engine-indexed-db';
// import createEngine from 'redux-storage-engine-localstorage';
let engine = createEngine('genius-hub-app');

// prevent these keys from being loaded
engine = filter(engine, [
    // 'whitelisted-key',
    // ['nested', 'key'],
    // ['another', 'very', 'nested', 'key']
], [
    ['app', 'encapsulateStyle'],
    ['router'],
]);


const blacklist: Array<string> = [];
// State will be persisted when these action types are dispatched
const whitelist = [LOGIN_OK, SAVE_STATE, FOCUS_LOST];


const storageMiddleware = storage.createMiddleware(engine, blacklist, whitelist);


/**********************************************************************
 * Middleware
 */
import {createLogger} from 'redux-logger'
import thunkMiddleware, {ThunkAction, ThunkDispatch} from 'redux-thunk';
import poll from './middleware/poll';
import pollOnFocus from './middleware/pollOnFocus';
import updateOnTick from './middleware/updateOnTick';
// import notifications from './middleware/notifications';
import {HubsCollection} from "./hubTypes";

const logger = createLogger({
    collapsed: true,
    // predicate: (getState: Function, action: {type: number, payload: any}) => ['POLL_TICK'].includes(action.type)
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,
    composeEnhancers(applyMiddleware(
        thunkMiddleware,
        poll,
        pollOnFocus,
        updateOnTick,
        // notifications,
        routerMiddleware,
        storageMiddleware,
        logger
    )));

export default store;
export const history = createReduxHistory(store);

const focusWatcher = new FocusWatcher(store.dispatch);


export async function loadState() {
    console.debug(`STARTUP: loadState`);
    console.timeLog('startup');

    const load = storage.createLoader(engine);

    console.debug(`STARTUP: loadState - created loader`);
    console.timeLog('startup');

    const newState = await load(store);

    console.debug(`STARTUP: loadState - loaded`);
    console.timeLog('startup');

    // .then(async (newState) => {
        //     console.log('Loaded state:', newState);
    focusWatcher.checkFocus();

            // Fill in default values if the don't exist already
    const state = merge({
            ...AppInitialState,
            ...DashboardsInitialState,
            ...HubsInitialState,
            ...LogInitialState,
            ...ChartsInitialState,
            ...ServicesInitialState,
            ...TSWInitialState,
            ...ToolsInitialState
        },
        newState);

    console.debug(`STARTUP: loadState - completed`);
    console.timeLog('startup');

    return state;
    // }, (err) => {
        //     console.log('Failed to load previous state', err)
        // });
}

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch | (() => ThunkDispatch<RootState, any, AnyAction>);

// export type AsyncAction = () => ThunkAction<any, RootState, unknown, AnyAction>
// export type Dispatcher = (action: Promise<AsyncAction> | Action) => void

// improve: Set this up properly. This is a temporary measure to bypass typescript warnings all over the place
export const useAppDispatch: any = useDispatch as any;
