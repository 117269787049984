import {HubCacheData, Issue, IssueLevel, Issues} from 'genius-hub-types'
import {getDeviceManfSpecData} from "genius-hub-utils";
import {registerFormatter} from "./issueFormatter";


registerFormatter('device:unknown_device_schema', issue => `Implement schema for ${issue.data.def.sku}`);
registerFormatter('device:device_unknown', issue => `Device ${issue.data.nodeID} is detected as unknown`);
registerFormatter('device:unknown', issue => `Device ${issue.data.nodeID} is detected as unknown`);
registerFormatter('device:count_excessive', issue => `Hub has too many devices (${issue.data.count} devices present)`);


function detectDeviceCountExcessive(hubData: HubCacheData): Issues {
    const result = [];

    let totalCount = 0;

    if (hubData?.zwave?.nodes) {
        const count = Object.keys(hubData.zwave.nodes).length;
        totalCount += count;
    }

    if (hubData?.zigbee?.network_manager?.mac_to_meta_data) {
        const count = Object.keys(hubData?.zigbee?.network_manager?.mac_to_meta_data).length;
    }

    if (totalCount > 40) {
        result.push(new Issue(
            'device:count_excessive',
            IssueLevel.WARNING,
            {
                count: totalCount
            }
        ));
    }

    return result;
}


export function detectDeviceIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];

    issues = issues.concat(
        // detectESWConfigIssues(hubData),
    );

    return issues;
}
