"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZoneDefaultSettings = void 0;
var zones_1 = require("./zones");
var managerDefaults = {
    iType: zones_1.ZoneType.Manager,
    iOverrideMode: 1,
    iOverrideDuration: 3600
};
var onOffDefaults = {
    iType: zones_1.ZoneType.OnOffTimer,
    iMode: 2,
    iOverrideMode: 1,
    iOverrideDuration: 3600,
    objTimer: [{ "iDay": 0, "iTm": -1, "fSP": 0 }, { "iDay": 0, "iTm": 25200, "fSP": 1 }, {
            "iDay": 0,
            "iTm": 32400,
            "fSP": 0
        },
        { "iDay": 0, "iTm": 64800, "fSP": 1 }, { "iDay": 0, "iTm": 79200, "fSP": 0 }, { "iDay": 1, "iTm": -1, "fSP": 0 },
        { "iDay": 1, "iTm": 25200, "fSP": 1 }, { "iDay": 1, "iTm": 32400, "fSP": 0 }, { "iDay": 1, "iTm": 64800, "fSP": 1 },
        { "iDay": 1, "iTm": 79200, "fSP": 0 }, { "iDay": 2, "iTm": -1, "fSP": 0 }, { "iDay": 2, "iTm": 25200, "fSP": 1 },
        { "iDay": 2, "iTm": 32400, "fSP": 0 }, { "iDay": 2, "iTm": 64800, "fSP": 1 }, { "iDay": 2, "iTm": 79200, "fSP": 0 },
        { "iDay": 3, "iTm": -1, "fSP": 0 }, { "iDay": 3, "iTm": 25200, "fSP": 1 }, { "iDay": 3, "iTm": 32400, "fSP": 0 },
        { "iDay": 3, "iTm": 64800, "fSP": 1 }, { "iDay": 3, "iTm": 79200, "fSP": 0 }, { "iDay": 4, "iTm": -1, "fSP": 0 },
        { "iDay": 4, "iTm": 25200, "fSP": 1 }, { "iDay": 4, "iTm": 32400, "fSP": 0 }, { "iDay": 4, "iTm": 64800, "fSP": 1 },
        { "iDay": 4, "iTm": 79200, "fSP": 0 }, { "iDay": 5, "iTm": -1, "fSP": 0 }, { "iDay": 5, "iTm": 25200, "fSP": 1 },
        { "iDay": 5, "iTm": 32400, "fSP": 0 }, { "iDay": 5, "iTm": 64800, "fSP": 1 }, { "iDay": 5, "iTm": 79200, "fSP": 0 },
        { "iDay": 6, "iTm": -1, "fSP": 0 }, { "iDay": 6, "iTm": 25200, "fSP": 1 }, { "iDay": 6, "iTm": 32400, "fSP": 0 },
        { "iDay": 6, "iTm": 64800, "fSP": 1 }, { "iDay": 6, "iTm": 79200, "fSP": 0 }]
};
var radiatorDefaults = {
    iType: zones_1.ZoneType.ControlSP,
    iMode: 2,
    iOverrideMode: 1,
    iOverrideDuration: 3600,
    bPrimaryZone: true,
    lOptions: 8,
    warmupDuration: {
        bEnable: false,
        bEnableCalcs: true,
        iTotalTime: 1852
    },
    objFootprint: {
        objReactive: {
            iSensitivity: 70,
            iReactiveMinutesTriggerOff: 20,
            iReactiveMinutesTriggerOn: 2,
            fThreshold: 1.0
        }
    },
    objTimer: [{ "iDay": 0, "iTm": -1, "fSP": 4 }, { "iDay": 0, "iTm": 25200, "fSP": 21 }, {
            "iDay": 0,
            "iTm": 32400,
            "fSP": 4
        },
        { "iDay": 0, "iTm": 64800, "fSP": 21 }, { "iDay": 0, "iTm": 79200, "fSP": 4 }, { "iDay": 1, "iTm": -1, "fSP": 4 },
        { "iDay": 1, "iTm": 25200, "fSP": 21 }, { "iDay": 1, "iTm": 32400, "fSP": 4 }, {
            "iDay": 1,
            "iTm": 64800,
            "fSP": 21
        },
        { "iDay": 1, "iTm": 79200, "fSP": 4 }, { "iDay": 2, "iTm": -1, "fSP": 4 }, { "iDay": 2, "iTm": 25200, "fSP": 21 },
        { "iDay": 2, "iTm": 32400, "fSP": 4 }, { "iDay": 2, "iTm": 64800, "fSP": 21 }, {
            "iDay": 2,
            "iTm": 79200,
            "fSP": 4
        },
        { "iDay": 3, "iTm": -1, "fSP": 4 }, { "iDay": 3, "iTm": 25200, "fSP": 21 }, { "iDay": 3, "iTm": 32400, "fSP": 4 },
        { "iDay": 3, "iTm": 64800, "fSP": 21 }, { "iDay": 3, "iTm": 79200, "fSP": 4 }, { "iDay": 4, "iTm": -1, "fSP": 4 },
        { "iDay": 4, "iTm": 25200, "fSP": 21 }, { "iDay": 4, "iTm": 32400, "fSP": 4 }, {
            "iDay": 4,
            "iTm": 64800,
            "fSP": 21
        },
        { "iDay": 4, "iTm": 79200, "fSP": 4 }, { "iDay": 5, "iTm": -1, "fSP": 4 }, { "iDay": 5, "iTm": 25200, "fSP": 21 },
        { "iDay": 5, "iTm": 32400, "fSP": 4 }, { "iDay": 5, "iTm": 64800, "fSP": 21 }, {
            "iDay": 5,
            "iTm": 79200,
            "fSP": 4
        },
        { "iDay": 6, "iTm": -1, "fSP": 4 }, { "iDay": 6, "iTm": 25200, "fSP": 21 }, { "iDay": 6, "iTm": 32400, "fSP": 4 },
        { "iDay": 6, "iTm": 64800, "fSP": 21 }, { "iDay": 6, "iTm": 79200, "fSP": 4 }
    ]
};
var groupDefaults = {
    iType: zones_1.ZoneType.Surrogate,
};
var openthermDefaults = {
    iType: zones_1.ZoneType.OpenTherm,
    setpointControl: {
        PI: {
            "e": 0,
            "fCV": 0,
            "fCV_limit_lower": 0,
            "fCV_limit_upper": 100,
            "fControlDeadband_lower": -2,
            "fControlDeadband_upper": 1,
            "fPV": 0,
            "fSP": 0,
            "kI": 0.004999999888241291,
            "kP": 5,
            "kpart": 0
        },
        constantSP: 60,
        controlStrategy: 1,
        dhwControlStrategy: 1,
        dhwSP: 70,
        tempScaler: {
            points: [
                { x: 0, y: 55 },
                { x: 1, y: 60 },
                { x: 2, y: 70 },
                { x: 3, y: 72 },
                { x: 4, y: 75 },
                { x: 5, y: 78 },
                { x: 6, y: 80 },
            ]
        }
    }
};
var tpiWetUnderfloor = {
    iType: zones_1.ZoneType.TPI,
    zoneSubType: 0,
    iMode: 2,
    iOverrideMode: 1,
    iOverrideDuration: 3600,
    lOptions: 8,
    warmupDuration: {
        bEnable: false,
        bEnableCalcs: true,
        iTotalTime: 1852
    },
    objFootprint: {
        objReactive: {
            iSensitivity: 70,
            iReactiveMinutesTriggerOff: 20,
            iReactiveMinutesTriggerOn: 2,
            fThreshold: 1.0,
            tmLastActivity: 0
        }
    },
    fPV_offset: 0,
    tpi: {
        kP: 25,
        kI: 5,
        cyclePeriod: 900,
        fControlDeadband: 2,
    },
    fMinSP: 4,
    fMaxSP: 28,
    objTimer: [{ "iDay": 0, "iTm": -1, "fSP": 18 }, { "iDay": 0, "iTm": 25200, "fSP": 21 }, {
            "iDay": 0,
            "iTm": 32400,
            "fSP": 18
        },
        { "iDay": 0, "iTm": 64800, "fSP": 21 }, { "iDay": 0, "iTm": 79200, "fSP": 18 }, { "iDay": 1, "iTm": -1, "fSP": 18 },
        { "iDay": 1, "iTm": 25200, "fSP": 21 }, { "iDay": 1, "iTm": 32400, "fSP": 18 }, {
            "iDay": 1,
            "iTm": 64800,
            "fSP": 21
        },
        { "iDay": 1, "iTm": 79200, "fSP": 18 }, { "iDay": 2, "iTm": -1, "fSP": 18 }, { "iDay": 2, "iTm": 25200, "fSP": 21 },
        { "iDay": 2, "iTm": 32400, "fSP": 18 }, { "iDay": 2, "iTm": 64800, "fSP": 21 }, {
            "iDay": 2,
            "iTm": 79200,
            "fSP": 18
        },
        { "iDay": 3, "iTm": -1, "fSP": 18 }, { "iDay": 3, "iTm": 25200, "fSP": 21 }, { "iDay": 3, "iTm": 32400, "fSP": 18 },
        { "iDay": 3, "iTm": 64800, "fSP": 21 }, { "iDay": 3, "iTm": 79200, "fSP": 18 }, { "iDay": 4, "iTm": -1, "fSP": 18 },
        { "iDay": 4, "iTm": 25200, "fSP": 21 }, { "iDay": 4, "iTm": 32400, "fSP": 18 }, {
            "iDay": 4,
            "iTm": 64800,
            "fSP": 21
        },
        { "iDay": 4, "iTm": 79200, "fSP": 18 }, { "iDay": 5, "iTm": -1, "fSP": 18 }, { "iDay": 5, "iTm": 25200, "fSP": 21 },
        { "iDay": 5, "iTm": 32400, "fSP": 18 }, { "iDay": 5, "iTm": 64800, "fSP": 21 }, {
            "iDay": 5,
            "iTm": 79200,
            "fSP": 18
        },
        { "iDay": 6, "iTm": -1, "fSP": 18 }, { "iDay": 6, "iTm": 25200, "fSP": 21 }, { "iDay": 6, "iTm": 32400, "fSP": 18 },
        { "iDay": 6, "iTm": 64800, "fSP": 21 }, { "iDay": 6, "iTm": 79200, "fSP": 18 }]
};
var tpiHotWater = {
    iType: zones_1.ZoneType.TPI,
    zoneSubType: 2,
    iMode: 2,
    iOverrideMode: 0,
    lOptions: 8,
    warmupDuration: {
        bEnable: false,
        bEnableCalcs: true,
        iTotalTime: 1852
    },
    fPV_offset: 0,
    tpi: {
        kP: 25,
        kI: 5,
        cyclePeriod: 900,
        fControlDeadband: 2,
    },
    fMinSP: 30,
    fMaxSP: 80,
    objTimer: [{ "iDay": 0, "iTm": -1, "fSP": 30 }, { "iDay": 0, "iTm": 21600, "fSP": 65 }, {
            "iDay": 0,
            "iTm": 28800,
            "fSP": 30
        },
        { "iDay": 0, "iTm": 61200, "fSP": 65 }, { "iDay": 0, "iTm": 75600, "fSP": 30 }, { "iDay": 1, "iTm": -1, "fSP": 30 },
        { "iDay": 1, "iTm": 21600, "fSP": 65 }, { "iDay": 1, "iTm": 28800, "fSP": 30 }, {
            "iDay": 1,
            "iTm": 61200,
            "fSP": 65
        },
        { "iDay": 1, "iTm": 75600, "fSP": 30 }, { "iDay": 2, "iTm": -1, "fSP": 30 }, { "iDay": 2, "iTm": 21600, "fSP": 65 },
        { "iDay": 2, "iTm": 28800, "fSP": 30 }, { "iDay": 2, "iTm": 61200, "fSP": 65 }, {
            "iDay": 2,
            "iTm": 75600,
            "fSP": 30
        },
        { "iDay": 3, "iTm": -1, "fSP": 30 }, { "iDay": 3, "iTm": 21600, "fSP": 65 }, { "iDay": 3, "iTm": 28800, "fSP": 30 },
        { "iDay": 3, "iTm": 61200, "fSP": 65 }, { "iDay": 3, "iTm": 75600, "fSP": 30 }, { "iDay": 4, "iTm": -1, "fSP": 30 },
        { "iDay": 4, "iTm": 21600, "fSP": 65 }, { "iDay": 4, "iTm": 28800, "fSP": 30 }, {
            "iDay": 4,
            "iTm": 61200,
            "fSP": 65
        },
        { "iDay": 4, "iTm": 75600, "fSP": 30 }, { "iDay": 5, "iTm": -1, "fSP": 30 }, { "iDay": 5, "iTm": 21600, "fSP": 65 },
        { "iDay": 5, "iTm": 28800, "fSP": 30 }, { "iDay": 5, "iTm": 61200, "fSP": 65 }, {
            "iDay": 5,
            "iTm": 75600,
            "fSP": 30
        },
        { "iDay": 6, "iTm": -1, "fSP": 30 }, { "iDay": 6, "iTm": 21600, "fSP": 65 }, { "iDay": 6, "iTm": 28800, "fSP": 30 },
        { "iDay": 6, "iTm": 61200, "fSP": 65 }, { "iDay": 6, "iTm": 75600, "fSP": 30 }]
};
function getZoneDefaultSettings(zoneType, zoneSubType) {
    if (zoneSubType === void 0) { zoneSubType = 0; }
    switch (zoneType) {
        case zones_1.ZoneType.Manager:
            return managerDefaults;
        case zones_1.ZoneType.OnOffTimer:
            return onOffDefaults;
        case zones_1.ZoneType.ControlSP:
            return radiatorDefaults;
        case zones_1.ZoneType.ControlOnOffPID:
            break;
        case zones_1.ZoneType.TPI:
            switch (zoneSubType) {
                case zones_1.ZoneSubType.WetUFH:
                    return tpiWetUnderfloor;
                case zones_1.ZoneSubType.DHW:
                    return tpiHotWater;
                case zones_1.ZoneSubType.OpenThermHeating:
                    return tpiHotWater;
            }
            break;
        case zones_1.ZoneType.Surrogate:
            return groupDefaults;
        case zones_1.ZoneType.OpenTherm:
            return openthermDefaults;
    }
    throw new Error("Cannot get default values for zoneType=".concat(zoneType, ", zoneSubType=").concat(zoneSubType));
}
exports.getZoneDefaultSettings = getZoneDefaultSettings;
