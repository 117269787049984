import * as React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../redux";
import {HubState, HubsCollection} from "../redux/hubTypes";
import {AppState} from "../redux/app";
import {detectIssues} from "genius-hub-issues";
import {removeDuplicateIssues} from "./issues";
import {Issue, IssueLevel, Issues} from "genius-hub-types";
import {HubServicesState} from "../redux/services";

/**
 * Get issue counts from active hub
 * const [countInfo, countWarning, countError, countCritical] = issueCounter();
 * @param removeDuplicates
 * @param exclude Issue IDs to exclude
 */
export function useIssueCounter(removeDuplicates: boolean = false, exclude: Array<string> = []): () => [number, number, number, number, Issues] {
    const activeHub = useSelector((state: RootState): HubState | null => {
        const activeHubName = (state.app as AppState)?.activeHubName;
        return state.hubs.hasOwnProperty(activeHubName) ? (state.hubs as HubsCollection)[activeHubName] : null;
    });
    const hubServices: HubServicesState = useSelector( (state: RootState) => {
        const activeHubName = (state.app as AppState)?.activeHubName;
        return state.services[activeHubName];
    });

    return () => {

        if (!activeHub?.data) return [0,0,0,0, []];
        let issues = detectIssues(activeHub.data, [],[], hubServices?.releases);

        if (exclude.length) {
            issues = issues.filter(issue => !exclude.includes(issue.id));
        }

        if (removeDuplicates) issues = removeDuplicateIssues(issues);

        const countInfo = issues.filter( i => i.level === IssueLevel.INFO ).length;
        const countWarning = issues.filter( i => i.level === IssueLevel.WARNING ).length;
        const countError = issues.filter( i => i.level === IssueLevel.ERROR ).length;
        const countCritical = issues.filter( i => i.level === IssueLevel.CRITICAL ).length;

        return [countInfo, countWarning, countError, countCritical, issues];
    }

}
