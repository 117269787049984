let theme: any = null;
// import * as def from "./default-encap.lazy.scss";
import * as dark from "./dark-encap.lazy.scss";

import "genius-hub-style";

// def.default.use();

function applyStyle(mod: any) {

    if (theme !== null) {
        theme.unuse();
    }
    theme = mod.default;
    theme.use();
}


export function useDefault(encapsulate: boolean) {

  /*  if (encapsulate) {
        import(`./default-encap.lazy.scss`).then(applyStyle);
        console.debug("Theme is default-encap");
    } else {
        import(`./default.lazy.scss`).then(applyStyle);
        console.debug("Theme is default");
    }*/

}

export function useDark(encapsulate: boolean) {

/*
    if (encapsulate) {
        import(`./dark-encap.lazy.scss`).then(applyStyle);
        console.debug("Theme is dark-encap");
    } else {
        import(`./dark.lazy.scss`).then(applyStyle);
        console.debug("Theme is dark");
    }
*/
}
