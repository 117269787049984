"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubUpgradeStatus = void 0;
var HubUpgradeStatus;
(function (HubUpgradeStatus) {
    // internal states for polling / UI
    HubUpgradeStatus[HubUpgradeStatus["None"] = 0] = "None";
    HubUpgradeStatus[HubUpgradeStatus["InProgress"] = 1] = "InProgress";
    HubUpgradeStatus[HubUpgradeStatus["Complete"] = 2] = "Complete";
    HubUpgradeStatus[HubUpgradeStatus["Failed"] = 3] = "Failed";
    // from hg-upgrade.sh on hub
    HubUpgradeStatus[HubUpgradeStatus["DOWNLOAD_BEGIN"] = 100] = "DOWNLOAD_BEGIN";
    HubUpgradeStatus[HubUpgradeStatus["DOWNLOAD_OK"] = 120] = "DOWNLOAD_OK";
    HubUpgradeStatus[HubUpgradeStatus["DOWNLOAD_FAIL"] = 150] = "DOWNLOAD_FAIL";
    HubUpgradeStatus[HubUpgradeStatus["INSTALL_BEGIN"] = 200] = "INSTALL_BEGIN";
    HubUpgradeStatus[HubUpgradeStatus["INSTALL_OK"] = 220] = "INSTALL_OK";
    HubUpgradeStatus[HubUpgradeStatus["INSTALL_FAIL"] = 250] = "INSTALL_FAIL";
    HubUpgradeStatus[HubUpgradeStatus["REVERT_BEGIN"] = 300] = "REVERT_BEGIN";
    HubUpgradeStatus[HubUpgradeStatus["REVERT_OK"] = 320] = "REVERT_OK";
    HubUpgradeStatus[HubUpgradeStatus["REVERT_FAIL"] = 350] = "REVERT_FAIL";
    HubUpgradeStatus[HubUpgradeStatus["CHECKSUM"] = 400] = "CHECKSUM";
    HubUpgradeStatus[HubUpgradeStatus["REBOOT"] = 405] = "REBOOT";
    HubUpgradeStatus[HubUpgradeStatus["INFO"] = 410] = "INFO";
    HubUpgradeStatus[HubUpgradeStatus["CLEAN"] = 415] = "CLEAN";
    HubUpgradeStatus[HubUpgradeStatus["VACUUM"] = 420] = "VACUUM";
    HubUpgradeStatus[HubUpgradeStatus["BACKUP_BEGIN"] = 500] = "BACKUP_BEGIN";
    HubUpgradeStatus[HubUpgradeStatus["BACKUP_OK"] = 520] = "BACKUP_OK";
    HubUpgradeStatus[HubUpgradeStatus["BACKUP_FAIL"] = 550] = "BACKUP_FAIL";
})(HubUpgradeStatus || (exports.HubUpgradeStatus = HubUpgradeStatus = {}));
