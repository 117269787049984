import * as React from 'react';
import {CSSProperties} from "react";


export interface GeniusHubIconProps {
    className?: string
    style?: CSSProperties
    width?: string
    height?: string
    size?: string
    iconName:string
    [propName: string ] : any
}

export function GeniusHubIcon(props:GeniusHubIconProps) {

    let {width, height, iconName, ...otherProps} = props;
    if (props.size) {
        width = props.size;
        height = props.size;
    }

    return (<svg viewBox={"0 0 128 128"}
                 width={width || "24px"}
                 height={height || "24px"}
                 {...otherProps}
    >
        <use href={'#' + props.iconName} />
    </svg>);

}