import {QuestionData, SolutionData} from "genius-hub-types";

export const questions: Array<QuestionData> = [
    {
        "id": 1, "title": "How Can I Help", "responses": [
            // {"hint": "radiator", "linkType": "none", "linkTo": null, "tags": ["radiator"]},
            {hint: "add device", tags: ["device", "include"], linkTo: 7001, linkType: "question"},
            {hint: "existing device", tags: ["device"], linkTo: 7001, linkType: "question"},
            {hint: "remove device", tags: ["device", "remove"], linkTo: 7001, linkType: "question"},
            {hint: "docs install", tags: ["docs", "install"], linkTo: 5001, linkType: "solution"},
            {hint: "docs user", tags: ["docs", "user_guide"], linkTo: 5002, linkType: "solution"},
            {
                hint: "docs technical_documents",
                tags: ["docs", "technical_documents"],
                linkTo: 5003,
                linkType: "solution"
            },
            {hint: "docs faq", tags: ["docs", "faq"], linkTo: 5004, linkType: "solution"},
            {hint: "docs videos", tags: ["docs", "videos"], linkTo: 5005, linkType: "solution"},
            {hint: "docs confluence", tags: ["docs", "confluence"], linkTo: 5006, linkType: "solution"},
        ], "tags": []
    },



    {
        "id": 3,
        "title": "Zone Related Issues",
        "responses": [{"hint": "radiator", "linkType": "none", "linkTo": null, "tags": ["radiator"]},
            {
                "hint": "onoff", "linkType": "none", "linkTo": null, "tags": ["onoff"]
            },
            {"hint": "on/off dhw", "linkType": "none", "linkTo": null, "tags": ["onoff", "dhw"]},
            {
                "hint": "tpi dhw", "linkType": "none", "linkTo": null, "tags": ["tpi", "dhw"]
            },
            {"hint": "tpi wet-ufh", "linkType": "none", "linkTo": null, "tags": ["tpi", "wet-ufh"]},
            {
                "hint": "tpi elec-ufh", "linkType": "none", "linkTo": null, "tags": ["tpi", "elec-ufh"]
            },
            {"hint": "tpi elec heat", "linkType": "none", "linkTo": null, "tags": ["tpi", "elec"]}],
        "tags": ["zone"]
    },

    {
        "id": 2100, "title": "Zone Troubleshooting", "responses": [
            { "hint": "rad_why_on", "linkType": "question", "linkTo": 2101, "tags": ["rad"] },
            { "hint": "rad_why_off", "linkType": "question", "linkTo": 2101, "tags": ["rad"] },
            { "hint": "wet_ufh_why_on", "linkType": "question", "linkTo": 2101, "tags": ["wet_ufh"] },
            { "hint": "wet_ufh_why_off", "linkType": "question", "linkTo": 2101, "tags": ["wet_ufh"] },
            { "hint": "elec_ufh_why_on", "linkType": "question", "linkTo": 2101, "tags": ["elec_ufh"] },
            { "hint": "elec_ufh_why_off", "linkType": "question", "linkTo": 2101, "tags": ["elec_ufh"] },
            { "hint": "elec_why_on", "linkType": "question", "linkTo": 2101, "tags": ["elec_heat"] },
            { "hint": "elec_why_off", "linkType": "question", "linkTo": 2101, "tags": ["elec_heat"] },
            { "hint": "dhw_onoff_why_on", "linkType": "question", "linkTo": 2101, "tags": ["dhw_onoff"] },
            { "hint": "dhw_onoff_why_off", "linkType": "question", "linkTo": 2101, "tags": ["dhw_onoff"] },
            { "hint": "dhw_temp_why_on", "linkType": "question", "linkTo": 2101, "tags": ["dhw_onoff"] },
            { "hint": "dhw_temp_why_off", "linkType": "question", "linkTo": 2101, "tags": ["dhw_onoff"] },
            { "hint": "dhw_irish_why_on", "linkType": "question", "linkTo": 2101, "tags": ["dhw_onoff"] },
            { "hint": "dhw_irish_why_off", "linkType": "question", "linkTo": 2101, "tags": ["dhw_onoff"] },
            { "hint": "onoff_why_on", "linkType": "question", "linkTo": 2101, "tags": ["onoff"] },
            { "hint": "onoff_why_off", "linkType": "question", "linkTo": 2101,  "tags": ["onoff"] },
            { "hint": "boiler_why_on", "linkType": "question", "linkTo": 2101, "tags": ["boiler"] },
            { "hint": "boiler_why_off", "linkType": "question", "linkTo": 2101, "tags": ["boiler"] }

        ], "tags": ["zone-issue"]
    }, 

    {
        "id": 2101, "title": "Zone Selection", "responses": [
            { "hint": "", "linkType": "question", "linkTo": 2102, "tags": [""] },
], "tags": ["zone-issue"]
    }, 

    {
        "id": 2102, "title": "Expected Devices in Zone", "responses": [
            { "hint": "", "linkType": "question", "linkTo": 2103, "tags": [""] },
            { "hint": "", "linkType": "solution", "linkTo": 2101, "tags": [""] }
        ], "tags": ["zone-issue"]
    }, 

    {
        "id": 2103, "title": "Zone Mappings", "responses": [
            { "hint": "correct_mappings", "linkType": "question", "linkTo": 2104, "tags": [""] },
            { "hint": "too_few_mappings", "linkType": "solution", "linkTo": 2101, "tags": [""] },
            { "hint": "too_many_mappings", "linkType": "solution", "linkTo": 2101, "tags": [""] }
        ], "tags": ["zone-issue"]
    }, 

    {
        "id": 2104, "title": "Zone Healthy?", "responses": [
            { "hint": "zone_devices_healthy", "linkType": "question", "linkTo": 2105, "tags": [""] },
            { "hint": "device_error", "linkType": "reset-tags", "linkTo": null, "tags": [""] } /* Macro to add relevant tags for each device */ 
        ], "tags": ["zone-issue"]
    }, 

    {
        "id": 2105, "title": "Device Selection", "responses": [
            { "hint": "", "linkType": "question", "linkTo": 2106, "tags": [""] },
        ], "tags": ["zone-issue"]
    }, 

    {
        "id": 2106, "title": "Device behaviour PENDING", "responses": [
            { "hint": "device_healthy", "linkType": "question", "linkTo": 2107, "tags": [""] },
            { "hint": "device_error", "linkType": "reset-tags", "linkTo": null, "tags": [""] } /* Macro to add relevant tags for each device */ 
        ], "tags": ["zone-issue"]
    }, /* MMH: See document about how this questions will work */

    {
        "id": 2107, "title": "Why Zone Heating", "responses": [
            { "hint": "zone_installation_issue", "linkType": "reset-tags", "linkTo": null, "tags": [""] }, /* Need to add in relevant tags such as device type and installation_issue for future solutions */
        ], "tags": ["zone-issue"]
    }, 
    {
        "id": 2111, "title": "Which zone are you having problems with", "responses": [
            { "hint": "zone chosen", "linkType": "question", "linkTo": 2112, "tags": ["zone-issue"] },
        ], "tags": ["zone-issue"]
    }, 
    {
        "id": 2112, "title": "Are the devices healthy", "responses": [
            { "hint": "zone_devices_healthy", "linkType": "question", "linkTo": 2113, "tags": [""] },
            { "hint": "device_error", "linkType": "reset-tags", "linkTo": null, "tags": [""] }, /* Need to add in relevant tags such as device type and installation_issue for future solutions */
        ], "tags": ["zone-issue"]
    }, 
    {
        "id": 2113, "title": "Why Zone Heating", "responses": [
            { "hint": "heating_as_expected", "linkType": "solution", "linkTo": 1, "tags": [""] },
            { "hint": "heating_not_as_expected", "linkType": "reset-tags", "linkTo": null, "tags": [""] },
            { "hint": "zone_installation_issue", "linkType": "question", "linkTo": 2114, "tags": [""] }
        ], "tags": ["zone-issue"]
    }, 
    {
        "id": 2114, "title": "Correct devices in zone", "responses": [
            { "hint": "correct_mappings", "linkType": "question", "linkTo": 2104, "tags": [""] },
            { "hint": "too_few_mappings", "linkType": "solution", "linkTo": 2101, "tags": [""] },
            { "hint": "too_many_mappings", "linkType": "solution", "linkTo": 2101, "tags": [""] },
            { "hint": "mixed_mappings", "linkType": "solution", "linkTo": 2101, "tags": [""] }
        ], "tags": ["zone-issue"]
    }, 
    {
        "id": 2115, "title": "Device with screen errors", "responses": [
            { "hint": "da-wrv-c", "linkType": "question", "linkTo": 2116, "tags": [""] },
            { "hint": "da-wrv-e", "linkType": "question", "linkTo": 2117, "tags": [""] },
            { "hint": "hi-prt-a", "linkType": "question", "linkTo": 2118, "tags": [""] },
        ], "tags": ["zone-issue"]
    }, 
    {
        "id": 2116, "title": "Device Screen Fault DA-WRV-C", "responses": [
            {"hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]
            },
            {"hint": "Mount Mode", "linkType": "none", "linkTo": null, "tags": ["fitting"] },
            {"hint": "E1", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "E2", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"] },
            {"hint": "E3", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "E5", "linkType": "reset-tags", "linkTo": null, "tags": ["crashed", "hub"] },
            {"hint": "comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
        ], "tags": ["zone-issue"]
    },
    {
        "id": 2117, "title": "Device Screen Fault DA-WRV-E", "responses": [
            {
                "hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]
            },
            { "hint": "Mount Mode", "linkType": "none", "linkTo": null, "tags": ["fitting"] },
            {
                "hint": "E1", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            { "hint": "E2", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"] },
            {
                "hint": "E6", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            { "hint": "E8", "linkType": "none", "linkTo": null, "tags": ["comms"] },
            {
                "hint": "E9", "linkType": "none", "linkTo": null, "tags": ["fitting", "screen-ok"]
            },
        ], "tags": ["zone-issue"]
    },
    {
        "id": 2118, "title": "Device Screen Fault HI-PRT-A", "responses": [
            { "hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"] },
            {
                "hint": "E1", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            { "hint": "Er2", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"] },
            {
                "hint": "Er3", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            { "hint": "Er4", "linkType": "none", "linkTo": null, "tags": ["floor-sensor"] },
            {
                "hint": "Er5", "linkType": "none", "linkTo": null, "tags": ["external-sensor"]
            },
        ], "tags": ["zone-issue"]
    },
    {
        "id": 7000,
        "title": "New Device?",
        "responses": [{"hint": "add new", "linkType": "question", "linkTo": 7001, "tags": ["include"]},
            {
                "hint": "existing", "linkType": "question", "linkTo": 7001, "tags": []
            },
            {"hint": "RDN", "linkType": "question", "linkTo": 7001, "tags": ["remove"]}],
        "tags": ["device"]
    },

    {
        "id": 7001,
        "title": "Which device?",
        "responses": [{"hint": "SCR", "linkType": "none", "linkTo": null, "tags": ["device", "scr"]},
            {
                "hint": "DCR", "linkType": "none", "linkTo": null, "tags": ["device", "dcr"]
            },
            {"hint": "ESW", "linkType": "none", "linkTo": null, "tags": ["device", "esw"]},
            {
                "hint": "ESW+TTP", "linkType": "none", "linkTo": null, "tags": ["device", "esw-ttp"]
            },
            {"hint": "PLG-B/C", "linkType": "none", "linkTo": null, "tags": ["device", "plg-c"]},
            {
                "hint": "PLG-D/E", "linkType": "none", "linkTo": null, "tags": ["device", "plg-e"]
            },
            {"hint": "IWM", "linkType": "none", "linkTo": null, "tags": ["device", "iwm"]},
            {
                "hint": "ERS", "linkType": "none", "linkTo": null, "tags": ["device", "ers"]
            },
            {"hint": "PRT", "linkType": "none", "linkTo": null, "tags": ["device", "prt"]},
            {
                "hint": "WRV-C", "linkType": "none", "linkTo": null, "tags": ["device", "wrv-c"]
            },
            {"hint": "WRV-E", "linkType": "none", "linkTo": null, "tags": ["device", "wrv-e"]},
            {
                "hint": "WRS", "linkType": "none", "linkTo": null, "tags": ["device", "wrs"]
            },
            {"hint": "WMS", "linkType": "none", "linkTo": null, "tags": ["device", "wms"]},
            {
                "hint": "WRT-B", "linkType": "none", "linkTo": null, "tags": ["device", "wrt-b"]
            },
            {"hint": "WRT-C", "linkType": "none", "linkTo": null, "tags": ["device", "wrt-c"]},
            {
                "hint": "WRT-D", "linkType": "none", "linkTo": null, "tags": ["device", "wrt-d"]
            },
            {"hint": "unknown", "linkType": "none", "linkTo": null, "tags": ["device", "unknown"]}],
        "tags": ["device"]
    },


    {
        "id": 1000,
        "title": "Identify Connection Issue",
        "responses": [
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["network"]},
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["credentials"]},
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["credentials_mismatch"]},
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["hub-b", "tunnel"]},
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["hub-b", "crashed"]},
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["hub-c", "tunnel"]},
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["hub-c", "crashed"]},

        ],
        "tags": [], //"hub-b", "hub-c", "crashed", "network", "tunnel", "credentials", "credentials_mismatch"]
    },

    // TODO: create a macro to append the hub type tag?
    {
        "id": 1001,
        "title": "Hub Type",
        "responses": [
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["hub-b"] },
            {"hint": "", "linkType": "none", "linkTo": null, "tags": ["hub-c"] },
        ],
        "tags": ["hub"]
    },

    {
        "id": 7002, "title": "Remove Device", "responses": [
            {"hint": "remove communicating", "linkType": "none", "linkTo": null, "tags": ["exclude"]},
            {"hint": "rdn", "linkType": "none", "linkTo": null, "tags": ["remove-dead"]},
            {"hint": "reset", "linkType": "none", "linkTo": null, "tags": ["factory-reset"]}
        ], "tags": ["device", "remove"]
    },

    {
        "id": 7003,
        "title": "SCR Which Lights?",
        "responses": [
            {"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-lights"]},
            {"hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {"hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {"hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]}
        ],
        "tags": ["device", "scr"]
    },

    {
        "id": 7450, "title": "WRV-C Screen?", "responses": [{
            "hint": "no power", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]},
            {
                "hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]
            },
            {
                "hint": "flashing backlight", "linkType": "none", "linkTo": null, "tags": ["flashing-screen"]
            },
            {"hint": "Mount Mode", "linkType": "none", "linkTo": null, "tags": ["fitting"]},
            {
                "hint": "E1", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "E2", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]},
            {
                "hint": "E3", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "E5", "linkType": "reset-tags", "linkTo": null, "tags": ["crashed", "hub"]},
            {
                "hint": "comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            }], "tags": ["device", "wrv-c"]
    },

    {
        "id": 7451,
        "title": "WRV-C Behaviour",
        "responses": [{"hint": "VHO", "linkType": "none", "linkTo": null, "tags": ["set-offset"]},
            {
                "hint": "remount", "linkType": "none", "linkTo": null, "tags": ["fitting"]
            },
            {"hint": "remount", "linkType": "none", "linkTo": null, "tags": ["fitting"]},
            {
                "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
            },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]}],
        "tags": ["device", "wrv-c", "screen-ok"]
    },

    {
        "id": 7050,
        "title": "DCR Which Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-lights"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-amber"]
            },
            {"hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]}],
        "tags": ["device", "dcr"]
    },

    {
        "id": 7004,
        "title": "SCR behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "scr", "solid-red"]
    },

    {
        "id": 7052,
        "title": "DCR behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]},
            {
                "hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "dcr", "solid-amber"]
    },

    {
        "id": 999,
        "title": "Testing",
        "responses": [{
            "hint": "pass", "linkType": "none", "linkTo": null, "tags": ["device", "scr", "include"]
        },
            {
                "hint": "fail", "linkType": "none", "linkTo": null, "tags": ["device", "wrv-c", "include"]
            },
            {
                "hint": "impossible",
                "linkType": "none",
                "linkTo": null,
                "tags": ["device", "wrv-c", "wrv-e", "include"]
            }],
        "tags": []
    },

    {
        "id": 7100,
        "title": "ESW Which Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-lights"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]
            },
            {"hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {
                "hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]
            }],
        "tags": ["device", "esw"]
    },

    {
        "id": 7101,
        "title": "ESW behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]},
            {
                "hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "esw", "solid-red"]
    },

    {
        "id": 7150,
        "title": "ESW-TTP Which Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-lights"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]
            },
            {"hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {
                "hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]
            }],
        "tags": ["device", "esw-ttp"]
    },

    {
        "id": 7152,
        "title": "ESW-TTP behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]},
            {
                "hint": "unassign", "linkType": "none", "linkTo": null, "tags": ["unassign"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "esw-ttp", "solid-red", "temperature"]
    },
    {
        "id": 7151,
        "title": "ESW-TTP Have Temperature?",
        "responses": [{
            "hint": "no probe", "linkType": "none", "linkTo": null, "tags": ["no-temperature"]
        },
            {"hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["temperature"]}],
        "tags": ["device", "esw-ttp", "solid-red"]
    },
    {
        "id": 7200,
        "title": "PLG-C Which Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-lights"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]
            },
            {"hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]}],
        "tags": ["device", "plg-c"]
    },
    {
        "id": 7201,
        "title": "PLG-C behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "plg-c", "solid-red"]
    },
    {
        "id": 7300,
        "title": "IWM Spur Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-light"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["fuse-power"]
            }],
        "tags": ["device", "iwm"]
    },
    {
        "id": 7301, "title": "IWM Device Lights?", "responses": [{
            "hint": "no power", "linkType": "reset-tags", "linkTo": null, "tags": ["device", "iwm", "no-light"]
        },
            {"hint": "has power", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {
                "hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]
            }], "tags": ["device", "iwm", "fuse-power"]
    },
    {
        "id": 7302,
        "title": "IWM behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "iwm", "fuse-power", "solid-red"]
    },
    {
        "id": 7350,
        "title": "ERS Spur Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-light"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["fuse-power"]
            }],
        "tags": ["device", "ers"]
    },
    {
        "id": 7352,
        "title": "ERS behaviour?",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "ers", "fuse-power", "solid-red"]
    },
    {
        "id": 7351, "title": "ERS Device Lights?", "responses": [{
            "hint": "no power", "linkType": "reset-tags", "linkTo": null, "tags": ["device", "ers", "no-light"]
        },
            {"hint": "has power", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {
                "hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]
            }], "tags": ["device", "ers", "fuse-power"]
    },
    {
        "id": 7401, "title": "PRT Behaviour", "responses": [{
            "hint": "screen brightness", "linkType": "none", "linkTo": null, "tags": ["screen-brightness"]
        },
            {"hint": "offset", "linkType": "none", "linkTo": null, "tags": ["set-offset"]},
            {
                "hint": "comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]},
            {
                "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
            },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "duplicate assignment", "linkType": "none", "linkTo": null, "tags": ["unassign"]
            }], "tags": ["device", "prt", "screen-ok"]
    },
    {
        "id": 7400, "title": "PRT Screen?", "responses": [{
            "hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-power"]
        },
            {"hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]},
            {
                "hint": "E1", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "Er2", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]},
            {
                "hint": "Er3", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "Er4", "linkType": "none", "linkTo": null, "tags": ["floor-sensor"]},
            {
                "hint": "Er5", "linkType": "none", "linkTo": null, "tags": ["external-sensor"]
            },
            {"hint": "LoC", "linkType": "none", "linkTo": null, "tags": ["screen-lock"]}], "tags": ["device", "prt"]
    },
    {
        "id": 7550,
        "title": "WRS Cover On Lights?",
        "responses": [{"hint": "pass", "linkType": "none", "linkTo": null, "tags": ["cover-ok"]},
            {
                "hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]
            }],
        "tags": ["device", "wrs"]
    },
    {
        "id": 7551,
        "title": "WRS Cover Off Lights?",
        "responses": [{"hint": "pass", "linkType": "none", "linkTo": null, "tags": ["coverless-ok"]},
            {
                "hint": "Reinclude", "linkType": "none", "linkTo": null, "tags": ["red-flash-coverless"]
            }],
        "tags": ["device", "wrs", "cover-ok"]
    },
    {
        "id": 7552,
        "title": "WRS Replace Battery Lights?",
        "responses": [{
            "hint": "Flat Battery", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "Pass", "linkType": "none", "linkTo": null, "tags": ["red-flash-battery"]}],
        "tags": ["device", "wrs", "cover-ok", "coverless-ok"]
    },
    {
        "id": 7553, "title": "WRS Behaviour", "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "unassign", "linkType": "none", "linkTo": null, "tags": ["unassign"]
            }], "tags": ["device", "wrs", "cover-ok", "coverless-ok", "red-flash-battery"]
    },
    {
        "id": 7600,
        "title": "WMS Cover On Lights?",
        "responses": [{"hint": "pass", "linkType": "none", "linkTo": null, "tags": ["cover-ok"]},
            {
                "hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]
            }],
        "tags": ["device", "wms"]
    },
    {
        "id": 7601,
        "title": "WMS Cover Off Lights?",
        "responses": [{"hint": "pass", "linkType": "none", "linkTo": null, "tags": ["coverless-ok"]},
            {
                "hint": "Reinclude", "linkType": "none", "linkTo": null, "tags": ["red-flash-coverless"]
            }],
        "tags": ["device", "wms", "cover-ok"]
    },
    {
        "id": 7602,
        "title": "WMS Replace Battery Lights?",
        "responses": [{
            "hint": "Flat Battery", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "Pass", "linkType": "none", "linkTo": null, "tags": ["red-flash-battery"]}],
        "tags": ["device", "wms", "cover-ok", "coverless-ok"]
    },
    {
        "id": 7603, "title": "WMS Behaviour", "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]},
            {
                "hint": "unassign", "linkType": "none", "linkTo": null, "tags": ["unassign"]
            }], "tags": ["device", "wms", "cover-ok", "coverless-ok", "red-flash-battery"]
    },
    {
        "id": 7650, "title": "WRT-B Screen?", "responses": [{
            "hint": "no power", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]},
            {
                "hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]
            },
            {"hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]},
            {
                "hint": "No Comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "Reinclude", "linkType": "none", "linkTo": null, "tags": ["antenna"]}], "tags": ["device", "wrt-b"]
    },
    {
        "id": 7651,
        "title": "WRT-B Behaviour",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]},
            {
                "hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]
            },
            {"hint": "unassign", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "wrt-b", "screen-ok"]
    },
    {
        "id": 7700, "title": "WRT-C Screen?", "responses": [{
            "hint": "no power", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]},
            {
                "hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]
            },
            {"hint": "No Comms", "linkType": "none", "linkTo": null, "tags": ["comms"]}], "tags": ["device", "wrt-c"]
    },
    {
        "id": 7702,
        "title": "WRT-C Behaviour",
        "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]},
            {
                "hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]
            },
            {"hint": "unassign", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "wrt-c", "screen-ok", "no-light"]
    },
    {
        "id": 7701,
        "title": "WRT-C Which Light?",
        "responses": [{"hint": "light-ok", "linkType": "none", "linkTo": null, "tags": ["no-light"]},
            {
                "hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-green"]
            }],
        "tags": ["device", "wrt-c", "screen-ok"]
    },
    {
        "id": 7750, "title": "WRT-D Screen?", "responses": [{
            "hint": "no power", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]},
            {
                "hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]
            }], "tags": ["device", "wrt-d"]
    },
    {
        "id": 7751, "title": "WRT-D Behaviour", "responses": [{
            "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
        },
            {"hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]},
            {
                "hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]
            },
            {"hint": "poor comms", "linkType": "none", "linkTo": null, "tags": ["comms"]},
            {
                "hint": "unassign", "linkType": "none", "linkTo": null, "tags": ["unassign"]
            }], "tags": ["device", "wrt-d", "screen-ok"]
    },
    {
        "id": 7250,
        "title": "PLG-E Which Lights?",
        "responses": [{"hint": "no power", "linkType": "none", "linkTo": null, "tags": ["no-lights"]},
            {
                "hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]
            },
            {"hint": "included", "linkType": "none", "linkTo": null, "tags": ["solid-red"]},
            {
                "hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]
            },
            {"hint": "re-include", "linkType": "none", "linkTo": null, "tags": ["flashing-red"]}],
        "tags": ["device", "plg-e"]
    },
    {
        "id": 7251,
        "title": "PLG-E behaviour?",
        "responses": [{"hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]},
            {
                "hint": "No dot", "linkType": "none", "linkTo": null, "tags": ["relearn"]
            },
            {
                "hint": "fix comms", "linkType": "none", "linkTo": null, "tags": ["comms"]
            },
            {"hint": "channel-assigned-too-many", "linkType": "none", "linkTo": null, "tags": ["unassign"]}],
        "tags": ["device", "plg-e", "solid-red"]
    },
    {
        "id": 7501,
        "title": "WRV-E Behaviour",
        "responses": [{"hint": "VHO", "linkType": "none", "linkTo": null, "tags": ["set-offset"]},
            {
                "hint": "remount", "linkType": "none", "linkTo": null, "tags": ["fitting"]
            },
            {"hint": "remount", "linkType": "none", "linkTo": null, "tags": ["fitting"]},
            {
                "hint": "reconfigure", "linkType": "none", "linkTo": null, "tags": ["configure"]
            },
            {"hint": "relearn", "linkType": "none", "linkTo": null, "tags": ["relearn"]}],
        "tags": ["device", "wrv-e", "screen-ok"]
    },
    {
        "id": 7500, "title": "WRV-E Screen?", "responses": [{
            "hint": "no power", "linkType": "none", "linkTo": null, "tags": ["flat-battery"]
        },
            {"hint": "Low Battery", "linkType": "none", "linkTo": null, "tags": ["low-battery"]},
            {
                "hint": "temperature", "linkType": "none", "linkTo": null, "tags": ["screen-ok"]
            },
            {
                "hint": "flashing antenna", "linkType": "none", "linkTo": null, "tags": ["include"]
            },
            {"hint": "Mount Mode", "linkType": "none", "linkTo": null, "tags": ["fitting"]},
            {
                "hint": "E1", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "E2", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]},
            {
                "hint": "E6", "linkType": "none", "linkTo": null, "tags": ["hardware-failure"]
            },
            {"hint": "E8", "linkType": "none", "linkTo": null, "tags": ["comms"]},
            {
                "hint": "E9", "linkType": "none", "linkTo": null, "tags": ["fitting", "screen-ok"]
            },
            {"hint": "antenna", "linkType": "none", "linkTo": null, "tags": ["comms"]}], "tags": ["device", "wrv-e"]
    },
    {"id": 0, "title": "No Question Found", "responses": [], "tags": []},

    {
        "id": 7900, "title": "Troubleshoot Unknown Device", "responses": [
            {"hint": "Option 1", "linkType": "none", "linkTo": null, "tags": ["include"]},
        ],
        "tags": ["device", "unknown"]
    }

];

export const solutions: Array<SolutionData> = [{
    
    "id": 2100, "title": "Zone Unexpected Devices", "tags": ["zone-issue"]
},
  {"id": 2101, "title": "Zone Incorrect Mappings", "tags": ["zone-issue"]
    },  
  {"id": 2102, "title": "Zone Installation Issue", "tags": ["zone-issue", "installation_issue"]
    },
    {"id": 7000, "title": "SCR Include", "tags": ["device", "include", "scr"]
},
    {"id": 7001, "title": "SCR Reinclude", "tags": ["device", "scr", "flashing-red"]},
    {
        "id": 7002, "title": "SCR Exclude", "tags": ["device", "remove", "exclude", "scr"]
    },

    {"id": 7003, "title": "SCR RDN", "tags": ["device", "remove", "remove-dead", "scr"]},
    {
        "id": 7005, "title": "SCR No Power", "tags": ["device", "no-lights", "scr"]
    },

    {"id": 7007, "title": "SCR Reconfigure", "tags": ["device", "configure", "scr", "solid-red"]},
    {
        "id": 7008, "title": "SCR Fix Comms", "tags": ["device", "comms", "solid-red", "scr"]
    },
    {"id": 7050, "title": "DCR Include", "tags": ["device", "include", "dcr"]},
    {
        "id": 7051, "title": "DCR Reinclude", "tags": ["device", "dcr", "flashing-red"]
    },
    {"id": 7052, "title": "DCR Exclude", "tags": ["device", "remove", "exclude", "dcr"]},
    {
        "id": 7053, "title": "DCR RDN", "tags": ["device", "remove", "remove-dead", "dcr"]
    },
    {"id": 7055, "title": "DCR No Power", "tags": ["device", "no-lights", "dcr"]},
    {
        "id": 7057, "title": "DCR Reconfigure", "tags": ["device", "dcr", "solid-amber", "configure"]
    },
    {"id": 7058, "title": "DCR Fix Comms", "tags": ["device", "dcr", "solid-amber", "comms"]},
    {
        "id": 7100, "title": "ESW-TTP Include", "tags": ["device", "include", "esw"]
    },
    {"id": 7101, "title": "ESW-TTP Reinclude", "tags": ["device", "esw", "flashing-red"]},

    /*
        {
            "id": 4000,
            "title": "Installation & Testing Guide",
            "tags": ["installation", "testing", "guide"]
        },
    */

    {"id": 7103, "title": "ESW-TTP RDN", "tags": ["device", "remove", "remove-dead", "esw"]},
    {
        "id": 7104, "title": "ESW-TTP Factory Reset", "tags": ["device", "factory-reset", "remove", "esw"]
    },
    {"id": 7105, "title": "ESW-TTP No Power", "tags": ["device", "no-lights", "esw"]},
    {
        "id": 7107, "title": "ESW-TTP Reconfigure", "tags": ["device", "esw", "solid-red", "configure"]
    },
    {"id": 7108, "title": "ESW-TTP Fix Comms", "tags": ["device", "esw", "solid-red", "comms"]},
    {
        "id": 7150, "title": "ESW+TTP Include", "tags": ["device", "include", "esw-ttp"]
    },
    {"id": 7151, "title": "ESW+TTP Reinclude", "tags": ["device", "esw-ttp", "flashing-red"]},
    {
        "id": 7152, "title": "ESW+TTP Exclude", "tags": ["device", "remove", "exclude", "esw-ttp"]
    },
    {"id": 7153, "title": "ESW+TTP RDN", "tags": ["device", "remove", "remove-dead", "esw-ttp"]},
    {
        "id": 7154, "title": "ESW+TTP Factory Reset", "tags": ["device", "factory-reset", "remove", "esw-ttp"]
    },
    {"id": 7155, "title": "ESW+TTP No Power", "tags": ["device", "no-lights", "esw-ttp"]},
    {
        "id": 7157,
        "title": "ESW+TTP Reconfigure",
        "tags": ["device", "esw-ttp", "solid-red", "temperature", "configure"]
    },
    {
        "id": 7158, "title": "ESW+TTP Fix Comms", "tags": ["device", "esw-ttp", "solid-red", "temperature", "comms"]
    },
    {"id": 7200, "title": "PLG-C Include", "tags": ["device", "include", "plg-c"]},
    {
        "id": 7201, "title": "PLG-C Reinclude", "tags": ["device", "plg-c", "flashing-red"]
    },
    {"id": 7202, "title": "PLG-C Exclude", "tags": ["device", "remove", "exclude", "plg-c"]},
    {
        "id": 7203, "title": "PLG-C RDN", "tags": ["device", "remove", "remove-dead", "plg-c"]
    },
    {"id": 7204, "title": "PLG-C Factory Reset", "tags": ["device", "factory-reset", "remove", "plg-c"]},
    {
        "id": 7205, "title": "PLG-C No Power", "tags": ["device", "no-lights", "plg-c"]
    },
    {"id": 7207, "title": "PLG-C Reconfigure", "tags": ["device", "plg-c", "solid-red", "configure"]},
    {
        "id": 7208, "title": "PLG-C Fix Comms", "tags": ["device", "plg-c", "solid-red", "comms"]
    },
    {"id": 7250, "title": "PLG-E Include", "tags": ["device", "include", "plg-e"]},
    {
        "id": 7251, "title": "PLG-E Reinclude", "tags": ["device", "plg-e", "flashing-red"]
    },
    {"id": 7252, "title": "PLG-E Exclude", "tags": ["device", "remove", "exclude", "plg-e"]},
    {
        "id": 7253, "title": "PLG-E RDN", "tags": ["device", "remove", "remove-dead", "plg-e"]
    },
    {"id": 7254, "title": "PLG-E Factory Reset", "tags": ["device", "factory-reset", "remove", "plg-e"]},
    {
        "id": 7255, "title": "PLG-E No Power", "tags": ["device", "no-lights", "plg-e"]
    },
    {"id": 7257, "title": "PLG-E Reconfigure", "tags": ["device", "plg-e", "solid-red", "configure"]},
    {
        "id": 7258, "title": "PLG-E Fix Comms", "tags": ["device", "plg-e", "solid-red", "comms"]
    },
    {"id": 7300, "title": "IWM Include", "tags": ["device", "include", "iwm"]},
    {
        "id": 7301, "title": "IWM Reinclude", "tags": ["device", "iwm", "flashing-red", "fuse-power"]
    },
    {"id": 7302, "title": "IWM Exclude", "tags": ["device", "remove", "exclude", "iwm"]},
    {
        "id": 7303, "title": "IWM RDN", "tags": ["device", "remove", "remove-dead", "iwm"]
    },
    {"id": 7304, "title": "IWM Factory Reset", "tags": ["device", "factory-reset", "remove", "iwm"]},
    {
        "id": 7305, "title": "IWM No Power", "tags": ["device", "no-light", "iwm"]
    },
    {
        "id": 7307, "title": "IWM Reconfigure", "tags": ["device", "iwm", "configure", "fuse-power", "solid-red"]
    },
    {"id": 7308, "title": "IWM Fix Comms", "tags": ["iwm", "device", "fuse-power", "solid-red", "comms"]},
    {
        "id": 7350, "title": "ERS Include", "tags": ["device", "include", "ers"]
    },
    {"id": 7351, "title": "ERS Reinclude", "tags": ["device", "ers", "fuse-power", "flashing-red"]},

    {
        "id": 7352, "title": "ERS Exclude", "tags": ["device", "remove", "exclude", "ers"]
    },
    {"id": 7353, "title": "ERS RDN", "tags": ["device", "remove", "remove-dead", "ers"]},
    {
        "id": 7354, "title": "ERS Factory Reset", "tags": ["device", "factory-reset", "remove", "ers"]
    },
    {"id": 7355, "title": "ERS No Power", "tags": ["device", "no-light", "ers"]},

    {
        "id": 7357, "title": "ERS Reconfigure", "tags": ["device", "ers", "fuse-power", "solid-red", "configure"]
    },
    {"id": 7358, "title": "ERS Fix Comms", "tags": ["device", "ers", "fuse-power", "solid-red", "comms"]},
    {
        "id": 7400, "title": "PRT Include", "tags": ["device", "include", "prt"]
    },
    {"id": 7401, "title": "PRT Reinclude", "tags": ["device", "reinclude", "prt"]},
    {
        "id": 7402, "title": "PRT Exclude", "tags": ["device", "remove", "exclude", "prt"]
    },
    {"id": 7403, "title": "PRT RDN", "tags": ["device", "remove", "remove-dead", "prt"]},
    {
        "id": 7404, "title": "PRT Factory Reset", "tags": ["device", "factory-reset", "remove", "prt"]
    },
    {"id": 7405, "title": "PRT No Power", "tags": ["device", "no-power", "prt"]},
    {
        "id": 7407, "title": "PRT Reconfigure", "tags": ["device", "prt", "screen-ok", "configure"]
    },
    {"id": 7408, "title": "PRT Fix Comms", "tags": ["device", "prt", "screen-ok", "comms"]},
    {
        "id": 7409, "title": "PRT Hardware Failure", "tags": ["device", "hardware-failure", "prt"]
    },
    {"id": 7450, "title": "WRV-C Include", "tags": ["device", "include", "wrv-c"]},
    {
        "id": 7451, "title": "WRV-C Reinclude", "tags": ["device", "wrv-c", "flashing-screen"]
    },
    {"id": 7452, "title": "WRV-C Exclude", "tags": ["device", "remove", "exclude", "wrv-c"]},
    {
        "id": 7453, "title": "WRV-C RDN", "tags": ["device", "remove", "remove-dead", "wrv-c"]
    },
    {"id": 7454, "title": "WRV-C Factory Reset", "tags": ["device", "factory-reset", "remove", "wrv-c"]},
    {
        "id": 7455, "title": "WRV-C Flat Battery", "tags": ["device", "flat-battery", "wrv-c"]
    },
    {"id": 7456, "title": "WRV-C Low Battery", "tags": ["device", "low-battery", "wrv-c"]},
    {
        "id": 7457, "title": "WRV-C Reconfigure", "tags": ["device", "configure", "wrv-c", "screen-ok"]
    },
    {"id": 7458, "title": "WRV-C Fix Comms", "tags": ["device", "comms", "wrv-c"]},
    {
        "id": 7459, "title": "WRV-C Hardware Failure", "tags": ["device", "hardware-failure", "wrv-c"]
    },
    {"id": 7500, "title": "WRV-E Include", "tags": ["device", "wrv-e", "include"]},

    {"id": 7502, "title": "WRV-E Exclude", "tags": ["device", "remove", "exclude", "wrv-e"]},
    {
        "id": 7503, "title": "WRV-E RDN", "tags": ["device", "remove", "remove-dead", "wrv-e"]
    },
    {"id": 7504, "title": "WRV-E Factory Reset", "tags": ["device", "factory-reset", "remove", "wrv-e"]},
    {
        "id": 7505, "title": "WRV-E Flat Battery", "tags": ["device", "flat-battery", "wrv-e"]
    },
    {"id": 7506, "title": "WRV-E Low Battery", "tags": ["device", "low-battery", "wrv-e"]},
    {
        "id": 7507, "title": "WRV-E Reconfigure", "tags": ["device", "wrv-e", "screen-ok", "configure"]
    },
    {"id": 7508, "title": "WRV-E Fix Comms", "tags": ["device", "wrv-e", "comms"]},
    {
        "id": 7509, "title": "WRV-E Hardware Failure", "tags": ["device", "hardware-failure", "wrv-e"]
    },
    {"id": 7550, "title": "WRS Include", "tags": ["device", "include", "wrs"]},
    {
        "id": 7551, "title": "WRS Reinclude", "tags": ["device", "wrs", "red-flash-coverless", "cover-ok"]
    },
    {"id": 7552, "title": "WRS Exclude", "tags": ["device", "remove", "exclude", "wrs"]},
    {
        "id": 7553, "title": "WRS RDN", "tags": ["device", "remove", "remove-dead", "wrs"]
    },
    {"id": 7554, "title": "WRS Factory Reset", "tags": ["device", "factory-reset", "remove", "wrs"]},
    {
        "id": 7555,
        "title": "WRS Flat Battery",
        "tags": ["device", "wrs", "flat-battery", "device", "wrs", "cover-ok", "coverless-ok"]
    },
    {"id": 7556, "title": "WRS Low Battery", "tags": ["device", "low-battery", "wrs"]},
    {
        "id": 7557,
        "title": "WRS Reconfigure",
        "tags": ["device", "wrs", "cover-ok", "coverless-ok", "red-flash-battery", "configure"]
    },
    {
        "id": 7558,
        "title": "WRS Fix Comms",
        "tags": ["device", "wrs", "cover-ok", "coverless-ok", "red-flash-battery", "comms"]
    },
    {"id": 7600, "title": "WMS Include", "tags": ["device", "include", "wms"]},
    {
        "id": 7601, "title": "WMS Reinclude", "tags": ["device", "wms", "cover-ok", "red-flash-coverless"]
    },
    {"id": 7602, "title": "WMS Exclude", "tags": ["device", "wms", "remove", "exclude"]},
    {
        "id": 7603, "title": "WMS RDN", "tags": ["device", "remove", "remove-dead", "wms"]
    },
    {
        "id": 7605, "title": "WMS Flat Battery", "tags": ["device", "wms", "cover-ok", "coverless-ok", "flat-battery"]
    },
    {"id": 7606, "title": "WMS Low Battery", "tags": ["device", "low-battery", "wms"]},
    {
        "id": 7607,
        "title": "WMS Reconfigure",
        "tags": ["device", "wms", "cover-ok", "coverless-ok", "red-flash-battery", "configure"]
    },
    {
        "id": 7608,
        "title": "WMS Fix Comms",
        "tags": ["device", "wms", "cover-ok", "coverless-ok", "red-flash-battery", "comms"]
    },
    {"id": 7650, "title": "WRT-B Include", "tags": ["device", "include", "wrt-b"]},
    {
        "id": 7651, "title": "WRT-B Reinclude", "tags": ["device", "wrt-b", "antenna"]
    },
    {"id": 7652, "title": "WRT-B Exclude", "tags": ["device", "remove", "exclude", "wrt-b"]},
    {
        "id": 7653, "title": "WRT-B RDN", "tags": ["device", "remove", "remove-dead", "wrt-b"]
    },
    {"id": 7654, "title": "WRT-B Factory Reset", "tags": ["device", "factory-reset", "remove", "wrt-b"]},
    {
        "id": 7655, "title": "WRT-B Flat Battery", "tags": ["device", "flat-battery", "wrt-b"]
    },
    {"id": 7656, "title": "WRT-B Low Battery", "tags": ["device", "low-battery", "wrt-b"]},
    {
        "id": 7657, "title": "WRT-B Reconfigure", "tags": ["device", "wrt-b", "screen-ok", "configure"]
    },
    {"id": 7658, "title": "WRT-B Fix Comms", "tags": ["device", "wrt-b", "comms"]},
    {
        "id": 7659, "title": "WRT-B Hardware Failure", "tags": ["device", "hardware-failure", "wrt-b"]
    },
    {"id": 7700, "title": "WRT-C Include", "tags": ["device", "include", "wrt-c"]},
    {
        "id": 7701, "title": "WRT-C Reinclude", "tags": ["device", "wrt-c", "screen-ok", "flashing-green"]
    },
    {"id": 7702, "title": "WRT-C Exclude", "tags": ["device", "remove", "exclude", "wrt-c"]},
    {
        "id": 7703, "title": "WRT-C RDN", "tags": ["device", "remove", "remove-dead", "wrt-c"]
    },
    {"id": 7704, "title": "WRT-C Factory Reset", "tags": ["device", "factory-reset", "remove", "wrt-c"]},
    {
        "id": 7705, "title": "WRT-C Flat Battery", "tags": ["device", "flat-battery", "wrt-c"]
    },
    {"id": 7706, "title": "WRT-C Low Battery", "tags": ["device", "low-battery", "wrt-c"]},
    {
        "id": 7707, "title": "WRT-C Reconfigure", "tags": ["device", "wrt-c", "no-light", "screen-ok", "configure"]
    },
    {"id": 7708, "title": "WRT-C Fix Comms", "tags": ["device", "wrt-c", "screen-ok", "no-light", "comms"]},
    {
        "id": 7709, "title": "WRT-C Hardware Failure", "tags": ["device", "hardware-failure", "wrt-c"]
    },
    {"id": 7750, "title": "WRT-D Include", "tags": ["device", "include", "wrt-d"]},
    {
        "id": 7751, "title": "WRT-D Reinclude", "tags": ["device", "wrt-d", "reinclude"]
    },
    {"id": 7752, "title": "WRT-D Exclude", "tags": ["device", "remove", "exclude", "wrt-d"]},
    {
        "id": 7753, "title": "WRT-D RDN", "tags": ["device", "remove", "remove-dead", "wrt-d"]
    },
    {"id": 7754, "title": "WRT-D Factory Reset", "tags": ["device", "factory-reset", "remove", "wrt-d"]},
    {
        "id": 7755, "title": "WRT-D Flat Battery", "tags": ["device", "wrt-d", "flat-battery"]
    },
    {"id": 7756, "title": "WRT-D Low Battery", "tags": ["device", "wrt-d", "low-battery"]},
    {
        "id": 7757, "title": "WRT-D Reconfigure", "tags": ["device", "wrt-d", "screen-ok", "configure"]
    },
    {"id": 7758, "title": "WRT-D Fix Comms", "tags": ["device", "wrt-d", "screen-ok", "comms"]},
    {
        "id": 7463, "title": "WRV-C Remounting", "tags": ["device", "fitting", "wrv-c", "screen-ok"]
    },
    {"id": 7461, "title": "WRV-C VHO", "tags": ["device", "set-offset", "wrv-c", "screen-ok"]},
    {
        "id": 7462, "title": "WRV-C Battery Leak", "tags": ["device", "battery-leak", "wrv-c"]
    },

    {"id": 1001, "title": "HUB Device No Internet", "tags": ["network"]},
    {"id": 1003, "title": "HUB-B No Server", "tags": ["hub", "hub-b", "tunnel"]},
    {"id": 1004, "title": "HUB-C No Server", "tags": ["hub", "hub-c", "tunnel"]},
    {"id": 1005, "title": "HUB Credentials Incorrect", "tags": ["credentials"]},
    {"id": 1006, "title": "HUB Credentials Mismatch", "tags": ["credentials_mismatch"]},
    {"id": 1007, "title": "HUB-B Crashed", "tags": ["hub", "hub-b", "crashed"]},
    {"id": 1008, "title": "HUB-C Crashed", "tags": ["hub", "hub-c", "crashed"]},

    {"id": 7060, "title": "DCR Unassign Duplicate", "tags": ["device", "dcr", "solid-amber", "unassign"]},
    {
        "id": 7010, "title": "SCR Unassign Duplicate", "tags": ["device", "scr", "solid-red", "unassign"]
    },
    {"id": 7102, "title": "ESW-TTP Exclude", "tags": ["device", "remove", "exclude", "esw"]},
    {
        "id": 9999, "title": "TEST Solution", "tags": []
    },
    {"id": 2000, "title": "Zone No Temperature", "tags": ["zone", "no-temperature"]},
    {
        "id": 1010, "title": "HUB-C Credentials Mismatch", "tags": ["hub", "hub-c", "password-mismatch"]
    },
    {"id": 1009, "title": "HUB-B Credentials Mismatch", "tags": ["hub", "hub-b", "password-mismatch"]},
    {
        "id": 1500, "title": "OpenTherm No Module", "tags": ["opentherm", "no-module"]
    },
    {"id": 1501, "title": "OpenTherm Comms", "tags": ["opentherm", "comms"]},

    {"id": 1011, "title": "No Weather Data", "tags": ["hub", "no-weather"]},

    { "id": 1012, "title": "Software Update Available", "tags": ["hub", "update"] },

    { "id": 1013, "title": "Create New Zone", "tags": ["create-zone"] },

    {"id": 1014, "title": "Hub Not Registered", "tags": ["hub", "unregistered"]},

    { "id": 1101, "title": "Full SD Card", "tags": [] },
    { "id": 1102, "title": "Corrupt SD Card", "tags": [] },
    { "id": 1103, "title": "DHCP Lease Time", "tags": [] },
    { "id": 1104, "title": "Z-Wave Overflow", "tags": ["hub", "overflow"] },
    { "id": 1105, "title": "ZigBee Missing", "tags": ["hub", "no-zigbee"] },

    {
        "id": 0, "title": "No Solution Found", "tags": ["no-solution"]
    },
    {"id": 7900, "title": "Generic Z-Wave Device Include", "tags": ["device", "generic", "include"]},
    {
        "id": 7901, "title": "Generic Z-Wave Device Exclude", "tags": ["device", "generic", "exclude"]
    },
    {"id": 7902, "title": "Generic Z-Wave Device Fix Comms", "tags": ["device", "generic", "comms"]},
    {
        "id": 7903, "title": "Generic Z-Wave Device Remove Dead Node", "tags": ["device", "generic", "remove-dead"]
    },
    {"id": 7910, "title": "Unknown Z-Wave Device Relearn", "tags": ["device", "unknown", "relearn"]},
    {
        "id": 7911, "title": "Unknown Z-Wave Device Fix Comms", "tags": ["device", "unknown", "comms"]
    },
    {
        "id": 7161, "title": "ESW+TTP Missing Temperature", "tags": ["device", "esw-ttp", "solid-red", "no-temperature"]
    },
    {"id": 7110, "title": "ESW-TTP Unassign Duplicate", "tags": ["device", "esw", "solid-red", "unassign"]},
    {
        "id": 7160,
        "title": "ESW+TTP Unassign Duplicate",
        "tags": ["device", "esw-ttp", "solid-red", "temperature", "unassign"]
    },
    {"id": 7210, "title": "PLG-C Unassign Duplicate", "tags": ["device", "plg-c", "solid-red", "unassign"]},
    {
        "id": 7260, "title": "PLG-E Unassign Duplicate", "tags": ["device", "plg-e", "solid-red", "unassign"]
    },
    {
        "id": 7310, "title": "IWM Unassign Duplicate", "tags": ["device", "iwm", "fuse-power", "solid-red", "unassign"]
    },
    {
        "id": 7360, "title": "ERS Unassign Duplicate", "tags": ["device", "ers", "fuse-power", "solid-red", "unassign"]
    },
    {"id": 7410, "title": "PRT Unassign Duplicate", "tags": ["device", "prt", "screen-ok", "unassign"]},
    {
        "id": 7460, "title": "WRV-C Unassign Duplicate", "tags": ["device", "wrv-c", "screen-ok", "unassign"]
    },
    {"id": 7510, "title": "WRV-E Unassign Duplicate", "tags": ["device", "wrv-e", "screen-ok", "unassign"]},
    {
        "id": 7560,
        "title": "WRS Unassign Duplicate",
        "tags": ["device", "wrs", "cover-ok", "coverless-ok", "red-flash-battery", "unassign"]
    },
    {
        "id": 7610,
        "title": "WMS Unassign Duplicate",
        "tags": ["device", "wms", "cover-ok", "coverless-ok", "red-flash-battery", "unassign"]
    },
    {"id": 7660, "title": "WRT-B Unassign Duplicate", "tags": ["device", "wrt-b", "screen-ok", "unassign"]},
    {
        "id": 7710,
        "title": "WRT-C Unassign Duplicate",
        "tags": ["device", "wrt-c", "screen-ok", "no-light", "unassign"]
    },
    {"id": 7760, "title": "WRT-D Unassign Duplicate", "tags": ["device", "wrt-d", "screen-ok", "unassign"]},
    {
        "id": 7511, "title": "WRV-E VHO", "tags": ["device", "set-offset", "wrv-e", "screen-ok"]
    },
    {"id": 7512, "title": "WRV-E Battery Leak", "tags": ["device", "battery-leak", "wrv-e"]},
    {
        "id": 7513, "title": "WRV-E Remounting", "tags": ["device", "fitting", "wrv-e", "screen-ok"]
    },
    {
        "id": 7912, "title": "Unknown Z-Wave Device RDN", "tags": ["device", "remove", "unknown", "remove-dead"]
    },
    {
        "id": 7913, "title": "Unknown Z-Wave Device Unassign", "tags": ["device", "unknown", "unassign"]
    },
    { "id": 7011, "title": "SCR Relearn", "tags": ["device", "relearn", "solid-red", "scr"] },
    {
        "id": 7061, "title": "DCR Relearn", "tags": ["device", "relearn", "solid-amber", "dcr"]
    },
    {"id": 7111, "title": "ESW-TTP Relearn", "tags": ["device", "relearn", "solid-red", "esw"]},
    {
        "id": 7162, "title": "ESW+TTP Relearn", "tags": ["device", "relearn", "solid-red", "esw-ttp"]
    },
    {"id": 7211, "title": "PLG-C Relearn", "tags": ["device", "relearn", "solid-red", "plg-c"]},
    {
        "id": 7261, "title": "PLG-E Relearn", "tags": ["device", "relearn", "solid-red", "plg-e"]
    },
    {"id": 7311, "title": "IWM Relearn", "tags": ["device", "iwm", "fuse-power", "solid-red", "relearn"]},
    {
        "id": 7361, "title": "ERS Relearn", "tags": ["device", "ers", "fuse-power", "solid-red", "relearn"]
    },
    {"id": 7411, "title": "PRT Relearn", "tags": ["device", "prt", "screen-ok", "relearn"]},
    {
        "id": 7412, "title": "PRT Er4", "tags": ["device", "prt", "floor-sensor"]
    },
    {"id": 7413, "title": "PRT Er5", "tags": ["device", "prt", "external-sensor"]},
    {
        "id": 7414, "title": "PRT Screen Lock", "tags": ["device", "prt", "screen-lock"]
    },
    {
        "id": 7415, "title": "PRT Screen Brightness", "tags": ["device", "prt", "screen-ok", "screen-brightness"]
    },
    {"id": 7416, "title": "PRT Set Offset", "tags": ["device", "prt", "screen-ok", "set-offset"]},
    {
        "id": 7464, "title": "WRV-C Relearn", "tags": ["device", "wrv-c", "screen-ok", "relearn"]
    },
    {"id": 7514, "title": "WRV-E Relearn", "tags": ["device", "wrv-e", "screen-ok", "relearn"]},
    {
        "id": 7561,
        "title": "WRS Relearn",
        "tags": ["device", "wrs", "cover-ok", "coverless-ok", "red-flash-battery", "relearn"]
    },
    {
        "id": 7611,
        "title": "WMS Relearn",
        "tags": ["device", "wms", "cover-ok", "coverless-ok", "red-flash-battery", "relearn"]
    },
    {"id": 7661, "title": "WRT-B Relearn", "tags": ["device", "wrt-b", "screen-ok", "relearn"]},
    {
        "id": 7711, "title": "WRT-C Relearn", "tags": ["device", "wrt-c", "screen-ok", "no-light", "relearn"]
    },
    { "id": 7761, "title": "WRT-D Relearn", "tags": ["device", "wrt-d", "screen-ok", "relearn"] },
    {
        "id": 7762, "title": "WRT-D Screen Lock", "tags": ["device", "wrt-d", "screen-lock"]
    },
    {
        "id": 7004, "title": "SCR Factory Reset", "tags": ["device", "factory-reset", "remove", "scr"]
    },
    {"id": 7054, "title": "DCR Factory Reset", "tags": ["device", "factory-reset", "remove", "dcr"]},
    {
        "id": 1000, "title": "HUB No problems found", "tags": ["hub", "hub-is-ok"]
    },
    {"id": 1, "title": "Device No problems found", "tags": ["device", "success"]},
    {
        "id": 5001, "title": "Installation Manual", "tags": ["docs", "install_manual"]
    },
    {"id": 5002, "title": "User Guide", "tags": ["docs", "user_guide"]},
    {
        "id": 5003, "title": "Technical Documents", "tags": ["docs", "technical_documents"]
    },
    {"id": 5004, "title": "Frequently Asked Questions", "tags": ["docs", "faq"]},
    {
        "id": 5005, "title": "Videos", "tags": ["docs", "youtube"]
    },
    {"id": 5006, "title": "Knowledge Base", "tags": ["docs", "confluence"]},

    {"id": 2001, "title": "Zone TPI Configuration", "tags": ["zone", "config", "tpi"]},


    {"id": 9901, "title": "Z-Wave Device Health", "tags": ["device", "health"]},

]

export const searchData = {};
    