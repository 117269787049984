import {
    HubCacheData, Issue,
    IssueLevel,
    Issues,
    ZoneWholeHouse, ZWaveData
} from 'genius-hub-types'
import {registerFormatter} from "./issueFormatter";


registerFormatter('hub:site_mapping', issue => `Hub has 'site' mapped to zone ${issue.data.zoneID}. This will cause a bug with Hub firmware version >= 5.4.0`);
registerFormatter('zwave:mapped_nodes_missing', issue => `The following devices are mapped to zones, but the device IDs do not exist: [${issue.data.missingNodes.join(', ')}]`);

function detectSiteMappingIssue(zone: ZoneWholeHouse): Issues {
    const result: Issues = [];

    Object.keys(zone.mappings).forEach((_zoneID: string) => {
        const zoneID = parseInt(_zoneID);
        const zoneMappings = zone.mappings[zoneID];

        zoneMappings.forEach((devicePath: string) => {
            if (devicePath === 'site') {
                result.push(new Issue('hub:site_mapping',
                    IssueLevel.ERROR,
                    {
                        zoneID: zoneID,
                    }
                ));
            }
        })
    });

    return result;
}

function detectMappedDevicesMissingZwaveNodes(zone: ZoneWholeHouse, zwave: ZWaveData): Issues {
    const result: Issues = [];
    const nodeIDs: Array<number> = [];
    const reDevicePath = /site\/0x[A-Fa-f0-9]{8}\/([0-9]{1,2})/;

    for (const [_zoneID, lstMappings] of Object.entries(zone.mappings)) {
        const zoneID = parseInt(_zoneID);

        lstMappings.forEach((devicePath: string) => {
            const matches = devicePath.match(reDevicePath);
            if (matches && matches.length > 1) {
                nodeIDs.push(parseInt(matches[1]));
            }
        });
    }

    // check if the nodeID exists on the zwave side, if not report an issue
    const zwaveNodes: Array<number> = zwave.nodes.map((node) => node.id);

    const missingNodes: Array<number> = [];
    nodeIDs.forEach((nodeID: number) => {
        if (!zwaveNodes.includes(nodeID)) {
            if (!missingNodes.includes(nodeID)) {
                missingNodes.push(nodeID)
            }
        }
    })

    if (missingNodes.length) {
        missingNodes.sort();
        result.push(new Issue(
            'zwave:mapped_nodes_missing',
            IssueLevel.ERROR,
            {missingNodes}
        ));
    }

    return result;
}


export function detectHubDeviceMappingIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];

    if (hubData?.zones && hubData.zones[0]) {
        issues = issues.concat(
            detectSiteMappingIssue(hubData.zones[0] as ZoneWholeHouse)
        );
    }
    if (hubData?.zones && hubData.zones[0] && hubData.zwave) {
        issues = issues.concat(
            detectMappedDevicesMissingZwaveNodes(hubData.zones[0] as ZoneWholeHouse, hubData.zwave)
        );
    }

    return issues;
}
