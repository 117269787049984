import {
    createDefaultZigbeeData,
    HubCacheData,
    HubConfig,
    HubUpgradeStatus,
    WifiSettingsStatus,
    ZWaveInclusionState
} from 'genius-hub-types'
import {HubAPI, HubAuth, HubConnectionManager, HubCordova, HubREST} from 'genius-hub-proxy';
import {CachedHub, HubsCollection, HubState, PollHub, SingleHubAuthState} from './hubTypes';
import {getStatusDescription} from './hubs';

declare global {
    interface CordovaPlugins {
        http: any;
    }
}


const hubConnectionManagers: { [hubName: string] : HubConnectionManager } = {};
// const cachedHubs: { [keyName: string]: CachedHub } = {};


export function defaultHubConfig(): HubConfig {
    return {
        defaultSetbackTemp: 14
    }
}

export function defaultHubCachedData(): HubCacheData {
    return {
        stats: undefined,
        auth: {
            tokens: []
        },
        hubTimestamp: 0,
        hubTimestring: "",
        credentials: {username: '', signature: '', token: null, refreshToken: null},
        version: {
            build_time: '',
            commit: '',
            release: '',
            UID: ''
        },
        upgradeState: {
            log: [],
            status: HubUpgradeStatus.None
        },
        config: defaultHubConfig(),
        devices: null,
        zones: null,
        zwave: null,
        zwaveInclusionStatus: {
            inclusionState: ZWaveInclusionState.INCLUSION_IDLE,
            lastIncludedNodeID: 0
        },
        zigbee: createDefaultZigbeeData(),
        zwave_log: [],
        wifi: {
            status: WifiSettingsStatus.Idle,
            ssids: [],
            wifiSystemState: {
                "network-interface": {
                    "mac-address": "",
                    "ip-address": "",
                    "broadcast": "",
                    "netmask": ""
                },
                "wireless-device": {
                    "wireless-mode": "",
                    "ssid": "",
                    "channel": "",
                    "signal": "",
                    "encryption": ""
                },
                "station-mode-configuration": {
                    "ssid": "",
                    "key": "",
                    "encryption": "",
                    "country": ""
                },
                "station-network-status": {
                    "access-point-visible": "",
                    "connected-to-access-point": ""
                }
            }
        },
        process_monitor: undefined

    };
}

function selectRESTType() {
    let result;

    if (typeof cordova === 'undefined' || typeof cordova.plugin === 'undefined' || typeof cordova.plugin.http === 'undefined') {
        console.debug(`REST is using XHR`)
        result = HubREST;
    } else {
        console.debug(`REST is using CordovaHTTP`)
        result = HubCordova;
    }
    return result;
}

function createHubREST() {
    const restType = selectRESTType();
    let hubREST  = new restType({debug: false});
    return hubREST;
}

export const createHubObject = (): HubState => {

    const serverNumber = 3; //Math.random() > 0.5 ? 1 : 2;

    return {
        connectionStatus: SingleHubAuthState.LOGGED_OUT,
        servers: [
            // {serverAddress: `https://192.168.0.159`, latency: 0, enabled: true},
            {serverAddress: `https://hub-server-3.heatgenius.co.uk`, latency: 0, enabled: true},
            // {serverAddress: `https://hub-server-${serverNumber}.heatgenius.co.uk`, latency: 0, enabled: true},
            // {serverAddress: 'http://10.0.0.1111:1223', latency: 0, enabled: true},
            // {serverAddress: 'https://192.168.0.102:1223', latency: 0, enabled: true},
            // {serverAddress: 'https://192.168.0.82:1223', latency: 0, enabled: true},
            // {serverAddress: 'http://192.168.0.17:1223', latency: 0, enabled: true},
            // {serverAddress: 'http://10.0.1.10:1223', latency: 0, enabled: true},

        ],
        _connectionManagerData: {},
        connectionStatusDescription: getStatusDescription(SingleHubAuthState.LOGGED_OUT),
        pollState: PollHub.ZONES,
        lastUpdate: 0,
        data: defaultHubCachedData(),
        dataIsSnapshot: false
    };
}


export const getOrCreateActiveHub = (hubName: string, hubs: HubsCollection): HubState => {
    if (!hubs.hasOwnProperty(hubName)) {
        hubs[hubName] = createHubObject();

        hubConnectionManagers[hubName] = new HubConnectionManager(selectRESTType(), null);
    } else {
        if (!hubConnectionManagers.hasOwnProperty(hubName)) {
            hubConnectionManagers[hubName] = new HubConnectionManager(selectRESTType(), null);
        }
        hubConnectionManagers[hubName].load(hubs[hubName]._connectionManagerData);

    }

    return hubs[hubName];
}




/*export async function getBestHubServer(servers: Array<HubServer>) {
    servers.sort((a, b) => {
        if (a.latency < b.latency) return -1;
        if (a.latency > b.latency) return 1;
        return 0;
    });

    for (let i = 0; i < servers.length; i++) {
        if (servers[i].enabled) {
            return servers[i];
        }
    }
    throw new Error("No available server");
}*/

/*export async function getHubProxyByHub_OLD(hub: HubState) {
    const hubName = hub.data.credentials.username;
    if (cachedHubs.hasOwnProperty(hubName)) {
        // console.debug(`${hubName} cached`);
        return cachedHubs[hubName];
    } else {
        // console.debug(`${hubName} not cached - creating instance`);
        let hubREST;
        if (typeof cordova === 'undefined' || typeof cordova.plugin === 'undefined' || typeof cordova.plugin.http === 'undefined') {
            console.debug(`REST is using XHR`)
            hubREST = new HubREST({debug: false});
        } else {
            console.debug(`REST is using CordovaHTTP`)
            hubREST = new HubCordova({debug: false});
        }
        const hubAPI = new HubAPI(hubREST);
        const hubProxy: CachedHub = {hubAPI, hubREST};
        await hubREST.auth.setAuth({...hub.data.credentials});
        const server = await getBestHubServer(hub.servers);
        hubREST.setServer(server.serverAddress);
        cachedHubs[hubName] = hubProxy;
        return hubProxy;
    }
}*/

function hasConnectionManager(username: string): boolean {
    return hubConnectionManagers.hasOwnProperty(username);
}

export function getConnectionManager(username: string): HubConnectionManager|null {
    if (hubConnectionManagers.hasOwnProperty(username)) {
        // console.debug(`${hubName} cached`);
        return hubConnectionManagers[username];
    }
    else {
        return null;
    }
}

export async function createConnectionManager(hubAuth: HubAuth): Promise<HubConnectionManager> {
    const hubName = hubAuth.username;
    if (hubConnectionManagers.hasOwnProperty(hubName)) {
        const manager = hubConnectionManagers[hubName];
        manager.setAuth(hubAuth);
        return manager;
    } else {
        const manager = new HubConnectionManager(selectRESTType(), hubAuth);
        hubConnectionManagers[hubName] = manager;
        await manager.selectBestConnection();
        return manager;
    }
}



export async function getHubProxyByHub(hub: HubState) {

    if (hasConnectionManager(hub.data.credentials.username)) {
        const auth = new HubAuth();
        auth.setAuth({...hub.data.credentials});
        const manager = await createConnectionManager(auth);
        return await manager.getHubProxy();
    }
}



export async function getHubProxyByHubName(hubName: string, hubs: HubsCollection) {
    if (hasConnectionManager(hubName)) {
        const manager = getConnectionManager(hubName);
        if (manager)   {
            return await manager.getHubProxy()
        }
    } else if (hubs.hasOwnProperty(hubName)) {
        const hub = hubs[hubName];
        const auth = new HubAuth();
        auth.setAuth({...hub.data.credentials});
        const manager = await createConnectionManager(auth);
        return await manager.getHubProxy();
    }
    throw Error(`getHubProxyByHubName('${hubName}', ...) failed - named hub not found in state`);
}



export function getHubCachedCredentials(hubName: string, hubs: HubsCollection) {
    if (hubs.hasOwnProperty(hubName)) {
        const hub = hubs[hubName];
        return hub.data.credentials;
    }
    throw Error(`getHubProxyByHubName(${hubName}, ...) failed - named hub not found in state`);
}

export async function restoreConnectionManagerData(hubs: HubsCollection) {

    const iter = Object.entries(hubs);
    for (let i of iter) {
        const k = i[0], v = i[1];
        console.log(`Key: ${k} Val: ${v}`);
        let manager;
        if (!hasConnectionManager(k)) {
            const auth = new HubAuth();
            auth.setAuth({...v.data.credentials});
            manager = await createConnectionManager(auth);
        }
        else {
            manager = getConnectionManager(k);
        }
        manager.load(v._connectionManagerData);
    }

}

export async function extractConnectionManagerData(hubs: HubsCollection) {
    const managerSettings: { [hubName: string]: any } = {};

    const iter = Object.entries(hubs);
    for (let i of iter) {
        const k = i[0], v = i[1];
        const manager = getConnectionManager(k);
        if (manager) {
            // v._connectionManagerData = manager.save();
            managerSettings[k] = manager.save();
        }
    }
    return managerSettings;
}