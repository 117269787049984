import {HubCacheData, Issue, IssueLevel, Issues, ZWaveNode} from 'genius-hub-types'
import {getDeviceManfSpecData, getZWaveNodeDeviceTypeInfo} from "genius-hub-utils";
import {iterateZWaveNodes} from './issueTools';
import {registerFormatter} from "./issueFormatter";
import {DeviceZWaveConfigDefaults} from "./device";


registerFormatter('zwave:config:param0', issue => `Device ${issue.data.nodeID} configuration has parameter 0 set!`);
registerFormatter('zwave:config:prt', issue => `Powered Room Thermostat ${issue.data.nodeID} has an incorrect configuration`);
registerFormatter('zwave:device:null_info', issue => `Device ${issue.data.nodeID} needs to be rediscovered`);
registerFormatter('zwave:device_info_invalid', issue => `Device ${issue.data.nodeID} needs to be re-learned`);
registerFormatter('zwave:config_invalid', issue => `Device ${issue.data.nodeID} needs to be reconfigured`);
registerFormatter('zwave:device', issue => `Device "${issue.data.nodeID}" has null device info`);
registerFormatter('device:unknown_device_schema', issue => `Device ${issue.data.nodeID} is not supported by Genius Hub`);
registerFormatter('zwave:config:esw_bad_parameters', issue => `Electric Switch ${issue.data.nodeID} needs to be reconfigured`);


function detectConfig0Issues(node: ZWaveNode): Issues {
    const result = [];
    let isWRTB = false;

    if (node &&
        node.hasOwnProperty('commandClasses') &&
        node.commandClasses.hasOwnProperty('COMMAND_CLASS_CONFIGURATION') &&
        node.commandClasses.COMMAND_CLASS_CONFIGURATION.hasOwnProperty('0')) {

        if (node.commandClasses.hasOwnProperty('COMMAND_CLASS_MANUFACTURER_SPECIFIC')
            && node.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.hasOwnProperty('hash')
            && (node.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.hash === '0x0000005900030001')
        ) {
            isWRTB = true;
        }

        result.push(new Issue(
            'zwave:config:param0',
            IssueLevel.ERROR,
            {
                nodeID: node.id,
                message: `has config param 0 ${isWRTB ? '(HO-WRT-B)':''}`,
                // val: node.commandClasses.COMMAND_CLASS_CONFIGURATION
            })
        );
    }

    return result;
}

function detectConfigPRTIssues(node: ZWaveNode): Issues {
    const result = [];

    if (node &&
        node.hasOwnProperty('commandClasses') &&
        node.commandClasses.hasOwnProperty('COMMAND_CLASS_CONFIGURATION') &&
        node.commandClasses.COMMAND_CLASS_CONFIGURATION.hasOwnProperty('9')) {

        if (node.commandClasses.hasOwnProperty('COMMAND_CLASS_MANUFACTURER_SPECIFIC')
            && node.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.hasOwnProperty('hash')
            && (node.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.hash === '0x0000019B02030003')
        ) {
            result.push(new Issue(
                'zwave:config:prt',
                IssueLevel.ERROR,
                {
                    nodeID: node.id,
                    message: `PRT has config param 9`,
                })
            );
        }
    }

    return result;
}

function detectNullNodeInfoIssues(node: ZWaveNode): Issues {
    const result = [];

    if (node && node.hasOwnProperty('nodeInfo')) {
        const notNullNodeInfo = node.nodeInfo.basicDeviceClass ||
                                node.nodeInfo.genericDeviceClass ||
                                node.nodeInfo.specificDeviceClass ||
                                node.nodeInfo.protocolSpecificPart1 ||
                                node.nodeInfo.protocolSpecificPart2 ||
                                node.nodeInfo.protocolSpecificPart3;

        if (! notNullNodeInfo && node.id !== 1) {
            result.push(new Issue(
                'zwave:device:null_info',
                IssueLevel.ERROR,
                {
                    nodeID: node.id
                }
            ));
        }
    }

    return result;
}

export function detectZWaveDeviceHealth(node: ZWaveNode): Issues {
    const result: Issues = [];

    // skip the controller
    if (node.id === 1) {
        return result;
    }

    const def = getZWaveNodeDeviceTypeInfo(node);
    if (!def) {
        result.push(new Issue('device:unknown', IssueLevel.WARNING, { nodeID: node.id }));
    } else {
        const schema = DeviceZWaveConfigDefaults?.[def.sku];
        if (schema) {
            const validationResult = schema.validate(node, {abortEarly: false});
            if (validationResult?.error) {
                if (!validationResult.error?.details) {
                    console.error("Malformed schema validation error", result);
                } else {
                    for (const err of validationResult.error.details) {
                        const value = err.context?.value;
                        const message = `${err.message} ${value !== undefined ? ' not [' + value + ']' : ''}`;

                        // todo: which errors require config / relearn
                        if ([
                            'object.unknown',   // extra valuves
                            'any.required'      // missing values
                        ].includes(err.type) ||
                            (err.path[0] === 'nodeInfo')
                        ) {
                            result.push(new Issue('zwave:device_info_invalid', IssueLevel.ERROR, {
                                message,
                                nodeID: node.id,
                                errorPath: err.path,
                                type: err.type
                            }));
                        } else {
                            result.push(new Issue('zwave:config_invalid', IssueLevel.ERROR, {
                                message,
                                nodeID: node.id,
                                errorPath: err.path,
                                type: err.type
                            }));
                        }
                    }
                }
            }
        }
        else {
            result.push(new Issue('device:unknown_device_schema', IssueLevel.WARNING, {
                def,
                nodeID: node.id
            }))
        }
    }

    return result;
}

function detectESWConfigIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];


    // iterate through zwave devices
    hubData.zwave.nodes.forEach((node) => {
        const manfSpecificData = getDeviceManfSpecData(node);
        const deviceIsESW_C = (manfSpecificData !== null) && manfSpecificData.manfID === 0x59 &&
            manfSpecificData.productType === 0x10 &&
            manfSpecificData.productID === 0x01;

        const deviceIsESW_E = (manfSpecificData !== null) && manfSpecificData.manfID === 0x59 &&
            manfSpecificData.productType === 0x10 &&
            manfSpecificData.productID === 0x03;


        if ( deviceIsESW_C || deviceIsESW_E ) {
            const hasConfigValue2 = node.commandClasses?.COMMAND_CLASS_CONFIGURATION.hasOwnProperty(2);
            const hasConfigValue3 = node.commandClasses?.COMMAND_CLASS_CONFIGURATION.hasOwnProperty(3);
            const hasConfigValue4 = node.commandClasses?.COMMAND_CLASS_CONFIGURATION.hasOwnProperty(4);
            const hasConfigValue5 = node.commandClasses?.COMMAND_CLASS_CONFIGURATION.hasOwnProperty(5);

            if (hasConfigValue2 || hasConfigValue3 || hasConfigValue4 || hasConfigValue5) {
                issues.push(new Issue(
                    'zwave:config:esw_bad_parameters',
                    IssueLevel.ERROR,
                    {
                        nodeID: node.id,
                    })
                );
            }
        }
    })

    return issues;
}


export function detectZWaveIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];

    issues = issues.concat(detectESWConfigIssues(hubData),
        iterateZWaveNodes(hubData, (node: ZWaveNode) => {

        return [
            ...detectConfig0Issues(node),
            ...detectNullNodeInfoIssues(node),
            ...detectConfigPRTIssues(node),
            ...detectZWaveDeviceHealth(node)
            ];
    }));

    return issues;
}
