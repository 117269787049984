"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Issue = exports.IssueLevel = void 0;
var IssueLevel;
(function (IssueLevel) {
    IssueLevel[IssueLevel["INFO"] = 0] = "INFO";
    IssueLevel[IssueLevel["WARNING"] = 1] = "WARNING";
    IssueLevel[IssueLevel["ERROR"] = 2] = "ERROR";
    IssueLevel[IssueLevel["CRITICAL"] = 3] = "CRITICAL";
})(IssueLevel || (exports.IssueLevel = IssueLevel = {}));
var Issue = /** @class */ (function () {
    function Issue(idOrObj, level, data) {
        var _a, _b, _c;
        if (typeof idOrObj === 'object') {
            this.id = (_a = idOrObj.id) !== null && _a !== void 0 ? _a : '';
            this.level = (_b = idOrObj.level) !== null && _b !== void 0 ? _b : IssueLevel.INFO;
            this.data = (_c = idOrObj.data) !== null && _c !== void 0 ? _c : {};
        }
        else {
            this.id = idOrObj;
            this.level = level || IssueLevel.WARNING;
            this.data = data;
        }
        this._formatter = this.defaultFormatter;
    }
    Issue.prototype.defaultFormatter = function () {
        return "".concat(IssueLevel[this.level], " ").concat(this.id, " - ").concat(JSON.stringify(this.data));
    };
    Issue.prototype.format = function () {
        return this._formatter();
    };
    Issue.prototype.toJSON = function () {
        return {
            id: this.id,
            level: this.level,
            data: this.data
        };
    };
    Issue.prototype.fromJSON = function (obj) {
        var _obj;
        if (typeof obj === 'string') {
            _obj = JSON.parse(obj);
        }
        else {
            _obj = obj;
        }
        this.id = _obj.id;
        this.level = _obj.level;
        this.data = _obj.data;
    };
    return Issue;
}());
exports.Issue = Issue;
