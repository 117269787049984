import {
    createSchemaZWaveDeviceListening,
    DeviceZWaveConfigDefaults,
    createSchemaZWaveCommandClassAssociation,
    createSchemaZWaveCommandClassBasic,
    createSchemaZWaveCommandClassConfiguration,
    createSchemaZWaveCommandClassManufacturerSpecific,
    createSchemaZWaveCommandClassNOP,
    createSchemaZWaveCommandClassPowerLevel,
    createSchemaZWaveCommandClassProtection,
    createSchemaZWaveCommandClassSwitchAll,
    createSchemaZWaveCommandClassSwitchBinary,
    createSchemaZWaveCommandClassThermostatMode,
    createSchemaZWaveCommandClassVersion,
    createSchemaZWaveCommandClassIndicator,
    createSchemaZWaveCommandClassSchedule,
    createSchemaZWaveCommandClassMultilevel,
    createZWaveConfigValueSchema,
    createSchemaZWaveDeviceNonListening,
    createSchemaZWaveCommandClassBattery,
    createSchemaZWaveCommandClassWakeup,
    createSchemaZWaveCommandClassThermostatSetpoint,
    createSchemaZWaveCommandClassMultiCmd,
    createSchemaZWaveCommandClassMark,
    createSchemaZWaveCommandClassClock,
    createSchemaZWaveCommandClassClimateControlSchedule
} from "./zwave";
import Joi from "joi";
import {DeviceDefinitionZWave} from "genius-hub-types";



DeviceZWaveConfigDefaults['DA-WRV-B'] = createSchemaZWaveDeviceNonListening().append({

    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(false).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(8).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(0).required(),
        "specificDeviceClass": Joi.number().valid(4).required(),
    }),
    commandClasses: Joi.object().append({
        ...createSchemaZWaveCommandClassBattery(),
        ...createSchemaZWaveCommandClassWakeup(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatSetpoint(),
        // create...()SchemaZWaveCommandClassMultilevel,
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassMultiCmd(),
        ...createSchemaZWaveCommandClassMark(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['DA-WRV-B']),
        ...createSchemaZWaveCommandClassClock(),
        ...createSchemaZWaveCommandClassClimateControlSchedule()
    })
});

DeviceZWaveConfigDefaults['DA-WRV-C'] = createSchemaZWaveDeviceNonListening().append({

    nodeInfo: Joi.object({
        "bListening": Joi.boolean().valid(false).required(),
        "bSensor1000ms": Joi.boolean().valid(false).required(),
        "bSensor250ms": Joi.boolean().valid(false).required(),
        "basicDeviceClass": Joi.number().valid(4).required(),
        "genericDeviceClass": Joi.number().valid(8).required(),
        "optFunc": Joi.boolean().valid(true).required(),
        "protocolSpecificPart1": Joi.number().valid(83).required(),
        "protocolSpecificPart2": Joi.number().valid(28).required(),
        "protocolSpecificPart3": Joi.number().valid(0).required(),
        "specificDeviceClass": Joi.number().valid(4).required(),
    }),
    commandClasses: {
        ...createSchemaZWaveCommandClassBattery(),
        ...createSchemaZWaveCommandClassWakeup(),
        ...createSchemaZWaveCommandClassVersion(),
        ...createSchemaZWaveCommandClassThermostatSetpoint(),
        ...createSchemaZWaveCommandClassMultilevel(),
        ...createSchemaZWaveCommandClassProtection(),
        ...createSchemaZWaveCommandClassNOP(),
        ...createSchemaZWaveCommandClassMultiCmd(),
        ...createSchemaZWaveCommandClassMark(),
        ...createSchemaZWaveCommandClassManufacturerSpecific(DeviceDefinitionZWave['DA-WRV-C']),
        ...createSchemaZWaveCommandClassClock(),
        ...createSchemaZWaveCommandClassClimateControlSchedule(),
    }
});
