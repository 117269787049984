

// import notify1 from '../assets/notification.wav';




// The browser will limit the number of concurrent audio contexts
// So be sure to re-use them whenever you can
const myAudioContext = new AudioContext();


const wait = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export async function playSound(soundFile: any) {
    let sound = new Audio(soundFile);
    await sound.play();
}

export async function playNotification() {
    // await playSound(notify1);
}

/**
 * Helper function to emit a beep sound in the browser using the Web Audio API.
 *
 * @param {number} duration - The duration of the beep sound in milliseconds.
 * @param {number} frequency - The frequency of the beep sound.
 * @param {number} volume - The volume of the beep sound.
 *
 * @returns {Promise} - A promise that resolves when the beep sound is finished.
 */
export function beep(duration: number, frequency: number, volume: number){
    return new Promise((resolve, reject) => {
        // Set default duration if not provided
        duration = duration || 200;
        frequency = frequency || 440;
        volume = volume || 100;

        try{
            let oscillatorNode = myAudioContext.createOscillator();
            let gainNode = myAudioContext.createGain();
            oscillatorNode.connect(gainNode);

            // Set the oscillator frequency in hertz
            oscillatorNode.frequency.value = frequency;

            // Set the type of oscillator
            oscillatorNode.type= "square";
            gainNode.connect(myAudioContext.destination);

            // Set the gain to the volume
            gainNode.gain.value = volume * 0.01;

            // Start audio with the desired duration
            oscillatorNode.start(myAudioContext.currentTime);
            oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);

            // Resolve the promise when the sound is finished
            oscillatorNode.onended = () => {
                resolve(null);
            };
        }catch(error){
            reject(error);
        }
    });
}


export function beepPositive() {
    beep(100, 130, 100).then( () => {
        beep(100, 261, 100);
    })
}

export function beepNegative() {
    beep(100, 261, 100).then( () => {
        beep(100, 130, 100);
    })
}

export interface Note {
    f: number, // frequency (Hz)
    t?: number // duration (ms)
}

/**
 * See http://www.sengpielaudio.com/calculator-notenames.htm
 * @param pattern
 * @param volume
 * @param defaultDuration
 */
export async function playPattern(pattern: Array<Note>, volume: number, defaultDuration: number|null = null) {

    for (const idx in pattern) {
        const note = pattern[idx];
        const duration = defaultDuration ?? note.t;
        await beep(duration, note.f, volume);
    }
}

export const octave0: {[note:string]: number} = {
    'C':    16.35,
    'C#':   17.32,
    'D':    18.35,
    'D#':   19.45,
    'E':    20.6,
    'F':    21.83,
    'F#':   23.12,
    'G':    24.5,
    'G#':   25.96,
    'A':    27.5,
    'A#':   29.14,
    'B':    30.87
}

export function getNote(noteName: string, octave: number = 0): number {
    return octave0[noteName] * 2 ** octave;
}

export async function playTune(notes: Array<string>, duration: number = 25) {
    for (const note of notes) {
        if (note === ' ' || note === '') {
            await wait(50);
        } else {
            await beep(duration, getNote(note, 4), 25);
        }
    }
}



export const odeToJoy = [
    'E',  'E',  'F',  'G', 'G', 'F', 'E', 'D',
    'C',  'C',  'D',  'E', 'E', 'D', 'D', ' ', ' ',
    'E',  'E',  'F',  'G', 'G', 'F', 'E', 'D',
    'C',  'C',  'D',  'E', 'D', 'C', 'C',
    'D',  'D',  'E',  'C', 'D', 'E', 'F', 'E', 'C',
    'D',  'E',  'F',  'E', 'D', 'C', 'D', 'G',
    'E',  'E',  'F',  'G', 'G', 'F', 'E', 'D',
    'C',  'C',  'D',  'E', 'D', 'C', 'C',

]
