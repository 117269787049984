
import { POLL_TICK, actionPollComplete } from './poll';
import {actionGetHubData} from '../hubs';
import { SAVE_STATE } from '../app';
import {HubDataType} from '../hubTypes';
import {act} from "react-dom/test-utils";

let tickCount = 0;

const updateOnTick = (store:any) => (next:any) => (action:any) => {

    if (action.type === POLL_TICK) {
        tickCount += 1;

        const state = store.getState();
        let activeHubName = state.app.activeHubName;
        const now = Date.now();
        const promises:Array<Promise<any>> = [];

        if (activeHubName &&
            state.hubs.hasOwnProperty(activeHubName) &&
            !state.hubs[activeHubName].dataIsSnapshot) {

            let activeHub = null;

            // if (now < (state.app.tmNextPollDue)) {
            //     store.dispatch(actionPollComplete());
            //     return next(action);
            // }

            if (state.hubs.hasOwnProperty(activeHubName)) {
                activeHub = state.hubs[activeHubName];
/*
                if (state.app.pollTargets.zones) {
                    promises.push(store.dispatch(actionGetHubData(HubDataType.Zones, activeHubName)));
                }
                if (state.app.pollTargets.devices) {
                    promises.push(store.dispatch(actionGetHubData(HubDataType.Devices, activeHubName)));
                }
                if (state.app.pollTargets.zwave) {
                    promises.push(store.dispatch(actionGetHubData(HubDataType.ZWave, activeHubName)));
                }
                if (state.app.pollTargets.zigbee) {
                    promises.push(store.dispatch(actionGetHubData(HubDataType.Zigbee, activeHubName)));
                }
                if (state.app.pollTargets.zwaveInclusion) {
                    promises.push(store.dispatch(actionGetHubData(HubDataType.ZWaveInclusion, activeHubName)));
                }
                if (state.app.pollTargets.upgrade) {
                    promises.push(store.dispatch(actionGetHubData(HubDataType.UpgradeStatus, activeHubName)));
                }
*/
                let dataTypes = 0;
                dataTypes |= state.app.pollTargets.zones ? HubDataType.Zones : 0;
                dataTypes |= state.app.pollTargets.devices ? HubDataType.Devices : 0;
                dataTypes |= state.app.pollTargets.zwave ? HubDataType.ZWave : 0;
                dataTypes |= state.app.pollTargets.zigbee ? HubDataType.Zigbee : 0;
                dataTypes |= state.app.pollTargets.zwaveInclusion ? HubDataType.ZWaveInclusion : 0;
                dataTypes |= state.app.pollTargets.upgrade ? HubDataType.UpgradeStatus : 0;
                promises.push(store.dispatch(actionGetHubData(dataTypes, activeHubName)));

                if (state.app.pollTargets.allHubs) {
                    Object.keys(state.hubs).forEach((hubName, idx) => {
                        promises.push(store.dispatch(actionGetHubData(HubDataType.Zones, hubName)));
                    })
                }
            }
        }

        const app = store.getState().app;
        const td = (now - app.tmLastSaveState);
        // console.debug(`It was ${now} - ${app.tmLastSaveState} = ${td}ms since last save`);
        if ( td > app.intervalSaveState_ms) {
            store.dispatch({type: SAVE_STATE, payload: null});
        }

        Promise.allSettled(promises).then( () => {
            store.dispatch(actionPollComplete());
        });
    }

    return next(action)
};

export default updateOnTick;
