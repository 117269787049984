"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceDefinitionZWave = void 0;
var devices_1 = require("./devices");
exports.DeviceDefinitionZWave = {
    '013C0002000C': {
        type: devices_1.DeviceType.ZWAVE,
        id: 3,
        manfID: 316,
        productType: 2,
        productID: 12,
        sku: "PH-WRS-B-DEV",
        description: "Wireless Room Sensor"
    },
    '013C00010001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 4,
        manfID: 316,
        productType: 1,
        productID: 1,
        sku: "PH-PLG-C",
        description: "Smart Plug"
    },
    '013C00020050': {
        type: devices_1.DeviceType.ZWAVE,
        id: 5,
        manfID: 316,
        productType: 2,
        productID: 80,
        sku: "PH-WMS-B",
        description: "Wireless Motion Sensor"
    },
    '000100000001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 6,
        manfID: 1,
        productType: 0,
        productID: 1,
        sku: "PH-HUB-C",
        description: "Genius Hub"
    },
    '013C0006001A': {
        type: devices_1.DeviceType.ZWAVE,
        id: 7,
        manfID: 316,
        productType: 6,
        productID: 26,
        sku: "PH-IWM-A",
        description: "Range Extender"
    },
    '005900030001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 8,
        manfID: 89,
        productType: 3,
        productID: 1,
        sku: "HO-SCR-C",
        description: "Single Channel Receiver"
    },
    '005900030002': {
        type: devices_1.DeviceType.ZWAVE,
        id: 9,
        manfID: 89,
        productType: 3,
        productID: 2,
        sku: "HO-DCR-C",
        description: "Dual Channel Receiver"
    },
    '005900100001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 10,
        manfID: 89,
        productType: 16,
        productID: 1,
        sku: "HO-ESW-C",
        description: "Electric Switch"
    },
    '000100000002': {
        type: devices_1.DeviceType.ZWAVE,
        id: 11,
        manfID: 1,
        productType: 0,
        productID: 2,
        sku: "HO-TTP-A",
        description: "Temperature Probe (Floor/Tank)"
    },
    '000200038010': {
        type: devices_1.DeviceType.ZWAVE,
        id: 12,
        manfID: 2,
        productType: 3,
        productID: 32784,
        sku: "DA-WRT-C",
        description: "Genius Room Thermostat"
    },
    '00027FFFA010': {
        type: devices_1.DeviceType.ZWAVE,
        id: 14,
        manfID: 2,
        productType: 32767,
        productID: 40976,
        sku: "DA-WRV-C",
        description: "Genius Radiator Valve"
    },
    '019B00030203': {
        type: devices_1.DeviceType.ZWAVE,
        id: 15,
        manfID: 411,
        productType: 3,
        productID: 515,
        sku: "HI-PRT-A",
        description: "Powered Room Thermostat"
    },
    '019B00040204': {
        type: devices_1.DeviceType.ZWAVE,
        id: 16,
        manfID: 411,
        productType: 4,
        productID: 516,
        sku: "HI-WRT-D",
        description: "Wireless Room Thermostat"
    },
    '013C0002000D': {
        type: devices_1.DeviceType.ZWAVE,
        id: 18,
        manfID: 316,
        productType: 2,
        productID: 13,
        sku: "PH-WRS-B", // NB formerly WRS-D, but this does not match stickers. Device type = PST02-1B rather than PST02-1A
        description: "Wireless Room Sensor"
    },
    '013C0001000F': {
        type: devices_1.DeviceType.ZWAVE,
        id: 19,
        manfID: 316,
        productType: 1,
        productID: 15,
        sku: "PH-ESW-B",
        description: "Electric Switch"
    },
    '013C00020002': {
        type: devices_1.DeviceType.ZWAVE,
        id: 20,
        manfID: 316,
        productType: 2,
        productID: 2,
        sku: "PH-WRS-A",
        description: "Wireless Room Sensor"
    },
    '013C00010010': {
        type: devices_1.DeviceType.ZWAVE,
        id: 21,
        manfID: 316,
        productType: 1,
        productID: 16,
        sku: "PH-ERS-A",
        description: "Electric Relay"
    },
    '013C00010011': {
        type: devices_1.DeviceType.ZWAVE,
        id: 22,
        manfID: 316,
        productType: 1,
        productID: 17,
        sku: "PH-PLG-D",
        description: "Smart Plug (Single Band)"
    },
    '013C00010029': {
        type: devices_1.DeviceType.ZWAVE,
        id: 22,
        manfID: 316,
        productType: 1,
        productID: 41,
        sku: "PH-PLG-D",
        description: "Smart Plug (Single Band)"
    },
    '0059000D0002': {
        type: devices_1.DeviceType.ZWAVE,
        id: 23,
        manfID: 89,
        productType: 13,
        productID: 2,
        sku: "HO-WTS-B",
        description: "Temperature Sensor"
    },
    '005900100002': {
        type: devices_1.DeviceType.ZWAVE,
        id: 24,
        manfID: 89,
        productType: 16,
        productID: 2,
        sku: "HO-ESW-D",
        description: "Electric Switch"
    },
    '005900010003': {
        type: devices_1.DeviceType.ZWAVE,
        id: 25,
        manfID: 89,
        productType: 1,
        productID: 3,
        sku: "HO-WRT-B",
        description: "Wireless Room Thermostat"
    },
    '005900010005': {
        type: devices_1.DeviceType.ZWAVE,
        id: 26,
        manfID: 89,
        productType: 1,
        productID: 5,
        sku: "HO-WRT-DEV",
        description: "Room Thermostat"
    },
    '005900030005': {
        type: devices_1.DeviceType.ZWAVE,
        id: 27,
        manfID: 89,
        productType: 3,
        productID: 5,
        sku: "HO-SCR-D",
        description: "Single Channel Receiver"
    },
    '005900030006': {
        type: devices_1.DeviceType.ZWAVE,
        id: 28,
        manfID: 89,
        productType: 3,
        productID: 6,
        sku: "HO-DCR-D",
        description: "Dual Channel Receiver"
    },
    '00710002035D': {
        type: devices_1.DeviceType.ZWAVE,
        id: 29,
        manfID: 113,
        productType: 2,
        productID: 861,
        sku: "LS-WTS-A",
        description: "Temperature Sensor"
    },
    '008100A00001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 30,
        manfID: 129,
        productType: 160,
        productID: 1,
        sku: "SA-COS-A",
        description: "CO2 Sensor"
    },
    '000200050003': {
        type: devices_1.DeviceType.ZWAVE,
        id: 31,
        manfID: 2,
        productType: 5,
        productID: 3,
        sku: "DA-WRV-A",
        description: "Danfoss Radiator Valve"
    },
    '000200050004': {
        type: devices_1.DeviceType.ZWAVE,
        id: 32,
        manfID: 2,
        productType: 5,
        productID: 4,
        sku: "DA-WRV-B",
        description: "Danfoss Radiator Valve"
    },
    '000280042000': {
        type: devices_1.DeviceType.ZWAVE,
        id: 33,
        manfID: 2,
        productType: 32772,
        productID: 8192,
        sku: "DA-DUR-A",
        description: "Dual Underfloor Receiver"
    },
    '006000030001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 34,
        manfID: 96,
        productType: 3,
        productID: 1,
        sku: "EV-PLG-A",
        description: "Smart Plug"
    },
    '006000060001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 35,
        manfID: 96,
        productType: 6,
        productID: 1,
        sku: "EV-WHS-A",
        description: "Humidity Sensor"
    },
    '006000010002': {
        type: devices_1.DeviceType.ZWAVE,
        id: 36,
        manfID: 96,
        productType: 1,
        productID: 2,
        sku: "EV-WMS-A",
        description: "Motion Sensor"
    },
    '008600020005': {
        type: devices_1.DeviceType.ZWAVE,
        id: 37,
        manfID: 134,
        productType: 2,
        productID: 5,
        sku: "AL-WRS-C",
        description: "Room Sensor"
    },
    '008600020064': {
        type: devices_1.DeviceType.ZWAVE,
        id: 38,
        manfID: 134,
        productType: 2,
        productID: 100,
        sku: "AL-WRS-D",
        description: "Room Sensor"
    },
    '009600100001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 39,
        manfID: 150,
        productType: 16,
        productID: 1,
        sku: "NQ-UMR-A",
        description: "Gas Meter Reader"
    },
    '015411000001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 40,
        manfID: 340,
        productType: 4352,
        productID: 1,
        sku: "PO-PLG-B",
        description: "Smart Plug (Blue Light)"
    },
    '005900010013': {
        type: devices_1.DeviceType.ZWAVE,
        id: 41,
        manfID: 89,
        productType: 1,
        productID: 19,
        sku: "HO-WRT-BEANBAG",
        description: "Wireless Thermostat"
    },
    '005900010014': {
        type: devices_1.DeviceType.ZWAVE,
        id: 42,
        manfID: 89,
        productType: 1,
        productID: 20,
        sku: "HO-DCR-BEANBAG",
        description: "Dual Channel Receiver"
    },
    '005900100003': {
        type: devices_1.DeviceType.ZWAVE,
        id: 43,
        manfID: 89,
        productType: 16,
        productID: 3,
        sku: "HO-ESW-E",
        description: "Electric Switch"
    },
    '005900100004': {
        type: devices_1.DeviceType.ZWAVE,
        id: 44,
        manfID: 89,
        productType: 16,
        productID: 4,
        sku: "HO-ESW-E-TMP",
        description: "Electric Switch w/temp"
    },
    '037100020015': {
        type: devices_1.DeviceType.ZWAVE,
        id: 45,
        manfID: 881,
        productType: 2,
        productID: 21,
        sku: "AT-WRV-DEV",
        description: "AeoTec Radiator Thermostat"
    },
    '006000150001': {
        type: devices_1.DeviceType.ZWAVE,
        id: 46,
        manfID: 96,
        productType: 21,
        productID: 1,
        sku: "EV-WRV-A",
        description: "Everspring AC301 Wireless Valve"
    },
    '013C00010095': {
        type: devices_1.DeviceType.ZWAVE,
        id: 47,
        manfID: 316,
        productType: 1,
        productID: 149,
        sku: "PH-PLG-E",
        description: "Smart Plug (Dual Band)"
    },
};
var iter = Object.entries(exports.DeviceDefinitionZWave);
for (var _i = 0, iter_1 = iter; _i < iter_1.length; _i++) {
    var i = iter_1[_i];
    var k = i[0], v = i[1];
    exports.DeviceDefinitionZWave[v.sku] = v;
}
exports.default = exports.DeviceDefinitionZWave;
