import * as React from "react";

export enum ModalButtonFlag {
    PRIMARY = 1,
    SECONDARY = 2,
    LEFT = 4,
    CENTRE = 8,
    RIGHT = 16,
}

export interface ModalButton {
    label: string,
    flags?: ModalButtonFlag,
    classes?: Array<string>,
    onClick: () => void,
}

export default function Modal(props: {
    show: boolean,
    title: string,
    buttons: Array<ModalButton>,
    children: React.ReactNode
}) {

    const generateButtons = (position: ModalButtonFlag) => props.buttons
        .filter(buttonProps => (buttonProps.flags || ModalButtonFlag.RIGHT) === position)
        .map( (buttonProps, idx)  => {
            let _class = "has-background-primary-80";
            if ( ((buttonProps.flags & ModalButtonFlag.PRIMARY) === ModalButtonFlag.PRIMARY) ||
                ( (buttonProps.flags & (ModalButtonFlag.PRIMARY | ModalButtonFlag.SECONDARY) ) === 0) && idx === 0) {
                _class = "is-primary";
            }
            const classes = buttonProps.classes ? buttonProps.classes.join(' ') : `button is-rounded ${_class}`;
            return (<button key={buttonProps.label} className={classes} onClick={buttonProps.onClick} style={{marginRight: "4px"}}>
                {buttonProps.label}
            </button>);
        })
        .reverse();

    const buttonsLeft = generateButtons(ModalButtonFlag.LEFT);
    const buttonsCentre = generateButtons(ModalButtonFlag.CENTRE);
    const buttonsRight = generateButtons(ModalButtonFlag.RIGHT);

    return (<div className={`modal ${props.show ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">{props.title}</p>
            </header>

            <section className="modal-card-body">
                {props.show && props.children}
            </section>

            <footer className="modal-card-foot">
                <div className={"flow-box spread-content full-width"}>
                    <div className={"container-left"}>{buttonsLeft}</div>
                    <div className={"container-centre"}>{buttonsCentre}</div>
                    <div className={"container-right"}>{buttonsRight}</div>
                </div>
            </footer>

        </div>
    </div>);
}
