import {createHubObject, getHubProxyByHubName} from './hubsCache';
import {ConnectionStatus} from 'genius-hub-proxy'
import {blendData} from '../widgets/chart/HubChart';
import {ChartSeriesWithData, ChartSeriesParameters} from 'genius-hub-types'
import produce from 'immer';

/**********************
 * ACTION CONSTANTS   *
 *********************/
export const FETCHING_CHART_DATA = "FETCHING_CHART_DATA";
export const FETCHING_TAGS = "FETCHING_TAGS";
export const FETCHED_TAGS = "FETCHED_TAGS";
export const FETCH_TAGS_FAIL = "FETCH_TAGS_FAIL";
export const FETCHED_CHART_DATA = "FETCHED_CHART_DATA";
export const FETCH_CHART_DATA_FAIL = "FETCH_CHART_DATA_FAIL";
export const SET_CHART_STATUS = "SET_CHART_STATUS";

// export const NAMED_CHART_SELECT = "NAMED_CHART_SELECT";
// export const NAMED_CHART_FETCH_DATA = "NAMED_CHART_FETCH_DATA";
// export const NAMED_CHART_STORE_DATA = "NAMED_CHART_STORE_DATA";
// export const NAMED_CHART_FETCH_DATA_FAIL = "NAMED_CHART_FETCH_DATA_FAIL";


/**********************
 * INTERFACES         *
 *********************/
export enum ChartStatus {
    NoData,
    OK,
    FetchingData,
    ErrorTimeout,
    ErrorOther
}

export interface SavedChart {
    hubName: string,
    tsStart: number,
    tsEnd: number,
    tsCreated: number,
    chartSeries: ChartSeriesWithData
}

// const createSavedChartObject = () => ({ data: {data: [], options: {}}, tags: [] });


export interface ChartsState {
    hubChartCache: {
        [chartName: string]: SavedChart
    },
    hubTagsCache: { [hubName: string]: Array<string> },
    displayChartName: string,
    chartStatus: ChartStatus,
    tsChartOperationBegin: number
}


export function getSavedChart(chartName: string, state: ChartsState) {

    if (state.hubChartCache.hasOwnProperty(chartName)) {

    }
}


/**********************
 * ACTION CREATORS    *
 *********************/
export const actionFetchChartDataAsync = (hubName: string, tsStart: number, tsEnd: number, chartName: string, seriesOptions: ChartSeriesParameters) => {

    return async (dispatch: any, getState: any) => {
        dispatch({
            type: FETCHING_CHART_DATA,
            payload: {
                hubName,
                tsStart,
                tsEnd,
                seriesOptions
            }
        });
        // debugger

        /*
                try {
        */
        const hubProxy = await getHubProxyByHubName(hubName, getState().hubs);

        dispatch({type: SET_CHART_STATUS, payload: { status: ChartStatus.FetchingData}});

        const response = await hubProxy.hubAPI.getChartData(tsStart, tsEnd, seriesOptions);

        if (response.connectionStatus === ConnectionStatus.OK) {

            dispatch({
                type: SET_CHART_STATUS,
                payload: {
                    status: ChartStatus.OK
                }
            });

            const blentData = blendData({...response.data});

            const chart: SavedChart = {
                hubName,
                chartSeries: {
                    data: blentData,
                    params: {...seriesOptions}
                },
                tsCreated: Date.now() / 1000,
                tsStart,
                tsEnd
            };

            dispatch({
                type: FETCHED_CHART_DATA,
                payload: {
                    chartName,
                    chart
                }
            });
        } else if (response.connectionStatus === ConnectionStatus.HUB_TIMEOUT) {
            dispatch({
                type: SET_CHART_STATUS,
                payload: {
                    status: ChartStatus.ErrorTimeout
                }
            });
        } else {
            dispatch({
                type: SET_CHART_STATUS,
                payload: {
                    status: ChartStatus.ErrorOther,
                    message: ConnectionStatus[response.connectionStatus]
                }
            });
        }


        /*
                catch (err) {
                    const msg = `Error fetching chart data for ${hubName} (${err.name} - ${err.message}): ${JSON.stringify(seriesOptions)}`;
                    console.error(msg);
                    dispatch({
                        type: SET_CHART_STATUS, payload: {
                            status: ChartStatus.ErrorOther,
                            message: msg
                        }
                    });

                }
        */

    }
};


export const actionFetchTags = (hubName: string) => {

    return async (dispatch: any, getState: any) => {
        dispatch({
            type: FETCHING_TAGS,
            payload: hubName
        });

        try {
            const hubProxy = await getHubProxyByHubName(hubName, getState().hubs);

            const response = await hubProxy.hubAPI.getTags();
            dispatch({
                type: FETCHED_TAGS,
                payload: {
                    hubName,
                    tags: response.data
                }
            });

        } catch (err: any) {
            const msg = `Error fetching tag data for ${hubName} (${err.name} - ${err.message})`;
            console.error(msg);
            dispatch({
                type: FETCH_TAGS_FAIL,
                payload: msg
            });
        }
    }

};


/**********************
 * REDUCERS           *
 *********************/
export const initialState: ChartsState = {
    hubChartCache: {},
    hubTagsCache: {},
    displayChartName: null,
    chartStatus: ChartStatus.NoData,
    tsChartOperationBegin: 0
};

export default produce((state = initialState, action) => {

    switch (action.type) {

        case FETCHED_CHART_DATA: {
            state.hubChartCache[action.payload.chartName] = {...action.payload.chart};
            break;
        }

        case FETCHED_TAGS: {
            state.hubTagsCache[action.payload.hubName] = action.payload.tags;
            break;
        }

        case SET_CHART_STATUS:
            state.chartStatus = action.payload.status;
            if (action.payload.status === ChartStatus.FetchingData) {
                state.tsChartOperationBegin = Date.now();
            }
            break;

        default:
            return state;

    }
});

