import {Issue} from "genius-hub-types";


export type IssueFormatter = (issue: Issue) => string;

export type FormatType = 'text' | 'html';

export interface IssueFormatterRegistry {
    [issueID: string]: {
        [formatType: string]: IssueFormatter
    }
}

export const registry: IssueFormatterRegistry = {};

export function registerFormatter(issueID: string, formatter: IssueFormatter, format: FormatType = 'text') {
    if (!registry.hasOwnProperty(issueID)) {
        registry[issueID] = {};
    }
    registry[issueID][format] = formatter;
}

export function formatIssue(issue:Issue, format:FormatType = 'text'): string {
    if (registry.hasOwnProperty(issue.id)) {
        // @ts-ignore
        try {
            // @ts-ignore
            return registry[issue.id][format].call(this, issue);
        } catch (e) {
            console.error(`Failed to format issue ${issue.id} with ${format} formatter`, e);
            return `Error formatting issue: ${JSON.stringify(issue)}`;
        }
    }
    return `${issue.id} - ${JSON.stringify(issue.data)}`;
}
