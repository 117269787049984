import * as React from "react";
import {useState} from "react";


export function CollapsibleDiv(props: {
    startCollapsed?: boolean,
    title?: string,
    children?: any
}) {
    const {title, startCollapsed, ...children} = props;

    const [collapsed, setCollapsed] = useState(startCollapsed || false);

    return (
        <>
            <div onClick={()=>setCollapsed(!collapsed)}>{collapsed ? '[+]' : '[-]'} {title}</div>

            {!collapsed && <div {...children}>

            </div>}
        </>
    );
}
