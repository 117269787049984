import {
    LOGIN_OK,
    LOGGING_IN,
    LOGIN_FAIL,
    GETTING_HUB_DATA,
    GOT_HUB_DATA,
    GOT_HUB_DATA_FAIL,
    SET_ACTIVE_CREDENTIALS
} from './hubs';
import {LOAD, SAVE} from 'redux-storage';
import {AppFeatures, HubDataType, Access} from './hubTypes';
import {AnyAction} from "redux";
import produce from "immer";
import {useDark, useDefault} from '../style';
import defaultFeatures from '../defaultFeatures';
import {push} from "redux-first-history";

/**********************
 * ACTION CONSTANTS   *
 *********************/
export const FOCUS_GAINED = "FOCUS_GAINED";
export const FOCUS_LOST = "FOCUS_LOST";
export const FOCUS_CHECK = "FOCUS_CHECK";
export const ZONE_SELECT = "ZONE_SELECT";
export const ZONE_DETAIL_TAB_SELECT = "ZONE_DETAIL_TAB_SELECT";
export const SAVE_STATE = "SAVE_STATE";
export const SET_POLL_TARGETS = "SET_POLL_TARGETS";
export const CLEAR_POLL_TARGETS = "CLEAR_POLL_TARGETS";
export const SET_FEATURE_LEVEL = "SET_FEATURE_LEVEL";
export const SET_ACTIVE_HUB = "SET_ACTIVE_HUB";
export const SET_POLL_INTERVALS = "SET_POLL_INTERVALS";
export const SET_SETTINGS_SECTION = "SET_SETTINGS_SECTION";
export const SET_UI_OPTION = "SET_UI_OPTION";
export const SET_ENCAPSULATE_STYLE = "SET_ENCAPSULATE_STYLE";

export const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

// Features
export const HOME = 'HOME';
export const DEBUG_INFO = 'DEBUG_INFO';
export const DEVICES = 'DEVICES';
export const CHARTS = 'CHARTS';
export const SETTINGS = 'SETTINGS';
export const TEST = 'TEST';
export const HELP = 'HELP';

/**********************
 * INTERFACES         *
 *********************/

export enum AppStatus {
    OK,
    Sending,
    Receiving,
    Paused,
    Stale, //  (active but last good data older than X)
    Issue,
    Initialising
}

export enum Tab {
    Detail,
    Chart,
    Settings,
    Devices,
    Test,
    Debug
}


export enum SettingsSection {
    Welcome,
    Interface,
    ManageZones,
    AssignDevices,
    Tokens,
    WiFi,
    RIPA,
    RIPA2,
    Maintenance
}

export enum NavigationStyle {
    TopMenu,
    SideBar
}

export interface UIOptions {
    setpointsNotIntervals: boolean,
    showWholeHouseTimerbars: boolean,
    navigationStyle: NavigationStyle,
    theme: string,
    engineerIssues: boolean
}

interface kv {
    [optionName: string]: any
}

export interface AppState {
    status: AppStatus,
    features: AppFeatures,
    activeHubName: string|null,
    selectedZoneID: number|null,
    selectedTab: number,
    settingsSection: SettingsSection,
    intervalHubPoll_ms: number,
    intervalSaveState_ms: number,
    pollTargets: {
        zwave: boolean,
        zwaveInclusion: boolean,
        devices: boolean,
        zones: boolean,
        upgrade: boolean,
        zigbee: boolean,
        allHubs: boolean
    },
    loginHub: string,
    tmNextPollDue: number,
    tmLastHubPoll: number,
    tmLastSaveState: number,
    uiOptions: UIOptions
    previousLocation: string
    _previousLocation: string,
    encapsulateStyle: boolean, // put a wrapper around bulma import, used when embedding GHA2
}

/**********************
 * HELPERS            *
 *********************/

export function hasAccess(featureName: string, features: AppFeatures): boolean {
    if (features.hasOwnProperty(featureName)) {
        return features[featureName].access > Access.None;
    }
    return false;
}

/**********************
 * ACTION CREATORS    *
 *********************/
export const focusChange = (inFocus: boolean) => {
    if (inFocus) {
        return {
            type: FOCUS_GAINED,
            payload: inFocus
        };
    } else {
        return {
            type: FOCUS_LOST,
            payload: inFocus
        };
    }
};

export const actionZoneSelect = (zoneID: number) => {
    return (dispatch: any, getState: any) => {
        const state = getState();

        const path = `/home/${zoneID}/${Tab[state.app.selectedTab].toLowerCase()}`;
        dispatch(push(path));

        dispatch({
            type: ZONE_SELECT,
            payload: zoneID
        });
    };
};

export const actionDetailTabSelect = (tab: number) => {

    return (dispatch: any, getState: any) => {
        const state = getState();
        const app = state.app;

        const path = `/home/${app.selectedZoneID}/${Tab[tab].toLowerCase()}`;
        dispatch(push(path));

        dispatch({
            type: ZONE_DETAIL_TAB_SELECT,
            payload: tab
        });
    }
};

export const actionSelectPollTargets = (type: HubDataType, enable: boolean) => {
    return {
        type: SET_POLL_TARGETS,
        payload: {type, enable}
    }
};

export const actionClearPollTargets = () => {
    const result: AnyAction = {
        type: CLEAR_POLL_TARGETS,
        payload: null
    }
    return result;
};

export const actionSetFeatureLevel = (featureName: string, featureLevel: Access) => {
    return {
        type: SET_FEATURE_LEVEL,
        payload: {
            featureName,
            featureLevel
        }
    }
}

export const actionSetActiveHub = (hubName: string) => {
    return {
        type: SET_ACTIVE_HUB,
        payload: {
            hubName
        }
    }
}

export const actionSetPollIntervals = (intervalHubPoll_ms: number, intervalSaveState_ms: number) => {
    return {
        type: SET_POLL_INTERVALS,
        payload: {intervalHubPoll_ms, intervalSaveState_ms}
    }
}

export const actionSettingsSelectSection = (section: SettingsSection) => {
    return {
        type: SET_SETTINGS_SECTION,
        payload: {section}
    }
}

export const actionSetUIOption = (optionName: string, value: any) => {
    return {
        type: SET_UI_OPTION,
        payload: {optionName, value}
    }
}

export const actionSetEncapsulateStyle = () => {
    return {
        type: SET_ENCAPSULATE_STYLE,
        payload: {}
    }
}


/**********************
 * REDUCERS           *
 *********************/
export const initialState: AppState = {
    status: AppStatus.Initialising,
    features: defaultFeatures,
    activeHubName: null,
    selectedZoneID: null,
    selectedTab: 0,
    settingsSection: SettingsSection.Welcome,
    intervalHubPoll_ms: 10000,
    intervalSaveState_ms: 120000,
    pollTargets: {
        zwave: false,
        zwaveInclusion: false,
        devices: false,
        zones: false,
        upgrade: false,
        zigbee: false,
        allHubs: false
    },
    loginHub: '',
    // tmLastPollComplete: 0,
    tmLastHubPoll: 0,
    tmNextPollDue: 0,
    tmLastSaveState: Date.now(),
    uiOptions: {
        setpointsNotIntervals: false,
        showWholeHouseTimerbars: false,
        theme: 'default',
        engineerIssues: false,
        navigationStyle: NavigationStyle.SideBar
    },
    previousLocation: '',
    _previousLocation: '',
    encapsulateStyle: false
};

// export default function reduceApp(state = initialState, action:any) {
export default produce((state: AppState = initialState, action): AppState => {
    switch (action.type) {
        case FOCUS_LOST:
            state.status = AppStatus.OK;
            break;
        case FOCUS_GAINED:
            state.status = AppStatus.Paused;
            break;

        case LOGIN_OK:
            state.status = action.payload ? AppStatus.OK : AppStatus.Paused;
            state.loginHub = action.payload.username;
            break;

        case LOGGING_IN:
            state.activeHubName = action.payload.username;
            break;

        case LOGIN_FAIL:
            state.status = AppStatus.Issue;
            state.loginHub = action.payload.username;
            break;

        case SET_ACTIVE_CREDENTIALS:
            state.activeHubName = action.payload.username;
            break;

        case GETTING_HUB_DATA:
            state.status = AppStatus.Receiving;
            state.tmLastHubPoll = Date.now();
            break;

        case GOT_HUB_DATA:
            state.status = AppStatus.OK;
            state.tmNextPollDue = Date.now() + state.intervalHubPoll_ms;
            break;

        case GOT_HUB_DATA_FAIL:
            state.status = AppStatus.Issue;
            state.tmNextPollDue = Date.now() + state.intervalHubPoll_ms;
            break;

        case SAVE_STATE:
            state.tmLastSaveState = Date.now();
            break;

        case ZONE_SELECT:
            state.selectedZoneID = action.payload;
            break;

        case ZONE_DETAIL_TAB_SELECT:
            state.selectedTab = action.payload;
            break;

        case SET_POLL_INTERVALS:
            state.intervalHubPoll_ms = action.payload.intervalHubPoll_ms;
            state.intervalSaveState_ms = action.payload.intervalSaveState_ms;
            break;

        case SET_POLL_TARGETS: {
            // console.debug(`Setting poll targets:`, action)
            const pollTargets = {...state.pollTargets};
            if (action.payload.type & HubDataType.Zones) {
                pollTargets.zones = action.payload.enable;
            }
            if (action.payload.type & HubDataType.Devices) {
                pollTargets.devices = action.payload.enable;
            }
            if (action.payload.type & HubDataType.ZWave) {
                pollTargets.zwave = action.payload.enable;
            }
            if (action.payload.type & HubDataType.Chart) {
            }
            if (action.payload.type & HubDataType.UpgradeStatus) {
                pollTargets.upgrade = action.payload.enable;
            }
            if (action.payload.type & HubDataType.AllHubs) {
                pollTargets.allHubs = action.payload.enable;
            }
            if (action.payload.type & HubDataType.Zigbee) {
                pollTargets.zigbee = action.payload.enable;
            }
            if (action.payload.type & HubDataType.ZWaveInclusion) {
                pollTargets.zwaveInclusion = action.payload.enable;
            }
            // console.debug(`New poll targets: ${JSON.stringify(pollTargets)}`)
            state.pollTargets = pollTargets;
            break;
        }

        case CLEAR_POLL_TARGETS: {
            const pollTargets = {
                zwave: false,
                zwaveInclusion: false,
                devices: false,
                zones: false,
                upgrade: false,
                zigbee: false,
                allHubs: false
            };
            state.pollTargets = pollTargets;
            break;
        }

        case SET_SETTINGS_SECTION:
            state.settingsSection = action.payload.section;
            break;


        case ROUTER_LOCATION_CHANGE: {
            state.previousLocation = state._previousLocation;
            state._previousLocation = action.payload.location.pathname;
            const rePath = /home\/([0-9]+)\/?(chart|settings|schedule|devices|debug)?/;
            const match = action.payload.location.pathname.match(rePath);
            // console.debug(match)
            if (match !== null) {
                if (match.length === 2) {
                    return {...state, selectedZoneID: parseInt(match[1])};
                } else if (match.length === 3) {
                    let selectedTab = Tab.Detail;
                    if (match[2] === 'schedule') {
                        selectedTab = Tab.Detail;
                    } else if (match[2] === 'chart') {
                        selectedTab = Tab.Chart;
                    } else if (match[2] === 'settings') {
                        selectedTab = Tab.Settings;
                    } else if (match[2] === 'debug') {
                        selectedTab = Tab.Debug;
                    } else if (match[2] === 'devices') {
                        selectedTab = Tab.Devices;
                    }
                    state.selectedZoneID = parseInt(match[1]);
                    state.selectedTab = selectedTab;
                }
            } else if (action.payload.location.pathname === '/') {
                state.selectedZoneID = null;
            }
            break;
        }

        case LOAD:
            state.status = AppStatus.OK;

            if (action?.payload?.value === 'dark') {
                useDark(state?.encapsulateStyle || false);
            } else {
                useDefault(state?.encapsulateStyle || false);
            }

            if (navigator.hasOwnProperty('splashscreen')) {
                // @ts-ignore
                navigator.splashscreen.hide();
            }
            break;

        case SAVE:
            // console.debug("Saved state", action);
            break;

        case SET_FEATURE_LEVEL:
            state.features[action.payload.featureName].access = action.payload.featureLevel;
            break;

        case SET_ACTIVE_HUB:
            state.activeHubName = action.payload.hubName;
            break;

        case SET_UI_OPTION:
            const option: kv = action.payload;
            // @ts-ignore
            state.uiOptions[option.optionName] = option.value;

            if (action.payload.optionName === 'theme') {
                if (action.payload.value === 'dark') {
                    useDark(state?.encapsulateStyle || false);
                } else {
                    useDefault(state?.encapsulateStyle || false);
                }
            }
            break;

        case SET_ENCAPSULATE_STYLE:
            // NB this is not persisted! See redux/index.ts
            state.encapsulateStyle = true;
            break;

        default:
            return state;
    }
});
