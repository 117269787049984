
import {HubCacheData} from 'genius-hub-types'
import {HubAPI, HubConnectionManager, HubREST} from 'genius-hub-proxy';
import {HubCordova, HubServer} from "genius-hub-proxy";


export enum SingleHubAuthState {
    LOGGED_OUT,
    OK,
    LOGGING_IN,
    NO_TUNNEL,
    AUTH_TUNNEL,
    AUTH_HUB,
    TUNNEL_ERROR,
    HUB_NO_RESPONSE,
    HUB_ERROR,
    HUB_FATAL_ERROR,
    HUB_TIMEOUT,
    REQUEST_NOT_FOUND,
    LIBRARY_ERROR,
    UNKNOWN
}

export const SingleHubAuthEmoji = {
    [SingleHubAuthState.LOGGED_OUT]: '🔒',
    [SingleHubAuthState.OK]: '✅',
    [SingleHubAuthState.LOGGING_IN]: '⏳',
    [SingleHubAuthState.NO_TUNNEL]: '❌',
    [SingleHubAuthState.AUTH_TUNNEL]: '🔒',
    [SingleHubAuthState.AUTH_HUB]: '🔒',
    [SingleHubAuthState.TUNNEL_ERROR]: '❗',
    [SingleHubAuthState.HUB_NO_RESPONSE]: '❗',
    [SingleHubAuthState.HUB_ERROR]: '❗',
    [SingleHubAuthState.HUB_FATAL_ERROR]: '🤕',
    [SingleHubAuthState.HUB_TIMEOUT]: '❌',
    [SingleHubAuthState.REQUEST_NOT_FOUND]: '🤕',
    [SingleHubAuthState.LIBRARY_ERROR]: '‼️',
    [SingleHubAuthState.UNKNOWN]: '❓',
};


export enum PollHub {
    STOP,
    ZONES,
    DEVICES,
    ZWAVE
}



export interface HubState {
    servers: Array<HubServer>,
    _connectionManagerData: any,
    connectionStatus: SingleHubAuthState,
    connectionStatusDescription: string,
    pollState: PollHub,
    lastUpdate: number,
    data: HubCacheData
    dataIsSnapshot: boolean
}

export interface CachedHub {
    hubREST: HubREST | HubCordova,
    hubAPI: HubAPI
}

export enum HubDataType {
    Zones=1,
    Devices=2,
    ZWave=4,
    Chart=8,
    HubVersion=16,
    UpgradeStatus=32,
    AllHubs=64,
    Zigbee = 128,
    ZWaveInclusion = 256,
    Stats = 512,
    ALL = 0xFFFF
}

export type HubsCollection = { [hubName: string]: HubState };


export interface AppFeature {
    access: Access,
    params?: any,
    subFeatures?: AppFeatures
}

export interface AppFeatures {
    [featureName: string]: AppFeature
}
export enum Access {
    None,
    ReadOnly,
    Full
}
