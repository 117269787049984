import {
    HubCacheData, Issue,
    IssueLevel,
    Issues, ReleaseInfo, ZWaveNode,
} from 'genius-hub-types'
import {registerFormatter} from "./issueFormatter";
import {semanticVersionFromString} from "genius-hub-utils";
import {compareSemanticVersion} from "genius-hub-utils";


registerFormatter('hub:update', issue => `Software update available (current: ${issue.data.currentVersion}, available: ${issue.data.availableVersion})`);
registerFormatter('hub:unregistered', issue => `Hub is not registered!`);


export function detectVersionIssues(hubData: HubCacheData, releases: Array<ReleaseInfo>): Issues {

    if (!hubData) {
        return [];
    }

    const hubVer = semanticVersionFromString(hubData.version.release);

    if (!hubVer) {
        return [];
    }

    const sortedReleases = [...releases].sort( (a: ReleaseInfo, b: ReleaseInfo) => {
        const _a = semanticVersionFromString(a.version);
        const _b = semanticVersionFromString(b.version);
        return compareSemanticVersion(_a, _b);
    });
    const maxReleaseVer = sortedReleases[sortedReleases.length - 1]?.version;

    const cmp = compareSemanticVersion(hubVer, semanticVersionFromString(maxReleaseVer));

    // console.info(`Hub version: ${hubVer} min: ${minVer} cmp: ${cmp}`);

    if (cmp < 0) {
        return [
            new Issue(
                'hub:update',
                IssueLevel.INFO,
                {
                    currentVersion: hubData.version.release,
                    availableVersion: maxReleaseVer
                }
            )
        ]
    }

    return [];
}


function detectHubIsUnregistered(hubData: HubCacheData): Issues {
    // const reUnregisteredHubUsername = /^23[a-fA-F0-9]{6}$|^[a-fA-F0-9]{6}$/;
    /*
        matches
        23003768
        aabbcc
        *18:cc:23:00:37:68
        23:00:37:68
        23003768
     */
    const reUnregisteredHubUsername = /^[\*]?(?:[a-fA-F0-9]{2}:?){5}[a-fA-F0-9]{2}|(?:[a-fA-F0-9]{2}:?){3,4}$/;
    const username: string = hubData?.credentials.username || "";

    if (username.match(reUnregisteredHubUsername) !== null) {
        return [
            new Issue(
                'hub:unregistered',
                IssueLevel.WARNING,
                {

                }
            )
        ]
    }

    return [];
}

function detectHubCorruptSDCard(hubData: HubCacheData): Issues {

    const sdCardHealthy = hubData?.stats?.sd_card?.sd_healthy;

    if (sdCardHealthy === 0) {
        return [
            new Issue(
                'hub:sd_corrupt',
                IssueLevel.ERROR,
                {

                }
            )
        ]
    }

    return [];
}

function detectHubSDCardFull(hubData: HubCacheData): Issues {

    const FS_FULL_THRESHOLD = 0.9;

    const fsData = hubData?.stats?.fs_stats?.fs2 || { free: 1, total: 1 };

    const pctUsed = (fsData.total - fsData.free) / fsData.total;

    if (pctUsed >= FS_FULL_THRESHOLD) {
        return [
            new Issue(
                'hub:sd_full',
                IssueLevel.WARNING,
                {
                    diskUsage: pctUsed
                }
            )
        ]
    }

    return [];
}

function detectVersionSpecificIssues(hubData: HubCacheData): Issues {

    const nodeIDs = [];

    /*
        if (hubData.version.release === '6.1.4') {

            for (const node of hubData.zwave.nodes) {

                // get a count for PRT devices
                if (node &&
                    node.commandClasses.hasOwnProperty('COMMAND_CLASS_MANUFACTURER_SPECIFIC')
                    && node.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.hasOwnProperty('hash')
                    && (node.commandClasses.COMMAND_CLASS_MANUFACTURER_SPECIFIC.hash === '0x0000019B02030003')
                ) {
                    nodeIDs.push(node.id);
                }

            }
        }

        if (nodeIDs.length) {
            return [
                {
                    id: 'hub:config:prt',
                    level: IssueLevel.WARNING,
                    data: {
                        version: hubData.version,
                        nodeIDs
                    }
                }
            ]
        } else {
            return [];
        }

    */

    return [];
}

export function detectHubIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];
    issues = issues.concat(
        detectVersionSpecificIssues(hubData),
        detectHubIsUnregistered(hubData),
        detectHubSDCardFull(hubData),
        detectHubCorruptSDCard(hubData),
    );

    return issues;
}
