import {HubCacheData, Issue, IssueLevel, Issues, ProcessMonitor, ProcessThread, ZWaveNode} from 'genius-hub-types'


// registerFormatter('zwave:config:param0', issue => `Z-Wave node ${issue.data.nodeID} configuration has parameter 0 set!`);

function detectMissingProcessIssues(processMonitor: ProcessMonitor): Issues {
    const result = [];

    if (!processMonitor) {
        result.push(new Issue(
            'process:missing',
            IssueLevel.ERROR,
            {
                message: `Process monitor is missing`,
            })
        );
    }

    // check that all mandatory processes are present
    for (const threadName of processMonitor.mandatory_threads) {

        const thread: ProcessThread | undefined = Object.values(processMonitor.threads).find(thread => thread.thread_name === threadName);

        if (thread === undefined) {
            result.push(new Issue(
                'process_monitor:missing',
                IssueLevel.ERROR,
                {
                    message: `Process monitor is missing mandatory thread ${threadName}`,
                })
            );
        }
    }
    return result;
}


export function detectProcessMonitorIssues(hubData: HubCacheData): Issues {
    let issues: Issues = [];

    if (hubData?.process_monitor) {

        issues = issues.concat(detectMissingProcessIssues(hubData.process_monitor));

    }

    return issues;
}
