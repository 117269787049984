import * as React from 'react';
import {useState} from 'react';
import {GeniusHubIcon} from "genius-hub-icons";
import {detectIssues, formatIssue} from "genius-hub-issues";
import {useSelector} from "react-redux";
import {RootState} from "../redux";
import {AppState} from "../redux/app";
import {HubState, HubsCollection} from "../redux/hubTypes";
import {Issue, IssueLevel, Issues} from "genius-hub-types";
import {compareIssue, encodeInjectIssue, LaunchTSW, mapIssueToMetaData} from "./TSWUtils";
import {useNavigate} from "react-router-dom";
import {InjectIssueMetaData} from "./TSWTypes";
import Modal, {ModalButton, ModalButtonFlag} from "../widgets/general/Modal";
import {removeDuplicateIssues} from "../utils/issues";
import {HubServicesState} from "../redux/services";
import {webNavigate} from "../utils/webNavigate";



export function IssuesPanel(props: {
    issues: Issues,
    removeDuplicates?: boolean,
    onClick?: (issue: Issue) => void,
}) {

    const [state, setState] = useState({
        showIssueDetailModal: false,
        issue: null,
    });

    let issues = props.issues;
    if (props.removeDuplicates) {
        issues = removeDuplicateIssues(issues);
    }

    const issuePartial = (idx: number, issue: Issue) => {
        return <div key={idx} className="panel-block is-justify-content-space-between is-size-7">
            <span onDoubleClick={() => setState({showIssueDetailModal: true, issue})}>
                {formatIssue(issue)}
            </span>

            {!!props.onClick &&
                <span>
                    <a onClick={(e: any) => props.onClick(issue)}>
                        <button className={"button is-primary is-small"}>
                            <GeniusHubIcon iconName={"tsw"}/>
                            &nbsp;Fix Now!
                        </button>
                    </a>
                </span>
            }
        </div>;
    }


    const {criticalCount, errorCount, warningCount, infoCount} = issues.reduce((acc, issue, idx) => {
        return {
            criticalCount: issue.level === IssueLevel.CRITICAL ? acc.criticalCount + 1 : acc.criticalCount,
            errorCount: issue.level === IssueLevel.ERROR ? acc.errorCount + 1 : acc.errorCount,
            warningCount: issue.level === IssueLevel.WARNING ? acc.warningCount + 1 : acc.warningCount,
            infoCount: issue.level === IssueLevel.INFO ? acc.infoCount + 1 : acc.infoCount,
        };
    }, {criticalCount: 0, errorCount: 0, warningCount: 0, infoCount: 0});

    const issueElementsCritical = issues
        .filter(issue => issue.level === IssueLevel.CRITICAL)
        .sort(compareIssue)
        .map((issue, idx) => issuePartial(idx, issue));

    const issueElementsError = issues
        .filter(issue => issue.level === IssueLevel.ERROR)
        .sort(compareIssue)
        .map((issue, idx) => issuePartial(idx, issue));

    const issueElementsWarning = issues
        .filter(issue => issue.level === IssueLevel.WARNING)
        .sort(compareIssue)
        .map((issue, idx) => issuePartial(idx, issue));

    const issueElementsInfo = issues
        .filter(issue => issue.level === IssueLevel.INFO)
        .sort(compareIssue)
        .map((issue, idx) => issuePartial(idx, issue));

    const noIssues = !(criticalCount || errorCount || warningCount || infoCount);

    const detailModalButtons: Array<ModalButton> = [
        {label: 'OK', onClick: () => setState({showIssueDetailModal: false, issue: null}) },
    ];

    return (
        <div className={"full-height scrollable-area"}>

            {!!criticalCount &&
                <nav className="panel">
                    <div className="panel-block is-justify-content-sstart is-size-5 is-align-items-center">
                        <GeniusHubIcon iconName={"error"} style={{fill: 'red'}}/>
                        Critical Issues ({criticalCount})
                    </div>
                    {issueElementsCritical}
                </nav>}

            {!!errorCount &&
                <nav className="panel">
                    <div className="panel-block is-justify-content-sstart is-size-5 is-align-items-center">
                        {/*<p className="panel-heading -is-size-6">*/}
                            <GeniusHubIcon iconName={"error"} style={{fill: 'red'}}/>
                            &nbsp;
                            Errors ({errorCount})
                        {/*</p>*/}
                    </div>
                        {issueElementsError}
                </nav>}

            {!!warningCount &&
                <nav className="panel">
                    <div className="panel-block is-justify-content-sstart is-size-5 is-align-items-center">
                        <GeniusHubIcon iconName={"warning"} style={{fill: 'yellow'}} />
                        Warnings ({warningCount})
                    </div>
                    {issueElementsWarning}
                </nav>}

            {!!infoCount &&
                <nav className="panel">
                    <div className="panel-block is-justify-content-sstart is-size-5 is-align-items-center">
                        <GeniusHubIcon iconName={"info"}/>
                        Information ({infoCount})
                    </div>
                    {issueElementsInfo}
                </nav>}

            {noIssues && <nav className="panel">
                <div className="panel-block is-justify-content-sstart is-size-5 is-align-items-center">
                    <span className={"has-text-centered"}>No issues found</span>
                </div>
                {issueElementsInfo}
            </nav>}

            <Modal show={state.showIssueDetailModal} title={"Issue details"} buttons={detailModalButtons}>
                <div className={"content"}>
                    <textarea style={{width: '100%', minHeight: '300px'}} disabled={true} value={JSON.stringify(state.issue, null, 4)} />
                </div>
            </Modal>
        </div>
    );
}


export default function IssueSummary(props: {
    baseURL?: string,
    returnURL?: string,
    removeDuplicates?: boolean,
    exclude?: Array<string>
}) {

    let navigate: any = null;

    try {
        navigate = useNavigate();
    } catch (e) {
        // not in react router context!
    }

    if (props.baseURL) {
        navigate = null;
    }
    const activeHub = useSelector((state: RootState): HubState | null => {
        const activeHubName = (state.app as AppState)?.activeHubName;
        return state.hubs.hasOwnProperty(activeHubName) ? (state.hubs as HubsCollection)[activeHubName] : null;
    });
    const hubServices: HubServicesState = useSelector( (state: RootState) => {
        const activeHubName = (state.app as AppState)?.activeHubName;
        return state.services[activeHubName];
    });

    if (!activeHub?.data) return;

    let issues = detectIssues(activeHub.data, [],[], hubServices?.releases);

    if (props.exclude) {
        issues = issues.filter(issue => !props.exclude.includes(issue.id));
    }

    const handleClick = (issue: Issue) => {

        LaunchTSW(issue, activeHub, props.baseURL, props.returnURL, navigate);

    }
    return (<><IssuesPanel issues={issues} removeDuplicates={props.removeDuplicates} onClick={handleClick}/></>);
}
