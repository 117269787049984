import {Issue} from "genius-hub-types";


export function pad(num, digits=2) {
  return ("" + num).padStart(digits, '0');
}

export function hhmmss(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}
export function hhmm(secs) {
  var minutes = Math.floor(secs / 60);

  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

/**
 *
 * @param epochSeconds
 * @return string "YYYY-MM-dd HH:mm:ss"
 */
export function isoTime(epochSeconds) {

  const dt = new Date(epochSeconds * 1000);

  return `${dt.getFullYear()}-${pad(dt.getMonth()+1)}-${pad(dt.getDate())} ${pad(dt.getHours())}:${pad(dt.getMinutes())}:${pad(dt.getSeconds())}`;
}



export function lzw_encode(s) {
  var dict = {};
  var data = (s + "").split("");
  var out = [];
  var currChar;
  var phrase = data[0];
  var code = 256;
  for (var i=1; i<data.length; i++) {
    currChar=data[i];
    if (dict[phrase + currChar] != null) {
      phrase += currChar;
    }
    else {
      out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
      dict[phrase + currChar] = code;
      code++;
      phrase=currChar;
    }
  }
  out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
  for (var i=0; i<out.length; i++) {
    out[i] = String.fromCharCode(out[i]);
  }

  // console.debug(`Dict: ${JSON.stringify(dict)}`);
  const result = out.join("");
  // console.debug(`output: ${out}`);
  return result;
}

// Decompress an LZW-encoded string
export function lzw_decode(s) {
  var dict = {};
  var data = (s + "").split("");
  var currChar = data[0];
  var oldPhrase = currChar;
  var out = [currChar];
  var code = 256;
  var phrase;
  for (var i=1; i<data.length; i++) {
    var currCode = data[i].charCodeAt(0);
    if (currCode < 256) {
      phrase = data[i];
    }
    else {
      phrase = dict[currCode] ? dict[currCode] : (oldPhrase + currChar);
    }
    out.push(phrase);
    currChar = phrase.charAt(0);
    dict[code] = oldPhrase + currChar;
    code++;
    oldPhrase = phrase;
  }
  return out.join("");
}

/**
 * Sort based on multiple fields. e.g.
 *
 *    const lst = [ {type: 1, priority: 1}, {type: 2, priority: 1},{type: 1, priority: 2},{type: 2, priority: 2}];
 *    const sorted = lst.sort(fieldSortOptimized(['priority', 'type']));
 *
 * @param fields - Array of field names. Will be sorted by first field, then second, then... prepend '-' to reverse sort order
 * @returns {Function}
 */
export function fieldSorterOptimized(fields) {
  var dir = [], i, l = fields.length;
  fields = fields.map(function(o, i) {
    if (o[0] === "-") {
      dir[i] = -1;
      o = o.substring(1);
    } else {
      dir[i] = 1;
    }
    return o;
  });

  return function (a, b) {
    for (i = 0; i < l; i++) {
      var o = fields[i];
      if (a[o] > b[o]) return dir[i];
      if (a[o] < b[o]) return -(dir[i]);
    }
    return 0;
  };
}


