import {
    BatteryData,
    DataNode,
    DataValue,
    device_catalogue_zwave,
    device_catalogue_zigbee,
    DeviceType,
    ManufacturerSpecificData,
    SingleValueData,
    WakeupData, ZoneCollection, ZoneWholeHouse, ZoneMapping,
    ZWaveData,
    ZWaveNode,
    regex_zigbee_pan, regex_zwave_homeid, regex_zigbee_eui64, ZigbeeData, HubCacheData, Device
} from 'genius-hub-types'
import {parseInt} from "lodash";
import { pad } from './utils';

export {
    getHomeID,
    getZWaveDeviceDataNode,
    getZWaveNode,
    getZWaveDeviceNode,
    getDeviceManfSpecData,
    getWakeupData,
    getBatteryData,
    getAirTempData,
} from './zwave_tools'


export interface DeviceDisplayEntry {
    type: DeviceType,
    sku: string,
    nodeID: number | string,
    description: string,
    path: string,
    hash: string,
    dataNode: DataNode | null,
    deviceData?: ZWaveNode
}

export enum DeviceValueType {
    Temperature,
    Switch,
    Humidity,
    Setpoint,
    BatteryLevel
}

export interface DeviceChannelDisplayEntry {
    type: DeviceValueType,
    path: string
}

export function getDeviceValues(deviceNode: DataNode, _result: Array<DeviceChannelDisplayEntry> = []): Array<DeviceChannelDisplayEntry> {
    if (!deviceNode) return _result;

    const iter = Object.entries(deviceNode?.childValues || {});
    for (let i of iter) {
        const k = i[0], v = i[1];
        console.log(`Key: ${k} Val: ${v}`);
        const path = `${v.path}/${v.addr}`;
        switch (v.addr) {
            case 'HEATING_1':
                _result.push({
                    type: DeviceValueType.Setpoint,
                    path
                });
                break;

            case 'TEMPERATURE':
                _result.push({
                    type: DeviceValueType.Temperature,
                    path
                });
                break;

            case 'SwitchBinary':
                _result.push({
                    type: DeviceValueType.Switch,
                    path
                });
                break;

            case 'Battery':
                _result.push({
                    type: DeviceValueType.BatteryLevel,
                    path
                });
                break;

            case 'Humidity':
                _result.push({
                    type: DeviceValueType.Humidity,
                    path
                });
                break;
        }
    }

    // recurse child nodes
    const iter2 = Object.entries(deviceNode.childNodes);
    for (let i of iter2) {
        const k = i[0], v = i[1];
        getDeviceValues(v, _result);
    }
    return _result;
}


export function isZigbeeDeviceID(deviceID: string): boolean {
    if (typeof deviceID !== 'string') {
        return false;
    }
    return deviceID.match(regex_zigbee_eui64) !== null;
}

export function getNodeIDFromDevicePath(devicePath: string): number | string | null {

    const pathParts = devicePath.split('/');
    // a device path components could be:
    // site - ignore
    // 0x12345678 - zwave home id
    // ZB-760AB4DA6D8270B8 - zigbee network id
    // WeatherData
    // 0x04CD15FFFE7DCF2F - zigbee node id
    // 1 - zwave node id
    // 1/1 - zwave node id and channel
    const zwaveDevicePathRegex = /(?:site\/)?0x[A-Fa-f0-9]{8}\/([0-9]{1,3})(?:\/([0-9]{1,2}))?(?:\/([A-Za-z]{1,16}))?/;
    const zwaveMatches = devicePath.match(zwaveDevicePathRegex);
    if (zwaveMatches) {
        const nodeID = zwaveMatches[1];
        const subNodeID = zwaveMatches[2];
        const channel = zwaveMatches[3];
        return parseInt(nodeID);
    }

    const zigbeeDevicePathRegex = /(?:site\/)?(?:ZB-[A-Fa-f0-9]{16}\/)?(0x[0-9A-Fa-f]{16})(?:.*)?/;
    const zigbeeMatches = devicePath.match(zigbeeDevicePathRegex);
    if (zigbeeMatches) {
        const nodeID = zigbeeMatches[1];
        return nodeID;
    }
    return null;
}

export function normalizeZigbeeDataManager(zigbeeData: ZigbeeData): DataNode {
    const networkID = 'ZB-' + zigbeeData.network_manager.extended_pan_id.slice(2);
    return {
        addr: 'site',
        type: '',
        childValues: {},
        childNodes: {
            [networkID]: {...zigbeeData.data_manager.nodes}
        }
    }
}

export function getPanID(rootNode: DataNode): string | null {
    if (rootNode?.hasOwnProperty('addr') && rootNode.addr === 'site') {
        if (rootNode.hasOwnProperty('childNodes')) {
            const rootKeys = Object.keys(rootNode.childNodes);
            for (const i of rootKeys) {
                if (i.match(regex_zigbee_pan)) {
                    return i;
                }
            }
        }
    }
    return null;
}


export function getDataNodeFromPath(rootNode: DataNode, path: string, stack: string = ''): DataNode | null {
    const baseAddr = stack ? stack : rootNode.addr;

    for (const childNode of Object.values(rootNode.childNodes)) {
        const tryPath = baseAddr + '/' + childNode.addr;

        if (tryPath === path) {
            return childNode;
        }
    }

    for (const childNode of Object.values(rootNode.childNodes)) {
        const tryPath = baseAddr + '/' + childNode.addr;
        const result = getDataNodeFromPath(childNode, path, tryPath);

        if (result) {
            return result;
        }
    }

    return null;
}



export function getZigbeeDeviceDataNode(rootNode: DataNode, eui64: string, _panID?: string): DataNode | null {
    const panID: string = _panID || getPanID(rootNode) as string;
    if (rootNode?.hasOwnProperty('childNodes')) {
        if (rootNode.childNodes.hasOwnProperty(panID)) {
            if (rootNode.childNodes[panID].hasOwnProperty('childNodes')) {
                if (rootNode.childNodes[panID].childNodes.hasOwnProperty(eui64)) {
                    return rootNode.childNodes[panID].childNodes[eui64.toString()];
                }
            }
        }
    }
    return null;
}

export function getOpenThermDataNode(rootNode: DataNode): DataNode | null {
    if (rootNode?.hasOwnProperty('childNodes')) {
        if (rootNode.childNodes.hasOwnProperty('opentherm')) {
            return rootNode.childNodes.opentherm;
        }
    }
    return null;
}

export function getZigbeeDataNode(rootNode: DataNode | null): DataNode | null {
    const regexZigbeeDevice = /^ZB-[0-9a-fA-F]{16}$/;
    if (!rootNode) return null;

    if (rootNode?.hasOwnProperty('childNodes')) {
        const nodeAddress = Object.keys(rootNode.childNodes).find((x) => x.match(regexZigbeeDevice) !== null);
        if (nodeAddress !== undefined) {
            return rootNode.childNodes[nodeAddress];
        }
    }
    return null;
}


export function getDevicePropertyValue(deviceNode: DataNode, propertyName: string): number | string | null {
    if (deviceNode && deviceNode.hasOwnProperty('childValues')) {
        if (deviceNode.childValues.hasOwnProperty(propertyName)) {
            if (deviceNode.childValues[propertyName].hasOwnProperty('val')) {
                return deviceNode.childValues[propertyName].val;
            }
        }
    }
    return null;
}

export function getDeviceChildValue(dataNode: DataNode, childValueName: string, recurse: boolean = true): DataValue | null {

    let result = null;

    if (dataNode?.hasOwnProperty('childValues') && dataNode.childValues.hasOwnProperty(childValueName)) {
        result = dataNode.childValues[childValueName];
    } else if (recurse && dataNode?.hasOwnProperty('childNodes')) {
        const iter = Object.entries(dataNode.childNodes);
        for (let i of iter) {
            const nodeName = i[0], node = i[1];
            const intermediateResult = getDeviceChildValue(node, childValueName);
            if (intermediateResult) {
                result = intermediateResult;
                break;
            }
        }
    }

    return result;
}

export function getNodeHash(node: DataNode): string | null {
    const result = getDeviceChildValue(node, 'hash');
    if (result) {
        return result.val;
    }
    return null;
    // if (node.hasOwnProperty('childValues')) {
    //     if (node.childValues.hasOwnProperty('hash')) {
    //         return node.childValues.hash.val;
    //     }
    // }
    // return null;
}

export function formatValueDescription(valueName: string): string | null {
    const valueNames: { [prop: string]: string } = {
        'TEMPERATURE': "Measured Temperature",
        'HEATING_1': "Set Temperature",
        'SwitchBinary': "Switch Output",
        'Motion': "Occupancy Trigger"
    };
    if (valueNames.hasOwnProperty(valueName)) {
        return valueNames[valueName];
    }
    return null;
}

export interface FilterDevicesOptions {
    showDCRChannels: boolean
}

/**
 * Produce a displayable list of devices
 * See definition of DeviceDisplayEntry
 * @param hubData - root node of 'device manager'
 */
export function filterDevicesForUI(hubData: HubCacheData, options: FilterDevicesOptions | null = null): Array<DeviceDisplayEntry> {
    const result: Array<DeviceDisplayEntry> = [];
    const node = hubData.devices;
    const _options = options || {
        showDCRChannels: false
    };

    if (node === undefined || node === null) {
        return result;
    }

    if (node.addr === 'site') {
        const iter = Object.entries(node.childNodes);
        for (let i of iter) {
            const addr = i[0], childNode = i[1];
            if (addr.match(regex_zigbee_pan)) {
                filterZigbeeDevicesForUI(childNode, `site/${addr}`, result, 0, _options);
            } else if (addr.match(regex_zwave_homeid)) {
                filterZWaveDevicesForUI(childNode, `site/${addr}`, result, _options);
            }
        }
    }

    return result;
}

function filterZWaveDevicesForUI(node: DataNode,
                                        path: string = '',
                                        result: Array<DeviceDisplayEntry> = [],
                                        options: FilterDevicesOptions
) {

    if (node === undefined || node === null || node.addr === '_cfg') {
        return result;
    }

    if (parseInt(node.addr) === 1) {
        return result;
    }

    const longHash = getNodeHash(node);

    const skip = !longHash || (parseInt(node.addr) > 232) || ( node.addr.match(regex_zwave_homeid) );

    if (!skip) {
        try {
            const manfData = parseLongHash(longHash as string);
            if (!manfData) {
                console.info(`filterDevicesForUI - Cannot derive manf data for ${path}`);
            } else {
                const shortHash = deviceHash(manfData);
                const deviceDef: Device = device_catalogue_zwave[shortHash];

                if (device_catalogue_zwave.hasOwnProperty(shortHash)) {

                    // handle special case devices
                    if ((deviceDef.sku === 'HO-DCR-C') && options.showDCRChannels) {
                        result.push({
                            type: DeviceType.ZWAVE,
                            description: `${deviceDef.description} channel 1`,
                            sku: deviceDef.sku,
                            nodeID: parseInt(node.addr),
                            path: `${path}/1`,
                            hash: shortHash,
                            dataNode: node.childNodes['1'],
                        });
                        result.push({
                            type: DeviceType.ZWAVE,
                            description: `${deviceDef.description} channel 2`,
                            sku: deviceDef.sku,
                            nodeID: parseInt(node.addr),
                            path: `${path}/2`,
                            hash: shortHash,
                            dataNode: node.childNodes['2']

                        });
                    } else {
                        result.push({
                            type: DeviceType.ZWAVE,
                            description: deviceDef.description,
                            sku: deviceDef.sku,
                            nodeID: parseInt(node.addr),
                            path,
                            hash: shortHash,
                            dataNode: node

                        });
                    }
                }

                else {
                    // Commented out as this is causing the z-wave 'root' home ID node to be displayed
                    if (shortHash === "000000000000") {
                        result.push({
                            type: DeviceType.ZWAVE,
                            description: 'Unknown device type',
                            sku: '?',
                            nodeID: parseInt(node.addr),
                            path,
                            hash: 'NONE',
                            dataNode: node
                        });
                    } else {
                        console.warn(`filterDevicesForUI - Cannot find device data in zwave catalogue for ${shortHash}`);
                    }
                }
            }
        } catch (err) {
            console.error(`bad node: ${JSON.stringify(err)} - ${JSON.stringify(node)} `)
        }
    } else {
        /*
        console.info(`filterDevicesForUI - Long hash is null for ${path}/${JSON.stringify(node.addr)}`);

        if (node.addr.match(/\d{1,3}/)) {
            result.push({
                type: DeviceType.ZWAVE,
                description: 'Undefined device type',
                sku: 'NONE',
                nodeID: parseInt(node.addr),
                path,
                hash: 'NONE',
                dataNode: node
            });
        }
        */
    }

    /*
        const arrValues = Object.entries(node.childValues || {}) || [];
        for (let i of arrValues) {
            // debugger
            const valueName = i[0], value = i[1];
            const valueDescription = formatValueDescription(valueName);

            if (valueDescription === null) {
                continue;
            }

            const valueResult: DeviceDisplayEntry = {
                path: `${path}/${valueName}`,
                nodeID: parseInt(node.addr),
                isNode: false,
                description: valueDescription,
                hash: '',
                dataNode: null
            };
            result.push(valueResult)
        }
    */


    const arr = Object.entries(node.childNodes || {});
    for (let i of arr) {
        const nodeAddress = i[0], node = i[1];
        // console.log(`Key: ${k} Val: ${v}`);
        filterZWaveDevicesForUI(node, `${path}/${nodeAddress}`, result, options);
    }
}

function filterZigbeeDevicesForUI(node: DataNode,
                                         path: string = '',
                                         result: Array<DeviceDisplayEntry> = [],
                                         depth: number = 0,
                                         options: FilterDevicesOptions
) {

    if (node === undefined || node === null) {
        return result;
    }
    // console.debug(`filterDevicesForAddToZone(${node}, ${path}, ${result})`);

    // examine this node
    if (node.addr.match(regex_zigbee_pan)) {

    } else {
        const zigbeeManfName = getDeviceChildValue(node, "ManufacturerName", true)?.val;
        const zigbeeModel = getDeviceChildValue(node, "ModelIdentifier", true)?.val;
        const zigbeeHash = `${zigbeeManfName}/${zigbeeModel}`;
        // const keys = Object.keys(device_catalogue_zigbee);
        if (zigbeeManfName && zigbeeModel) {
            try {
                if (device_catalogue_zigbee.hasOwnProperty(zigbeeHash)) {
                    // @ts-ignore
                    const deviceDef: Device = device_catalogue_zigbee[zigbeeHash];
                    result.push({
                        type: DeviceType.ZIGBEE,
                        description: deviceDef.description,
                        sku: deviceDef.sku,
                        nodeID: node.addr,
                        path,
                        hash: zigbeeHash,
                        dataNode: node
                    });
                } else {
                    console.warn(`filterDevicesForUI - Cannot find device data in zigbee catalogue for ${zigbeeHash}`);
                }
            } catch (err) {
                console.error(`bad node: ${JSON.stringify(node)}`)
            }
        }
    }

    if (depth === 1) return;
    /*
        const arrValues = Object.entries(node.childValues || {}) || [];
        for (let i of arrValues) {
            // debugger
            const valueName = i[0], value = i[1];
            const valueDescription = formatValueDescription(valueName);

            if (valueDescription === null) {
                continue;
            }

            const valueResult: DeviceDisplayEntry = {
                path: `${path}/${valueName}`,
                nodeID: parseInt(node.addr),
                isNode: false,
                description: valueDescription,
                hash: '',
                dataNode: null
            };
            result.push(valueResult)
        }
    */

    const arr = Object.entries(node.childNodes || {});
    for (let i of arr) {
        const nodeAddress = i[0], node = i[1];
        // console.log(`Key: ${k} Val: ${v}`);
        filterZigbeeDevicesForUI(node, `${path}/${nodeAddress}`, result, depth + 1, options);
    }
}


export function parseLongHash(longHash: string): ManufacturerSpecificData | null {
    if (longHash?.length !== 18) {
        return null;
    }
    if (longHash?.slice(0, 2) !== '0x') {
        return null;
    }

    return {
        manfID: parseInt(longHash.slice(2, 10), 16),
        productID: parseInt(longHash.slice(10, 14), 16),
        productType: parseInt(longHash.slice(14, 18), 16),
    };
}

export function longHashToShortHash(longHash: string): string {
    const manfData = parseLongHash(longHash);
    if (manfData === null) {
        return '';
    }
    return deviceHash(manfData);
}

export function shortHashToLongHash(shortHash: string): string {

    const manfData = {
        manfID: parseInt(shortHash.slice(0, 4), 16),
        productID: parseInt(shortHash.slice(8, 12), 16),
        productType: parseInt(shortHash.slice(4, 8), 16),
    };

    const part1 = manfData.manfID.toString(16).padStart(8, '0').toUpperCase();
    const part2 = manfData.productID.toString(16).padStart(4, '0').toUpperCase();
    const part3 = manfData.productType.toString(16).padStart(4, '0').toUpperCase();

    return `0x${part1}${part2}${part3}`;
}

/**
 * Get short hash for a device
 * @param manfData
 */
export function deviceHash(manfData: ManufacturerSpecificData): string {
    const strManfID = manfData.manfID.toString(16).padStart(4, '0');
    const strProductType = manfData.productType.toString(16).padStart(4, '0');
    const strProductID = manfData.productID.toString(16).padStart(4, '0');
    return (strManfID + strProductType + strProductID).toUpperCase();
}

export function getDeviceLocations(deviceID: number | string, networkID: string, zoneData: ZoneCollection): Array<number> {
    const result: Array<number> = [];
    if (zoneData.hasOwnProperty(0)) {
        if (zoneData[0].hasOwnProperty('mappings')) {
            const zoneWholeHouse = (zoneData[0] as ZoneWholeHouse);
            const mappingZoneIDs = Object.keys(zoneWholeHouse.mappings);
            for (const mappingZoneID of mappingZoneIDs) {
                const mappings = zoneWholeHouse.mappings[parseInt(mappingZoneID)];
                for (const deviceRef of mappings) {
                    const parts = deviceRef.split('/');
                    if (parts.length >= 3) {
                        if (networkID === parts[1] && deviceID === parseInt(parts[2])) {
                            if (zoneData.hasOwnProperty(mappingZoneID)) {
                                // const zoneName = zoneData[parseInt(mappingZoneID)].strName;
                                result.push(parseInt(mappingZoneID));
                            }
                        }
                    }
                }
            }
        }
    }
    return result;
}

export function getValueLocations(path: string, zoneData: ZoneCollection): Array<number> {
    const result: Array<number> = [];
    if (zoneData.hasOwnProperty(0)) {
        if (zoneData[0].hasOwnProperty('mappings')) {
            const zoneWholeHouse = (zoneData[0] as ZoneWholeHouse);
            const mappingZoneIDs = Object.keys(zoneWholeHouse.mappings);
            for (const mappingZoneID of mappingZoneIDs) {
                const mappings = zoneWholeHouse.mappings[parseInt(mappingZoneID)];
                for (const deviceRef of mappings) {
                    if (deviceRef === path) {
                        if (zoneData.hasOwnProperty(mappingZoneID)) {
                            result.push(parseInt(mappingZoneID));
                        }
                    }
                }
            }
        }
    }
    return result;
}


export function lookupSKU(sku: string) {
    const iter = Object.entries(device_catalogue_zwave);
    for (const [shortHash, entry] of iter) {
        // @ts-ignore
        if (sku === entry.sku) {
            return {...entry, shortHash};
        }
    }
    return null;
}

/**
 * Takes a { [zoneID: number]: Array<string> } (from zone mappings) and
 * returns a { [devicePath: string] : Array<number> } mapping
 */
export function transposeZoneDevicesMappings(mappings: { [zoneID: number]: Array<string> }): { [devicePath: string]: Array<number> } {
    const result: { [devicePath: string]: Array<number> } = {};

    Object.keys(mappings).forEach(_zoneID => {
        const zoneID = parseInt(_zoneID)
        const map = mappings[zoneID];
        map.forEach(devicePath => {
            if (!result.hasOwnProperty(devicePath)) {
                result[devicePath] = [];
            }
            result[devicePath].push(zoneID);
        });
    })

    return result;
}

export function zonesMappedToDevice(devicePath: string, mappings: { [zoneID: number]: Array<string> }): Array<number> {
    const result: Array<number> = [];
    const devicePathParts = devicePath.split('/');

    Object.keys(mappings).forEach(_zoneID => {
        const zoneID = parseInt(_zoneID);
        const map = mappings[zoneID];
        map.forEach(_devicePath => {
            const _devicePathParts = _devicePath.split('/');
            // Is matching .../3 & .../37 (for example)
            // if (_devicePath.search(devicePath) !== -1) {
            //     result.push(zoneID);
            // }
            if ((devicePathParts[0] === _devicePathParts[0]) &&
                (devicePathParts[1] === _devicePathParts[1]) &&
                (devicePathParts[2] === _devicePathParts[2])) {
                result.push(zoneID);
            }
        });
    });
    // console.debug(`zonesMappedToDevice(${devicePath}, ${mappings}) = ${result}`);
    const s = new Set(result);

    return [...s];
}
