import {Access} from "./redux/hubTypes";


const featuresWifi =
    {
        DEBUG_INFO: { access: Access.None },
        HOME: { access: Access.None },
        DEVICES: {access: Access.Full},
        CHARTS: {access: Access.None,},
        SETTINGS: {
                access: Access.Full,
                subFeatures: {
                        UI: { access: Access.None},
                        ZONES: { access: Access.None},
                        ASSIGN: { access: Access.None},
                        TOKENS: { access: Access.None},
                        WIFI: { access: Access.Full},
                        RIPA: { access: Access.None},
                }
        },
        TEST: {access: Access.None},
        PROFILES: {access: Access.None},
        TOOLS: {access: Access.None},
        HELP: {access: Access.None},
        ENGINEER: {access: Access.None},
        DASHBOARD: {access: Access.None}
    };


const defaultFeatures = {
        'BASIC': { access: Access.Full },
        'PRO': { access: Access.Full },
        'ENGINEER': { access: Access.None },
        'DEVELOPER': { access: Access.None },
}

export default defaultFeatures;
