import * as React from 'react';
import {Suspense, useEffect, useReducer} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider, useSelector} from 'react-redux';

import store, {loadState, RootState, useAppDispatch} from './redux';
import {Icons} from 'genius-hub-icons'
import {ErrorBoundary} from "./widgets/components/ErrorBoundary";
import Loading from './widgets/general/Loading';
import {actionSelectPollTargets, actionSetEncapsulateStyle, actionSetPollIntervals, AppState} from "./redux/app";
import localforage from "localforage";
import {actionGetHubData, actionLogin} from "./redux/hubs";
import {ModalButton, ModalButtonFlag} from "./widgets";
import Modal from "./widgets/general/Modal";
import IssueSummary from "./TSW/IssueSummary";
import {HubState, HubDataType, HubsCollection} from "./redux/hubTypes";
import Feedback from "./Help/Feedback";
import {DeviceDefinitionZWave, Issue, IssueLevel} from "genius-hub-types";
import {useIssueCounter} from './utils/useIssueCounter';
import {actionGetReleases} from "./redux/services";
import BugReport from "./Help/BugReport";


declare global {
    interface Window {
        gha2: {
            issueCount: {
                info: number,
                warning: number,
                error: number,
                critical: number,
            }
        }
    }
}


var POPUP_TPL;

store.dispatch(actionSetEncapsulateStyle());


const excludedIssues = [
    'zwave:config_invalid',
    'device:unknown',
    'zwave:device_info_invalid',
    'zwave:mapped_nodes_missing',
    'hub:wh_shared_path',
    'config:tpi',
    'device:unknown_device_schema',
    'zone:waiting_for_temp',
    'zwave:config:esw_bad_parameters'
];

function ModalFeedback(props: {
    show: boolean,
    onClose: () => void,
}) {
    const dispatch = useAppDispatch();
    const app = useSelector((state: RootState) => state.app);

    const buttons: Array<ModalButton> = [
        {label: "OK", onClick: props.onClose},
        {label: "Cancel", onClick: props.onClose},
    ];

    useEffect(() => {
        dispatch(actionSelectPollTargets(HubDataType.Zones | HubDataType.Devices, true));
        return () => {
            dispatch(actionSelectPollTargets(HubDataType.Zones | HubDataType.Devices, false));
        }
    }, []);

    return (<Modal buttons={buttons} show={props.show} title={`Issues for ${app.activeHubName}`}>
        <Feedback/>
    </Modal>);
}

function ModalIssues(props: {
    show: boolean,
    onClose: () => void,
    returnURL: string,
}) {
    const dispatch = useAppDispatch();
    const app = useSelector((state: RootState) => state.app);

    const buttons: Array<ModalButton> = [
        {label: "OK", onClick: props.onClose}
    ];

    useEffect(() => {
        dispatch(actionGetHubData(HubDataType.Zones | HubDataType.Devices | HubDataType.ZWave | HubDataType.Zigbee));
        return () => {
            dispatch(actionSelectPollTargets(HubDataType.Zones | HubDataType.Devices, false));
        }
    }, []);

    const baseURL = 'v6/tsw.html';

    return (<Modal buttons={buttons} show={props.show} title={`Issues for ${app.activeHubName}`}>
        <IssueSummary baseURL={baseURL} returnURL={props.returnURL} removeDuplicates={true} exclude={excludedIssues}/>
    </Modal>);
}

function ModalBugReport(props: {
    show: boolean,
    onClose: () => void,
}) {
    const app = useSelector((state: RootState) => state.app);
    const buttons: Array<ModalButton> = [
        {label: "Done", onClick: props.onClose}
    ];

    return (<Modal buttons={buttons} show={props.show} title={`Bug Report from '${app.activeHubName}'`}>
        <BugReport />
    </Modal>);
}


function ModalTest(props: {
    show: boolean,
    onClose: () => void,
}) {
    const buttons: Array<ModalButton> = [
        {label: "OK", onClick: props.onClose}
    ];

    return (<Modal buttons={buttons} show={props.show} title={"TEST!"}>
        <div>Hello World</div>
    </Modal>);
}


function AppEmbedded() {
    const app = useSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();

    const [state, setState] = useReducer((state: any, action: any) => ({...state, ...action}), {
        showModal: '',
        callerURL: null
    });
    const issueCounter = useIssueCounter(true, excludedIssues);
    useEffect(() => {
        const [countInfo, countWarning, countError, countCritical] = issueCounter();
        window.gha2 = {
            ...window.gha2,
            issueCount: {
                info: countInfo,
                warning: countWarning,
                error: countError,
                critical: countCritical
            }
        }
    }, [app.tmLastHubPoll]);

    const loginToHub = async () => {
        await localforage.setDriver(localforage.INDEXEDDB);
        localforage.config({
            name: 'user'
        });
        const credentials: any = await localforage.getItem('user-credentials');

        if (credentials !== null &&
            credentials.data?.username &&
            app.activeHubName !== credentials.data.username) {
            console.debug(`Need to log in ${app.activeHubName} -> ${credentials.data.username} / ${credentials.data.signature}`);
            await dispatch(actionLogin(credentials.data, null));
        }
        dispatch(actionGetReleases(credentials?.data?.username));
        dispatch(actionGetHubData(HubDataType.Zones | HubDataType.Devices | HubDataType.ZWave | HubDataType.Zigbee | HubDataType.HubVersion));
        dispatch(actionSetPollIntervals(30 * 1000, app.intervalSaveState_ms));
        dispatch(actionSelectPollTargets(HubDataType.Zones | HubDataType.Devices | HubDataType.ZWave | HubDataType.Zigbee, true));

    };

    const handleModalEvent = (evt: any) => {
        console.debug("Received event:", evt)
        setState({
            showModal: evt.detail.modal,
            callerURL: evt.detail.callerURL
        });

        // if (evt.detail.modal === "test") {
        //     setState({showModal: true});
        // } else if (evt.detail.modal === "issues") {
        //     setState({showModalIssues: true});
        // } else if (evt.detail.modal === "feedback") {
        //     setState({showModalFeedback: true});
        // } else if (evt.detail.modal === "bug-report") {
        //     setState({showModalBugReport: true});
        // }
    }

    useEffect(() => {
        loginToHub().then();
        document.addEventListener("gha2-show-modal", handleModalEvent);
        document.addEventListener("gha2-update-login", loginToHub);

        // setPopupTPL();
        return () => {
            // console.debug("Removing listener")
            // document.removeEventListener("gha2-show-modal", handleModalEvent);
        }
    }, []);

    const handleShowModal = () => {
        setState({showModalTest: true})
    }

    const handleCloseModalTest = () => {
        setState({showModal: ''})
        console.debug("Closing...")
    }
    return (
        <>
            <Icons/>
            <Suspense fallback={<Loading/>}>

                { state.showModal === 'test' &&
                <ModalTest show={true} onClose={handleCloseModalTest}/> }

                { state.showModal === 'issues' &&
                    <ModalIssues show={true} returnURL={state.callerURL}
                             onClose={() => setState({showModal: ''})}/> }

                { state.showModal === 'feedback' &&
                    <ModalFeedback show={true} onClose={() => setState({showModal: ''})}/> }

                { state.showModal === 'bug-report' &&
                    <ModalBugReport show={true} onClose={() => setState({showModal: ''})}/> }

            </Suspense>
        </>);

}


export default function App() {

    return (
        <React.StrictMode>
            <Provider store={store}>
                <ErrorBoundary label={"App error"}>
                    <AppEmbedded/>
                </ErrorBoundary>
            </Provider>
        </React.StrictMode>
    );
}


function startApp() {
    let container = document.getElementById("gha2");

    loadState().then(() => {
        const root = createRoot(container);
        root.render(<App/>);
    });
}


function clearAppSettingsV5() {
    const store = localforage.createInstance({
        name: "nameHere"
    });
}


if (!window.cordova) {
    window.addEventListener('load', () => {
        startApp();
    });

} else {

    const started = Date.now();
    document.addEventListener('deviceready', () => {
        const finished = Date.now();
        console.debug(`It took ${finished - started}ms for the device to become ready`);
        startApp();

    }, false);
}


/** EXPORTS FOR LIBRARY **/
/** (e.g. window.gha2.[export]) **/

console.debug(`Initialising GHA2...`);
export {encodeInjectIssue} from "./TSW/TSWUtils";
export {IssueLevel};
export {DeviceDefinitionZWave};
export {HubAuth} from 'genius-hub-proxy';

