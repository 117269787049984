



export class Vector3 {

    public i: number
    public j: number
    public k: number


    constructor(x:number, y:number, z:number) {
        this.i = x || 0;
        this.j = y || 0;
        this.k = z || 0;
    }

    add(p:Vector3) {
        return new Vector3(this.i + p.i, this.j + p.j, this.k + p.k);
    }

    sub(p:Vector3) {
        return new Vector3(this.i - p.i, this.j - p.j, this.k - p.k);
    }

    scale(x:number) {
        return new Vector3(this.i * x, this.j * x, this.k * x);
    }

    mag2diff(p:Vector3) {
        return (this.i - p.i) ** 2 + (this.j - p.j) ** 2 + (this.k - p.k) ** 2;
    }

    mag() {
        return Math.sqrt(this.i * this.i + this.j * this.j + this.k + this.k);
    }

    equal(x:Vector3) {
        return this.i === x.i && this.j === x.j && this.k === x.k;
    }

    findNearestPoint(points:Array<Vector3>) {
        let nearestPt = points[0];
        let dist = nearestPt.mag2diff(this);

        for (let i = 0; i < points.length; i++) {
            if (i === 0) continue;
            const pdist = nearestPt.mag2diff(points[i]);

            if (pdist < dist) {
                nearestPt = points[i];
            }
        }

        return nearestPt;
    }

    toString() {
        return `(${this.i},${this.j},${this.k})`;
    }

}
