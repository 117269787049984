import * as React from 'react';
import {useState, useEffect, MouseEventHandler} from 'react';
import {actionSendFeedback} from "../redux/services";
import {RootState, useAppDispatch} from "../redux";
import {useSelector} from "react-redux";
import {AppState} from "../redux/app";
import {HubsCollection} from "../redux/hubTypes";
import {webNavigate} from "../utils/webNavigate";

export function BugReportWidget (props: {}) {

}

export default function BugReport(props: {
    initialValue?: number,
    onAction?: (value: number) => null
}) {

    const dispatch = useAppDispatch();
    const hub = useSelector((state: RootState) => {
        const activeHubName = (state.app as AppState)?.activeHubName;
        return (state.hubs as HubsCollection)[activeHubName]
    });

    const [state, setState] = useState({
        value: props.initialValue ?? 0,
        message: ''
    });

    const refDescription = React.useRef(null);
    const refSteps = React.useRef(null);

    const navigateAway = () => {
        window.location.href = "/";
    }

    const handleClickCancel = (evt: any) => {
        navigateAway();
    }

    const handleClickSubmit = async (evt: any) => {
        const description = refDescription.current.value;
        const steps = refSteps.current.value;

        if (hub) {
            const result = await dispatch(actionSendFeedback(hub.data.credentials.username, "bugReport", `${description}\n\n${steps}`));
            if (result) {
                setState({...state, message: "Bug report sent successfully"});
            } else {
                setState({...state, message: "Error sending bug report"});
            }
        }
        else {
            console.error("No hub selected");
        }
        // navigateAway();
    }

    return (
        <div>
            {/*<h1 className={"is-size-3"}>Bug Report from '{hub.data.credentials.username}'</h1>*/}
            <p>Please report any bugs that you find in the App.</p>
            <p>If you require assistance with an issue, then please use either
                the <a onClick={() => webNavigate(`v6/tsw.html?returnURL=${btoa(window.location.href)}`)}>Doctor</a>,
                or visit <a href={"https://www.geniushub.co.uk/support"}>https://www.geniushub.co.uk/support</a> for
                further options
            </p>
            <br/>
            <p><b>Please note that you will not receive a reply or feedback from a bug report</b>, but it will be
                recorded in order to help us improve the product in future software and firmware releases.</p>
            <br/>

            <br/>
            <div className="field">
                <label className="label">Description of the problem</label>
                <div className="control">
                    <textarea ref={refDescription} className="textarea"
                              placeholder="A particular feature is not working as expected..."/>
                </div>
            </div>

            <div className="field">
                <label className="label">Steps to reproduce the issue</label>
                <div className="control">
                    <textarea ref={refSteps} className="textarea"
                              placeholder="First I clicked on X, and then on Y...."/>
                </div>
            </div>

            <br/>

            {!!state.message &&
                <div className={"notification"}>
                    {state.message}
                </div>
            }

            <div className="field is-grouped is-justify-content-center">
                <div className="control">
                    <button className="button is-primary is-rounded is-centered" onClick={handleClickSubmit}>Submit</button>
                </div>
               {/* <div className="control">
                    <button className={"button is-danger is-rounded is-light"} onClick={handleClickCancel}>Cancel
                    </button>
                </div>*/}
            </div>


        </div>
    );
}


